<script>
const TwoFactorAuthService = {};

TwoFactorAuthService.install = function(Vue) {
  var twoFactorAuthService = new Object();

  Object.defineProperty(Vue.prototype, "$twoFactorAuthService", {
    value: twoFactorAuthService,
  });

  twoFactorAuthService.register = function(username, password) {
    var url = "2fa?action=register";
    var data = {
      username: username,
      password: password,
      type: "admin",
    };

    return Vue.axios
      .post(url, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.handleError(error);
        return Promise.reject(error);
      });
  };

  twoFactorAuthService.enable = function(username, password, otp, key) {
    var url = "2fa?action=enable";
    var data = {
      username: username,
      password: password,
      type: "admin",
      topt: otp,
      key: key,
    };

    return Vue.axios
      .post(url, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.handleError(error);
        return Promise.reject(error);
      });
  };

  twoFactorAuthService.disable = function(username, password) {
    var url = "2fa?action=disable";
    var data = {
      username: username,
      password: password,
      type: "admin",
    };

    return Vue.axios
      .post(url, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.handleError(error);
        return Promise.reject(error);
      });
  };

  twoFactorAuthService.handleError = function(error) {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      return error.response;
      // this.displayNotification('error', error.response.status + 'error')
    } else if (error.request) {
      // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      console.log("error.request ", error.request);
      return error.request;
    } else {
      // Something happened in setting up the request that triggered an Error
      // this.displayNotification('error', error.message, true)
    }
  };
  twoFactorAuthService.displayNotification = function(
    type,
    message,
    redirect = false
  ) {
    Vue.awn.alert(message, "Error");
    if (redirect) {
      this.$router.push("/login");
    }
  };
};
export default TwoFactorAuthService;
</script>
