var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"document-viewer"},[(_vm.file.contentType.includes('image'))?_c('img',{attrs:{"src":_vm.file.url,"alt":"","className":"img-fluid","width":"20%"},on:{"click":function($event){return _vm.openModal('image')}}}):_vm._e(),(
            !_vm.file.contentType.includes('image') &&
                !_vm.file.contentType.includes('application/octet-stream')
        )?_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.openModal('pdf')}}},[_vm._v(" Click to view document ")]):_vm._e(),(_vm.file && _vm.file.contentType.includes('application/octet-stream'))?_c('span',[_vm._v(" No preview available ")]):_vm._e(),_c('br'),_c('p',{staticClass:"uploaded-image-name"},[_vm._v(_vm._s(_vm.file.fileName))]),(_vm.file && _vm.file.contentType.includes('application/octet-stream'))?_c('a',{attrs:{"href":_vm.file.url,"target":"_blank","rel":"noreferrer","className":"btn btn-primary"}},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v(" Download Image ")])],1):_vm._e(),(
            _vm.file &&
                !_vm.file.contentType.includes('image') &&
                !_vm.file.contentType.includes('application/octet-stream')
        )?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.downloadFile()}}},[_vm._v(" Download "+_vm._s(_vm.file.contentType.includes("image") ? "Image" : "Document")+" ")]):_vm._e(),(_vm.file && _vm.file.status === true)?_c('b-button',{on:{"click":function($event){return _vm.archiveImage(_vm.file)}}},[_vm._v(" "+_vm._s(_vm.archiveBtnTxt)+" "+_vm._s(_vm.file.contentType.includes("image") ? "Image" : "Document")+" ")]):_vm._e(),_c('b-modal',{attrs:{"title":_vm.file.fileName,"hide-footer":"","centered":""},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[(_vm.type == 'image')?_c('img',{attrs:{"src":_vm.file.url,"alt":"","className":"img-fluid","width":"100%"}}):_vm._e(),(_vm.type == 'pdf')?_c('embed',{staticClass:"pdf-viewer",attrs:{"src":_vm.file.url + '#toolbar=0',"type":"application/pdf","width":"100%","height":"100%"}}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }