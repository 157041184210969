<template>
    <orders-table :title="title" :orders="orders" :page="currentPage" :action="action" :click-event="clickEvent"
        :column-names="columnNames" :search-action="searchAction"></orders-table>
</template>
  
<script>

import OrdersTable from '../../components/OrdersTable'
import OrderEventBus from '../../EventBus.js'

export default {
    components: {
        OrdersTable
    },
    props: {
        types: {
            default: () => ['FORM', 'BOOKING', 'TRAVEL', 'PRESCRIPTION_ONLY']
        }
    },
    data: function () {
        return {
            title: 'Pharmacy Orders',
            orders: {},
            currentPage: 1,
            action: "<button class='btn btn-sm btn-outline-primary'>Open Order</button>",
            columnNames: ['id', 'creationDate', 'status', 'isRepeatOrder', 'description', 'patient', 'platform', 'consultedBy', 'consultedPharmacistName', 'consultedPharmacyEmail', 'consultedPharmacyPhone', 'pharmacy', 'actions'],
            clickEvent: 'open-order-click',
            newOrderSound: new Audio('/static/message.mp3'),
            timer: '',
            searchAction: "search-order-click"
        }
    },
    mounted() {
        this.getOrders()
        OrderEventBus.$on('currentPage', (currentPage) => {
            this.currentPage = currentPage;
        })
        OrderEventBus.$on(this.clickEvent, (orderId) => {
            this.$router.push('/order/' + orderId)
        })
        OrderEventBus.$on(this.searchAction, (orderId) => {
            this.searchOrder(orderId)
        })
    },
    watch: {
        currentPage: function () {
            this.getOrders();
        },
        types: function () {
            this.getOrders(true);
        }
    },
    methods: {
        getOrders(muted = false) {
            this.$orderService.getAllOrders(this.currentPage - 1, 10, this.types).then(orders => {
                if (!muted && this.orders.totalElements < orders.totalElements) {
                    this.newOrderSound.play();
                }
                this.orders = orders;
                this.timer = setTimeout(this.getOrders, 60000);
            }).catch(error => {
                console.error(error);
                this.$awn.alert('There was an error getting orders');
            });
        },

        searchOrder(searchOrderId) {
            if (searchOrderId === "") {
                this.getOrders();
                return;
            }

            this.$orderService.searchOrder(searchOrderId, this.types).then(orders => {
                this.orders = {
                    content: [orders]
                };
            }).catch(error => {
                console.error(error);
                this.orders = { content: [] };
                this.$awn.alert('There was an error getting orders');
            });
        }
    },
    beforeDestroy() {
        clearTimeout(this.timer);
        OrderEventBus.$off();
    }
}

</script>