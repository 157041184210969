<template>
  <PatientAppointment />
</template>

<script>

import PatientAppointment from './PatientAppointment.vue';

export default {
  name: 'BookingAppointment',

  components: {
    PatientAppointment
  },
}
</script>
