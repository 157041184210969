
<script>

const PlatformService = {}

PlatformService.install = function (Vue) {

  var platformService = {}

  Object.defineProperty(Vue.prototype, '$platformService', { value: platformService })

  platformService.getPlatform = function (id) {
    var url = '/api/admin/platform/' + id
    return Vue.axios.get(url)
      .then(response => { return response.data })
      .catch(error => {
        return this.handleError(error)
      })
  }

  platformService.queryPharmacies = function (gphc, q, page, size) {
    var url = '/api/admin/pharmacy/search?page=' + page + '&size=' + size + (gphc ? '&gphc=' + gphc : '') + (q ? '&q=' + q : '')
    return Vue.axios.get(url)
      .then(response => response.data)
      .catch(error => {
        this.handleError(error)
        return Promise.reject(error)
      })
  }

  platformService.getPlatformPharmacies = function (id, page, size) {
    var url = '/api/admin/pharmacy/search?p=' + id + '&page=' + page + '&size=' + size
    return Vue.axios.get(url)
      .then(response => response.data)
      .catch(error => {
        this.handleError(error)
        return Promise.reject(error)
      })
  }

  platformService.createPlatform = function (platform) {
    var url = '/api/admin/platform'
    return Vue.axios.post(url, platform)
      .then(response => { return response.data })
      .catch(error => {
        this.handleError(error)
        return Promise.reject(error)
      })
  }

  platformService.editPlatform = function (platform) {
    var url = '/api/admin/platform/' + platform.id
    return Vue.axios.post(url, platform)
      .then(response => { return response.data })
      .catch(error => {
        this.handleError(error)
        return Promise.reject(error)
      })
  }

  platformService.search = function (searchQuery, page = 0, size = 10) {
    var url
    if (searchQuery) {
      url = '/api/admin/platform/search?q=' + searchQuery + '&page=' + page + '&size=' + size
    } else {
      url = '/api/admin/platform/search?page=' + page + '&size=' + size
    }
    return Vue.axios.get(url)
      .then(response => {
        let data = response.data
        data.content = data.content.map(c => {
          c.pharmacyCount = c.pharmacyIds.length
          return c
        })
        return data
      }
      )
      .catch(error => {
        this.handleError(error)
        return Promise.reject(error)
      })
  }

  platformService.handleError = function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    } else if (error.request) {
      // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      // Vue.$router.push('/login')
      console.log('error.request ', error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      // Vue.$router.push('/login')
      console.log('error', error.request)
    }
  }
}

export default PlatformService
</script>