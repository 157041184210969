<template>
    <div>
        <template v-if="!!files.images.length || !!files.documents.length">
            <div v-if="!!files.documents.length" class="documents-container">
                <a v-for="document in files.documents" :key="document.id" :href="document.url" target="_blank" rel="noopener noreferrer">
                    <img src="/static/vectors/pdf-icon.svg" alt=""/>
                    <h6>{{document[nameField]}}</h6>
                </a>
            </div>
            <div v-if="!!files.images.length" class="images-container">
                <div v-for="image in files.images" :key="image.id">
                    <h4>{{image[nameField]}}</h4>
                    <img :src="image.url" alt=""/>
                </div>
            </div>
        </template>
        <template v-else>
            No files found.
        </template>
    </div>
</template>

<script>

export default {
    props: {
        files: {
            type: Object,
            required: true,
        },
        nameField: {
            type: String,
            required: true,
        },
    }
};
</script>

<style lang="scss" scoped>
.documents-container {
    margin-bottom: 1.5rem;
    display: flex;
    width: 7rem;
    gap: 0.5rem;
    flex-wrap: wrap;
    text-align: center;
    color: black;
    
    &.hover {
        text-decoration: none;
    }

    img {
        width: 100%;
    }

    h6 {
        margin-bottom: 0;
    }
}

.images-container {
    img {
        width: 100%;
        margin-bottom: 2rem;
        border-radius: 0.8rem;
    }
}
</style>
