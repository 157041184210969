<template>
    <div class="note-container" id="print-content">
        <div class="print-only">
            <div>
                <b-row>
                    <b-col class="mt-5">
                        <img src="/static/img/logo.png" style="width: 25em; height: 7em;" />
                    </b-col>
                    <b-col class="text-center">
                        <h3>Private and Confidential</h3>
                    </b-col>
                    <b-col class="text-right smaller-paragraphs mt-5">
                        <p>Coventry University Technology Park,</p>
                        <p>The TechnoCentre,</p>
                        <p>Puma Way,</p>
                        <p>CV1 2TT Coventry</p>
                        <p>Prescriber Number: 09359853</p>
                        <p>Tel: 0247 509 8777</p>
                        <p>Email: Medical@theGPservice.co.uk</p>
                    </b-col>
                </b-row>
                <div class="clear"></div>
                <div>
                    <br />
                    <h3>Dear Dr.</h3>
                    <p>
                        A patient that is registered with your practice has
                        recently had a private consultation with one of our GPs.
                        The patient requested that a summary of this
                        consultation, whose details are below, be shared with
                        you in order to update their medical records. Should you
                        have any questions, please contact a member of our team
                        on 0203 105 0352.
                    </p>
                </div>
            </div>
        </div>
        <div class="no-print">
            <b-button class="float-right pt-2" id="print-btn" size="sm" variant="outline-primary" @click="print()">Print</b-button>
        </div>
        
        <template v-if="'doctorComments' in gpNote">
            <h3>Doctor comments</h3>
            <p>{{gpNote.doctorComments}}</p>
        </template>

        <template v-else>
            <b>
                <b-row :no-gutters="true">
                    <b-col class="pr-3">Question</b-col>
                    <b-col cols="2">Answer</b-col>
                    <b-col cols="4" class="pl-3">Description</b-col>
                </b-row>
            </b>

            <div v-for="section in questionnaire" :key="section.title" :class="getPageBreak(section.step)">
                <h3>{{ section.title }}</h3>
                <template v-for="questionItem in section.questions">
                    <div :key="questionItem.questionId">
                        <b-row :no-gutters="true" class="mb-4">
                            <template v-if="!!gpNote[questionItem.questionId].choices && !!gpNote[questionItem.questionId].choices.length && !gpNote[questionItem.questionId].question">
                                <b-col class="pr-3">{{ gpNote[questionItem.questionId].choices[0].label }}</b-col>
                                <b-col cols="2">
                                    <span v-if="gpNote[questionItem.questionId].choices[0].checked" class="pill yes">
                                        Yes
                                    </span>
                                    <span v-else class="pill no">No</span>
                                </b-col>
                                <b-col cols="4" class="pl-3">
                                    {{gpNote[questionItem.questionId].choices[0].text ? gpNote[questionItem.questionId].choices[0].text : "-"}}
                                </b-col>
                            </template>

                            <template v-else-if="!!gpNote[questionItem.questionId].choices && !!gpNote[questionItem.questionId].choices.length">
                                <b-col class="pr-3">{{ questionItem.question }}</b-col>
                                <b-col cols="6">
                                    <b-row v-for="choiceItem in gpNote[questionItem.questionId].choices"
                                        :key="choiceItem.id"
                                        :no-gutters="true"
                                        class="mb-1"
                                    >
                                        <b-col cols="4">{{ choiceItem.label }}</b-col>
                                        <b-col class="pl-3">{{ !!choiceItem.text ? choiceItem.text : "-" }}</b-col>
                                    </b-row>
                                </b-col>
                            </template>

                            <template v-else-if="!!gpNote[questionItem.questionId].choice">
                                <b-col class="pr-3">{{ questionItem.question }}</b-col>
                                <b-col cols="2">
                                    <span v-if="gpNote[questionItem.questionId].choice"
                                        class="pill yes"
                                    >
                                        Yes
                                    </span>
                                    <span v-else class="pill no">No</span>
                                </b-col>
                                <b-col cols="4" class="pl-3">
                                    {{ !!gpNote[questionItem.questionId].text ? gpNote[questionItem.questionId].text : "-"}}
                                </b-col>
                            </template>

                            <template v-else>
                                <b-col class="pr-3">{{ questionItem.question }}</b-col>
                                <b-col cols="2">-</b-col>
                                <b-col cols="4" class="pl-3">
                                    {{ !!gpNote[questionItem.questionId].text ? gpNote[questionItem.questionId].text : "-" }}
                                </b-col>
                            </template>
                        </b-row>
                        <hr />
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import { GP_QUESTIONNAIRE } from "../util/questionnaires.js";

export default {
    data() {
        return {
            questionnaire: undefined,
        };
    },
    props: {
        gpNote: {
            type: Object,
            required: true,
        },
    },
    created() {
        this.questionnaire = GP_QUESTIONNAIRE.map((questionItem) => {
            const filteredQuestions = questionItem.questions.filter(
                (question) => question.questionId in this.gpNote
            );

            return {
                title: questionItem.title,
                questions: filteredQuestions,
                step: questionItem.step
            };
        });
    },
    methods: {
        print() {
            const printContents = document.getElementById('print-content').innerHTML;

            let stylesHtml = "";
            for (const node of [
                ...document.querySelectorAll('link[rel="stylesheet"], style'),
            ]) {
                stylesHtml += node.outerHTML;
            }
            if (stylesHtml) {
                const WindowPrint = window.open(
                    "",
                    "",
                );

                WindowPrint.document.write(`<!DOCTYPE html>
                <html>
                    <head>
                        ${stylesHtml}
                    </head>
                    <body>
                        ${printContents}
                    </body>
                </html>`);

                const printBtn = WindowPrint.document.getElementById('print-btn');
                printBtn.style.display = 'none';

                WindowPrint.document.close();

                const checkCSSApplication = () => {
                    const styleSheets = WindowPrint.document.styleSheets;
                    const isCSSApplied = [...styleSheets].every(sheet => sheet.cssRules.length > 0);

                    if (isCSSApplied) {
                        WindowPrint.onload = null;

                        const beforeUnloadHandler = () => {
                            WindowPrint.close();
                        };

                        WindowPrint.addEventListener("afterprint", beforeUnloadHandler);

                        WindowPrint.focus();
                        WindowPrint.print();

                        const waitForPrintDialogClosed = setInterval(() => {
                            if (WindowPrint.closed) {
                                clearInterval(waitForPrintDialogClosed);
                                WindowPrint.removeEventListener('afterprint', beforeUnloadHandler);
                            }
                        }, 100);
                    } else {
                        setTimeout(checkCSSApplication, 100);
                    }
                };

                WindowPrint.onload = checkCSSApplication;
            }
        },
        getPageBreak(step){
            switch (step) {
                case 2:
                    return 'page-break-before'
                case 3:
                    return 'page-break-before'
            
                default:
                    break;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.note-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    background-color: white;

    .pill {
        width: 2rem;
        display: block;
        text-align: center;
        border-radius: 5px;

        &.yes {
            background-color: #c8a6ff;
        }

        &.no {
            background-color: #ff8f90;
        }
    }
}

.smaller-paragraphs {
    p {
        margin: 0;
    }
}

.print-only {
    display: none;
}

@media print {
    .page-break-before {
      page-break-before: always;
    }
    
    .page-break-after {
      page-break-after: always;
    }
  
    .print-only {
        display: block;
    }

    .no-print {
        display: none;
    }


}

@page {
    size: auto; /* Set the size of the pages, e.g., "auto" or specific dimensions */
    margin: 2rem !important; /* Set the margins for all pages */
}
</style>
