<template>
    <div v-if="isLoading" class="spinner-overlay">
        <div style="display: flex; align-items: center; flex-direction: column">
            <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
            <div style="color: white; font-size: 30px; margin-top: 5%;">{{ text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            required: true
        },
        text: {
            type: String,
            required: true
        }
    }
};
</script>

<style>
.spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0 0 0 / 80%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.fa-circle-o-notch {
    font-size: 50px;
    color: #fff;
}
</style>