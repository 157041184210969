<template>
  <b-card>

    <div slot="header">
      <i class='fa fa-align-justify'></i> All Prescribers
    </div>

    <b-modal v-model="addPrescriberModal" title="Add Prescriber" centered size="xl" ok-title="Confirm" ok-variant="primary" @ok="validateAndAddPrescriber()">
      <b-row>
      <b-col md="12" lg="8">
        <h4>Prescriber Information</h4>
        <b-form-group id="newName" label="Name *">
          <b-form-input data-vv-scope="newPrescriber" type="text" v-validate="{ required: true }" v-model="newPrescriber.name" name="name"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('newPrescriber.name') }}&nbsp;</span>
        </b-form-group>
        <b-row>
        <b-col cols="6">
        <b-form-group id="newEmail" label="Email *">
          <b-form-input data-vv-scope="newPrescriber" type="text" v-validate="{ required: true }" v-model="newPrescriber.email" name="email"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('newPrescriber.email') }}&nbsp;</span>
        </b-form-group>
        <b-form-group id="newPhone" label="Phone *">
          <b-form-input data-vv-scope="newPrescriber" type="text" v-validate="{ required: true }" v-model="newPrescriber.phone" name="phone"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('newPrescriber.phone') }}&nbsp;</span>
        </b-form-group>
        <p class="mt-4 pt-2 text-muted">Fields marked with * are required</p>
        </b-col>
        <b-col cols="6">
        <b-form-group id="newLogo" label="Logo *">
          <div class="border mb-1" style="width: 100%; height: 150px; display: flex; justify-content: center; align-content: center;">
            <img v-if="Boolean(logoFile)" style="max-width: 100%; max-height: 100%; object-fit: contain;" :src="urlOf(logoFile)">
          </div>
          <b-form-file data-vv-scope="newPrescriber" accept="image/png, image/jpeg" v-validate="{ required: true }" v-model="logoFile" :state="Boolean(logoFile)" name="logoUrl"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('newPrescriber.logoUrl') }}&nbsp;</span>
        </b-form-group>
        </b-col>
        </b-row>
      </b-col>
      <b-col md="12" lg="4">
        <h4>Address</h4>
        <b-form-group id="newAddressLine1" label="Line 1 *">
          <b-form-input data-vv-scope="newPrescriber" type="text" v-validate="{ required: true }" v-model="newPrescriber.address.line1" name="address_line_1"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('newPrescriber.address_line_1') }}&nbsp;</span>
        </b-form-group>
        <b-form-group id="newAddressLine2" label="Line 2">
          <b-form-input data-vv-scope="newPrescriber" type="text" v-validate="{ required: false }" v-model="newPrescriber.address.line2" name="address_line_2"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('newPrescriber.address_line_2') }}&nbsp;</span>
        </b-form-group>
        <b-form-group id="newAddressCity" label="City *">
          <b-form-input data-vv-scope="newPrescriber" type="text" v-validate="{ required: true }" v-model="newPrescriber.address.city" name="address_city"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('newPrescriber.address_city') }}&nbsp;</span>
        </b-form-group>
        <b-form-group id="newAddressCounty" label="County">
          <b-form-input data-vv-scope="newPrescriber" type="text" v-validate="{ required: false }" v-model="newPrescriber.address.county" name="address_county"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('newPrescriber.address_county') }}&nbsp;</span>
        </b-form-group>
        <b-form-group id="newAddressPostcode" label="Postcode *">
          <b-form-input data-vv-scope="newPrescriber" type="text" v-validate="{ required: true }" v-model="newPrescriber.address.postcode" name="address_postcode"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('newPrescriber.address_postcode') }}&nbsp;</span>
        </b-form-group>
      </b-col>
      </b-row>
    </b-modal>

    <b-modal v-model="newImageModal" title="Update Image" size="md" :ok-disabled="uploadingImage" :cancel-disabled="uploadingImage" :ok-title="uploadingImage ? 'Uploading, Please Wait...' : 'Confirm'" ok-variant="primary" @show="resetImageModal($event)" @ok="putImage($event)" no-close-on-backdrop no-close-on-esc hide-header-close>
      <b-row>
        <b-col>
          <b-form-group id="newImg" label="Select Image">
          <div class="border mb-1" style="display: flex; justify-content: center; align-items: center; height: 150px;">
            <img v-if="Boolean(newImage)" style="max-width: 100%; max-height: 100%; object-fit: contain;" :src="urlOf(newImage)">
          </div>
          <b-form-file data-vv-scope="img" accept="image/png, image/jpeg" v-validate="{ required: true }" v-model="newImage" :state="Boolean(newImage)" name="file"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('img.file') }}&nbsp;</span>
        </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal v-model="previewImageModal" hide-footer title="Preview" size="md">
      <b-row>
        <b-col>
          <div class="border" style="display: flex; justify-content: center; align-items: center; height: 150px;">
            <img v-if="Boolean(previewImage)" style="max-height: 100%; max-width: 100%; object-fit: contain;" :src="previewImage">
          </div>
        </b-col>
      </b-row>
    </b-modal>
    

    <b-row class="form-group">
      <b-col sm="12" md="2" offset-md="7">
        <b-button block variant="primary" class="btn--2" v-on:click="addPrescriberModal = true">Add Prescriber</b-button>
      </b-col>
      <b-col sm="12" md="3">
        <b-input-group size="md">
          <input type="search" class="form-control" id="searchQuery" v-model="searchQuery" @search="search()">
          <b-input-group-append>
            <b-button block variant="primary" class="btn--2" :disabled="!searchQuery" v-on:click="search()"><i class="fa fa-search"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <b-pagination align="center" size="sm" :total-rows="prescribers.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>

    <b-table :items="prescribers.content" :fields="columnNames" :current-page="1" :per-page="perPage" hover outlined show-empty responsive>
      <template v-slot:cell(active)="row">
        <b-form-checkbox block variant="primary" v-model="row.value" :disabled="true" />
      </template>
      <template v-slot:cell(id)="row">
        <b-button variant="light">{{row.value}}</b-button>
      </template>
      <template v-slot:cell(name)="row">
        <span v-if="row.value">{{row.value}}</span>
        <span v-if="!row.value">N/A</span>
      </template>
      <template v-slot:cell(phone)="row">
        <span v-if="row.value">{{row.value}}</span>
        <span v-if="!row.value">N/A</span>
      </template>
      <template v-slot:cell(email)="row">
        <span v-if="row.value">{{row.value}}</span>
        <span v-if="!row.value">N/A</span>
      </template>
      <template v-slot:cell(address)="row">
        <span v-if="row.value.line1">{{row.value.line1}},</span>
        <span v-if="row.value.line2"><br/>{{row.value.line2}},</span>
        <span v-if="row.value.city"><br/>{{row.value.city}}</span>
        <span v-if="row.value.county">,&nbsp;{{row.value.county}}</span>
        <span v-if="row.value.postcode">&nbsp;{{row.value.postcode}}</span>
      </template>
      <template v-slot:cell(logoUrl)="row">
        <b-button variant="primary" size="sm" v-if="row.value" @click="previewImageModal = true; previewImage = row.value;">Preview</b-button>
        <b-button variant="dark" size="sm" @click="newImageModal = true; newImagePath = row.value;">{{row.value ? 'Change' : 'Add'}}</b-button>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button variant="primary" size="sm" center :to="{ path: '/prescriber/' + row.item.id}">Edit Prescriber</b-button>
      </template>
    </b-table>

    <b-pagination align="center" size="sm" :total-rows="prescribers.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>

    <br>

  </b-card>
</template>

<script>

export default {
  data () {
    return {
      searchQuery: '',
      addPrescriberModal: false,
      uploadingImage: false,
      newImageModal: false,
      previewImageModal: false,
      previewImage: null,
      newImage: null,
      newImagePath: null,
      prescribers: [],
      newPrescriber: {
        name: '',
        logoUrl: '',
        phone: '',
        email: '',
        address: {
          line1: '',
          line2: '',
          city: '',
          county: '',
          postcode: '',
        }
      },
      editedPrescriber: {
        name: '',
        logoUrl: '',
        phone: '',
        email: '',
        address: {
          line1: '',
          line2: '',
          city: '',
          county: '',
          postcode: '',
        }
      },
      columnNames: [
        {key: 'id', label: 'ID'},
        'name',
        {key: 'logoUrl', label: 'Logo'},
        'phone',
        'email',
        'address',
        {key: 'actions', label: 'Action'}
      ],
      currentPage: 1,
      perPage: 10,
      logoFile: null
    }
  },
  created () {
    this.search()
  },
  watch: {
    currentPage: function () {
      this.search()
    }
  },
  methods: {
    urlOf (file) {
      return URL.createObjectURL(file)
    },
    search () {
      return this.$prescriberService.search(this.searchQuery, this.currentPage - 1, this.perPage).then(prescribers => {
        this.prescribers = prescribers
        console.log(prescribers)
      }).catch(error => {
        this.$awn.alert(error, 'An error occurred')
      })
    },
    resetImageModal (event) {
      console.log(event);
      this.newImage = null
      this.previewImage = null
    },
    validateAndAddPrescriber () {
      this.$validator.validateAll("newPrescriber").then((success) => {
        if (success) {
          this.addPrescriber()
        } else {
          this.$awn.alert('Please check the form above for any missing fields or errors.')
        }
      })
    },
    getRandomString () {
      var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      var result = ''
      for (var i = 0; i < 4; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length))
      }
      return result
    },
    putImage (event) {
      event.preventDefault();
      this.$validator.validateAll("img").then((success) => {
        if (success) {
          this.uploadingImage = true;
          var key = this.newImagePath.match(/(.*?\/)(img\/.+)$/)[2]
          this.$fileService.uploadFile(this.newImage, key).then(async (res) => {
            console.log(res);
            await this.search()
            this.$awn.success('Image Updated')
          }).catch(error => {
            console.error(error);
            this.$awn.alert(error, 'An error occurred')
          }).finally(() => {
            this.uploadingImage = false;
            this.newImageModal = false;
            this.resetImageModal();
          })
        } else {
          this.$awn.alert('Please select a valid image to upload before you continue.')
        }
      });
    },
    addPrescriber () {
      var randId = this.getRandomString()
      var logoPath = `img/prescriber-${randId}-logo`
      this.newPrescriber.logoUrl = `https://static.thegpservice.co.uk/${logoPath}`
      this.$prescriberService.createPrescriber(this.newPrescriber).then(res => {
        console.log(res);
        this.$fileService.uploadFile(this.logoFile, logoPath).then(async (res2) => {
          console.log(res2);
          this.searchQuery = ''
          this.logoFile = null
          await this.search()
          this.$awn.success('Prescriber Added')
        })
      }).catch(error => {
        console.error(error);
        this.$awn.alert(error, 'An error occurred')
      })
    },
    preventNumberExponential (event) {
      var iKeyCode = (event.which) ? event.which : event.keyCode
      if (iKeyCode === 101 || iKeyCode === 43 || iKeyCode === 45) { event.preventDefault() }
    },
  }
}
</script>

<style lang="scss">
  .table td {
    vertical-align: middle;
  }
  .red-text {
    color: red;
    font-size: small;
  }
  .small-input {
    width: fit-content;
  }
  .form-group {
    margin-bottom: 0.75rem;
  }
</style>
