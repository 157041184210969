<template>
  <div v-if="platform.id">

    <b-modal v-model="isPharmModalShown" title="Select Pharmacies" centered size="lg" ok-title="Confirm Selection" ok-variant="primary" @ok="confirmSelection($event)">
      <b-row>
        <b-col cols="5">
          <b-input-group prepend="GPHC">
            <b-form-input name="search_gphc" type="search" @search="searchPharm()" v-model="allPharmGphc"/>
          </b-input-group>
        </b-col>
        <b-col cols="6">
          <b-input-group prepend="Trading Name / ID">
            <b-form-input name="search_trading_name" @search="searchPharm()" type="search" v-model="allPharmTradingName"/>
          </b-input-group>
        </b-col>
        <b-col cols="1">
          <b-button block variant="primary" @click="searchPharm()"><i class="fa fa-search"></i></b-button>
        </b-col>
      </b-row>
      <br>
      <b-pagination align="center" size="sm" :total-rows="allPharm.totalElements" v-model="allPharmCurrentPage" :per-page="allPharmPerPage"></b-pagination>
      <b-table :items="allPharm.content" :fields="allPharmColumnNames" :current-page="1" :per-page="allPharmPerPage" hover outlined show-empty responsive>
        <template v-slot:head(action)>
          <b-form-checkbox block variant="primary" :checked="allPharm.content.every(p => selectedPharmIds.includes(p.id))" @change="selectAllPharm($event)" />
        </template>
        <template v-slot:cell(action)="row">
          <b-form-checkbox block variant="primary" :checked="selectedPharmIds.includes(row.item.id)" @change="selectPharm(row.item.id, $event)" />
        </template>
      </b-table>
      <b-pagination align="center" size="sm" :total-rows="allPharm.totalElements" v-model="allPharmCurrentPage" :per-page="allPharmPerPage"></b-pagination>
      <br>
      <span>You have <strong>{{selectedPharmIds.length}}</strong> pharmacies selected</span>
    </b-modal>

    <b-card no-title no-body>
      <div class="own-header">
        <b-row>
          <b-col class="display-name" sm="12" md="6">
            <i class="fa fa-align-justify"></i>
            <strong class="header-spacing"> {{ platform.displayName }} </strong>
          </b-col>
          <b-col sm="12" md="6">
            <b-button v-if="!editable" variant="warning" @click="allowChanges()" class="float-right">Update Platform</b-button>
            <b-button v-if="editable" variant="primary" @click="editPlatform()" class="float-right">Confirm</b-button>
            <b-button v-if="editable" variant="outline-danger" @click="reset()" class="float-right margin-1-percent">Reset</b-button>
          </b-col>
        </b-row>
      </div>
      <b-card-body>
        <b-row>
          <b-col md="12" lg="8">
            <b-row><b-col><h4>Platform Information</h4></b-col></b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group id="id" label="Platform ID" label-for="id">
                  <b-form-input name="id" type="text" v-model="platform.id" disabled/>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group id="slug" label="URL Identifier" label-for="id">
                  <b-form-input name="slug" type="text" v-model="platform.slug" :disabled="!editable"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group id="name" label="Name" label-for="name">
              <b-form-input name="name" type="text" v-model="platform.name" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('name')" class="text-danger">{{ errors.first('name') }}</span>
            </b-form-group>
            <b-row>
            <b-col cols="6">
            <b-form-group id="logoUrl" label="Logo URL" label-for="logoUrl">
              <div class="border mb-1" style="width: 100%; height: 150px; display: flex; justify-content: center; align-content: center;">
                <img v-if="platform.logoUrl" style="max-width: 100%; max-height: 100%; object-fit: contain;" :src="platform.logoUrl">
              </div>
            </b-form-group>
            </b-col>
            <b-col cols="6">
            <b-form-group id="backgroundUrl" label="Background URL" label-for="backgroundUrl">
              <div class="border mb-1" style="width: 100%; height: 150px; display: flex; justify-content: center; align-content: center;">
                <img v-if="platform.backgroundUrl" style="max-width: 100%; max-height: 100%; object-fit: contain;" :src="platform.backgroundUrl">
              </div>
            </b-form-group>
            </b-col>
            </b-row>
            <!-- pharmacies in platform (table) -->
            <b-row class="mb-1 mt-2">
              <b-col>
                <h4 style="display: inline;">Pharmacies</h4>
                <b-button class="b-button" variant="dark" size="md" :disabled="!editable" @click="showPharmModal()"><i class="fa fa-edit"></i> Edit</b-button>
              </b-col>
            </b-row>
            <b-table :items="platformPharmacies.content" :fields="columnNames" :current-page="1" :per-page="perPage" hover outlined show-empty responsive>
              
            </b-table>
            <b-pagination align="center" size="sm" :total-rows="platformPharmacies.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>

          </b-col>
          <b-col md="12" lg="4">
            <!-- appointment prices -->
            <b-row><b-col><h4>Pricing Information</h4></b-col></b-row>
            <b-form-group v-if="platform.formPrice" id="formPrice" label="Form Order" label-for="formPrice">
              <b-form-input name="formPrice" type="text" v-model="platform.formPrice" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('formPrice')" class="text-danger">{{ errors.first('formPrice') }}</span>
            </b-form-group>
            <br>
            <b-form-group id="appointmentPrice" label="Appointment" label-for="appointmentPrice">
              <b-form-input name="appointmentPrice" type="text" v-model="platform.appointmentPrice" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('appointmentPrice')" class="text-danger">{{ errors.first('appointmentPrice') }}</span>
            </b-form-group>
            <b-form-group id="appointmentExtensionPrice" label="Appointment Extension" label-for="appointmentExtensionPrice">
              <b-form-input name="appointmentExtensionPrice" type="text" v-model="platform.appointmentExtensionPrice" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('appointmentExtensionPrice')" class="text-danger">{{ errors.first('appointmentExtensionPrice') }}</span>
            </b-form-group>
            <b-form-group id="appointmentPreAuthPrice" label="Appointment Pre-Auth" label-for="appointmentPreAuthPrice">
              <b-form-input name="appointmentPreAuthPrice" type="text" v-model="platform.appointmentPreAuthPrice" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('appointmentPreAuthPrice')" class="text-danger">{{ errors.first('appointmentPreAuthPrice') }}</span>
            </b-form-group>
            
            <!-- deliverables -->
            <div>
              <br>
              <b-form-group id="prescriptionPrice" label="Prescription" label-for="prescriptionPrice">
                <b-form-input name="prescriptionPrice" type="text" v-model="platform.prescriptionPrice" v-validate="'required'" :disabled="!editable"/>
                <span v-show="errors.has('prescriptionPrice')" class="text-danger">{{ errors.first('prescriptionPrice') }}</span>
              </b-form-group>
              <b-form-group id="sickNotePrice" label="Fit Note" label-for="sickNotePrice">
                <b-form-input name="sickNotePrice" type="text" v-model="platform.sickNotePrice" v-validate="'required'" :disabled="!editable"/>
                <span v-show="errors.has('sickNotePrice')" class="text-danger">{{ errors.first('sickNotePrice') }}</span>
              </b-form-group>
              <b-form-group id="referralLetterPrice" label="Referral Letter" label-for="referralLetterPrice">
                <b-form-input name="referralLetterPrice" type="text" v-model="platform.referralLetterPrice" v-validate="'required'" :disabled="!editable"/>
                <span v-show="errors.has('referralLetterPrice')" class="text-danger">{{ errors.first('referralLetterPrice') }}</span>
              </b-form-group>
            </div>

            <!-- drug prices -->
            <div>
              <br>
              <b-form-group id="drugMinimumCharge" label="Drug Minimum Charge" label-for="drugMinimumCharge">
                <b-form-input name="drugMinimumCharge" type="text" v-model="platform.drugMinimumCharge" v-validate="'required'" :disabled="true"/>
                <span v-show="errors.has('drugMinimumCharge')" class="text-danger">{{ errors.first('drugMinimumCharge') }}</span>
              </b-form-group>
              <b-form-group id="drugProfessionalFee" label="Drug Professional Fee" label-for="drugProfessionalFee">
                <b-form-input name="drugProfessionalFee" type="text" v-model="platform.drugProfessionalFee" v-validate="'required'" :disabled="true"/>
                <span v-show="errors.has('drugProfessionalFee')" class="text-danger">{{ errors.first('drugProfessionalFee') }}</span>
              </b-form-group>
              <b-form-group id="drugMarkupFactor" label="Drug Markup Factor" label-for="drugMarkupFactor">
                <b-form-input name="drugMarkupFactor" type="text" v-model="platform.drugMarkupFactor" v-validate="'required'" :disabled="true"/>
                <span v-show="errors.has('drugMarkupFactor')" class="text-danger">{{ errors.first('drugMarkupFactor') }}</span>
              </b-form-group>
            </div>

          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <br/>
  </div>
</template>

<script>

export default {
  name: 'Orders',
  components: {},
  data: function () {
    return {
      newImage: null,
      previewImage: null,
      platform: {},
      platformPharmacies: {
        totalElements: 0,
        content: []
      },
      perPage: 5,
      currentPage: 1,
      editable: false,
      columnNames: [
        { key: 'id', label: 'ID' },
        { key: 'gphc', label: 'GPhC' },
        { key: 'displayName', label: 'Name' },
        'email'
      ],
      truthyFalsy: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      isPharmModalShown: false,
      selectedPharmIds: [],
      allPharmColumnNames: [
        { key: 'action' },
        { key: 'id', label: 'ID' },
        { key: 'gphc', label: 'GPhC' },
        { key: 'displayName', label: 'Name' },
        'email'
      ],
      allPharm: {
        totalElements: 0,
        content: []
      },
      allPharmGphc: '',
      allPharmTradingName: '',
      allPharmCurrentPage: 1,
      allPharmPerPage: 10
    }
  },
  watch: {
    currentPage: function () {
      this.getPlatformPharmacies()
    },
    allPharmCurrentPage: function () {
      this.searchPharmacies()
    }
  },
  mounted: function () {
    this.getPlatform()
  },
  methods: {
    putImage (file) {
      console.log(file)
      var key = this.newImagePath.match(/(.*?\/)(img\/.+)$/)[2]
      this.$fileService.uploadFile(this.newImage, key).then(async (res) => {
        console.log(res)
        await this.searchPharmacies()
        this.$awn.success('Image Updated')
      }).finally(() => {
        this.resetImageModal();
      })
    },
    resetImageModal () {
      this.newImage = null
      this.previewImage = null
    },
    urlOf (file) {
      return URL.createObjectURL(file)
    },
    getPlatform () {
      this.$platformService.getPlatform(this.$route.params.platformId).then(platform => {
        this.platform = platform
        this.selectedPharmIds = [...platform.pharmacyIds]
        this.getPlatformPharmacies()
      })
    },
    getPlatformPharmacies () {
      this.$platformService.getPlatformPharmacies(this.$route.params.platformId, this.currentPage - 1, this.perPage).then(pharmacies => {
        this.platformPharmacies = pharmacies
      })
    },
    searchPharmacies () {
      this.$platformService.queryPharmacies(this.allPharmGphc, this.allPharmTradingName, this.allPharmCurrentPage - 1, this.allPharmPerPage).then(res => {
        this.allPharm = res
      })
    },
    confirmSelection (event) {
      console.log(event)
      this.platform.pharmacyIds = this.selectedPharmIds
    },
    allowChanges () {
      this.editable = true
      this.truthyFalsy.forEach(option => {
        this.$delete(option, 'disabled')
      })
    },
    reset () {
      this.truthyFalsy.forEach(option => {
        this.$set(option, 'disabled')
      })
      this.editable = false
      this.getPlatform()
    },
    editPlatform () {
      this.$validator.validateAll().then((success) => {
        if (success) {
          this.$platformService.editPlatform(this.platform).then(() => {
            this.$router.push('/platforms')
            this.$awn.success('Platform updated successfully')
          }).catch((error) => {
            console.log(error.response)
            if (error.response && error.response.data) {
              this.$awn.alert(this.concatErrorMessage(error.response.data))
            } else {
              this.$awn.alert('There was a network error')
            }
          })
        } else {
          this.$awn.alert('Please check the form above for any missing fields or errors.')
        }
      })
    },
    searchPharm () {
      this.searchPharmacies()
      this.allPharmCurrentPage = 1
    },
    showPharmModal () {
      this.searchPharm()
      this.isPharmModalShown = true
    },
    selectPharm (idx, event) {
      if (event === false) {
        this.selectedPharmIds = this.selectedPharmIds.filter(i => i !== idx)
      } else {
        this.selectedPharmIds = [...this.selectedPharmIds, idx]
      }
    },
    selectAllPharm (event) {
      var allPharmIds = this.allPharm.content.map(c => c.id)
      if (event === false) {
        this.selectedPharmIds = this.selectedPharmIds.filter(i => !allPharmIds.includes(i))
      } else {
        this.selectedPharmIds = [...this.selectedPharmIds, ...allPharmIds]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .action-buttons {
    display: flex; 
    justify-content: flex-end;
  }
  .margin-1-percent {
    margin-right:1%
  }
  .collapsed > .when-opened, :not(.collapsed) > .when-closed {
    display: none;
  }
  .form-control:disabled {
    text-align: start;
  }
  h4 {
    margin-top: 0.5em;
    margin-bottom: 0.25em;
  }
  .b-button {
    display: inline-block;
    margin-bottom: 0.5em;
    margin-left: 1em;
  }
</style>