<template>
    <div>
        <b-card>
            <div slot="header">
                <i class='fa fa-align-justify'></i> <strong class='header-spacing'>Order details</strong>
            </div>
            <b-form-group id="orderIdGroup" label="Order ID" label-for="orderId" label-cols="9">
                <b-form-input name="orderId" type="text" v-model="order.id" disabled/>
            </b-form-group>
            <b-form-group id="orderTypeGroup" label="Type" label-for="orderType" label-cols="8">
                <b-form-input name="orderType" type="text" v-model="orderType" disabled/>
            </b-form-group>
            <b-form-group id="orderCreationDateGroup" label="Creation Date" label-for="orderCreationDate" label-cols="6">
                <b-form-input name="orderCreationDate" type="text" :value="creationDate" disabled/>
            </b-form-group>
            <b-form-group id="orderName" label="Description" label-for="orderName" label-cols="2">
<!--                <b-form-input name="orderName" type="text" v-model="order.name" disabled/>-->
                <b-form-input name="orderName" type="text" :value="description" v-b-tooltip.hover :title="description" disabled/>
            </b-form-group>
            <b-form-group id="orderPriceGroup" label="Price" label-for="orderPrice" label-cols="9">
                <b-form-input name="orderPrice" type="text" :value="price" disabled/>
            </b-form-group>
            <b-form-group id="orderDeliveryGroup" label="Delivery Type" label-for="orderDelivery" label-cols="8">
                <b-form-input name="orderDelivery" type="text" v-model="order.deliveryType" disabled/>
            </b-form-group>
            <b-form-group id="orderPaymentGroup" label="Payment Type" label-for="orderPayment" label-cols="9">
                <b-form-input name="orderPayment" type="text" v-model="order.paymentType" disabled/>
            </b-form-group>
            <b-form-group id="orderStatusGroup" label="Status" label-for="orderStatus" label-cols="3">
                <b-form-input name="orderStatus" type="text" v-model="order.status" disabled/>
            </b-form-group>
            <b-form-group v-if="order.status === 'DELIVERED'" id="orderDeliveryDateGroup" label="Delivery Date" label-for="deliveryDate" label-cols="3">
                <b-form-input name="deliveryDate" type="text" :value="deliveryDate" disabled/>
            </b-form-group>
            <b-form-group 
              v-if="shouldShowRepeatDueDaysGroup" 
              id="orderRepeatDueDaysGroup" 
              label="Next Repeat Due Days" 
              label-for="repeatDueDays" 
              label-cols="3"
            >
              <b-form-input name="repeatDueDays" type="text"  class="text-ellipsis" v-model="orderStatusMessage" disabled/>
            </b-form-group>
            <b-form-group id="orderIsRepeatGroup" label="Order Type" label-for="isRepeatOrder" label-cols="3">
                <b-form-input name="isRepeatOrder" type="text" :value="order.isRepeatOrder ? 'Repeat order' : 'Consultation'" disabled/>
            </b-form-group>
            <b-form-group v-if="order.parentOrderItemId" id="orderParentOrderGroup" label="Parent Order" label-for="parentOrderItemId" label-cols="3">
                <a class="float-right" :href="'/order/' + order.parentOrderItemId">#{{ order.parentOrderItemId }}</a>
            </b-form-group>
            <b-form-group id="orderPlatformGroup" label="Platform" label-for="orderPlatform" label-cols="3">
                <b-form-input name="orderPlatform" type="text" v-model="order.platform" disabled/>
            </b-form-group>
            <b-form-group v-if="order.prescriber" id="orderPrescriberGroup" label="Prescriber" label-for="orderPrescriber" label-cols="3">
                <b-form-input name="orderPrescriber" type="text" :value="order.prescriber.name" disabled/>
            </b-form-group>
            <b-form-group v-if="order.workflow.PHARMACY_ASSIGN_TRACKING_NUMBER" id="orderTrackingNumberGroup" label="Tracking Number" label-for="trackingNumber" label-cols="3">
                <b-form-input name="trackingNumber" type="text" v-model="order.workflow.PHARMACY_ASSIGN_TRACKING_NUMBER" disabled/>
            </b-form-group>
            <b-form-group v-if="order.workflow.PHARMACY_ASSIGN_TRACKING_NUMBER" id="deliveryMethod" label="Courier Service" label-for="deliveryMethod" label-cols="3">
                <b-form-input name="deliveryMethod" type="text" :value="formatString(order.deliveryMethod)" disabled/>
            </b-form-group>
            <b-form-group v-if="getCancellationDetails(order.orderNotes)" id="orderCancellationGroup" label="Canceled by" label-for="canceledBy" label-cols="3">
                <b-form-input name="canceledBy" type="text" v-model="getCancellationDetails(order.orderNotes).canceledBy" disabled/>
            </b-form-group>
            <b-form-group v-if="getCancellationDetails(order.orderNotes)" id="orderCancellationGroup" label="Canceled reason" label-for="canceledReason" label-cols="3">
                <b-form-input name="canceledReason" type="text" v-model="getCancellationDetails(order.orderNotes).reason" disabled/>
            </b-form-group>
            <b-form-row v-if="order.status === 'BOOKED'" style="display: flex; flex-direction: row; justify-content: flex-end; margin-top: 20px;">
              <b-button v-if="getChangeAppointmentVisibility()" variant="warning"
                        style="margin-right: 20px; color: white;"
                        @click="showChangeAppointmentModal()">
                Change Appointment
              </b-button>
              <b-button variant="danger" style="margin-right: 5px;" @click="showCancelModal()">
                Cancel Order
              </b-button>
            </b-form-row>

        </b-card>
      <b-modal ref="cancel-order-modal" title="Cancel Video Consultation" @ok="cancelOrder" >
        <div style="padding: 10px;">
          <b-form-group label="Please specify the reason why you would like to cancel this consultation:" label-class="cancel-input-label">
            <b-form-radio name="cancelReasonOption" v-model="cancelReason" value="Consultation no longer required">Consultation no longer required</b-form-radio>
            <b-form-radio name="cancelReasonOption" v-model="cancelReason" value="Consultation required at another time">Consultation required at another time</b-form-radio>
          </b-form-group>
          <b-form-group label="Should the patient be charged for this consultation?" label-class="cancel-input-label">
            <b-form-radio name="cancelCharge" v-model="cancelPaid" value="true">Yes</b-form-radio>
            <b-form-radio name="cancelCharge" v-model="cancelPaid" value="false">No</b-form-radio>
          </b-form-group>
          <b-form-group>
            <label for="cancelReason" class="cancel-input-label">Reason for cancellation</label>
            <b-form-textarea id="cancelReason" v-model="cancelNote" class="form-control" name="cancelReason" required></b-form-textarea>
          </b-form-group>
          <b-alert :show="hasFormError" variant="danger">
              <p>Please complete all above fields</p>
          </b-alert>
          <b-alert :show="hasProcessError" variant="danger">
              <p>Error occured in cancelling appointment! - {{processErrorMessage}}</p>
          </b-alert>
                      <!-- <b-form-row style="display: flex; flex-direction: row;">
            <b-button size="sm"  variant="primary" @click="hideModal" style="margin-left: auto; margin-right: 5px;">
              Okay
            </b-button>
          </b-form-row> -->
        </div>
      </b-modal>

      <b-modal ref="change-appointment-modal" title="Change Appointment Slot"
               @ok="changeAppointment" @cancel="resetChangeAppointmentData" @close="resetChangeAppointmentData">
        <b-container fluid>
          <div style="padding: 10px;">
            <p v-if="isLoadingAppointmentSlots">Loading...</p>
            <p v-if="!isLoadingAppointmentSlots && appointmentDates.length === 0">No slots are available.</p>
            <div v-if="!isLoadingAppointmentSlots && appointmentDates.length > 0">
              <b-form-group label="Please select new appointment slot:" label-class="cancel-input-label">
                <b-form-select v-model="selectedAppointmentDate" :options="appointmentDates"></b-form-select>
              </b-form-group>

              <div v-if="selectedAppointmentDate" style="margin-top: 20px;">
                <p>Please select an appointment time:</p>
                <div style="display: flex; max-height: 200px; overflow-y: auto;">
                  <div v-for="slot in appointmentSlots[selectedAppointmentDate]" :key="slot.id"
                       v-bind:class="selectedAppointmentSlot === slot.id
                       ? 'new-appointment-slot-selected' : 'new-appointment-slot'"
                       @click="setSelectedAppointment(slot.id)">
                    {{ formatSlotTime(slot.datetime) }}
                  </div>
                </div>
              </div>
            </div>

            <b-alert :show="hasChangeAppointmentFormError" variant="danger">
              <p>Please complete all above fields</p>
            </b-alert>
            <b-alert :show="hasChangeAppointmentProcessError" variant="danger">
              <p>Error occured in changing appointment - {{changeAppointmentProcessErrorMessage}}</p>
            </b-alert>
          </div>
        </b-container>

      </b-modal>
    </div>
</template>

<script>
import moment from 'moment';
import OrderEventBus from '../EventBus.js';
import publicService from '../services/PublicService';

export default {
  name: 'order-details',
  props: ['order'],
  data: function () {
    return {
      cancelReason: null,
      cancelPaid: null,
      cancelNote: "",
      hasFormError: false,
      hasProcessError: false,
      processErrorMessage: "",
      hasChangeAppointmentFormError: false,
      hasChangeAppointmentProcessError: false,
      changeAppointmentProcessErrorMessage: '',
      isLoadingAppointmentSlots: false,
      appointmentDates: [],
      appointmentSlots: {},
      selectedAppointmentDate: null,
      selectedAppointmentSlot: null,
      isRepeatOrder: false,
      orderType: 'BOOKING',
      remainingTime: null,
      lastVideoConsultationDate: null
    }
  },
  computed: {
    shouldShowRepeatDueDaysGroup() {
      return this.order.status === 'DELIVERED' && this.order.eligibleToReorder && !this.order.hasActiveRepeatOrder;
    },
    creationDate: function () {
      return moment(this.order.creationDate).local().format('Do MMMM YYYY, HH:mm');
    },
    deliveryDate: function () {
      return moment(this.order.updateDate).local().format('Do MMMM YYYY, HH:mm');
    },
    price: function () {
      return this.order.price.toLocaleString('en-US', {style: 'currency', currency: 'GBP', minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },
    // To change the name field into more descriptive way
    description: function () {
      let description = this.order.name;
      if (this.order.consultationDuration === 'MINUTES_00') {
        description = `Repeat prescription - Order ${this.order.id}`;
      } else if (this.order.consultationDuration === 'MINUTES_15') {
        description = `Repeat consultation (15 minutes) - Order ${this.order.id} ${this.order?.name.slice(this.order?.name.indexOf('('))}`;
      } else if (this.order.consultationDuration === 'MINUTES_30') {
        description = `Consultation (30 minutes) - Order ${this.order.id} ${this.order?.name.slice(this.order?.name.indexOf('('))}`;
      } else if (this.order.consultationDuration === 'IN_PERSON_MINUTE_00') {
        description = `Pharmacy Consultation - Order ${this.order.id} (${this.formatDateTime(this.extractDateTime (this.order?.name))})`;
      }
      return description;
    },
    orderStatusMessage() {
      const updateDate = this.order?.updateDate;
      if (!updateDate) {
        return '';
      }
      if (this.getIsOrderPast14Days(updateDate)) {
        this.getLastVideoConsultationDate(this.order.patient.id);
        if (this.getIsOrderPast6Months(this.lastVideoConsultationDate)) {
          return 'Patient is due for a new consultation before a repeat prescription can be issued';
        } else {
          return 'Patient is due for a repeat prescription';
        }
      } else {
        const remainingTime = this.calculateRemainingTime(updateDate);
        if (remainingTime) {
          if (remainingTime.days > 0) {
            return `${remainingTime.days} days and ${remainingTime.hours} hours`;
          } else if (remainingTime.hours > 1) {
            return `${remainingTime.hours} hours`;
          } else {
            return '1 hour';
          }
        } else {
          return '';
        }
      }
    }
  },
  mounted: function () {

  },
  watch : {
    order: function() {
      this.getIsRepeatOrder(this.order.patient.id)
      this.orderType = this.order.consultationDuration === 'IN_PERSON_MINUTE_00' ? 'Pharmacy Consultation' : this.order.type
    }
  },
  methods: {
    getIsRepeatOrder(id) {
      this.$patientService.getPatient(id).then(patient => {
        this.isRepeatOrder = patient.settings.INITIAL_CONSULTATION_DONE === "true"
      })
    },
    getLastVideoConsultationDate(id) {
      this.$patientService.getPatient(id).then(patient => {
         this.lastVideoConsultationDate = patient.settings.LAST_VIDEO_CONSULTATION_DATE
      })
    },
    showCancelModal() {
      this.$refs['cancel-order-modal'].show()
    },
    hideCancelModal() {
      this.$refs['cancel-order-modal'].hide()
    },
    cancelOrder(evt) {
      evt.preventDefault();
      if(this.cancelReason === null || this.cancelPaid === null || this.cancelNote === ""){
        this.hasFormError = true;
        return;
      }

      this.hasFormError = false;
      const cancelReason = this.cancelReason + " - " + this.cancelNote;
      this.$orderService.cancelOrder(this.order.appointment.id, this.cancelPaid, cancelReason)
          .then((response) => {
            console.log(response);
            this.hideCancelModal();
            OrderEventBus.$emit("getOrderDetails", this.order.id);
            this.$awn.success('Appointment cancelled successfully!');
          })
          .catch((error) => {

            this.hasProcessError = true;
            if(error.response){
              this.processErrorMessage = error.response.data.message;
            }

          })
    },
    getCancellationDetails(orderNotes) {
      const cancelledTypes = ["DOCTOR_ORDER_CANCELLATION_NOTE", "ADMIN_ORDER_CANCELLATION_NOTE"];
      const cancellationNote = orderNotes.find(note => cancelledTypes.includes(note.type)) || null;

      if(cancellationNote) {
        if(cancellationNote.type.includes("DOCTOR")) {
          return {
            cancelled: true,
            canceledBy: `Doctor - ${cancellationNote.doctor.displayName}`,
            reason: cancellationNote.description
          }
        } else if(cancellationNote.type.includes("ADMIN")) {
          return {
            cancelled: true,
            canceledBy: `Admin - ${cancellationNote.admin.displayName}`,
            reason: cancellationNote.description
          }
        } else {
          return {
            cancelled: true,
            canceledBy: null,
            reason: cancellationNote.description
          }
        }
      }

      return null
    },
    loadAvailableAppointmentSlots() {
      this.isLoadingAppointmentSlots = true;
      publicService.getAppointments(0, 320, this.isRepeatOrder)
      .then(response => {
        if (response.success) {
          if (response.data?.length > 0) {
            this.selectedAppointmentDate = null;
            let dates = [{ value: null, text: 'Please select a date', disabled: true }];
            let slots = {};
            for (let i = 0; i < response.data.length; i++) {
              let datetime = moment(response.data[i]['datetime']);
              let date = datetime.format('YYYY-MM-DD');
              if (!dates.some( _date => _date.value === date)) {
                dates.push({ value: date, text: date });
              }
              if (Object.keys(slots).includes(date)) {
                slots[date].push(response.data[i]);
              } else {
                slots[date] = [response.data[i]];
              }
            }
            this.appointmentDates = dates;
            this.appointmentSlots = slots;
          }
        } else {
          this.hasChangeAppointmentProcessError = true;
          this.changeAppointmentProcessErrorMessage = response.message;
        }
      }).catch(error => {
        this.hasChangeAppointmentProcessError = true;
        this.changeAppointmentProcessErrorMessage = error.message;
      }).finally(() => {
        this.isLoadingAppointmentSlots = false;
      });
    },
    getChangeAppointmentVisibility() {
      // only allow changing appointment for bookings, and only if they are in the future.
      return this.order.type === 'BOOKING' && moment(this.order?.appointment?.datetime).isAfter(moment());
    },
    showChangeAppointmentModal() {
      this.loadAvailableAppointmentSlots();
      this.$refs['change-appointment-modal'].show();
    },
    hideChangeAppointmentModal() {
      this.$refs['change-appointment-modal'].hide();
    },
    changeAppointment(event) {
      event.preventDefault(); // stop modal from closing automatically.
      this.changeAppointmentProcessErrorMessage = null;
      this.hasChangeAppointmentFormError =  false;
      this.hasChangeAppointmentProcessError =  false;

      if (!this.selectedAppointmentDate || !this.selectedAppointmentSlot) {
        this.hasChangeAppointmentFormError = true;
        return;
      }

      this.$orderService.changeAppointment(this.order.id, this.selectedAppointmentSlot)
      .then(response => {
        if (response.success) {
          this.$emit('change-appointment'); // notify parent component to reload the order.
          this.$awn.success('Appointment changed successfully.');
          this.resetChangeAppointmentData();
          this.hideChangeAppointmentModal();
        } else {
          this.hasChangeAppointmentProcessError = true;
          this.changeAppointmentProcessErrorMessage = response.message;
        }
      }).catch(error => {
        this.changeAppointmentProcessErrorMessage = error.message;
      });
    },
    setSelectedAppointment(slotId) {
      this.selectedAppointmentSlot = slotId;
      this.hasChangeAppointmentFormError = false;
    },
    resetChangeAppointmentData() {
      this.appointmentDates = [];
      this.appointmentSlots = {};
      this.selectedAppointmentDate = null;
      this.selectedAppointmentSlot = null;
      this.changeAppointmentProcessErrorMessage = null;
      this.hasChangeAppointmentFormError =  false;
      this.hasChangeAppointmentProcessError =  false;
    },
    formatSlotTime(dateTime) {
      return moment(dateTime).format('hh:mm a');
    },
    formatString(value){
      const exceptions = ['DPD', 'DX', 'DHL', 'UPS'];
      if (exceptions.includes(value)) {
        return value;
      }

      if(value == "APC_OVERNIGHT"){
        return "APC Overnight";
      }

      return value?.toLowerCase()
                  .replace(/_/g, ' ')
                  .replace(/\b\w/g, char => char.toUpperCase());
    },
    extractDateTime(fullDateString) {
      const dateTimePart = fullDateString.split(' - ')[1];
      return dateTimePart.trim();
    },
    formatDateTime(dateTimeString) {
      let [date, time] = dateTimeString.split(', ');
      let [month, day, year] = date.split('/');
      let [hours, minutes] = time.split(':');

      if (hours == '24') {
        hours = '00';
      }

      let dateObject = new Date(`${year}-${month}-${day}T${hours}:${minutes}`);
      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      };

      let formattedDateTime = dateObject.toLocaleString('en-GB', options).replace(',', '');
      return formattedDateTime;
    },
    getIsOrderPast6Months() {
      if (this.lastVideoConsultationDate) {
        const dateWithoutTimeZone = this.lastVideoConsultationDate.split('[')[0];
        const givenDate = moment(dateWithoutTimeZone);
        const currentDate = moment();
        const differenceInMonths = currentDate.diff(givenDate, 'months', true); // Use 'months' for whole months difference, 'months', true for decimal months difference
        return differenceInMonths >= 6;
      }
      return false; // Return false if lastVideoConsultationDate is not set
    },
    getIsOrderPast14Days(date) {
      if (date) {
        const givenDate = moment(date);
        const currentDate = moment();
        const differenceInDays = currentDate.diff(givenDate, 'days');
        return differenceInDays >= 14;
      }
      return false;
    },
    calculateRemainingTime(date) {
      const givenDate = moment(date);
      if (!givenDate.isValid()) {
        console.error('Invalid date:', date);
        return null;
      }
      const endDate = givenDate.clone().add(14, 'days');
      const currentDate = moment();
      if (currentDate.isBefore(endDate)) {
        const duration = moment.duration(endDate.diff(currentDate));
        const days = Math.floor(duration.asDays());
        const hours = Math.floor(duration.asHours() % 24);
        return { days, hours };
      } else {
        return null;
      }
    }
  }
}
</script>

<style lang="scss">
  .cancel-input-label {
    font-weight: bold;
  }

  .new-appointment-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 80px;
    height: 50px;
    border-radius: 5px;
    border: 2px solid gray;
    margin: 10px;
    cursor: pointer;
    min-height: unset;
    padding: 10px
  }

  .new-appointment-slot-selected {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 80px;
    height: 50px;
    background-color: #6d4485;
    color: white;
    border-radius: 5px;
    border: 2px solid #6d4485;
    margin: 10px;
    padding: 10px;
  }

  .text-ellipsis {
    overflow-y: auto;
  }
</style>
