<script>
import Vue from 'vue';

export default {

  //Get postcodes api
  //Ticket: CG-319
  //Author: Sarmilan
  postcodeAutoComplete(partialValue) {
    let url = `https://api.postcodes.io/postcodes/${partialValue}/autocomplete`;

    return fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(error => {
        return error;
      });
  },

  //Get surgery list api
  //Ticket: CG-319
  //Author: Sarmilan
  getSurgeries(postcode) {
    let url = `https://api.nhs.uk/service-search/search-postcode-or-place?api-version=1&search=${postcode}`;
    let config = {
      headers: {
        "subscription-key": "02768fc86b4b478795b5618b2e26ce77",
        "Content-Type": "application/json",
      }
    }
    let body = {
      filter: "OrganisationTypeID eq 'GPB'",
      select: "OrganisationName,Address1,Address2,Address3,City,County,Postcode,Contacts",
      top: 10,
      skip: 0,
      count: true,
    }
    return Vue.axios.post(url, body, config)
      .then(response => {
        return response
      }
      )
      .catch(error => {
        console.log('error', error)
        this.handleError(error)
        return Promise.reject(error)
      })
  }
}
</script>