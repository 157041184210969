<template>
  <!-- Display a card with consultation details -->
  <b-card class="text-center">
    <h1>Consultation Details</h1>
    <!-- Success message for order placement -->
    <p class="mt-5">
      <i class="success-icon fa fa-check-circle-o fa-3x" aria-hidden="true"></i>
    </p>
    <p class="success-message mb-5">Order placed successfully!</p>
    <!-- Display consultation details in a row -->
    <b-row class="m-2">
      <b-col v-for="(column, index) in columns" :key="index" class="icon-column">
        <b-row class="icon-row">
          <b-col cols="12" class="icon-wrapper">
            <i :class="column.iconClass" aria-hidden="true"></i>
          </b-col>
          <b-col cols="12">
            <p class="icon-title">{{ column.title }}</p>
            <p class="icon-data">{{ column.data }}</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Display a message about the temporary order -->
    <b-row class="centered-row mt-5">Temporary order successfully created for the patient. All necessary details have been recorded and are now accessible for further processing.</b-row>

    <div class="test-card w-100">

      <b-row class="mb-6">
        <b-col v-if="patientid && patientid !== ''" class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
          <b-row v-if="userObj" class="mb-6 text-right">
            <b-col class="col-12 col-md-4 pe-md-6 mb-6 mb-md-0">
              <Label class="user-data-label">Patient ID : </Label>
            </b-col>
            <b-col class="col-12 col-md-8 pe-md-6 mb-6 mb-md-0 text-left">
              <Label style="padding-left: 5px;">{{ patientid }}</Label>
            </b-col>
          </b-row>
        </b-col>

        <b-col v-if="userObj?.emailInput !== ''" class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
          <b-row v-if="userObj" class="mb-6 text-right">
            <b-col class="col-12 col-md-4 pe-md-6 mb-6 mb-md-0">
              <Label class="user-data-label">E-mail : </Label>
            </b-col>
            <b-col class="col-12 col-md-8 pe-md-6 mb-6 mb-md-0 text-left">
              <Label style="padding-left: 5px;">{{ userObj.emailInput }}</Label>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mb-6">
        <b-col v-if="userObj?.firstName !== ''" class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
          <b-row v-if="userObj" class="mb-6 text-right">
            <b-col class="col-12 col-md-4 pe-md-6 mb-6 mb-md-0">
              <Label class="user-data-label">First Name : </Label>
            </b-col>
            <b-col class="col-12 col-md-8 pe-md-6 mb-6 mb-md-0 text-left">
              <Label style="padding-left: 5px;">{{ userObj.firstName }}</Label>
            </b-col>
          </b-row>
        </b-col>

        <b-col v-if="userObj?.lastName !== ''" class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
          <b-row v-if="userObj" class="mb-6 text-right">
            <b-col class="col-12 col-md-4 pe-md-6 mb-6 mb-md-0">
              <Label class="user-data-label">Last Name : </Label>
            </b-col>
            <b-col class="col-12 col-md-8 pe-md-6 mb-6 mb-md-0 text-left">
              <Label style="padding-left: 5px;">{{ userObj.lastName }}</Label>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mb-6">
        <b-col v-if="userObj?.selectedGender !== ''" class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
          <b-row v-if="userObj" class="mb-6 text-right">
            <b-col class="col-12 col-md-4 pe-md-6 mb-6 mb-md-0">
              <Label class="user-data-label">Gender : </Label>
            </b-col>
            <b-col class="col-12 col-md-8 pe-md-6 mb-6 mb-md-0 text-left">
              <Label style="padding-left: 5px;">{{ userObj.selectedGender }}</Label>
            </b-col>
          </b-row>
        </b-col>

        <b-col v-if="userObj?.dateOfBirth !== ''" class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
          <b-row v-if="userObj" class="mb-6 text-right">
            <b-col class="col-12 col-md-4 pe-md-6 mb-6 mb-md-0">
              <Label class="user-data-label">Date of Birth : </Label>
            </b-col>
            <b-col class="col-12 col-md-8 pe-md-6 mb-6 mb-md-0 text-left">
              <Label style="padding-left: 5px;">{{ userObj.dateOfBirth | formatDate }}</Label>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mb-6">
        <b-col v-if="userObj?.selectedAddress?.address?.postcode?.label !== ''" class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
          <b-row v-if="userObj" class="mb-6 text-right">
            <b-col class="col-12 col-md-4 pe-md-6 mb-6 mb-md-0">
              <Label class="user-data-label">Post Code : </Label>
            </b-col>
            <b-col class="col-12 col-md-8 pe-md-6 mb-6 mb-md-0 text-left">
              <Label style="padding-left: 5px;">{{ userObj.selectedAddress.address.postcode.label }}</Label>
            </b-col>
          </b-row>
        </b-col>

        <b-col v-if="userObj?.selectedAddress?.address?.line1 !== ''" class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
          <b-row v-if="userObj" class="mb-6 text-right">
            <b-col class="col-12 col-md-4 pe-md-6 mb-6 mb-md-0">
              <Label class="user-data-label">Address Line 1 : </Label>
            </b-col>
            <b-col class="col-12 col-md-8 pe-md-6 mb-6 mb-md-0 text-left">
              <Label style="padding-left: 5px;">{{ userObj.selectedAddress.address.line1 }}</Label>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="userObj?.selectedAddress?.address?.line2 !== ''" class="mb-6">
        <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
          <b-row v-if="userObj" class="mb-6 text-right">
            <b-col class="col-12 col-md-4 pe-md-6 mb-6 mb-md-0">
              <Label class="user-data-label">Address Line 2 : </Label>
            </b-col>
            <b-col class="col-12 col-md-8 pe-md-6 mb-6 mb-md-0 text-left">
              <Label style="padding-left: 5px;">{{ userObj.selectedAddress.address.line2 }}</Label>
            </b-col>
          </b-row>
        </b-col>

        <b-col v-if="userObj?.selectedAddress?.address?.city !== ''" class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
          <b-row v-if="userObj" class="mb-6 text-right">
            <b-col class="col-12 col-md-4 pe-md-6 mb-6 mb-md-0">
              <Label class="user-data-label">City : </Label>
            </b-col>
            <b-col class="col-12 col-md-8 pe-md-6 mb-6 mb-md-0 text-left">
              <Label style="padding-left: 5px;">{{ userObj.selectedAddress.address.city }}</Label>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mb-6">
        <b-col v-if="userObj?.mobileNumber !== ''" class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
          <b-row v-if="userObj" class="mb-6 text-right">
            <b-col class="col-12 col-md-4 pe-md-6 mb-6 mb-md-0">
              <Label class="user-data-label">Mobile No : </Label>
            </b-col>
            <b-col class="col-12 col-md-8 pe-md-6 mb-6 mb-md-0 text-left">
              <Label style="padding-left: 5px;">{{ userObj.mobileNumber }}</Label>
            </b-col>
          </b-row>
        </b-col>

        <b-col v-if="userObj?.selectedGPPostcode !== ''" class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
          <b-row v-if="userObj" class="mb-6 text-right">
            <b-col class="col-12 col-md-4 pe-md-6 mb-6 mb-md-0">
              <Label class="user-data-label">GP Postcode : </Label>
            </b-col>
            <b-col class="col-12 col-md-8 pe-md-6 mb-6 mb-md-0 text-left">
              <Label style="padding-left: 5px;">{{ userObj.selectedGPPostcode }}</Label>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mb-6">
        <b-col v-if="userObj?.practice !== ''" class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
          <b-row v-if="userObj" class="mb-6 text-right">
            <b-col class="col-12 col-md-4 pe-md-6 mb-6 mb-md-0">
              <Label class="user-data-label">GP Practice : </Label>
            </b-col>
            <b-col class="col-12 col-md-8 pe-md-6 mb-6 mb-md-0 text-left">
              <Label style="padding-left: 5px;">{{ userObj.practice }}</Label>
            </b-col>
          </b-row>
        </b-col>

        <b-col v-if="userObj?.gpAddress !== ''" class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
          <b-row v-if="userObj" class="mb-6 text-right">
            <b-col class="col-12 col-md-4 pe-md-6 mb-6 mb-md-0">
              <Label class="user-data-label">GP Address Line 1 : </Label>
            </b-col>
            <b-col class="col-12 col-md-8 pe-md-6 mb-6 mb-md-0 text-left">
              <Label style="padding-left: 5px;">{{ userObj.gpAddress }}</Label>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mb-6">
        <b-col v-if="userObj?.gpAddress2 !== ''" class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
          <b-row v-if="userObj" class="mb-6 text-right">
            <b-col class="col-12 col-md-4 pe-md-6 mb-6 mb-md-0">
              <Label class="user-data-label">GP Address Line 2 : </Label>
            </b-col>
            <b-col class="col-12 col-md-8 pe-md-6 mb-6 mb-md-0 text-left">
              <Label style="padding-left: 5px;">{{ userObj.gpAddress2 }}</Label>
            </b-col>
          </b-row>
        </b-col>

        <b-col v-if="userObj?.gpTown !== ''" class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
          <b-row v-if="userObj" class="mb-6 text-right">
            <b-col class="col-12 col-md-4 pe-md-6 mb-6 mb-md-0">
              <Label class="user-data-label">GP Town : </Label>
            </b-col>
            <b-col class="col-12 col-md-8 pe-md-6 mb-6 mb-md-0 text-left">
              <Label style="padding-left: 5px;">{{ userObj.gpTown }}</Label>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mb-6">
        <b-col v-if="userObj?.gpCountry !== ''" class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
          <b-row v-if="userObj" class="mb-6 text-right">
            <b-col class="col-12 col-md-4 pe-md-6 mb-6 mb-md-0">
              <Label class="user-data-label">GP county : </Label>
            </b-col>
            <b-col class="col-12 col-md-8 pe-md-6 mb-6 mb-md-0 text-left">
              <Label style="padding-left: 5px;">{{ userObj.gpCountry }}</Label>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

    </div>

    <!-- Display instructions for the patient -->
    <b-row class="centered-row highlighted-text mt-5">Please instruct the patient to check their email, click the link provided, and proceed with making payments to confirm the booking.</b-row>

    <b-row class="mt-4 next-btn-parent">
      <b-col class="col-12 col-md-12 pe-md-6 mb-6 mb-md-0">
        <button class="next-btn"  @click="$router.push('/orders/call-to-book')">Done</button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import moment from 'moment';

export default {
  name: 'BookingConfirmation',
  props: {
  },

  data: function () {
    return {
      patientName: '',
      doctorName: '',
      consultationDate: '',
      userObj: null,
      patientid: ''
    }
  },

  filters: {
    formatDate(value) {
      if (!value) return '';
      const date = new Date(value);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
  },

  mounted() {
    this.AppointmentData();
  },

  computed: {
    columns() {
      return [
        {
          iconClass: 'fa fa-user fa-2x details-icon',
          title: 'Patient',
          data: this.patientName
        },
        {
          iconClass: 'fa fa-stethoscope fa-2x details-icon',
          title: 'Clinician',
          data: this.doctorName
        },
        {
          iconClass: 'fa fa-calendar fa-2x details-icon',
          title: 'Consultation date & time',
          data: this.formatDate(this.consultationDate)
        }
      ];
    }
  },
  methods: {
    // Method to format the date and time
    formatDate(date) {
      const datePart = date.split('[')[0]; // example datepart: "2024-03-07T06:45Z"
      const convertedDate = moment(datePart).format('Do MMMM, YYYY | hh:mm a');
      return convertedDate;
    },

    //Display confirmed appointment in UI
    //Ticket: CG-319
    //Author: Sarmilan
    AppointmentData() {
      let data = localStorage.getItem('appointmentDetails') ? localStorage.getItem('appointmentDetails') : null;
      let finalData = data !== null ? JSON.parse(data) : {};
      this.patientid = localStorage.getItem('patientID') ? localStorage.getItem('patientID') : "";
      this.userObj = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null;

      if(finalData?.consultationDate){
        this.patientName = finalData?.patientName ? finalData.patientName : '';
        this.doctorName = finalData?.doctorName ? finalData.doctorName : '';
        this.consultationDate = finalData?.consultationDate ? finalData.consultationDate : '';

        localStorage.removeItem("EligibilityForm");
        localStorage.removeItem("appointmentDetails");
        localStorage.removeItem("patientID");
        localStorage.removeItem("userData");
      }
    }
  }
};
</script>

<style scoped>
h1,
.success-icon,
.details-icon,
.icon-title,
.highlighted-text {
  color: #550385;
}

.success-icon,
.details-icon {
  cursor: pointer;
}

.icon-title {
  font-size: 15px;
  font-weight: 500;
  margin: 10px;
}

.success-message {
  font-size: 20px;
  font-weight: 500;
}

.icon-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
}

.icon-data {
  font-weight: bold;
}

.centered-row {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 15px;
}

.highlighted-text {
  font-size: 16px;
  font-weight: bold;
  background-color: rgba(222, 175, 255, 0.464);
  padding: 10px;
}

.next-btn-parent {
  text-align: center;
}

.next-btn {
  background: #550385;
  color: #fff;
  border-radius: 6px !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  padding: 10px 30px !important;
  border: 0;
  width: 150px;
  height: 50px;
}

.test-card {
    background-color: rgba(222, 175, 255, 0.464);
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 1rem !important;
    margin-top: 4%;
}

.user-data-label {
  color: #550385;
  font-weight: bolder;
}

.text-left {
  text-align-last: justify
}

.text-right {
  text-align-last: left
}
</style>
