
<script>
    import Vue from 'vue';

    export default {

        getNearestPharmacies (lat, lng, limit = 10, deliveryOnly = false, memberOnly = false, doesVaccines = 0) {
            var url = `/api/public/nearest-pharmacies?latitude=${lat}&longitude=${lng}`;

            var config = {
                headers: {
                    'X-Origin-Platform': 'ANY',
                }
            }

            if (deliveryOnly || memberOnly) {
                url = `${url}&limit=30`;
            } else {
                url = `${url}&limit=${limit}`;
            }

            if (memberOnly) {
                url = `${url}&member=true`;
            } else {
                url = `${url}&member=false`;
            }

            if (doesVaccines == 1) {
                url = `${url}&doesVaccines=1`;
            }

            return Vue.axios.get(url, config)
                .then(response => {return response.data;});
        },

        getAppointments(page = 0, size = 320, isRepeatOrder = false) {
          const url = `/api/public/appointments?type=${isRepeatOrder ? "repeat" : "full"}&page=${page}&size=${size}&sort=datetime,asc`;
          return Vue.axios.get(url)
              .then(response => ({
                success: true,
                data: response.data.content,
              }))
              .catch(error => {
                if (error.response) {
                  // The request was made and the server responded with a status code that falls out of the range of 2xx
                  console.debug(error.response);
                  return {
                    success: false,
                    status: error.response.status,
                    message: error.response.message,
                  }
                } else if (error.request) {
                  // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
                  console.debug('error.request ', error.request);
                  return {
                    success: false,
                    message: 'Did not receive a response from the server.',
                  }
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.debug('error', error.request);
                  return {
                    success: false,
                    message: 'Failed to make the request.',
                  }
                }
              });
        },

        //Get all addressed based on postcode
        //Ticket: CG-319
        //Author: Sarmilan
        getAddresses(postcode) {
          const url = `/api/public/get-address?postcode=${postcode}`;
          return Vue.axios.get(url)
            .then(response => ({
              success: true,
              data: response.data,
            }))
            .catch(error => {
              console.log(error);
              return {
                success: false,
                status: error,
                message: error.response.message,
              }
            })
        },

        //check email exists
        //Ticket: CG-319
        //Author: Sarmilan
        emailCheck(data) {
          const url = `/api/admin/register/`;
          return Vue.axios.post(url, data)
            .then(response => {
              return response.data; 
            })
            .catch(error => {
              if (error.response && error.response.status === 401) {
                return error.response.status
              } else {
                console.error('Error:', error.message);
              }
          });
        },

        //Create temporary appointment
        //Ticket: CG-319
        //Author: Sarmilan
        tempAppointment(data) {
          const url = `/api/admin/patient/partialAppointment`;

          return Vue.axios.post(url, data)
            .then(response => {
              return response.data;
            })
            .catch(error => {
              return error.response.data;
          });
        },

        //Update patient by admin
        //Ticket: CG-319
        //Author: Sarmilan
        updatePatient(data) {
          const url = `/api/admin/me`;

          return Vue.axios.put(url, data)
            .then(response => {
              return response;
            })
            .catch(error => {
              return error.response.data;
          });
        },

        //get existing patient details
        //Ticket: CG-343
        //Author: Sarmilan
        searchByEmail(searchQuery, page = 0, size = 1) {
            var url = '/api/admin/patient/search?q=' + searchQuery + '&email=true&page=' + page + '&size=' + size;

            return Vue.axios.get(url)
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error)
                    return Promise.reject(error);
            });
        }
    }
</script>
