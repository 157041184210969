/* eslint-disable no-extra-boolean-cast */
<template>
    <div>
        <b-card class="no-print">
            <div slot="header">
                <i class="fa fa-align-justify"></i>
                <strong class="header-spacing"
                    >Consultation Summary Generator</strong
                >
            </div>
            <b-row>
                <b-col>
                    <label class="order-label" for="orderId"
                        >Please enter an Order ID to retrieve the GP
                        Notes</label
                    >
                </b-col>
                <b-col>
                    <b-input-group>
                        <b-form-input
                            id="orderId"
                            type="number"
                            v-model="orderId"
                            autofocus
                            placeholder="Enter an Order ID"
                            @keypress.enter="submit()"
                        ></b-form-input>
                        <b-input-group-append>
                            <b-button
                                variant="outline-primary"
                                @click="submit()"
                                >Search</b-button
                            >
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>
        </b-card>

        <template v-if="!!consultation && !!orderNotes">
            <b-card>
                <div slot="header" class="no-print">
                    <i class="fa fa-align-justify"></i>
                    <strong class="header-spacing">General Notes</strong>
                  <b-button v-if="isShowLetter" class="float-right pt-2" size="sm" variant="outline-primary" @click="print()">Print</b-button>
                </div>

                <b-row :no-gutters="true" class="mb-4 no-print">
                    <b-col v-if="consultation.appointment" cols="6">
                        <h2>Consultation Details</h2>
                        <p>
                            Consultation date: {{parseDate(consultation.appointment.datetime)}} <br/>
                            Order date: {{consultation.creationDate | date}} <br/>
                            Order ID: {{consultation.id}}
                        </p>
                    </b-col>
                    <b-col cols="6">
                        <h2>Patient Details</h2>
                        <p>
                            Name: {{consultation.patient.displayName}} <br/>
                            Date of Birth: {{consultation.patient.dateOfBirth | date}} <br/>
                            Email: {{consultation.patient.email}}
                        </p>
                    </b-col>
                    <b-col v-if="consultation.doctor" cols="6">
                        <h2>Consulting Doctor Details</h2>
                        <p>
                            Name: {{consultation.doctor.displayName}} <br/>
                            {{consultation.doctor.settings.QUALIFICATIONS}}
                        </p>
                    </b-col>
                    <b-col v-if="consultation.patient.preferences.shareDetailsWithGP" cols="6">
                        <h2>Patient NHS GP Details</h2>
                        <p>
                            {{consultation.patient.displayName}}
                        </p>
                    </b-col>
                </b-row>

                <div v-if="!!orderNotes.prescriptionChangeReason" class="d-flex mb-2 no-print">
                    <span class="mr-2"><b>Reason for the repeat order:</b></span>
                    <span>{{orderNotes.prescriptionChangeReason.descriptionKey}} - {{orderNotes.prescriptionChangeReason.description}}</span>
                </div>
                
                <b-row :no-gutters="true" class="no-print">
                  <b-button v-if="!!patientFiles" class="mr-2" variant="outline-primary" v-b-modal.scrModal>Patient SCR documents</b-button>
                  <b-button v-if="!!orderNotes.repeatQuestionnaire" class="mr-2" variant="outline-primary" v-b-modal.repeatProformaModal>Repeat proforma</b-button>
                  <b-button v-if="!!orderNotes.pegNote && !!consultation.parentOrderItemId" class="mr-2" variant="outline-primary" v-b-modal.pegModal>PEG note</b-button>
                </b-row>

                <b-row v-if="isShowLetter">
                  <b-col cols="12">
                    <b-row>
                      <b-col cols="12">
                        <div style="padding-left: 10%; padding-right: 10%; text-align: right">
                          <img src="/static/img/logo.png" style="width: 7rem; margin-bottom: 1rem">
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <div style="padding-left: 10%; padding-right: 10%; font-size: 16px; text-align: right;">
                          Treat it, The TechnoCentre,<br>
                          Coventry University Technology Park,<br>
                          Puma Way, Coventry,<br>
                          CV1 2TT.<br>
                          <span>{{ gpLetterData.dateToday | date }}</span> <br>
                          <div class="form-container no-print">
                            <input type="date" v-model="selectedDate" class="date-input" :min="minDate" />
                            <button @click="submitDate" class="submit-button">Submit</button>
                          </div>
                        </div>
                        <div class="mt-3 mb-3" style="padding-left: 10%; padding-right: 10%; font-size: 16px; font-weight: bold">
                          Patient Name: {{ gpLetterData.patient.displayName }}<br>
                          Patient Date of Birth: {{ gpLetterData.patient.dateOfBirth }}<br>
                          Patient Address: {{ gpLetterData.patient.address }}<br>
                          Patient NHS Number: {{ gpLetterData.patient.patientNHSNumber }}
                        </div>
                        <div style="padding-left: 10%; padding-right: 10%; font-size: 16px" >
                          <div style="font-size: 16px; font-weight: bold">
                            Dear {{ gpLetterData.doctor && gpLetterData.doctor.gpName !== '' ? `${ gpLetterData.doctor.gpName }` : 'GP Practice' }},
                          </div>
                          <p class="mt-2" style="text-align: justify">
                            I am writing to you regarding our mutual patient, {{ gpLetterData.patient.displayName }}, who has attended our clinic for consideration of treatment with medical cannabis. After specialist consultation and MDT review, the patient has been approved for treatment.
                          </p>
                          <p style="margin-top: 5px">
                            If you require any additional information, please do not hesitate to contact me at - <b><a href="mailto:support@treat-it.clinic">support@treat-it.clinic</a></b>.
                          </p>
                          <p style="margin-top: 5px">
                            Yours sincerely,<br>
                            Dr Mehran Afshar<br>
                            MB BS(Lond) MRCP(UK) PGDIP PHDTreat IT<br>
                            TL: 0247 745 0733<br>
                            Email: support@treat-it.clinic
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </b-card>


            <b-card v-if="!consultation.isRepeatOrder" class="no-print">
                <div slot="header">
                    <i class="fa fa-align-justify"></i>
                    <strong class="header-spacing">GP Notes</strong>
                </div>    
                <decision-label role="GP" :approved="getOrderDecision('gp')" :description="!!orderNotes.gpDecision ? orderNotes.gpDecision.description : ''"/>
                <template v-if="!!orderNotes.gpDecision">
                    <b-row :no-gutters="true" class="mt-2">
                      <b-button class="mr-2" variant="outline-primary" v-b-modal.gpNoteModal>Questionnaire</b-button>
                      <b-button v-if="!!orderNotes.gpRecommendation" class="mr-2" variant="outline-primary" v-b-modal.gpRecommendationModal>GP Recommendation</b-button>
                    </b-row>

                    <b-row :no-gutters="true" class="mt-2">
                      <b-button v-if="!!orderNotes.auditNote" class="mr-2" variant="outline-primary" v-b-modal.auditModal>AUDIT note</b-button>
                      <b-button v-if="!consultation.parentOrderItemId && !!orderNotes.pegNote" class="mr-2" variant="outline-primary" v-b-modal.pegModal>PEG note</b-button>
                      <b-button v-if="!!orderNotes.gadNote" class="mr-2" variant="outline-primary" v-b-modal.gadModal>GAD note</b-button>
                      <b-button v-if="!!orderNotes.consentNote" class="mr-2" variant="outline-primary" v-b-modal.consentModal>Consent note</b-button>
                    </b-row>
                </template>
            </b-card>


            <b-card v-if="!consultation.isRepeatOrder && !!orderNotes.gpDecision" class="no-print">
                <div slot="header">
                    <i class="fa fa-align-justify"></i>
                    <strong class="header-spacing">MDT Notes</strong>
                </div>
                <decision-label role="MDT" :approved="getOrderDecision('mdt')" :description="!!orderNotes.mdtDecision ? orderNotes.mdtDecision.description : ''"/>

                <template v-if="!!orderNotes.mdtDecision">
                    <b-row :no-gutters="true" class="mt-2">
                      <b-button v-if="!!orderNotes.mdtProforma" class="mr-2" variant="outline-primary" v-b-modal.mdtProforma>MDT Proforma</b-button>
                      <b-button v-if="!!orderNotes.mdtRecommendation" class="mr-2" variant="outline-primary" v-b-modal.mdtRecommendationModal>MDT Recommendation</b-button>
                    </b-row>
                </template>
            </b-card>


            <b-card v-if="consultation.isRepeatOrder || (!consultation.isRepeatOrder && !!orderNotes.mdtDecision && orderNotes.mdtDecision.approved)" class="no-print">
                <div slot="header">
                    <i class="fa fa-align-justify"></i>
                    <strong class="header-spacing">Specialist Notes</strong>
                </div>
                <decision-label role="Specialist" :approved="getOrderDecision('specialist')" :description="!!orderNotes.specialistDecision ? orderNotes.specialistDecision.description : ''"/>
            
                <template v-if="!!orderNotes.specialistDecision">
                    <b-row :no-gutters="true" class="mt-2">
                      <b-button v-if="!!prescription" class="mr-2" variant="outline-primary" v-b-modal.prescriptionModal>Prescription</b-button>
                      <b-button v-if="!!orderNotes.specialistCallPatient && !!orderNotes.specialistCallPatient.questionAnswer" class="mr-2" variant="outline-primary" v-b-modal.specialistCall>Call proforma</b-button>
                      <b-button v-if="!!orderNotes.specialistPatientConvo" class="mr-2" variant="outline-primary" v-b-modal.specialistConversationModal>Conversations</b-button>
                      <b-button v-if="!!pinkPrescription && (pinkPrescription.images.length || pinkPrescription.documents.length)" class="mr-2" variant="outline-primary" v-b-modal.pinkPrescriptionModal>Pink prescription</b-button>
                    </b-row>
                </template>
            </b-card>


            <b-card v-if="!!orderNotes.pharmacyDecision || !!orderNotes.pharmacyRejecReason" class="no-print">
                <div slot="header">
                    <i class="fa fa-align-justify"></i>
                    <strong class="header-spacing">Pharmacy Notes</strong>
                </div>
                <decision-label role="Pharmacy" :approved="getOrderDecision('pharmacy')" :description="!!orderNotes.pharmacyRejecReason ? orderNotes.pharmacyRejecReason.description : ''"/>
                <span v-if="!orderNotes.pharmacyDecision && isRejectReasonAvailable()" style="margin-left: 1.8rem">
                    Rejected tracking code: {{isRejectReasonAvailable()}}
                </span>
                <span v-if="!!orderNotes.pharmacyDecision && !!prescription" style="margin-left: 1.8rem">
                    Tracking code: {{prescription.trackingCode}}
                </span>

                <decision-label role="Patient" :approved="getOrderDecision('patient')" :description="!!orderNotes.patientPharmacyDecision ? orderNotes.patientPharmacyDecision.description : ''"/>

                <h5 class="mt-3"><b>Pharmacy Details</b></h5>
                <p>
                    ID: {{consultation.pharmacy.id}}<br/>
                    Name: {{consultation.pharmacy.displayName}}<br/>
                    Contact: {{consultation.pharmacy.email}}
                </p>

                <b-row :no-gutters="true" class="mt-2">
                  <b-button v-if="!orderNotes.pharmacyDecision" class="mr-2" variant="outline-primary" v-b-modal.rejectedPrescriptionModal>Rejected prescription</b-button>
                </b-row>

            </b-card>

        </template>


        <!-- MODALS ========================================================================================= -->
        <template v-if="!!orderNotes">

            <b-modal v-if="!!patientFiles" id="scrModal" title="Patient SCR Documents" size="lg" :hide-footer="true">
                <file-view-modal :files="patientFiles" nameField="category" />
            </b-modal>

            <b-modal v-if="!!orderNotes.repeatQuestionnaire" id="repeatProformaModal" title="Repeat Order Proforma" :hide-footer="true">
                <repeat-proforma-modal :repeatNote="orderNotes.repeatQuestionnaire" />
            </b-modal>

            
            <b-modal v-if="!!orderNotes.auditNote" id="auditModal" title="AUDIT Notes" :hide-footer="true">
                <audit-modal :auditNote="orderNotes.auditNote.note" />
            </b-modal>
            
            <b-modal v-if="!!orderNotes.pegNote" id="pegModal" title="PEG Notes" :hide-footer="true">
                <peg-modal :pegNote="orderNotes.pegNote.note" />
            </b-modal>
            
            <b-modal v-if="!!orderNotes.gadNote" id="gadModal" title="GAD Notes" :hide-footer="true">
                <gad-modal :gadNote="orderNotes.gadNote.note" />
            </b-modal>
            
            <b-modal v-if="!!orderNotes.consentNote" id="consentModal" title="Patient Consent" size="lg" :hide-footer="true">
                <consent-modal />
            </b-modal>
            
            <b-modal v-if="!!orderNotes.gpRecommendation" id="gpRecommendationModal" title="GP Recommendation" :hide-footer="true">
                <recommendation-modal :recommendation="orderNotes.gpRecommendation.formulary" />
            </b-modal>
            
            <b-modal v-if="!!orderNotes.gpNote" id="gpNoteModal" title="Consultation Note" size="xl" :hide-footer="true">
                <consultation-note-modal :gpNote="orderNotes.gpNote.note" />
            </b-modal>


            <b-modal v-if="!!orderNotes.mdtRecommendation" id="mdtRecommendationModal" title="MDT Recommendation" :hide-footer="true">
                <recommendation-modal :recommendation="orderNotes.mdtRecommendation.formulary" :downloadable="true" printTitle="MDT Recommendation" />
            </b-modal>

            <b-modal v-if="!!orderNotes.mdtProforma" id="mdtProforma" title="MDT Proforma" :hide-footer="true" size="lg">
                <MDT-proforma-modal :mdtNote="orderNotes.mdtProforma" />
            </b-modal>


            <b-modal v-if="!!prescription" id="prescriptionModal" title="Prescription" :hide-footer="true">
                <recommendation-modal :recommendation="orderNotes.prescription" :downloadable="true" printTitle="Prescription" />
            </b-modal>

            <b-modal v-if="!!orderNotes.specialistCallPatient && !!orderNotes.specialistCallPatient.questionAnswer" id="specialistCall" title="Specialist - Patient Call Proforma" :hide-footer="true">
                <p>{{orderNotes.specialistCallPatient.questionAnswer}}</p>
            </b-modal>

            <b-modal v-if="!!pinkPrescription" id="pinkPrescriptionModal" title="Pink Prescription" size="lg" :hide-footer="true">
                <file-view-modal :files="pinkPrescription" nameField="originalFileName" />
            </b-modal>

            <b-modal v-if="!!orderNotes.specialistPatientConvo" id="specialistConversationModal" size="lg" title="Specialist - Patient conversations" :hide-footer="true">
                <specialist-conversation-modal :convoList="orderNotes.specialistPatientConvo" />
            </b-modal>


            <b-modal v-if="!!prescription" id="rejectedPrescriptionModal" title="Rejected Prescription" :hide-footer="true">
                <recommendation-modal :recommendation="orderNotes.prescription" />
            </b-modal>
        </template>
    </div>
</template>

<script>
import moment from "moment";

import DecisionLabel from './DecisionLabel.vue';
import FileViewModal from '../../modals/FileViewModal.vue';
import RepeatProformaModal from '../../modals/RepeatProformaModal.vue';
import AuditModal from '../../modals/AuditModal.vue';
import PegModal from '../../modals/PegModal.vue';
import GadModal from '../../modals/GadModal.vue';
import ConsentModal from '../../modals/ConsentNoteModal.vue';
import RecommendationModal from '../../modals/RecommendationModal.vue';
import ConsultationNoteModal from '../../modals/ConsultationNoteModal.vue';
import MDTProformaModal from '../../modals/MDTProformaModal.vue';
import SpecialistConversationModal from '../../modals/SpecialistConversationModal.vue';

export default {
    name: "GPNotes",
    components: {
      DecisionLabel,
      FileViewModal,
      RepeatProformaModal,
      PegModal,
      AuditModal,
      GadModal,
      ConsentModal,
      RecommendationModal,
      ConsultationNoteModal,
      MDTProformaModal,
      SpecialistConversationModal,
    },
    data() {
        return {
            orderId: "",
            pages: "",
            consultationDate: "",
            consultation: "",
            gpNotes: "",
            prescription: "",
            pinkPrescription: undefined,
            patientFiles: undefined,
            keys: "",
            shareDetailsWithGp: true,
            orderNotFound: false,
            submitted: false,
            gridColumns: ["Question", "Answer"],
            orderNotes: undefined,
            gpLetterData: {},
            appointmentMedicationFields: [
              { key: 'formulary.productName',
                label: 'Medication'
              },
              { key: 'formulary.administrationRoute',
                label: 'Route'
              },
              { key: 'formulary.medicineProfile',
                label: 'Profile'
              },
              { key: 'formulary.quantity',
                label: 'Unit Quantity'
              },
              { key: 'dosage',
                label: 'Dosage'
              },
              { key: 'quantity',
                label: 'Quantity'
              }
            ],
          selectedDate: '',
          minDate: new Date().toISOString().split('T')[0],
          isShowLetter: false
        };
    },
    filters: {
        dateTime: function(date) {
            return moment(date)
                .local()
                .format("Do MMMM YYYY, HH:mm");
        },
        date: function(date) {
            return moment(date).format("DD MMM YYYY");
        },
        dateLong: function(date) {
            return moment(date).format("Do MMMM YYYY");
        },
        capitalize: function(text) {
            return text[0].toUpperCase() + text.slice(1);
        },
    },
    created() {
        this.orderId = this.$router.currentRoute.params.orderId;
        if (this.orderId) {
            this.submit();
        }
    },
    methods: {
        async submit() {
            this.$router.push("/gp-notes/" + this.orderId);
            this.clear();
            const data = await this.$orderService.getOrder(this.orderId);
            if (data && data.isRepeatOrder === false) {
              if (data.patient.preferences.shareDetailsWithGp !== 'false') {
                this.isShowLetter = true;
              }
            }
            this.submitted = true;
            this.consultation = data;
            const notes = this.$orderService.getOrderNotes(data.orderNotes);
            this.orderNotes = notes;
            this.getPatientFiles();
            await this.getPrescription();
            await this.prepareGPLetterData();

        },
        isRejectReasonAvailable(){
            console.log(this.orderNotes.pharmacyRejecReason.trackingCode)
            return this.orderNotes.pharmacyRejecReason.trackingCode
        },
        parseDate(dateValue) {
            if (!dateValue) {
                return "";
            }
            const date = new Date(dateValue);
            const options = {
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            };
            const formattedDate = date.toLocaleString("en-GB", options);
            return formattedDate;
        },
        getOrderDecision(role){
            switch (role) {
                case 'gp':
                    if (this.orderNotes.gpDecision) {
                        return this.orderNotes.gpDecision.approved
                    }
                    return undefined

                case 'mdt':
                    if (this.orderNotes.mdtDecision) {
                        return this.orderNotes.mdtDecision.approved
                    }
                    return undefined

                case 'specialist':
                    if (this.orderNotes.specialistDecision) {
                        return this.orderNotes.specialistDecision.approved
                    }
                    return undefined

                case 'pharmacy':
                    if (this.orderNotes.pharmacyDecision) {
                        return true;
                    }
                    if (this.orderNotes.pharmacyRejecReason) {
                        return false;
                    }
                    return undefined

                case 'patient':
                    if (this.orderNotes.patientPharmacyDecision) {
                        return this.orderNotes.patientPharmacyDecision.approved
                    }
                    return undefined

                default:
                    break;
            }
        },
        async getPrescription() {
            const pinkNote = this.$orderService.getPinkPrescription(this.consultation.files);
            this.pinkPrescription = pinkNote;
            const data = await this.$orderService.getPrescription(this.orderId) 
            this.prescription = data;
            this.orderNotes.prescription = {
                ...data.medication[0].formulary,
                comments: data.additionalPatientNotes
            }
        },
        async prepareGPLetterData () {
          if (this.isShowLetter) {
            this.gpLetterData = {
              dateToday:  this.parseDate(new Date()),
            }
            if (this.consultation.patient !== null) {
              this.gpLetterData.patient = {
                id: this.consultation.patient.id,
                displayName: this.consultation.patient.displayName,
                dateOfBirth: this.consultation.patient.dateOfBirth,
                address:`${this.consultation.patient.address.line1}, ${this.consultation.patient.address.line2 !== null ? this.consultation.patient.address.line2 : ''}, ${this.consultation.patient.address.city}, ${ this.consultation.patient.address.county !== null ? this.consultation.patient.address.county : '' }, ${ this.consultation.patient.address.postcode !== null ? this.consultation.patient.address.postcode : '' }.`,
                patientNHSNumber: this.consultation.patient.nhsNumber && this.consultation.patient.nhsNumber !== null ? this.consultation.patient.nhsNumber : 'XXXX'
              }
            }
          }
        },
        getPatientFiles() {
            const docs = this.$orderService.getPatientDocs(this.consultation.patient.files);
            this.patientFiles = docs;
        },
        handleAnswer(answer) {
            if (answer === "true") {
                return "Confirmed";
            } else if (answer == "false") {
                return "No";
            } else {
                return answer;
            }
        },
        submitDate() {
          this.gpLetterData.dateToday = this.selectedDate;
        },
        clear() {
            this.orderNotFound = false;
            this.orderNotes = undefined;
            this.consultationDate = null;
            this.consultation = null;
            this.gpNotes = null;
            this.prescription = null;
            this.keys = null;
        },

        checkSharedDetailsWithGP() {
            if (this.consultation.type === "BOOKING" && this.gpNotes) {
                if (this.gpNotes.details.shareDetailsWithGp == "false") {
                    this.shareDetailsWithGp = false;
                } else {
                    this.shareDetailsWithGp = true;
                }
            }
            if (this.consultation.type !== "BOOKING" && this.keys) {
                if (
                    this.consultation.form["disclaimer.shareDetailsWithGp"] &&
                    this.consultation.form["disclaimer.shareDetailsWithGp"] ===
                        "false"
                ) {
                    this.shareDetailsWithGp = false;
                } else if (
                    this.consultation.form["disclaimer.shareDetailsWithGP"] &&
                    this.consultation.form["disclaimer.shareDetailsWithGP"] ===
                        "false"
                ) {
                    this.shareDetailsWithGp = false;
                } else {
                    this.shareDetailsWithGp = true;
                }
            }

            let status = this.consultation.status;

            let arr = [
                "BOOKED",
                "CANCELED_BY_DOCTOR",
                "REJECTED_BY_DOCTOR",
                "REJECTED_BY_PATIENT",
                "WAITING_FOR_DOCTOR_APPROVAL",
                "PROPOSED_BY_DOCTOR",
            ];

            if (arr.includes(status)) {
                this.shareDetailsWithGp = false;
            }
        },

        print() {
            document.title = "Patient Notes";
            window.print();
        },
    },
};
</script>

<style lang="scss" scoped>
.smaller-paragraphs p {
    margin-bottom: 0em;
}
.print-only {
    display: none;
}
@media screen {
    div.divFooter {
        display: none;
    }
}
@media print {
  @page {
    margin-top: 2cm;
    margin-bottom: 2cm;
  }
  @page :first {
    margin-top: 1cm;
  }
  div.divFooter {
    position: fixed;
    bottom: 0;
  }
  table {
    page-break-inside:auto;
  }
  tr {
    page-break-inside:avoid;
    /* padding: 0; */
    /* margin: 0; */
  }
  table tr td, table tr th {
    page-break-inside: avoid;
  }
}
.order-label {
    margin-top: 0.4em;
}
.site-logo {
    width: 7em;
    margin-top: 1em;
}
.darker-row {
    background: #f0f3f5;
}

.form-container {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 8px;
}

.date-input {
  padding: 0.5rem;
  margin-right: 1rem;
}

.submit-button {
  padding: 0.5rem 1rem;
  background-color: #550385;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>
