<script>
const FormularyService = {};

FormularyService.install = function(Vue) {
    var formularyService = new Object();

    Object.defineProperty(Vue.prototype, "$formularyService", {
        value: formularyService,
    });

    formularyService.search = function(
        pharmacy = "",
        searchQuery = "",
        page = 0,
        size = 10
    ) {
        var url;
        if (searchQuery) {
            url = `/api/admin/admin-formulary?pharmacy=${pharmacy}&q=${searchQuery}&page=${page}&size=${size}`;
        } else {
            url = `/api/admin/admin-formulary?pharmacy=${pharmacy}&page=${page}&size=${size}`;
        }
        return Vue.axios
            .get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    formularyService.addFormulary = function(formulary) {
        var url = "/api/admin/admin-formulary";
        return Vue.axios
            .post(url, { adminFormularyList: formulary })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    formularyService.updateFormulary = function(id, formulary) {
        var url = `/api/admin/admin-formulary/${id}`;
        return Vue.axios
            .put(url, { ...formulary })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    formularyService.uploadCSV = function(file, pharmacyId) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("pharmacyId", pharmacyId);
        var url = "/api/admin/admin-formulary/bulk/upload";
        return Vue.axios.post(url, formData).catch((error) => {
            this.handleError(error);
            return Promise.reject(error);
        });
    };

    formularyService.handleError = function(error) {
        if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
            // Vue.$router.push('/login')
            console.log("error.request ", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            // Vue.$router.push('/login')
            console.log("error", error.request);
        }
    };
};

export default FormularyService;
</script>
