<template>
  <div v-if="patient.id">

    <b-card no-title no-body>
      <div class="own-header">
        <b-row>
          <b-col class="display-name" sm="12" md="6">
            <i class="fa fa-align-justify"></i>
            <strong class="header-spacing"> {{patient.displayName}} </strong>
          </b-col>
          <b-col sm="12" md="6">
            <b-button v-if="!editable" variant="warning" @click="allowChanges()" class="float-right">Update Patient</b-button>
            <b-button v-if="editable" variant="primary" @click="updatePatient()" class="float-right">Confirm</b-button>
            <b-button v-if="editable" variant="outline-danger" @click="reset()" class="float-right margin-1-percent">Reset</b-button>
          </b-col>
        </b-row>
      </div>
      <b-card-body>
        <b-row>
          <b-col md="12" lg="6">
            <b-row>
              <b-col sm="12" md="4" lg="12">
                <b-form-group id="id" label="Patient ID" label-for="id">
                  <b-form-input name="id" type="text" v-model="patient.id" disabled />
                </b-form-group>
              </b-col>

            </b-row>
            <b-row>
              <b-col sm="6" md="4" lg="3">
                <b-form-group id="active" label="Active" label-for="active">
                  <b-form-radio-group v-if="editable && !patientManaged" :block="true" button-variant="outline-primary" buttons v-model="patient.active" name="active" :options="truthyFalsy"/>
                  <b-button-toolbar v-if="!editable || patientManaged">
                    <b-button v-bind:variant="patient.active ? 'primary' : 'outline-primary'" name="active" type="text" disabled> Yes </b-button>
                    <b-button v-bind:variant="!patient.active ? 'primary' : 'outline-primary'" name="active" type="text" disabled> No </b-button>
                  </b-button-toolbar>
                </b-form-group>
              </b-col>

              <b-col sm="6" md="4" lg="3">
                <b-form-group id="marketingConsent" label="Marketing" label-for="marketingConsent">
                  <b-form-radio-group v-if="editable  && !patientManaged" :block="true" button-variant="outline-primary" buttons v-model="patient.marketingConsent" name="marketing" :options="truthyFalsy"/>
                  <b-button-toolbar v-if="!editable || patientManaged">
                    <b-button v-bind:variant="patient.marketingConsent ? 'primary' : 'outline-primary'" name="active" type="text" disabled> Yes </b-button>
                    <b-button v-bind:variant="!patient.marketingConsent ? 'primary' : 'outline-primary'" name="active" type="text" disabled> No </b-button>
                  </b-button-toolbar>
                </b-form-group>
              </b-col>

              <b-col sm="6" md="4" lg="4">
                <b-form-group id="gender" label="Gender" label-for="gender">
                  <b-form-radio-group v-if="editable" :block="true" button-variant="outline-primary" buttons v-model="patient.gender" name="gender" :options="genderOptions"/>
                  <b-button-toolbar v-if="!editable">
                    <b-button v-bind:variant="patient.gender === 'MALE' ? 'primary' : 'outline-primary'" name="active" type="text" disabled> Male </b-button>
                    <b-button v-bind:variant="patient.gender === 'FEMALE' ? 'primary' : 'outline-primary'" name="active" type="text" disabled> Female </b-button>
                  </b-button-toolbar>
                </b-form-group>
              </b-col>

              <b-col sm="6" md="4" lg="3">
                <b-form-group id="dischargedConsent" label="Discharged" label-for="dischargedConsent">
                  <b-form-radio-group v-if="editable  && !patientManaged" :block="true" button-variant="outline-danger" buttons v-model="patient.discharged" name="discharged" :options="truthyFalsy"/>
                  <b-button-toolbar v-if="!editable || patientManaged">
                    <b-button v-bind:variant="patient.discharged ? 'danger' : 'outline-danger'" name="active" type="text" disabled> Yes </b-button>
                    <b-button v-bind:variant="!patient.discharged ? 'danger' : 'outline-danger'" name="active" type="text" disabled> No </b-button>
                  </b-button-toolbar>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-select v-model="patient.title" :options="titleOptions" class="mb-3" :disabled="!editable"/>

            <b-row>
              <b-col md="12" lg="6">
                <b-form-group id="firstName" label="First Name" label-for="firstName">
                  <b-form-input name="firstName" type="text" v-model="patient.firstName" v-validate="'required'" :disabled="!editable"/>
                  <span v-show="errors.has('firstName')" class="text-danger">{{ errors.first('firstName') }}</span>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="lastName" label="Last Name" label-for="lastName">
                  <b-form-input name="lastName" type="text" v-model="patient.lastName" v-validate="'required'" :disabled="!editable"/>
                  <span v-show="errors.has('lastName')" class="text-danger">{{ errors.first('lastName') }}</span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group id="email" label="Email" label-for="email" label-cols="4">
              <b-form-input name="email" type="text" v-model="patient.email" v-validate="'required|email'" :disabled="!editable"/>
              <span v-show="errors.has('email')" class="text-danger">{{ errors.first('email') }}</span>
            </b-form-group>
            <b-form-group id="phone" label="Phone" label-for="phone" label-cols="4">
              <b-form-input name="phone" type="text" v-model="patient.phone" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('phone')" class="text-danger">{{ errors.first('phone') }}</span>
            </b-form-group>
            <b-form-group id="dob" label="Date of Birth" label-for="dob" label-cols="4">
              <b-form-input name="dob" type="text" v-model="patient.dateOfBirth" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('dob')" class="text-danger">{{ errors.first('dob') }}</span>
            </b-form-group>
            <b-form-group id="platform" label="Platform" label-for="platform" label-cols="4">
              <b-form-input name="platform" type="text" v-model="patient.platform" v-validate="'required'" :disabled="true"/>
              <span v-show="errors.has('platform')" class="text-danger">{{ errors.first('platform') }}</span>
            </b-form-group>
            <b-form-group id="regStatus" label="Registration status" label-for="regStatus" label-cols="4">
              <b-form-input name="regStatus" type="text" v-model="patient.regStatus" v-validate="'required'" :disabled="true"/>
              <span v-show="errors.has('regStatus')" class="text-danger">{{ errors.first('regStatus') }}</span>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group id="line1" label="Line 1" label-for="line1">
              <b-form-input name="line1" type="text" v-model="patient.address.line1" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('line1')" class="text-danger">{{ errors.first('line1') }}</span>
            </b-form-group>
            <b-form-group id="line2" label="Line 2" label-for="line2">
              <b-form-input name="line2" type="text" v-model="patient.address.line2" :disabled="!editable"/>
            </b-form-group>
            <b-form-group id="county" label="County" label-for="county">
              <b-form-input name="county" type="text" v-model="patient.address.county" :disabled="!editable"/>
            </b-form-group>
            <b-form-group id="city" label="City" label-for="city">
              <b-form-input name="city" type="text" v-model="patient.address.city" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('city')" class="text-danger">{{ errors.first('city') }}</span>
            </b-form-group>
            <b-form-group id="postcode" label="Postcode" label-for="postcode">
              <b-form-input name="postcode" type="text" v-model="patient.address.postcode" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('postcode')" class="text-danger">{{ errors.first('postcode') }}</span>
            </b-form-group>

            <b-form-group id="primaryspecialist" label="Primary Specialist" label-for="primaryspecialist">
              <b-form-input name="primaryspecialist" type="text" v-model="careSpecialist" v-validate="'required'" :disabled="true"/>
            </b-form-group>

            </b-col>
          </b-row>

          <br/>
          <b-row>
            <b-col>
            <div v-if="patient.settings">
              <b-row>
                <b-col md="4" class="mb-1">
                  <b-form-group id="EXTERNALLY_IDENTIFIED" label="Externally Identified" label-for="EXTERNALLY_IDENTIFIED_BY" label-cols="6" >
                    <b-form-radio-group v-if="editable" button-variant="outline-primary" buttons v-model="patient.settings.EXTERNALLY_IDENTIFIED" name="EXTERNALLY_IDENTIFIED" :options="truthyFalsy"/>
                    <b-button-toolbar>
                      <b-button v-if="!editable" v-bind:variant="patient.settings.EXTERNALLY_IDENTIFIED ? 'primary' : 'outline-primary'" name="active" type="text" disabled> Yes </b-button>
                      <b-button v-if="!editable" v-bind:variant="!patient.settings.EXTERNALLY_IDENTIFIED ? 'primary' : 'outline-primary'" name="active" type="text" disabled> No </b-button>
                    </b-button-toolbar>
                  </b-form-group>
                </b-col>
                <b-col v-if="patient.settings.EXTERNALLY_IDENTIFIED">
                  <b-form-group id="EXTERNALLY_IDENTIFIED_BY" label="Externally Identified By" label-for="EXTERNALLY_IDENTIFIED_BY" label-cols="5">
                    <b-form-input name="EXTERNALLY_IDENTIFIED_BY" type="text" v-model="patient.settings.EXTERNALLY_IDENTIFIED_BY" v-validate="externalIdentificationRule" disabled/>
                    <span v-show="errors.has('EXTERNALLY_IDENTIFIED_BY')" class="text-danger">{{ errors.first('EXTERNALLY_IDENTIFIED_BY') }}</span>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4" class="mb-1">
                  <b-form-group id="SCR_VALIDITY_CONFIRMATION" label="SCR Validity Confirmed" label-for="SCR_VALIDITY_CONFIRMATION" label-cols="6" >
                    <b-form-radio-group v-if="editable" button-variant="outline-primary" buttons v-model="patient.settings.SCR_VALIDITY_CONFIRMATION" name="SCR_VALIDITY_CONFIRMATION" :options="truthyFalsy"/>
                    <b-button-toolbar>
                      <b-button v-if="!editable" v-bind:variant="patient.settings.SCR_VALIDITY_CONFIRMATION ? 'primary' : 'outline-primary'" name="active" type="text" disabled> Yes </b-button>
                      <b-button v-if="!editable" v-bind:variant="!patient.settings.SCR_VALIDITY_CONFIRMATION ? 'primary' : 'outline-primary'" name="active" type="text" disabled> No </b-button>
                    </b-button-toolbar>
                  </b-form-group>
                </b-col>
                <b-col v-if="patient.settings.SCR_VALIDITY_CONFIRMATION">
                  <b-form-group id="SCR_CONFIRMED_BY" label="SCR Validity Confirmed By" label-for="SCR_CONFIRMED_BY" label-cols="5">
                    <b-form-input name="SCR_CONFIRMED_BY" type="text" v-model="patient.settings.SCR_CONFIRMED_BY" v-validate="scrValidityConfirmationRule" disabled/>
                    <span v-show="errors.has('SCR_CONFIRMED_BY')" class="text-danger">{{ errors.first('SCR_CONFIRMED_BY') }}</span>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group v-if="patient.settings.LOGIN_ATTEMPTS" id="LOGIN_ATTEMPTS" label="Login attempts" label-for="LOGIN_ATTEMPTS" label-cols="5">
                <b-row>
                  <b-col md="4" class="mb-1">
                    <b-form-input name="LOGIN_ATTEMPTS" type="text" v-model="patient.settings.LOGIN_ATTEMPTS" disabled/>
                  </b-col>
                  <b-col>
                    <b-button block type="button" variant="outline-danger" @click="patient.settings.LOGIN_ATTEMPTS = ''" :disabled="!editable">Clear login attempts</b-button>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group v-if="patient.settings.COUPON_ATTEMPTS" id="COUPON_ATTEMPTS" label="Coupon attempts" label-for="COUPON_ATTEMPTS" label-cols="5">
                <b-row>
                  <b-col md="4" class="mb-1">
                    <b-form-input name="COUPON_ATTEMPTS" type="text" v-model="patient.settings.COUPON_ATTEMPTS" disabled/>
                  </b-col>
                  <b-col>
                    <b-button block type="button" variant="outline-danger" @click="patient.settings.COUPON_ATTEMPTS=''" :disabled="!editable">Clear coupon attempts</b-button>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group id="associateWithAnEnterprise" label="Enterprise" label-for="associateWithAnEnterprise" label-cols="5">
                <b-button v-if="!associateWithAnEnterprise" type="button" block variant="outline-primary" @click="associateWithAnEnterprise = true" :disabled="!editable">Add enterprise</b-button>
                <b-row v-if="associateWithAnEnterprise">
                  <b-col md="4" class="mb-1">
                    <b-form-input name="ENTERPRISE" type="text" v-model="patient.settings.ENTERPRISE" v-validate="enterpriseRule" :disabled="!editable"/>
                    <span v-show="errors.has('ENTERPRISE')" class="text-danger">{{ errors.first('ENTERPRISE') }}</span>
                  </b-col>
                  <b-col>
                    <b-button block type="button" variant="outline-danger" @click="removeEnterprise()" :disabled="!editable">
                      Remove enterprise
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Patient KYC Documents -->
    <b-card no-title no-body>
      <div class="own-header">
        <b-row>
          <b-col class="display-name" sm="12" md="6">
            <i class="fa fa-align-justify"></i>
            <strong class="header-spacing">Patient KYC Documents</strong>
          </b-col>
          <b-col sm="12" md="6">
            <b-button variant="warning" @click="toggleUpload('KYC', true)" class="float-right">Upload KYC Documents</b-button>
            <b-button v-if="uploadKYC" variant="outline-danger" @click="toggleUpload('KYC', false)" class="float-right margin-1-percent">Reset</b-button>
            <b-button variant="warning" @click="openActiveArchive('kyc')" class="float-right active-archive-btn">Active / Archive Image</b-button>
          </b-col>
        </b-row>
      </div>
      <b-card-body>
        <b-row v-if="!showKYCloading">
          <b-col sm="4">
            <div v-for="(item, index) in patient.files" v-bind:key="index">
              <div v-if="item.fileName && (item.action === 'PATIENT_KYC_DOCUMENTS' || item.action === 'PATIENT_PROFILE_PICTURE') && item.status === true">
                <p v-if="item.category !== 'PATIENT_PROFILE_PICTURE'" class="image-name-label">Category: {{ item.category.replace(/_/g, ' ') }}</p>
                <p v-if="item.category === 'PATIENT_PROFILE_PICTURE'" class="image-name-label">Category: Profile Picture</p>
                <document-viewer :file="item" :archiveMethod="moveKYCActiveArchive"/>
                <hr />
              </div>
            </div>
            <div v-if="patient.files.filter(file => file.action === 'PATIENT_KYC_DOCUMENTS').length === 0">
              No files to show
            </div>
          </b-col>

          <b-col sm="8" v-if="closeKYCActiveTable">
            <div class="close-parent">
              <button @click="closeActiveArchive('kyc')" class="close-button">
                <i class="fa fa-times"></i>
              </button>
            </div>
            
            <b-row class="form-group">

              <!-- KYC Active Panel -->
              <b-col sm="6" class="archive-panel">
                <b-card-group deck>
                  <b-card no-body header="Active Documents" class="text-center">
                    <b-list-group class="text-left">
                      <b-list-group-item v-for="(docs, index) in kycActiveDocuments" v-bind:key="index">
                        <div class="d-flex justify-content-between align-items-center">
                          <span style="max-width: 80%;">{{ docs.fileName }}</span>
                          <b-form-checkbox
                            v-bind:key="index"
                            v-model="selectedKYCActive"
                            v-bind:name="docs.fileName"
                            v-bind:value="docs.id"
                            unchecked-value=""
                          />
                        </div>
                      </b-list-group-item>
                    </b-list-group>

                    <b-button v-if="kycActiveDocuments && kycActiveDocuments.length > 0" @click="moveKYCActiveArchive()" class="m-3 width-fit-content align-self-center" >
                        Move to Archive Documents
                    </b-button>
                    <div v-if="kycActiveDocuments && kycActiveDocuments.length <= 0" class="m-3 width-fit-content align-self-center">No files to show</div>
                  </b-card>
                </b-card-group>
              </b-col>

              <!-- KYC Archive Panel -->
              <b-col sm="6" class="archive-panel">
                <b-card-group deck>
                  <b-card no-body header="Archive Documents" class="text-center">
                    <b-list-group class="text-left">
                      <b-list-group-item v-for="(docs, index) in kycArchiveDocuments.files" v-bind:key="index">
                        <div v-if="docs.status === false" class="d-flex justify-content-between align-items-center">
                          <span style="max-width: 80%;">{{ docs.fileName }}</span>
                          <b-form-checkbox
                            v-bind:key="index"
                            v-model="selectedKYCArchive"
                            v-bind:name="docs.fileName"
                            v-bind:value="docs.id"
                            unchecked-value=""
                          />
                        </div>
                      </b-list-group-item>
                    </b-list-group>

                    <b-button v-if="kycArchiveDocuments && kycArchiveDocuments.files && kycArchiveDocuments.files.length > 0" @click="moveKYCArchiveActive()" class="m-3 width-fit-content align-self-center" >
                        Move to Active Documents
                    </b-button>
                    <div v-if="kycArchiveDocuments && kycArchiveDocuments.files && kycArchiveDocuments.files.length <= 0" class="m-3 width-fit-content align-self-center">No files to show</div>
                  </b-card>
                </b-card-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="12">
            <div v-if="uploadKYC === true">
              <hr v-if="patient.files.filter(file => file.action === 'PATIENT_KYC_DOCUMENTS').length === 0" />
              <b-row class="form-group">
                  <b-col>
                    <label for="file">Select KYC Document</label>
                      <b-form-file
                          type="file"
                          v-model="file"
                          name="file"
                          accept=".pdf, .jpg, .png, .doc, .docs, .ppt, .pptx .xls, .xlsx"
                      />
                  </b-col>
                  <b-col>
                      <label for="file">Select Document Type</label>
                      <select class="form-control" v-model="selectedFileType">
                          <option value="" selected hidden>Select an option</option>
                          <option value="driving_license">Driving license</option>
                          <option value="identification_of_residence">Identification of Residence (ID)</option>
                          <option value="passport">Passport</option>
                          <option value="PATIENT_PROFILE_PICTURE">Profile Picture</option>
                      </select>
                  </b-col>
              </b-row>
              <b-row class="mt-3 mx-0">
                  <div class="ml-auto">
                      <b-btn
                          variant="primary"
                          class="w-100"
                          @click="uploadDocument"
                          :disabled="file.length === 0 || selectedFileType === undefined"
                      >
                          Upload Document
                      </b-btn>
                  </div>
              </b-row>
            </div>
          </b-col>

        </b-row>
        <div v-if="showKYCloading" class="loadingOrders">Loading...</div>
      </b-card-body>
    </b-card>

    <!-- Patient SCR Documents -->
    <b-card no-title no-body>
      <div class="own-header">
        <b-row>
          <b-col class="display-name" sm="12" md="6">
            <i class="fa fa-align-justify"></i>
            <strong class="header-spacing">Patient SCR Documents</strong>
          </b-col>
          <b-col sm="12" md="6">
            <b-button variant="warning" @click="toggleUpload('SCR', true)" class="float-right">Upload SCR Documents</b-button>
            <b-button v-if="uploadSCR" variant="outline-danger" @click="toggleUpload('SCR', false)" class="float-right margin-1-percent">Reset</b-button>
            <b-button variant="warning" @click="openActiveArchive('scr')" class="float-right active-archive-btn">Active / Archive Image</b-button>
          </b-col>
        </b-row>
      </div>
      <b-card-body>
        <b-row v-if="!showSCRloading">
          <b-col sm="4">
            <div v-for="(item, index) in patient.files" v-bind:key="index">
              <div v-if="item.fileName && item.action === 'PATIENT_SCR_DOCUMENTS' && item.status === true">
                <p class="image-name-label">Category: {{ item.category }}</p>
                <document-viewer :file="item" :archiveMethod="moveSCRActiveArchive"/>
                <hr />
              </div>
            </div>
            <div v-if="patient.files.filter(file => file.action === 'PATIENT_SCR_DOCUMENTS').length === 0">
              No files to show
            </div>
          </b-col>

          <b-col sm="8" v-if="closeSCRActiveTable">
            <div class="close-parent">
              <button @click="closeActiveArchive('scr')" class="close-button">
                <i class="fa fa-times"></i>
              </button>
            </div>
            
            <b-row class="form-group">

              <!-- SCR Active Panel -->
              <b-col sm="6" class="archive-panel">
                <b-card-group deck>
                  <b-card no-body header="Active Documents" class="text-center">
                    <b-list-group class="text-left">
                      <b-list-group-item v-for="(docs, index) in scrActiveDocuments" v-bind:key="index">
                        <div class="d-flex justify-content-between align-items-center">
                          <span style="max-width: 80%;">{{ docs.fileName }}</span>
                          <b-form-checkbox
                            v-bind:key="index"
                            v-model="selectedSCRActive"
                            v-bind:name="docs.fileName"
                            v-bind:value="docs.id"
                            unchecked-value=""
                          />
                        </div>
                      </b-list-group-item>
                    </b-list-group>

                    <b-button v-if="scrActiveDocuments && scrActiveDocuments.length > 0" @click="moveSCRActiveArchive()" class="m-3 width-fit-content align-self-center" >
                        Move to Archive Documents
                    </b-button>
                    <div v-if="scrActiveDocuments && scrActiveDocuments.length <= 0" class="m-3 width-fit-content align-self-center">No files to show</div>
                  </b-card>
                </b-card-group>
              </b-col>

              <!-- SCR Archive Panel -->
              <b-col sm="6" class="archive-panel">
                <b-card-group deck>
                  <b-card no-body header="Archive Documents" class="text-center">
                    <b-list-group class="text-left">
                      <b-list-group-item v-for="(docs, index) in scrArchiveDocuments.files" v-bind:key="index">
                        <div class="d-flex justify-content-between align-items-center">
                          <span style="max-width: 80%;">{{ docs.fileName }}</span>
                          <b-form-checkbox
                            v-bind:key="index"
                            v-model="selectedSCRArchive"
                            v-bind:name="docs.fileName"
                            v-bind:value="docs.id"
                            unchecked-value=""
                          />
                        </div>
                      </b-list-group-item>
                    </b-list-group>

                    <b-button v-if="scrArchiveDocuments && scrArchiveDocuments.files && scrArchiveDocuments.files.length > 0" @click="moveSCRArchiveActive()" class="m-3 width-fit-content align-self-center" >
                        Move to Active Documents
                    </b-button>
                    <div v-if="scrArchiveDocuments && scrArchiveDocuments.files && scrArchiveDocuments.files.length <= 0" class="m-3 width-fit-content align-self-center">No files to show</div>
                  </b-card>
                </b-card-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="12">
            <div v-if="uploadSCR === true">
              <hr v-if="patient.files.filter(file => file.action === 'PATIENT_SCR_DOCUMENTS').length === 0" />
              <b-row class="form-group">
                  <b-col>
                      <label for="file">Select SCR Document</label>
                      <b-form-file
                          type="file"
                          v-model="file"
                          name="file"
                          accept=".pdf, .jpg, .png, .doc, .docs, .ppt, .pptx .xls, .xlsx"
                      />
                  </b-col>
                  <b-col>
                      <label for="file">Select Document Type</label>
                      <select class="form-control" v-model="selectedFileType">
                        <option value="" selected hidden>Select an option</option>
                        <option value="previous_prescription">Previous prescription copy</option>
                        <option value="other">Other supporting document</option>
                      </select>
                  </b-col>
              </b-row>
              <b-row class="mt-3 mx-0">
                  <div class="ml-auto">
                      <b-btn
                          variant="primary"
                          class="w-100"
                          @click="uploadDocument"
                          :disabled="file.length === 0 || selectedFileType === undefined"
                      >
                          Upload Document
                      </b-btn>
                  </div>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <div v-if="showSCRloading" class="loadingOrders">Loading...</div>
      </b-card-body>
    </b-card>

    <!-- Admin Key Alert -->
    <b-card no-title no-body>
      <div class="own-header">
        <b-row>
          <b-col class="display-name" sm="12" md="6">
            <i class="fa fa-align-justify"></i>
            <strong class="header-spacing">Admin Key Alert</strong>
          </b-col>
          <b-col sm="12" md="6">
            <b-button v-if="!adminAlertEditable" variant="warning" @click="toggleAdminAlertEditable()" class="float-right">Update Alert</b-button>
            <b-button v-if="adminAlertEditable" variant="primary" @click="updateAdminAlert()" class="float-right">Confirm</b-button>
            <b-button v-if="adminAlertEditable" variant="outline-danger" @click="toggleAdminAlertEditable()" class="float-right margin-1-percent">Cancel</b-button>
          </b-col>
        </b-row>
      </div>
      <b-card-body>
        <b-row>
          <b-col sm="12">
            <b-form-group id="adminKeyAlert" label="Information about the patient that all doctors should be aware of:" label-for="adminKeyAlert">
              <b-form-textarea name="adminKeyAlert" type="text" class="text-left" :maxlength="250"
                               v-model="tempAdminAlert" :disabled="!adminAlertEditable"/>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Orders of this patient-->
    <orders-table title="Orders" :orders="orders" :page="currentPage" :action="action" :click-event="clickEvent" :column-names="columnNames"></orders-table>
  </div>
</template>

<script>

import moment from 'moment'
import OrdersTable from '../../components/OrdersTable'
import OrderEventBus from '../../EventBus.js'
import DocumentViewer from '../../components/DocumentViewer.vue'

export default {
  name: 'Orders',
  components: {
    OrdersTable,
    DocumentViewer,
  },
  data: function () {
    return {
      uploadKYC: false,
      uploadSCR: false,
      file: [],
      selectedFileType: undefined,
      admin: {},
      patient: {},
      editable: false,
      patientManaged: false,
      adminAlertEditable: false,
      tempAdminAlert: '',
      postcodeLocation: {},
      showMap: false,
      truthyFalsy: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      genderOptions: [
        { text: 'Male', value: 'MALE' },
        { text: 'Female', value: 'FEMALE' }
      ],
      titleOptions: [
        { text: 'Mr', value: 'Mr' },
        { text: 'Mrs', value: 'Mrs'},
        { text: 'Miss', value: 'Miss'},
        { text: 'Dr', value: 'Dr'},
        { text: 'Prof', value: 'Prof'},
        { text: 'Ms', value: 'Ms'}
      ],
      associateWithAnEnterprise: false,
      orders: {},
      currentPage: 1,
      action: "<button class='btn btn-sm btn-outline-primary'>Open Order</button>",
      columnNames: ['id', 'creationDate', 'status', 'description', 'consultedBy', 'pharmacy', 'actions'],
      clickEvent: 'open-order-click',
      newOrderSound: new Audio('/static/message.mp3'),
      timer: '',
      careSpecialist: "",
      activeStatus: "active",
      selectedKYCActive: [],
      selectedKYCArchive: [],
      selectedSCRActive: [],
      selectedSCRArchive: [],
      kycActiveDocuments: {},
      kycArchiveDocuments: {},
      scrActiveDocuments: {},
      scrArchiveDocuments: {},
      closeKYCActiveTable: false,
      closeSCRActiveTable: false,
      showKYCloading: false,
      showSCRloading: false,
      kycActiveStatus: "not started",
      kycArchiveStatus: "not started",
      scrActiveStatus: "not started",
      scrArchiveStatus: "not started",
      getPatientStatus: "not started",
      imageModelTitle: "KYC",
      imageModel: false
    }
  },
  watch: {
    currentPage: function () {
      this.getPatientOrders();
    }
  },
  computed: {
    externalIdentificationRule () {
      return this.patient.settings.EXTERNALLY_IDENTIFIED ? 'required' : '';
    },
    scrValidityConfirmationRule () {
      return this.patient.settings.SCR_VALIDITY_CONFIRMATION ? 'required' : '';
    },
    enterpriseRule () {
      return this.associateWithAnEnterprise ? 'required' : '';
    }
  },
  mounted: function () {
    this.getCurrentAdmin();
    this.getPatient();
    this.getKYCActiveDocuments();
    this.getKYCArchiveDocuments();
    this.getSCRActiveDocuments();
    this.getSCRArchiveDocuments();
    OrderEventBus.$on('currentPage', (currentPage) => {
      this.currentPage = currentPage;
    })
    OrderEventBus.$on(this.clickEvent, (orderId) => {
      this.$router.push('/order/' + orderId)
    })
  },
  methods: {
    toggleUpload (type, action) {
      this.file = [];
      this.selectedFileType = undefined;

      if(type === "KYC") {
        this.uploadKYC = action;
        this.uploadSCR = false;
      } else {
        this.uploadSCR = action;
        this.uploadKYC = false;
      }
    },
    uploadDocument () {
      var action = this.uploadKYC ? "PATIENT_KYC_DOCUMENTS" : "PATIENT_SCR_DOCUMENTS";
      action = this.selectedFileType == "PATIENT_PROFILE_PICTURE" ? "PATIENT_KYC_DOCUMENTS" : action;
      
      this.$fileService
          .documentUpload(this.file, action, this.selectedFileType, this.patient.id)
          .then(async (res) => {
              console.log(res);
              this.$awn.success("Document uploaded");
              this.getPatient();
              this.getKYCActiveDocuments();
              this.getSCRActiveDocuments();
          })
          .finally(() => {
              this.file = [];
              this.selectedFileType = undefined;
              this.uploadKYC = false;
              this.uploadSCR = false;
          });
    },
    getCurrentAdmin () {
      this.$userService.getProfile().then(admin => {
        this.admin = admin;
      }).catch(error => {
        this.$awn.alert(error, 'An error occurred')
      });
    },
    async getPatient () {
      this.$patientService.getPatient(this.$route.params.patientId).then(patient => {
        this.getPatientStatus = "completed";
        this.patient = patient;

        this.careSpecialist = this.patient?.careSpecialist ? this.patient.careSpecialist : "";

        // DoB conversion
        if(this.patient.dateOfBirth){
          this.patient.dateOfBirth = moment(this.patient.dateOfBirth).format('DD MMM YYYY')
        }

        this.convertSettings();
        this.getPatientOrders();

        this.patientManaged = this.patient.preferences.disabledByPatient === 'true';

        this.tempAdminAlert =  this.patient?.settings?.ADMIN_ALERTS || this.patient?.allAlerts?.admin || this.patient?.adminAlerts || '';
      })
    },
    convertSettings () {
      const kycStatus = this.patient.settings?.EXTERNALLY_IDENTIFIED === "true"
      const scrStatus = this.patient.settings?.SCR_VALIDITY_CONFIRMATION === "true"

      if(kycStatus) {
        if(!this.patient.settings.EXTERNALLY_IDENTIFIED_BY) {
          this.$set(this.patient.settings, 'EXTERNALLY_IDENTIFIED_BY', `Verified by admin ${this.admin.displayName ? ` - ${this.admin.displayName}` : ""}`);
        }
      } else if(!kycStatus && (!this.patient.settings.EXTERNALLY_IDENTIFIED_BY || this.patient.settings.EXTERNALLY_IDENTIFIED_BY === "External API")) {
        this.$set(this.patient.settings, 'EXTERNALLY_IDENTIFIED_BY', `Verified by admin ${this.admin.displayName ? ` - ${this.admin.displayName}` : ""}`);
      }
      
      if(scrStatus) {
        if(!this.patient.settings.SCR_CONFIRMED_BY) {
          this.$set(this.patient.settings, 'SCR_CONFIRMED_BY', `Verified by admin ${this.admin.displayName ? ` - ${this.admin.displayName}` : ""}`);
        }
      } else if(!scrStatus && (!this.patient.settings.SCR_CONFIRMED_BY || this.patient.settings.SCR_CONFIRMED_BY === "External API")) {
        this.$set(this.patient.settings, 'SCR_CONFIRMED_BY', `Verified by admin ${this.admin.displayName ? ` - ${this.admin.displayName}` : ""}`);
      }
      
      this.$set(this.patient.settings, 'EXTERNALLY_IDENTIFIED', kycStatus);
      this.$set(this.patient.settings, 'SCR_VALIDITY_CONFIRMATION', scrStatus);
      
      this.associateWithAnEnterprise = this.patient.settings.ENTERPRISE ? true : false;
    },
    allowChanges() {
      this.editable = true;
      this.truthyFalsy.forEach(option => {
        this.$delete(option, 'disabled');
      })
    },
    reset() {
      this.truthyFalsy.forEach(option => {
        this.$set(option, 'disabled');
      })
      this.editable = false;
      this.getPatient();
    },
    updatePatient () {
      this.prepareToUpdate()
      this.$validator.validateAll().then((success) => {
        if (success) {
          // fix dob for backend
          if(this.patient.dateOfBirth){
            this.patient.dateOfBirth = moment(this.patient.dateOfBirth).format('YYYY-MM-DD')
          }

          this.$patientService.updatePatient(this.patient).then(() => {
            this.$router.push('/patients');
            this.$awn.success('Patient updated successfully');
          }).catch((error) => {
            if (error.response && error.response.data) {
              this.$awn.alert(this.concatErrorMessage(error.response.data.message));
            } else {
              this.$awn.alert('There was a network error');
            }
          });
        } else {
          this.$awn.alert('Please check the form above for any missing fields or errors.');
        }
      })
    },
    prepareToUpdate () {
      this.patient.username = this.patient.email;
      this.patient.password = "qwerty";

      delete this.patient.displayName;

      if (this.patient.settings.EXTERNALLY_IDENTIFIED) {
        this.patient.settings.EXTERNALLY_IDENTIFIED = "true";

        if(!this.patient.settings.EXTERNALLY_IDENTIFIED_BY) {
          this.patient.settings.EXTERNALLY_IDENTIFIED_BY = `Verified by admin${this.admin.displayName ? ` - ${this.admin.displayName}` : ""}`;
        }
      } else {
        this.patient.settings.EXTERNALLY_IDENTIFIED = "false";
        this.patient.settings.EXTERNALLY_IDENTIFIED_BY = "";
      }

      if (this.patient.settings.SCR_VALIDITY_CONFIRMATION) {
        this.patient.settings.SCR_VALIDITY_CONFIRMATION = "true";

        if(!this.patient.settings.SCR_CONFIRMED_BY) {
          this.patient.settings.SCR_CONFIRMED_BY = `Verified by admin${this.admin.displayName ? ` - ${this.admin.displayName}` : ""}`;
        }
      } else {
        this.patient.settings.SCR_VALIDITY_CONFIRMATION = "false";
        this.patient.settings.SCR_CONFIRMED_BY = "";
      }
    },
    removeEnterprise () {
      this.associateWithAnEnterprise = false;
      this.patient.settings.ENTERPRISE = '';
    },
    concatErrorMessage (message) {
      let errorMessage = message + ". ";
      return errorMessage;
    },
    getPatientOrders () {
      this.$orderService.getPatientOrders(this.patient.id, this.currentPage - 1, 10).then(orders => {
        if (this.orders.totalElements < orders.totalElements) {
          this.newOrderSound.play();
        }
        this.orders = orders;
        this.timer = setTimeout(this.getPatientOrders, 60000);
      }).catch(error => {
        console.error(error);
        this.showKYCloading = false;
        this.$awn.alert('There was an error getting orders');
      });
    },
    toggleAdminAlertEditable() {
      if (!this.adminAlertEditable) {
        this.adminAlertEditable = true;
      } else {
        this.tempAdminAlert =  this.patient?.settings?.ADMIN_ALERTS || this.patient?.allAlerts?.admin || this.patient?.adminAlerts || '';
        this.adminAlertEditable = false;
      }
    },
    updateAdminAlert() {
      const existingAdminAlert = this.patient?.settings?.ADMIN_ALERTS || this.patient?.allAlerts?.admin || this.patient?.adminAlerts || '';
      const newAdminAlert = this.tempAdminAlert?.trim();
      if (existingAdminAlert.length === 0 && newAdminAlert.length === 0) {
        return;
      }
      if (newAdminAlert.length > 250) {
        this.$awn.alert('Message too long. Please shorten to 250 characters.');
        return;
      }
      let _request = this.$patientService.updateKeyAlert;
      if (newAdminAlert.length === 0) {
        _request = this.$patientService.removePatientSetting;
      }
      _request(this.patient.id, newAdminAlert)
      .then(async (response) => {
        if (response.success) {
          await this.getPatient();
          this.$awn.success('Admin alert updated successfully');
          this.adminAlertEditable = false;
        } else {
          this.$awn.alert(response.message);
        }
      }).catch(error => {
        this.$awn.alert(error.message);
      });
    },

    //Get all KYC active documents
    //Ticket: CR-148
    //Author: Sarmilan
    async getKYCActiveDocuments() {
      this.$patientService.getActiveAndArchiveDocuments(this.$route.params.patientId, "PATIENT_KYC_DOCUMENTS", true).then(documents => {
        this.kycActiveStatus = "completed";
        let tempDocs = documents.files.filter(doc => doc.status === true);
        this.kycActiveDocuments = tempDocs;
      }).catch(error => {
        this.kycActiveStatus = "error";
        this.$awn.alert(error, 'An error occurred')
      });
    },

    //Get All KYC archive documents
    //Ticket: CR-148
    //Author: Sarmilan
    async getKYCArchiveDocuments() {
      this.$patientService.getActiveAndArchiveDocuments(this.$route.params.patientId, "PATIENT_KYC_DOCUMENTS", false).then(documents => {
        this.kycArchiveStatus = "completed";
        this.kycArchiveDocuments = documents;
      }).catch(error => {
        this.kycArchiveStatus = "error";
        this.$awn.alert(error, 'An error occurred')
      });
    },

    //Get all SCR active documents
    //Ticket: CR-148
    //Author: Sarmilan
    async getSCRActiveDocuments() {
      this.$patientService.getActiveAndArchiveDocuments(this.$route.params.patientId, "PATIENT_SCR_DOCUMENTS", true).then(documents => {
        this.scrActiveStatus = "completed";
        let tempDocs = documents.files.filter(doc => doc.status === true);
        this.scrActiveDocuments = tempDocs;
      }).catch(error => {
        this.scrActiveStatus = "error";
        this.$awn.alert(error, 'An error occurred')
      });
    },

    //Get all SCR archive documents
    //Ticket: CR-148
    //Author: Sarmilan
    async getSCRArchiveDocuments() {
      this.$patientService.getActiveAndArchiveDocuments(this.$route.params.patientId, "PATIENT_SCR_DOCUMENTS", false).then(documents => {
        this.scrArchiveStatus = "completed";
        this.scrArchiveDocuments = documents;
      }).catch(error => {
        this.scrArchiveStatus = "error";
        this.$awn.alert(error, 'An error occurred')
      });
    },

    //Move KYC Data from active to archive
    //Ticket: CR-148
    //Author: Sarmilan
    async moveKYCActiveArchive(file){
      this.showKYCloading = true;

      if(file && file.id){
        this.selectedKYCActive = [];
        this.selectedKYCActive.push(file.id);
      }
      
      if(this.selectedKYCActive.length <= 0){
        this.$awn.alert('Please select one or more documents to move!');
        this.showKYCloading = false;
        return;
      }

      await this.$patientService.updateActiveArchive(false, this.selectedKYCActive).then(response => {
        if(response.data == "File successfully updated.") {
          this.$awn.success("Successfully moved to archive");
          this.kycActiveDocuments = {};
          this.kycArchiveDocuments = {};
          this.patient.files = this.patient.files.filter(item => !this.selectedKYCActive.includes(item.id));
          this.selectedKYCActive = [];
          this.getKYCActiveDocuments();
          this.getKYCArchiveDocuments();
        }
        else {
          this.$awn.alert(response.data);
        }
      }).catch(error => {
        this.$awn.alert(error, 'An error occurred');
      }).finally(() => {
        this.resetVariable();
      });
    },

    //Move KYC Data from archive to active
    //Ticket: CR-148
    //Author: Sarmilan
    async moveKYCArchiveActive() {
      this.showKYCloading = true;
      if(this.selectedKYCArchive.length <= 0){
        this.$awn.alert('Please select one or more documents to move!');
        this.showKYCloading = false;
        return;
      }

      await this.$patientService.updateActiveArchive(true, this.selectedKYCArchive).then(response => {
        if(response.data == "File successfully updated.") {
          this.$awn.success("Successfully moved to active");
          this.kycActiveDocuments = {};
          this.kycArchiveDocuments = {};
          this.selectedKYCArchive = [];
          this.getPatient();
          this.getKYCActiveDocuments();
          this.getKYCArchiveDocuments();
        }
        else {
          this.$awn.alert(response.data);
        }
      }).catch(error => {
        this.$awn.alert(error, 'An error occurred');
      }).finally(() => {
        this.resetVariable();
      });
    },

    //Move SCR Data from active to archive
    //Ticket: CR-148
    //Author: Sarmilan
    async moveSCRActiveArchive(file) {
      this.showSCRloading = true;

      if(file && file.id){
        this.selectedSCRActive = [];
        this.selectedSCRActive.push(file.id);
      }

      if(this.selectedSCRActive.length <= 0){
        this.$awn.alert('Please select one or more documents to move!');
        this.showSCRloading = false;
        return;
      }

      await this.$patientService.updateActiveArchive(false, this.selectedSCRActive).then(async response => {
        if(response.data == "File successfully updated.") {
          this.$awn.success("Successfully moved to archive");
          this.scrActiveDocuments = {};
          this.scrArchiveDocuments = {};
          this.patient.files = this.patient.files.filter(item => !this.selectedSCRActive.includes(item.id));
          this.selectedSCRActive = [];
          await this.getSCRActiveDocuments();
          await this.getSCRArchiveDocuments();
        }
        else {
          this.$awn.alert(response.data);
        }
      }).catch(error => {
        this.$awn.alert(error, 'An error occurred');
      }).finally(() => {
        this.resetVariable();
      });
    },

    //Move SCR Data from archive to active
    //Ticket: CR-148
    //Author: Sarmilan
    async moveSCRArchiveActive() {
      this.showSCRloading = true;
      if(this.selectedSCRArchive.length <= 0){
        this.$awn.alert('Please select one or more documents to move!');
        this.showSCRloading = false;
        return;
      }

      await this.$patientService.updateActiveArchive(true, this.selectedSCRArchive).then(async response => {
        if(response.data == "File successfully updated.") {
          this.$awn.success("Successfully moved to active");
          this.scrActiveDocuments = {};
          this.scrArchiveDocuments = {};
          this.selectedSCRArchive = [];
          await this.getPatient();
          await this.getSCRActiveDocuments();
          await this.getSCRArchiveDocuments();
        }
        else {
          this.$awn.alert(response.data);
        }
      }).catch(error => {
        this.$awn.alert(error, 'An error occurred');
      }).finally(() => {
        this.resetVariable();
      });
    },

    //Close active archive panel
    //Ticket: CR-148
    //Author: Sarmilan
    closeActiveArchive(type) {
      if(type == "kyc"){
        this.closeKYCActiveTable = false;
      }
      else{
        this.closeSCRActiveTable = false;
      }
    },

    //Open active archive panel
    //Ticket: CR-148
    //Author: Sarmilan
    openActiveArchive(type) {
      if(type == "kyc"){
        this.closeKYCActiveTable = true;
      }
      else{
        this.closeSCRActiveTable = true;
      }
    },

    //Resetting some common variables
    //Ticket: CR-148
    //Author: Sarmilan
    resetVariable() {
      this.showKYCloading = false;
      this.showSCRloading = false;
      this.kycActiveStatus = "not started";
      this.kycArchiveStatus = "not started";
      this.scrActiveStatus = "not started";
      this.scrArchiveStatus = "not started";
      this.getPatientStatus = "not started";
    }
  },
  beforeDestroy () {
    clearTimeout(this.timer);
    OrderEventBus.$off();
  },
  filters: {
    formatDoB: function(date){
      return moment(date).format('DD MMM YYYY');
    }
  }
}
</script>

<style lang="scss" scoped>
  .action-buttons {
    display: flex;
    justify-content: flex-end;
  }
  .margin-1-percent {
    margin-right:1%
  }
  #patientIdDescription small {
    margin-top: -1.8%;
  }
  .btn-group .btn + .btn {
    background-color: #009344 !important;
    border-color: #009344 !important;
  }

  .image-name-label {
    margin-bottom: 0.25rem;
    font-size: 15px;
    font-weight: bold;
    text-transform: capitalize;
  }

  .uploaded-image {
    width: 50%;

    @media(max-width: 1199px) {
      width: 100%;
    }
  }

  .uploaded-image-name {
    margin-bottom: 1rem;
  }

  .archive-panel {
    display: flex;
    flex-direction: column;
  }

  .document-list-header {
    background-color: #550385;
  }

  .card-header {
    background-color: #550385;
    opacity: 0.65;
    color: white;
  }

  .close-button {
    position: relative;
    // top: 10px; /* Adjust top position as needed */
    // right: 10px; /* Adjust right position as needed */
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #333;
  }

  .close-parent {
    width: 100%;
    text-align: center;
  }

  .active-archive-btn {
    margin-right: 10px;
  }

.loadingOrders,
.loadingOrders:before,
.loadingOrders:after {
  border-radius: 100%;
  width: 1.5em;
  height: 1.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 2.2s infinite ease-in-out;
  animation: load7 2.2s infinite ease-in-out;
}
.loadingOrders {
  color: #550385;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loadingOrders:before,
.loadingOrders:after {
  content: '';
  position: absolute;
  top: 0;
}
.loadingOrders:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loadingOrders:after {
  left: 3.5em;
}
</style>
