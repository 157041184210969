//Properly format Address
export function getDisplayAddress(address, skipCity = false) {
    if (address) {
        let displayAddress = address.line1;

        if (address.line2 && /\S/.test(address.line2)) {
            displayAddress += ", " + address.line2;
        }

        if (!skipCity && address.city && /\S/.test(address.city)) {
            displayAddress += ", " + address.city;
        }

        if (address.county && /\S/.test(address.county)) {
            displayAddress += ", " + address.county;
        }

        return displayAddress;
    }
    
    return "";
}

//Clean spaces between string
export function cleanSpaces(string) {
    return string?.trim().replace(/\s+/, " ") || "";
}

//Check for valid email
export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

//Check for valid Phone number
export function isValidPhone(number) {
    const phoneRegex = /^\+?[0-9]{1,4}[\s-]?[0-9\s-]{6,}$/;
    return phoneRegex.test(number);
}

//Age should be above 21
export function isValidDateofBirth(date) {
    const dob = new Date(date);
    const currentDate = new Date();

    const age = currentDate.getFullYear() - dob.getFullYear();

    if (age < 18 || age > 150) {
        return false;
    }

    return true;
}

// Properly format surgery list contact number
export function getGPContact(contactStr) {
    let contacts = JSON.parse(contactStr || "[]");

    if (contacts.length === 0) {
        return null;
    }

    return contacts[0]?.OrganisationContactValue;
}