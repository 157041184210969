import Vue from "vue";
import Router from "vue-router";

// Containers
import Full from "@/containers/Full";

// Views
import Dashboard from "@/views/Dashboard";
import Login from "@/views/Login";
import ResetPassword from "@/views/ResetPassword";
import ForgetPassword from "@/views/ForgetPassword";
import AllOrders from "@/views/Orders/AllOrders";
import PharmacyChannel from "@/views/Orders/PharmacyChannel";
import PrescriberOrders from "@/views/Orders/PrescriberOrders";
import ChangePin from "@/views/Shared/ChangePin";
import ChangePassword from "@/views/Shared/ChangePassword";
import TwoFactorAuthentication from "@/views/Shared/TwoFactorAuthentication";
import Doctors from "@/views/Doctors/Doctors";
import Patients from "@/views/Patients/Patients";
import Patient from "@/views/Patients/Patient";
import Pharmacies from "@/views/Pharmacies/Pharmacies";
import Appointments from "@/views/Doctors/Appointments";
import Pharmacy from "@/views/Pharmacies/Pharmacy";
import Order from "@/views/Orders/Order";
import ConfirmDelivery from "@/views/Orders/ConfirmDelivery";
import ChangeDeliveryOrders from "@/views/Orders/ChangeDeliveryOrders";
import ChangeDelivery from "@/views/Orders/ChangeDelivery";
import RejectedOrders from "@/views/Orders/RejectedOrders";
import WaitingForPatient from "@/views/Orders/WaitingForPatient";
import WaitingForPharmacy from "@/views/Orders/WaitingForPharmacy";
import WaitingForMDT from "@/views/Orders/WaitingForMDT";
import WaitingForSpecialist from "@/views/Orders/WaitingForSpecialist";
import RejectedOrder from "@/views/Orders/RejectedOrder";
import PaymentFailedOrders from "@/views/Orders/PaymentFailedOrders";
import PaymentFailed from "@/views/Orders/PaymentFailed";
import Formulary from "@/views/Formulary/Formulary";
import Upload from "@/views/Formulary/Upload";
import FormFormulary from "@/views/Formulary/FormFormulary";
import Coupons from "@/views/Coupons/Coupons";
import Enterprises from "@/views/Enterprises/Enterprises";
import Platforms from "@/views/Platforms/Platforms";
import Platform from "@/views/Platforms/Platform";
import Subscriptions from "@/views/Subscriptions/Subscriptions";
import Packages from "@/views/Packages/Packages";
import GPNotes from "@/views/GPNotes/GPNotes";
import PageNotFound from "@/views/PageNotFound";
import Admins from "@/views/Admins/Admins";
import Prescribers from "@/views/Prescribers/Prescribers";
import Prescriber from "@/views/Prescribers/Prescriber";
import CreatePatient from "@/views/Patients/CreatePatient";
import PatientProfileCreation from "@/views/Patients/PatientProfileCreation.vue";
import PatientAppointment from "@/views/Patients/PatientAppointment.vue";
import CallToBookOrders from "@/views/Orders/CallToBookOrders.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      redirect: "/login",
      name: "Home",
      component: Full,
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "change-pin/:userType/:userId",
          name: "Change PIN",
          props: true,
          component: ChangePin,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "change-password",
          name: "Update password",
          component: ChangePassword,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "two-factor-auth",
          name: "Two Factor Authentication",
          component: TwoFactorAuthentication,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "change-password/:userType/:userId",
          name: "Change Password",
          props: true,
          component: ChangePassword,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "doctors",
          name: "Doctors",
          component: Doctors,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "patients",
          name: "Patients",
          component: Patients,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "patient/create-patient",
          name: "CreatePatient",
          component: CreatePatient,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "patient/:patientId",
          name: "Patient",
          component: Patient,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "pharmacies",
          name: "Pharmacies",
          component: Pharmacies,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "appointments",
          name: "Appointments",
          component: Appointments,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "pharmacy/:pharmacyId",
          name: "Pharmacy",
          component: Pharmacy,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "orders/all",
          name: "All orders",
          props: {
            types: ["FORM", "BOOKING", "TRAVEL", "PRESCRIPTION_ONLY"],
          },
          component: AllOrders,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "orders/pharmacyorders",
          name: "Pharmacy Orders",
          props: {
            types: ["FORM", "BOOKING", "TRAVEL", "PRESCRIPTION_ONLY"],
          },
          component: PharmacyChannel,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "orders/gps",
          name: "GPS orders",
          props: {
            types: ["FORM", "BOOKING", "TRAVEL"],
          },
          component: AllOrders,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "orders/prescriber",
          name: "Prescriber orders",
          props: true,
          component: PrescriberOrders,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "orders/waiting-for-patient",
          name: "Orders - Awaiting for Patient",
          props: true,
          component: WaitingForPatient,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "orders/waiting-for-mdt",
          name: "Orders - Awaiting for MDT",
          props: true,
          component: WaitingForMDT,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "orders/waiting-for-specialist",
          name: "Orders - Awaiting for Specialist",
          props: true,
          component: WaitingForSpecialist,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "orders/waiting-for-pharmacy",
          name: "Orders - Awaiting for Pharmacy",
          props: true,
          component: WaitingForPharmacy,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "orders/change-delivery",
          name: "Orders - Change delivery details",
          props: true,
          component: ChangeDeliveryOrders,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "orders/rejected",
          name: "Orders - Rejected",
          props: true,
          component: RejectedOrders,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "order/rejected/:orderId",
          name: "Rejected",
          component: RejectedOrder,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "orders/payment-failed",
          name: "Orders - Payment Faileds",
          props: true,
          component: PaymentFailedOrders,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "order/payment-failed/:orderId",
          name: "Payment Failed",
          component: PaymentFailed,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "order/:orderId",
          name: "Order",
          component: Order,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "order/waiting-for-patient/:orderId",
          name: "Order",
          component: Order,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "order/waiting-for-mdt/:orderId",
          name: "Order",
          component: Order,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "order/waiting-for-specialist/:orderId",
          name: "Order",
          component: Order,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "order/waiting-for-pharmacy/:orderId",
          name: "Order",
          component: Order,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "order/confirm-delivery/:orderId",
          name: "Confirm delivery details",
          component: ConfirmDelivery,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "order/change-delivery/:orderId",
          name: "Change delivery details",
          component: ChangeDelivery,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "orders/call-to-book",
          name: "Call to Book Reservation",
          component: CallToBookOrders,
          meta: { description: "",  requiresAuth: true }
        },
        {
          path: "gp-notes/:orderId?",
          name: "GP Notes",
          props: true,
          component: GPNotes,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "formulary/all",
          name: "Formulary",
          props: true,
          component: Formulary,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "formulary/upload",
          name: "Formulary upload",
          props: true,
          component: Upload,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "form-formulary",
          name: "FormFormulary",
          props: true,
          component: FormFormulary,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "coupons",
          name: "Coupons",
          props: true,
          component: Coupons,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "enterprises",
          name: "Enterprises",
          props: true,
          component: Enterprises,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "platforms",
          name: "Platforms",
          props: true,
          component: Platforms,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "platform/:platformId",
          name: "Platform",
          component: Platform,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "subscriptions",
          name: "Subscriptions",
          props: true,
          component: Subscriptions,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "packages",
          name: "Packages",
          props: true,
          component: Packages,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "admins",
          name: "Admins",
          component: Admins,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "prescribers",
          name: "Prescribers",
          component: Prescribers,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "prescriber/:prescriberId",
          name: "Prescriber",
          component: Prescriber,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "Patient-profile-creation",
          name: "Patient Details",
          component: PatientProfileCreation,
          meta: { description: "", requiresAuth: true },
        },
        {
          path: "patient-appointment",
          name: "Patient Appointment",
          component: PatientAppointment,
          meta: { description: "", requiresAuth: true },
        },
      ],
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/forget-password",
      name: "Forget",
      component: ForgetPassword,
    },
    {
      path: "/page-not-found",
      name: "404 - Page not found",
      component: PageNotFound,
    },
    { path: "*", component: PageNotFound },
  ],
});
