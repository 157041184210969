<template>
  <div>
    <b-card v-if="patient">
      <div slot="header">
        <i class='fa fa-align-justify'></i><strong class='header-spacing'> Patient details</strong>
      </div>
      <b-form-group id="patientId" label="Patient ID" label-for="patientId" label-cols="9">
        <a class="float-right" :href="'/patient/' + patient.id">{{ patient.id }}</a>
      </b-form-group>
      <b-form-group id="patientNameGroup" label="Name" label-for="patientName" label-cols="5">
        <b-form-input name="patientName" type="text" v-model="patient.displayName" disabled/>
      </b-form-group>
      <b-row class="form-group">
        <b-col cols="6">
          <label for="patientGender">Gender </label>
          <b-form-input id="patientGender" type="text" v-model="patient.gender" disabled></b-form-input>
        </b-col>
        <b-col>
          <label for="patientDOB">Date of Birth </label>
          <b-form-input id="patientDOB" type="text" v-model="dateOfBirth" disabled></b-form-input>
        </b-col>
      </b-row>
      <div class="form-group">
        <label for="patientEmail">Email </label>
        <b-form-input id="patientEmail" type="text" v-model="patient.email" disabled></b-form-input>
      </div>
      <b-row class="form-group">
        <b-col cols="6">
          <label for="patientPhone">Phone </label>
          <b-form-input id="patientPhone" type="text" v-model="patient.phone" dir="ltr" disabled></b-form-input>
        </b-col>
        <b-col cols="6">
          <label for="patientPhone">Home phone </label>
          <b-form-input id="patientPhone" type="text" v-model="homePhone" dir="ltr" disabled></b-form-input>
        </b-col>
      </b-row>
      <div class="form-group" v-if="patient.address">
        <div class="form-group">
          <label for="patientAddressLine1">Line1</label>
          <b-form-input name="patientAddressLine1" type="text" v-model="patient.address.line1" disabled/>
        </div>
        <div class="form-group">
          <label for="patientAddressLine2">Line2</label>
          <b-form-input name="patientAddressLine2" type="text" v-model="patient.address.line2" disabled/>
        </div>
        <div class="form-group">
          <label for="patientAddressCounty">County</label>
          <b-form-input name="patientAddressCounty" type="text" v-model="patient.address.county" disabled/>
        </div>
        <b-row class="form-group">
          <b-col cols="6">
            <label for="patientAddressPostcode">Postcode</label>
            <b-form-input name="patientAddressPostcode" type="text" v-model="patient.address.postcode" disabled/>
          </b-col>
          <b-col>
            <label for="patientAddressCity">City</label>
            <b-form-input name="patientAddressCity" type="text" v-model="patient.address.city" disabled/>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: 'payment-details',
  props: ['patient'],
  computed: {
    homePhone: function () {
      return this.patient.preferences.homePhone ? this.patient.preferences.homePhone : 'Not set';
    },
    dateOfBirth: function() {
      return moment(this.patient.dateOfBirth).format('DD MMM YYYY');
    }
  }
}
</script>