<template>
  <router-view></router-view>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

export default {
  name: 'app'
}

Vue.axios.defaults.headers.common['Authorization'] = localStorage.getItem('id_token');

</script>

<style lang="scss">
  @import './src/scss/style';
</style>
