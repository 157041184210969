
<script>
    import Vue from 'vue';

    export default {

        getNearestPharmacies (lat, lng, limit = 10, deliveryOnly = false, memberType, doesVaccines = 0) {
            var url = `/api/public/nearest-pharmacies?latitude=${lat}&longitude=${lng}`;

            var config = {
                headers: {
                    'X-Origin-Platform': 'ANY',
                }
            }

            if (deliveryOnly || memberType !== 'NONE') {
                url = `${url}&limit=100`;
            } else {
                url = `${url}&limit=${limit}`;
            }

            if (memberType) {
                url = `${url}&memberType=${memberType}`;
            }

            if (doesVaccines == 1) {
                url = `${url}&doesVaccines=1`;
            }
            return Vue.axios.get(url, config).then(pharmacies => {
                if (pharmacies) {
                    pharmacies = pharmacies.data;
                    if (deliveryOnly) {
                        pharmacies = pharmacies.filter(p => { return p.delivers; })
                    }
                }
                return pharmacies;
            });
        }
        
    }
</script>