<template>
    <invoice
        v-if="lineItems.length > 0"
        :elementId="'consultation-invoice'"
        :invoiceNumber="order.id"
        :invoiceDate="invoiceDate"
        :customerName="order.patient.displayName"
        :address="address"
        :customerEmail="order.patient.email"
        :lineItems="lineItems"
        :subTotal="subTotal"
        :discount="discount"
        :totalAmount="total"
        :paid="!!invoiceDate"
    />
</template>

<script>
import moment from "moment";
import Invoice from "../../components/Invoice.vue";

export default {
    name: "consultation-invoice",
    components: {
        Invoice,
    },
    data: function() {
        return {
            order: {},
            address: {},
            prescription: {},
            subTotal: 0,
            discount: 0,
            total: 0,
            invoiceDate: 0,
            lineItems: [],
        };
    },
    mounted: function() {
        this.getOrderDetails();
    },
    watch: {
        order(newValue) {
            if (newValue.id) {
                this.getConsultationInvoice();
            }
        },
    },
    methods: {
        getOrderDetails() {
            this.$orderService
                .getOrder(this.$route.params.orderId)
                .then((order) => {
                    this.order = order;
                    this.address = { ...order.patient.address };
                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
                        console.log("a", error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.displayNotification("error", error.message);
                    }
                });
        },
        getConsultationInvoice() {
            this.$orderService
                .getInvoiceItems(this.$route.params.orderId)
                .then((result) => {
                    if (result.length > 0) {
                        const subTotal =
                            result?.find(
                                (data) =>
                                    data.phase === "CONSULTATION" &&
                                    (this.order.parentOrderItemId &&
                                    this.order.isRepeatOrder
                                        ? data.type ===
                                          "SPECIALIST_SERVICE_CHARGE"
                                        : data.type === "APPOINTMENT")
                            )?.price || 0;
                        const discount =
                            result?.find(
                                (data) =>
                                    data.phase === "CONSULTATION" &&
                                    data.type === "COUPON"
                            )?.price * subTotal || 0;
                        const total =
                            result?.find(
                                (data) =>
                                    data.phase === "CONSULTATION" &&
                                    data.type === "TOTAL"
                            )?.price || 0;

                        const invoiceDate = result.find(
                            (data) =>
                                data.phase === "CONSULTATION" &&
                                data.type === "TOTAL"
                        )?.paymentDate;

                        const formattedInvoiceDate = invoiceDate
                            ? moment(invoiceDate).format("MMMM Do, YYYY")
                            : null;

                        const lineItems = [
                            {
                                desc: {
                                    title:
                                        this.order?.parentOrderItemId &&
                                        !this.order.isRepeatOrder
                                            ? "Repeat consultation fee"
                                            : this.order?.parentOrderItemId &&
                                              this.order.isRepeatOrder
                                            ? "Repeat prescription fee"
                                            : "Consultation fee",
                                    items: null,
                                },
                                qty: 1,
                                unitPrice: subTotal,
                                vat: 0,
                                net: subTotal,
                            },
                        ];

                        this.subTotal = subTotal;
                        this.discount = discount;
                        this.total = total;
                        this.invoiceDate = formattedInvoiceDate;
                        this.lineItems = lineItems;
                    }
                });
        },
    },
};
</script>
