<template>
  <div v-if="pharmacy.id">

    <b-card no-title no-body>
      <div class="own-header">
        <b-row>
          <b-col class="display-name" sm="12" md="6">
            <i class="fa fa-align-justify"></i>
            <strong class="header-spacing"> {{ pharmacy.displayName }} </strong>
          </b-col>
          <b-col sm="12" md="6">
            <b-button v-if="!editable" variant="warning" @click="allowChanges()" class="float-right">Update Pharmacy</b-button>
            <b-button v-if="editable" variant="primary" @click="updatePharmacy()" class="float-right">Confirm</b-button>
            <b-button v-if="editable" variant="outline-danger" @click="reset()" class="float-right margin-1-percent">Reset</b-button>
          </b-col>
        </b-row>
      </div>
      <b-card-body>
        <b-row>
          <b-col md="12" lg="6">
            <b-row>
              <b-col cols="6">
                <b-form-group id="id" label="Pharmacy ID" label-for="id">
                  <b-form-input name="id" type="text" v-model="pharmacy.id" disabled/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <b-form-group id="memberType" label="Member Type" label-for="memberType">
                  <b-form-radio-group v-if="editable" :block="true" button-variant="outline-primary" buttons v-model="pharmacy.memberType" name="memberType" :options="memberTypes"/>
                  <b-button-toolbar v-if="!editable">
                    <b-button v-bind:variant="pharmacy.memberType === 'GOLD' ? 'primary' : 'outline-primary'" name="memberType" type="text" disabled> Gold </b-button>
                    <b-button v-bind:variant="pharmacy.memberType === 'SILVER' ? 'primary' : 'outline-primary'" name="memberType" type="text" disabled> Silver </b-button>
                    <b-button v-bind:variant="pharmacy.memberType === 'NONE' ? 'primary'  : 'outline-primary'" name="memberType" type="text" disabled> None </b-button>
                  </b-button-toolbar>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="active" label="Active" label-for="active">
                  <b-form-radio-group v-if="editable" :block="true" button-variant="outline-primary" buttons v-model="pharmacy.active" name="active" :options="truthyFalsy" />
                  <b-button-toolbar v-if="!editable">
                    <b-button v-bind:variant="pharmacy.active ? 'primary' : 'outline-primary'" name="active" type="text" disabled> Yes </b-button>
                    <b-button v-bind:variant="!pharmacy.active ? 'primary' : 'outline-primary'" name="active" type="text" disabled> No </b-button>
                  </b-button-toolbar>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group id="gphc" label="GPhC" label-for="gphc">
                  <b-form-input name="gphc" type="text" v-model="pharmacy.gphc" v-validate="'required'" :disabled="!editable"/>
                  <span v-show="errors.has('gphc')" class="text-danger">{{ errors.first('gphc') }}</span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group id="name" label="Name" label-for="name">
              <b-form-input name="name" type="text" v-model="pharmacy.name" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('name')" class="text-danger">{{ errors.first('name') }}</span>
            </b-form-group>
            <b-form-group id="email" label="Email" label-for="email">
              <b-form-input name="email" type="text" v-model="pharmacy.email" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('email')" class="text-danger">{{ errors.first('email') }}</span>
            </b-form-group>
            <b-form-group id="phone" label="Phone" label-for="phone">
              <b-form-input name="phone" type="text" v-model="pharmacy.phone" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('phone')" class="text-danger">{{ errors.first('phone') }}</span>
            </b-form-group>
            <b-form-group id="platform" label="Platform" label-for="platform">
              <b-form-input name="platform" type="text" v-model="pharmacy.platform" v-validate="'required'" :disabled="true"/>
              <span v-show="errors.has('platform')" class="text-danger">{{ errors.first('platform') }}</span>
            </b-form-group>
          </b-col>
          <b-col>
            <div v-if="pharmacy.preferences">

              <b-form-group id="addDeliveryCharge" label="Delivery Charge" label-for="addDeliveryCharge" label-cols="5">
                <b-button v-if="!addDeliveryCharge" type="button" block variant="outline-primary" @click="addDeliveryCharge = true">Add delivery charge</b-button>
                <b-row v-if="addDeliveryCharge">
                  <b-col lg="4" class="mb-1">
                    <b-form-input name="deliveryCharge" type="text" v-model="pharmacy.preferences.deliveryCharge" v-validate="deliveryChargeRule" :disabled="!editable"/>
                    <span v-show="errors.has('deliveryCharge')" class="text-danger">{{ errors.first('deliveryCharge') }}</span>
                  </b-col>
                  <br/>
                  <b-col>
                    <b-button block type="button" variant="outline-danger" @click="removeDeliveryCharge()" :disabled="!editable">
                      Remove delivery charge
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group id="addDeliveryRadius" label="Delivery Radius (in meters)" label-for="addDeliveryRadius" label-cols="5">
                <b-button v-if="!addDeliveryRadius" type="button" block variant="outline-primary" @click="addDeliveryRadius = true">Add delivery radius</b-button>
                <b-row v-if="addDeliveryRadius">
                  <b-col lg="4" class="mb-1">
                    <b-form-input name="deliveryRadius" type="text" v-model="pharmacy.preferences.deliveryRadius" v-validate="deliveryRadiusRule" :disabled="!editable"/>
                    <span v-show="errors.has('deliveryRadius')" class="text-danger">{{ errors.first('deliveryRadius') }}</span>
                  </b-col>
                  <br/>
                  <b-col>
                    <b-button block type="button" variant="outline-danger" @click="removeDeliveryRadius()" :disabled="!editable">
                      Remove delivery radius
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group id="addOpeningTimes" label="Opening Times" label-for="addOpeningTimes" label-cols="5">
                <b-button v-if="!addOpeningTimes" type="button" block variant="outline-primary" @click="addOpeningTimes = true">Add opening times</b-button>
                <div v-if="addOpeningTimes">
                  <b-form-textarea class="mb-1" name="openingTimes" v-model="pharmacy.preferences.openingTimes" :rows="3" :max-rows="3" v-validate="openingTimesRule" :disabled="!editable"/>
                  <span v-show="errors.has('openingTimes')" class="text-danger">{{ errors.first('openingTimes') }}</span>
                  <b-button block type="button" variant="outline-danger" @click="removeOpeningTimes()" :disabled="!editable">
                    Remove opening times
                  </b-button>
                </div>
              </b-form-group>
            </div>
            <div>
              <b-form-group id="addCPPQ" label="Add CPPQ" label-for="addCPPQ" label-cols="5">
                <b-button v-if="!addCPPQ" type="button" block variant="outline-primary" @click="addCPPQ = true">Add CPPQ</b-button>
                <b-row v-if="addCPPQ">
                  <b-col lg="4" class="mb-1">
                    <b-form-input name="AVERAGE_MONTHLY_SCRIPT_VOLUME" type="text" v-model="pharmacy.settings.AVERAGE_MONTHLY_SCRIPT_VOLUME" v-validate="cppqRule" :disabled="!editable"/>
                    <span v-show="errors.has('AVERAGE_MONTHLY_SCRIPT_VOLUME')" class="text-danger">{{ errors.first('AVERAGE_MONTHLY_SCRIPT_VOLUME') }}</span>
                  </b-col>
                  <b-col>
                    <b-button block type="button" variant="outline-danger" @click="removeCPPQ()" :disabled="!editable">
                      Remove CPPQ
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group v-if="pharmacy.settings.LOGIN_ATTEMPTS" id="LOGIN_ATTEMPTS" label="Login attempts" label-for="LOGIN_ATTEMPTS" label-cols="5">
                <b-row>
                  <b-col cols="4">
                    <b-form-input name="LOGIN_ATTEMPTS" type="text" v-model="pharmacy.settings.LOGIN_ATTEMPTS" disabled/>
                  </b-col>
                  <b-col>
                    <b-button block type="button" variant="outline-danger" @click="pharmacy.settings.LOGIN_ATTEMPTS = ''" :disabled="!editable">Clear login attempts</b-button>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group id="DOES_VACCINES" label="Does vaccines" label-for="DOES_VACCINES">
                <b-form-radio-group v-if="editable" buttons :block="true" button-variant="outline-primary" name="DOES_VACCINES" v-model="pharmacy.settings.DOES_VACCINES" :options="truthyFalsy"/>
                <b-button-toolbar v-if="!editable">
                  <b-button v-bind:variant="pharmacy.settings.DOES_VACCINES ? 'primary' : 'outline-primary'" name="DOES_VACCINES" type="text" disabled> Yes </b-button>
                  <b-button v-bind:variant="!pharmacy.settings.DOES_VACCINES ? 'primary' : 'outline-primary'" name="DOES_VACCINES" type="text" disabled> No </b-button>
                </b-button-toolbar>
              </b-form-group>
            </div>
          </b-col>
        </b-row>

        <br/>
        <br/>

        <b-row>
          <b-col sm="12" md="12" lg="5" xl="5">
            <b-form-group id="line1" label="Line 1" label-for="line1" :horizontal="true" label-cols="3">
              <b-form-input name="line1" type="text" v-model="pharmacy.address.line1" :disabled="!editable"/>
            </b-form-group>
            <b-form-group id="line2" label="Line 2" label-for="line2" :horizontal="true" label-cols="3">
              <b-form-input name="line2" type="text" v-model="pharmacy.address.line2" :disabled="!editable"/>
            </b-form-group>
            <b-form-group id="county" label="County" label-for="county" :horizontal="true" label-cols="3">
              <b-form-input name="county" type="text" v-model="pharmacy.address.county" :disabled="!editable"/>
            </b-form-group>
            <b-form-group id="city" label="City" label-for="city" :horizontal="true" label-cols="3">
              <b-form-input name="city" type="text" v-model="pharmacy.address.city" :disabled="!editable"/>
            </b-form-group>
            <b-form-group id="postcode" label="Postcode" label-for="postcode" :horizontal="true" label-cols="3">
              <b-form-input name="postcode" type="text" v-model="pharmacy.address.postcode" :disabled="!editable"/>
            </b-form-group>
            <b-form-group id="latitude" label="Latitude" label-for="latitude" :horizontal="true" label-cols="3">
              <b-form-input name="latitude" type="number" v-model.number="pharmacy.latitude" @change="updateMap()" :disabled="!editable"/>
            </b-form-group>
            <b-form-group id="longitude" label="Longitude" label-for="longitude" :horizontal="true" label-cols="3">
              <b-form-input name="longitude" type="number" v-model.number="pharmacy.longitude" @change="updateMap()" :disabled="!editable"/>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="12" lg="7" xl="7">
            <div v-if="showMap" class="map_canvas">
              <gmap-map :center="postcodeLocation" ref="map" :zoom="9" style="width: 100%; height:345px">
                <gmap-marker v-if="editable" :draggable="true" :position="{lat: pharmacy.latitude, lng: pharmacy.longitude}" @dragend="updateLatLng($event)" :icon="'https://cdn2.iconfinder.com/data/icons/color-svg-vector-icons-2/512/map_marker_base-48.png'">
                </gmap-marker>
                <gmap-marker v-if="!editable" :position="{lat: pharmacy.latitude, lng: pharmacy.longitude}" @dragend="updateLatLng($event)" :icon="'https://cdn2.iconfinder.com/data/icons/color-svg-vector-icons-2/512/map_marker_base-48.png'">
                </gmap-marker>
              </gmap-map>
            </div>
          </b-col>
        </b-row>
        <br/>
      </b-card-body>
    </b-card>
    <br/>
  </div>
</template>

<script>

import { gmapApi } from 'vue2-google-maps'

export default {
  name: 'Orders',
  components: {},
  data: function () {
    return {
      pharmacy: {},
      editable: false,
      postcodeLocation: {},
      showMap: false,
      truthyFalsy: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      memberTypes: [
        { text: 'Gold', value: 'GOLD'},
        { text: 'Silver', value: 'SILVER'},
        { text: 'None', value: 'NONE'},
      ],
      addDeliveryCharge: false,
      addDeliveryRadius: false,
      addOpeningTimes: false,
      addCPPQ: false
    }
  },
  computed: {
    deliveryChargeRule () {
      return this.addDeliveryCharge ? 'required' : '';
    },
    deliveryRadiusRule () {
      return this.addDeliveryRadius ? 'required' : '';
    },
    openingTimesRule () {
      return this.addOpeningTimes ? 'required' : '';
    },
    cppqRule () {
      return this.addCPPQ ? 'required' : '';
    },
    google: function() {
      return gmapApi;
    }
  },
  mounted: function () {
    this.getPharmacy()
  },
  methods: {
    updateLatLng (event) {
      this.pharmacy.latitude = event.latLng.lat();
      this.pharmacy.longitude = event.latLng.lng();
    },
    getPharmacy () {
      this.showMap = false;
      this.$pharmacyService.getPharmacy(this.$route.params.pharmacyId).then(pharmacy => {
        this.pharmacy = pharmacy;
        this.convertPreferences();
        this.convertSettings();
        this.updateMap();
      })
    },
    updateMap () {
      this.pharmacy.latitude = parseFloat(this.pharmacy.latitude);
      this.pharmacy.longitude = parseFloat(this.pharmacy.longitude);
      this.getLatLan(`${this.pharmacy.address.postcode},UK`).then(location => {
        this.postcodeLocation = location;
        this.showMap = true;
        this.$nextTick(() => {
          if(this.$refs.map) {
            this.$refs.map.$mapPromise.then((map) => {
              const r = 0.005;
              map.fitBounds({
                south: location.lat() - r,
                west: location.lng() - r,
                north: location.lat() + r,
                east: location.lng() + r
              });
            })
          }
        })
      })
    },
    convertPreferences () {
      if (!this.pharmacy.preferences) {
        this.$set(this.pharmacy, 'preferences', {});
      }
      if (!this.pharmacy.preferences.deliveryCharge) {
        this.$set(this.pharmacy.preferences, 'deliveryCharge', ''); // done for reactivity
      }
      if (!this.pharmacy.preferences.deliveryRadius) {
        this.$set(this.pharmacy.preferences, 'deliveryRadius', ''); // done for reactivity
      }
      if (!this.pharmacy.preferences.openingTimes) {
        this.$set(this.pharmacy.preferences, 'openingTimes', ''); // done for reactivity
      }

      this.addDeliveryCharge = this.pharmacy.preferences.deliveryCharge ? true : false;
      this.addDeliveryRadius = this.pharmacy.preferences.deliveryRadius ? true : false;
      this.addOpeningTimes = this.pharmacy.preferences.openingTimes ? true : false;
    },
    convertSettings () {
      if (!this.pharmacy.settings) {
        this.$set(this.pharmacy, 'settings', {});
      }
      if (!this.pharmacy.settings.AVERAGE_MONTHLY_SCRIPT_VOLUME) {
        this.$set(this.pharmacy.settings, 'AVERAGE_MONTHLY_SCRIPT_VOLUME', ''); // done for reactivity
      }
      this.addCPPQ = this.pharmacy.settings.AVERAGE_MONTHLY_SCRIPT_VOLUME ? true : false;

      if (this.pharmacy.settings.DOES_VACCINES) {
        this.pharmacy.settings.DOES_VACCINES = true;
      } else {
        this.$set(this.pharmacy.settings, 'DOES_VACCINES', false); // done for reactivity
      }
    },
    async getLatLan(address) {
      await this.$gmapApiPromiseLazy();
      const google = this.google();
      return await new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': address }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
              resolve(results[0].geometry.location);
          } else {
              reject(status);
          }
        });
      })
    },
    removeDeliveryCharge () {
      this.addDeliveryCharge = false;
      this.pharmacy.preferences.deliveryCharge = '';
    },
    removeDeliveryRadius () {
      this.addDeliveryRadius = false;
      this.pharmacy.preferences.deliveryRadius = '';
    },
    removeOpeningTimes () {
      this.addOpeningTimes = false;
      this.pharmacy.preferences.openingTimes = '';
    },
    removeCPPQ () {
      this.addCPPQ = false;
      this.pharmacy.settings.AVERAGE_MONTHLY_SCRIPT_VOLUME = '';
    },
    allowChanges() {
      this.editable = true;
      this.truthyFalsy.forEach(option => {
        this.$delete(option, 'disabled');
      })
    },
    reset() {
      this.truthyFalsy.forEach(option => {
        this.$set(option, 'disabled');
      })
      this.editable = false;
      this.getPharmacy();
    },
    updatePharmacy () {
      this.prepareToUpdate()
      this.$validator.validateAll().then((success) => {
        if (success) {
          this.$pharmacyService.updatePharmacy(this.pharmacy).then(() => {
            this.$router.push('/pharmacies')
            this.$awn.success('Pharmacy updated successfully')
          }).catch((error) => {
            console.log(error.response)
            if (error.response && error.response.data) {
              this.$awn.alert(this.concatErrorMessage(error.response.data)); 
            } else {
              this.$awn.alert('There was a network error'); 
            }
          });
        } else {
          this.$awn.alert('Please check the form above for any missing fields or errors.'); 
        }
      })
    },
    prepareToUpdate () {
      this.pharmacy.username = this.pharmacy.email;
      this.pharmacy.password = "qwerty";
      if (!this.pharmacy.settings.DOES_VACCINES) {
        delete this.pharmacy.settings.DOES_VACCINES;
      }
      delete this.pharmacy.displayName;
    }
  }
}
</script>

<style lang="scss" scoped>
  .action-buttons {
    display: flex; 
    justify-content: flex-end;
  }
  .margin-1-percent {
    margin-right:1%
  }
  #pharmacyIdDescription small {
    margin-top: -1.8%;
  }
  .collapsed > .when-opened, :not(.collapsed) > .when-closed {
    display: none;
  }
</style>