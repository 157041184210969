export const OrderStatus = Object.freeze({
    WAITING_FOR_PHARMACY_APPROVAL: 'WAITING_FOR_PHARMACY_APPROVAL',
    PROPOSED_BY_DOCTOR: 'PROPOSED_BY_DOCTOR',
    DELIVERED: 'DELIVERED',
    BOOKED: 'BOOKED',
    REJECTED_BY_PATIENT: 'REJECTED_BY_PATIENT',
    AWAITING_PHARMACY_DISPATCH: 'AWAITING_PHARMACY_DISPATCH',
    AWAITING_PATIENT_PRESCRIPTION_PAYMENT: 'AWAITING_PATIENT_PRESCRIPTION_PAYMENT',
    AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION: 'AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION',
    AWAITING_PHARMACY_DELIVER: 'AWAITING_PHARMACY_DELIVER',
    REJECTED_BY_PHARMACY_AWAITING_SPECIALIST_ALTERATION: 'REJECTED_BY_PHARMACY_AWAITING_SPECIALIST_ALTERATION'
});