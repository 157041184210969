import Vue from "vue";

Vue.filter("orderStatus", (value) => {
    switch (value) {
        case "WAITING_FOR_PHARMACY_APPROVAL":
            return "Sent to Pharmacy";
        case "REJECTED_BY_PHARMACY":
            return "Rejected by Pharmacy";
        case "CANCELED_BY_PHARMACY":
            return "Canceled by Pharmacy";
        case "PAYMENT_FAILED":
            return "Payment Failed";
        case "APPROVED_BY_PHARMACY":
            return "Dispensed";
        case "PROPOSED_BY_DOCTOR":
            return "Awaiting Patient Approval";
        case "DELIVERED":
            return "Complete";
        case "BOOKED":
            return "Booked";
        case "WAITING_FOR_TRACKING_CODE":
            return "Awaiting Tracking Code";
        case "AWAITING_PHARMACY_DISPENSE":
            return "Awaiting Pharmacy Dispense";
        case "AWAITING_SPECIALIST_PRESCRIPTION_CREATION":
            return "Awaiting Specialist Prescription Creation";
        case "AWAITING_SPECIALIST_CALL_PATIENT":
            return "Awaiting Specialist Call Patient";
        case "AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION":
            return "Awaiting Patient Specialist Prescription Approval";
        case "AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION":
            return "Awaiting Patient Pharmacy Prescription Approval";
        case "WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_PATIENT_APPROVAL":
            return "Awaiting Pink Prescription Confirmation With Patient Approval";
        case "WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_NO_CHANGES":
            return "Awaiting Pink Prescription Confirmation With No Changes";
        case "WAITING_FOR_DOCTOR_APPROVAL":
            return "Awaiting Doctor Approval";
        case "AWAITING_PATIENT_PRESCRIPTION_PAYMENT":
            return "Awaiting Prescription Payment";
        case "AWAITING_PHARMACY_DISPATCH":
            return "Awaiting Pharmacy Dispatch";
        case "AWAITING_PHARMACY_DELIVER":
            return "Awaiting Pharmacy Deliver";
        case "AWAITING_MDT_SESSION_INITIATION":
            return "Awaiting MDT Session Initiation";
        case "WAITING_FOR_PATIENT_DELIVERY_DETAILS":
            return "Awaiting Patient Delivery Details";
        case "AWAITING_PATIENT_SPECIALIST_ALERT_REPLY":
            return "Awaiting Patient Message Reply";
        case "WAITING_FOR_DOCTOR_PRESCRIPTION_COMPLETION":
            return "Awaiting Doctor Prescription Completion";
        case "WAITING_FOR_ADMIN_DELIVERY_DETAILS":
            return "Awaiting Admin Delivery Details";
        case "CANCELED_BY_PATIENT":
            return "Cancelled by Patient";
        case "RESERVED_BY_DOCTOR":
            return "In Progress";
        case "REJECTED_BY_SPECIALIST":
            return "Rejected by Specialist";
        case "REJECTED_BY_MDT":
            return "Rejected by MDT";
        case "REJECTED_BY_DOCTOR":
            return "Rejected by Doctor";
        case "REJECTED_BY_PATIENT":
            return "Rejected by Patient";
        case "REJECTED_BY_PHARMACY_AWAITING_SPECIALIST_ALTERATION":
            return "Rejected by Pharmacy Awaiting Specialist Alteration";
        case "ONGOING":
            return "Ongoing";
        case "APPOINTMENT_COMPLETED":
            return "Appointment Completed";
        case "CANCELED_BY_DOCTOR":
            return "Cancelled by Doctor";
        case "CANCELED_BY_ADMIN":
            return "Cancelled by Admin";
        default:
            return value;
    }
});
