<template>
    <div>
        <div class="invoice-container" :id="elementId">
            <div class="invoice">
                <div class="d-flex w-100">
                    <h3 class="mr-auto fw-bold">Invoice</h3>
                    <div class="ml-auto">
                        <img
                            class="logo"
                            src="/static/img/logo.png"
                            alt=""
                            width="150"
                        />
                    </div>
                </div>
                <!-- <div class="w-50 row">
                    <div class="col-5">
                        <span class="fw-semibold">VAT number</span>
                    </div>
                    <div class="col-7">
                        <span class="fw-normal">12345</span>
                    </div>
                </div> -->
                <div class="my-3 invoice-details">
                    <div class="row mb-4">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-5">
                                    <span class="fw-semibold">
                                        Invoice number
                                    </span>
                                </div>
                                <div class="col-7">
                                    <span class="fw-semibold">
                                        {{ invoiceNumber }}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5">
                                    <span class="fw-normal">
                                        Invoice date
                                    </span>
                                </div>
                                <div class="col-7">
                                    <span class="fw-normal">
                                        {{ invoiceDate }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6"></div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <p class="mb-0 fw-semibold">Treat it</p>
                            <p class="mb-0 fw-normal">
                                The GP Service (UK) Limited
                            </p>
                            <p class="mb-0 fw-normal">HDTI Building</p>
                            <p class="mb-0 fw-normal">
                                University Technology Park, Puma Way
                            </p>
                            <p class="mb-0 fw-normal">
                                Coventry CV1 2TT, UK
                            </p>
                            <p class="mb-0 fw-normal">
                                support@treat-it.clinic
                            </p>
                        </div>
                        <div class="col-6">
                            <p class="mb-0 fw-semibold">Bill to</p>
                            <p class="mb-0 fw-normal">{{ customerName }}</p>
                            <p v-if="address.line1" class="mb-0 fw-normal">
                                {{ address.line1 }}
                            </p>
                            <p v-if="address.line2" class="mb-0 fw-normal">
                                {{ address.line2 }}
                            </p>
                            <p v-if="address.city" class="mb-0 fw-normal">
                                {{ address.city }}
                            </p>
                            <p v-if="address.country" class="mb-0 fw-normal">
                                {{ address.country }}
                            </p>
                            <p v-if="address.postcode" class="mb-0 fw-normal">
                                {{ address.postcode }}
                            </p>
                            <p class="mb-0 fw-normal">{{ customerEmail }}</p>
                        </div>
                    </div>
                </div>
                <span>Treat It is operated by The GP Service (UK) Limited</span>
                <div class="invoice-table position-relative my-5">
                    <b-table
                        class="mb-0"
                        responsive
                        :items="lineItems"
                        :fields="fields"
                    >
                        <template v-slot:head(net)="row">
                            <span class="float-right">{{ row.label }}</span>
                        </template>
                        <template v-slot:cell(desc)="row">
                            <span>{{ row.value.title }}</span>
                            <ul
                                v-if="row.value.items"
                                class="m-0 mt-2 pl-4"
                                style="list-style: square;"
                            >
                                <li>
                                    Admin route -
                                    {{ row.value.items.adminRoute }}
                                </li>
                                <li>
                                    Quantity - {{ row.value.items.quantity }}
                                </li>
                                <li>
                                    Med. profile -
                                    {{ row.value.items.medicineProfile }}
                                </li>
                                <li>Strain - {{ row.value.items.strain }}</li>
                            </ul>
                        </template>
                        <template v-slot:cell(net)="row">
                            <span class="mb-0 float-right">{{
                                row.value.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "GBP",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })
                            }}</span>
                        </template>
                        <template v-slot:cell(unitPrice)="row">
                            <span class="mb-0">{{
                                row.value.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "GBP",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })
                            }}</span>
                        </template>
                        <template v-slot:cell(vat)="row">
                            <span class="mb-0">
                                {{
                                    row.value.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "GBP",
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })
                                }}
                            </span>
                        </template>
                    </b-table>
                    <div class="d-flex justify-content-end">
                        <div class="w-50">
                            <table class="table table-responsive">
                                <tbody>
                                    <tr>
                                        <td>Subtotal</td>
                                        <td>
                                            <span class="mb-0 float-right">
                                                {{
                                                    subTotal.toLocaleString(
                                                        "en-US",
                                                        {
                                                            style: "currency",
                                                            currency: "GBP",
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }
                                                    )
                                                }}
                                            </span>
                                        </td>
                                    </tr>

                                    <tr v-if="discount">
                                        <td>Discount</td>
                                        <td>
                                            <span class="mb-0 float-right">
                                                -{{
                                                    discount.toLocaleString(
                                                        "en-US",
                                                        {
                                                            style: "currency",
                                                            currency: "GBP",
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }
                                                    )
                                                }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr v-if="baseDelivery">
                                        <td>Base delivery</td>
                                        <td>
                                            <span class="mb-0 float-right">
                                                {{
                                                    baseDelivery.toLocaleString(
                                                        "en-US",
                                                        {
                                                            style: "currency",
                                                            currency: "GBP",
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }
                                                    )
                                                }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr v-if="deliveryVat">
                                        <td>Delivery VAT</td>
                                        <td>
                                            <span class="mb-0 float-right">
                                                {{
                                                    deliveryVat.toLocaleString(
                                                        "en-US",
                                                        {
                                                            style: "currency",
                                                            currency: "GBP",
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }
                                                    )
                                                }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fw-bold">Total</td>
                                        <td>
                                            <span class="mb-0 float-right fw-bold">
                                                {{
                                                    totalAmount.toLocaleString(
                                                        "en-US",
                                                        {
                                                            style: "currency",
                                                            currency: "GBP",
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }
                                                    )
                                                }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <h2 v-if="!paid" class="watermark fw-bold">UNPAID</h2>

                <div v-if="pharmacy" class="mt-5">
                    <h5 class="fw-bold">Notes:</h5>
                    <span>
                        Medications are supplied by {{ pharmacy }}
                        <span v-if="gphcNumber">(GPhC {{ gphcNumber }})</span>
                    </span>
                </div>
                <div class="footer d-flex">
                    <div class="mr-auto">
                        The GP Service (UK) Limited is registered in England and
                        Wales under company number 09359853
                    </div>
                </div>
            </div>
        </div>
        <b-button
            v-if="lineItems.length > 0"
            class="float-right"
            variant="primary"
            @click="($event) => handlePrintInvoice(this.elementId)"
            >Download Invoice</b-button
        >
    </div>
</template>

<script>
export default {
    name: "invoice",
    props: [
        "elementId",
        "invoiceNumber",
        "invoiceDate",
        "customerName",
        "address",
        "customerEmail",
        "lineItems",
        "subTotal",
        "discount",
        "prescriptionFee",
        "baseDelivery",
        "deliveryVat",
        "totalAmount",
        "paid",
        "pharmacy",
        "gphcNumber",
    ],
    data: function() {
        return {
            fields: [
                { key: "desc", label: "Description", thClass: "w-22" },
                { key: "qty", label: "Qty", thClass: "" },
                { key: "unitPrice", label: "Unit Price", thClass: "" },
                { key: "vat", label: "VAT%", thClass: "" },
                { key: "net", label: "Net", thClass: "" },
            ],
        };
    },
    watch: {
        order(newValue) {
            if (newValue.id) {
                this.getConsultationInvoice();
            }
        },
        prescription(newValue) {
            if (newValue.id) {
                this.getPrescriptionInvoice();
            }
        },
    },
    methods: {
        handlePrintInvoice(elementId) {
            const htmlToPrint = document.getElementById(elementId).innerHTML;

            let stylesHtml = "";
            for (const node of [
                ...document.querySelectorAll('link[rel="stylesheet"], style'),
            ]) {
                stylesHtml += node.outerHTML;
            }

            if (stylesHtml) {
                const WindowPrint = window.open(
                    "",
                    "",
                    "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
                );

                WindowPrint.document.write(`<!DOCTYPE html>
                <html>
                    <head>
                        ${stylesHtml}
                    </head>
                    <body>
                        ${htmlToPrint}
                    </body>
                </html>`);

                WindowPrint.document.close();
                WindowPrint.focus();
                setTimeout(() => {
                    WindowPrint.print();
                }, 300);
            }
        },
        getTitle(type) {
            switch (type) {
                case "PRESCRIPTION":
                    return "Prescription";
                case "PRESCRIPTION_DRUG":
                    return "Medication";
                case "BASE_DELIVERY":
                    return "Delivery";
                case "DELIVERY_VAT":
                    return "Delivery VAT";
                case "TOTAL":
                    return "Total amount for medication";
                default:
                    break;
            }
        },
    },
};
</script>

<style lang="scss">
.invoice {
    position: relative;
    width: 21cm;
    height: 29.7cm;
    background: white;
    display: block;
    margin: 0 auto;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 2.5rem;
    border: #550385 1px solid;
    border-top: #550385 5px solid;

    .fw-semibold {
        font-weight: 600;
    }

    .w-22 {
        width: 22rem;
    }

    .fw-bold {
        font-weight: bold;
    }

    .table-responsive {
        display: inline-table;
    }

    .invoice-table {
        z-index: 10;
    }

    .watermark {
        position: absolute;
        color: #5f5f5f2d;
        top: 50%;
        right: 50%;
        transform: rotate(-20deg) translate(50%, 10px);
        font-size: 100px;
        font-weight: bolder;
        -webkit-user-select: none;
        -ml-user-select: none;
        user-select: none;
        z-index: 1;
    }

    .footer {
        border-top: gray 1px solid;
        position: absolute;
        bottom: 3rem;
        width: calc(100% - 5rem);
        padding-top: 1rem;

        @media print {
            position: fixed;
        }
    }

    @media print {
        margin: 0 !important;
        width: 27cm;
        height: 38cm;
        border: none;
        border-top: #550385 5px solid;
    }
}

@page {
    margin: 0 !important;
    size: 21cm 29.7cm !important;
}
</style>
