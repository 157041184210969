<template>
    <orders-table
        :title="title"
        :orders="orders"
        :page="currentPage"
        :action="action"
        :column-names="columnNames"
        :click-event="clickEvent"
    ></orders-table>
</template>

<script>
import OrdersTable from "../../components/OrdersTable";
import OrderEventBus from "../../EventBus.js";

export default {
    components: {
        OrdersTable,
    },
    data: function() {
        return {
            title: "Failed Payments",
            orders: {},
            currentPage: 1,
            action:
                "<button class='btn btn-sm btn-outline-danger'>View details</button>",
            columnNames: [
                "id",
                "creationDate",
                "status",
                "description",
                "patient",
                "doctor",
                "pharmacy",
                "actions",
            ],
            clickEvent: "rejected",
        };
    },
    mounted() {
        this.getOrders();
        OrderEventBus.$on("currentPage", (currentPage) => {
            this.currentPage = currentPage;
        });
        OrderEventBus.$on(this.clickEvent, (orderId) => {
            this.$router.push("/order/payment-failed/" + orderId);
        });
    },
    watch: {
        currentPage: function() {
            this.getOrders();
        },
    },
    methods: {
        getOrders() {
            this.$orderService
                .getPaymentFailedOrders(this.currentPage - 1, 10)
                .then((orders) => {
                    this.orders = orders;
                })
                .catch((error) => {
                    console.log(error);
                    this.$awn.alert("There was an error getting orders");
                });
        },
    },
    beforeDestroy() {
        OrderEventBus.$off();
    },
};
</script>
