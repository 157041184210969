<template>
  <b-card>
    <b-row>
      <b-col sm="6" v-for="(question, index) in filteredQuestions" :key="index">
        <div v-show="shouldShowQuestion(question)">
          <label><strong>Q{{ index + 1 }}. {{ question.title }}<span v-if="question.required"
                class="text-danger">*</span></strong></label>
          <div class="ml-3 mb-3">
            <p v-if="question.description" class="text-muted">{{ question.description }}</p>
            <!-- Render different input types based on question type -->
            <template v-if="question.type === 'radio' || question.type === 'checkbox'">
              <b-form-radio-group v-if="question.type === 'radio'" v-model="answers[question.patientTitle]"
                :options="question.options" @change="checkError(question)"></b-form-radio-group>
              <b-form-checkbox-group v-if="question.type === 'checkbox'" v-model="answers[question.patientTitle]"
                :options="question.options"></b-form-checkbox-group>
            </template>

            <template v-else-if="question.type === 'text' || question.type === 'textArea'">
              <b-form-input v-if="question.type === 'text'" v-model="answers[question.patientTitle]" type="text"
                @blur="checkError(question)"></b-form-input>
              <b-form-textarea v-if="question.type === 'textArea'" v-model="answers[question.patientTitle]"
                @blur="checkError(question)"></b-form-textarea>
            </template>

            <template v-else-if="question.type === 'dropdown'">
              <b-form-select v-model="answers[question.patientTitle]" :options="question.options"
                @change="checkError(question)"></b-form-select>
            </template>
            <!-- Display error message if answer is invalid -->
            <small v-if="formErrors[question.patientTitle]" class="text-danger">{{ formErrors[question.patientTitle]
              }}</small>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Button to proceed to the next page, disabled if form is invalid -->
    <div class="d-flex justify-content-center mt-3">
      <b-button variant="primary" @click="nextPage" class="next-btn">Next</b-button>
    </div>
  </b-card>
</template>

<script>
// Import questions from external file
import { questions } from '../../util/eligibilityQuestionnaires.js';

export default {
  name: 'CheckEligibility',
  data() {
    return {
      questions: questions,
      answers: {},
      formErrors: {},
    };
  },
  computed: {
    filteredQuestions() {
      const filtered = this.questions.filter(question => {
        if (question.dependOn) {
          const dependQuestion = this.questions.find(q => q.id === question.dependOn.id);
          return dependQuestion && this.answers[dependQuestion.patientTitle] === question.dependOn.value;
        }
        return true;
      });
      return filtered;
    },
    // Check if form has any errors
    isFormInvalid() {
      // Check if any required questions don't have answers or have invalid answers
      return this.filteredQuestions.some(question => {
        const answer = this.answers[question.patientTitle];
        if (question.required) {
          if (!answer || (question.type === 'text' || question.type === 'textArea') && !answer.trim()) {
            return true; // Return true if required question doesn't have an answer
          } else if (question.validAnswer && !question.validAnswer.includes(answer)) {
            return true; // Return true if required question has an invalid answer
          }
        }
        return false;
      });
    },

  },
  created() {
    // Load answers from localStorage on component creation
    this.loadAnswersFromLocalStorage();
  },
  methods: {
    // Validate and handle errors for each question
    checkError(question) {
      if (question.required) {
        const answer = this.answers[question.patientTitle];
        if (!answer || (question.type === 'text' || question.type === 'textArea') && !answer.trim()) {
          this.$set(this.formErrors, question.patientTitle, question.errorText);
        } else if (question.validAnswer && answer && !question.validAnswer.includes(answer)) {
          this.$set(this.formErrors, question.patientTitle, question.errorText);
        } else {
          this.$delete(this.formErrors, question.patientTitle);
        }
      }
      this.saveAnswersToLocalStorage();
    },
    // Load answers from localStorage and check for errors
    loadAnswersFromLocalStorage() {
      const storedAnswers = JSON.parse(localStorage.getItem('EligibilityForm'));
      if (storedAnswers) {
        storedAnswers.forEach(answer => {
          this.$set(this.answers, answer.question, answer.answer);
          this.checkError(this.questions.find(question => question.patientTitle === answer.question));
        });
      }
    },
    // Save answers to localStorage
    saveAnswersToLocalStorage() {
      const eligibilityFormData = Object.keys(this.answers).map(key => ({
        question: key,
        answer: this.answers[key]
      }));
      localStorage.setItem('EligibilityForm', JSON.stringify(eligibilityFormData));
    },
    getErrorMessage() {
      // Check if any required questions don't have answers or have invalid answers
      const hasRequiredError = this.filteredQuestions.some(question => {
        const answer = this.answers[question.patientTitle];
        if (question.required && (!answer || (question.type === 'text' || question.type === 'textArea') && !answer.trim())) {
          return true; // Return true if required question doesn't have an answer
        }
        return false;
      });

      // Check if any question has an invalid selection
      const hasInvalidSelection = this.filteredQuestions.some(question => {
        const answer = this.answers[question.patientTitle];
        if (answer && question.validAnswer && !question.validAnswer.includes(answer)) {
          return true; // Return true if required question has an invalid answer
        }
        return false;
      });
      if (hasRequiredError && hasInvalidSelection) {
        return 'Please fill in all required fields and ensure all criteria are met before proceeding!';
      } else if (hasRequiredError) {
        return 'Please fill in all required fields!';
      } else if (hasInvalidSelection) {
        return 'Eligibility verification unsuccessful. Please ensure all criteria are met before proceeding!';
      }

      return ''; // No errors
    },
    // Proceed to the next page if form is valid
    nextPage() {
      if (this.isFormInvalid) {
        const errorMessage = this.getErrorMessage();
        if (errorMessage) {
          this.$awn.alert(errorMessage);
          return;
        }
        return;
      }
      this.$emit('next-page-clicked', 'create-account');
    },
    // display questions according to parent question answers
    shouldShowQuestion(question) {
      if (!question.dependOn)
        return true;
      const key = this.questions.find(x => x.id === question.dependOn.id).patientTitle;
      if (this.answers[key] === question.dependOn.value) {
        this.questions.forEach(element => {
          if (element.id === question.id) {
            element.required = true;
          } else if (element.dependOn && element.dependOn.id === question.dependOn.id) {
            element.required = false;
            delete this.answers[element.patientTitle];
          }
        });
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.next-btn {
  background: #550385;
  color: #fff;
  border-radius: 6px !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  padding: 10px 30px !important;
  border: 0;
  width: 150px;
  height: 50px;
}
</style>
