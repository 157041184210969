<template>
  <button class="sidebar-minimizer" type="button" @click="sidebarMinimize();brandMinimize()"></button>
</template>
<script>

export default {
  name: 'sidebar-minimizer',
  methods: {
    sidebarMinimize () {
      var display = document.getElementsByClassName('logo')[0].style.display;
      document.getElementsByClassName('logo')[0].style.display = display !== 'none' ? 'none' : 'block';
      document.body.classList.toggle('sidebar-minimized')
    },
    brandMinimize () {
      document.body.classList.toggle('brand-minimized')
    }
  }
}
</script>
