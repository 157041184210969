<template>
    <div>
        <b-row>
            <b-col md="12" lg="6">
                <order-details
                    :order="order"
                    @change-appointment="handleAppointmentChange"
                />
            </b-col>
            <b-col>
                <patient-details :patient="order.patient" />
            </b-col>
        </b-row>
        <br />

        <b-row>
          <b-col md="12">
            <b-card>
              <div slot="header">
                <i class='fa fa-align-justify'></i><strong class='header-spacing'>Actions</strong>
              </div>
              <b-form-row style="display: flex; flex-direction: row; justify-content: flex-start; margin-top: 20px;">
                <b-button variant="warning"
                          style="margin-right: 20px;"
                          @click="changeDeliveryDateModal = true"
                          :disabled="disableChangeDeliveryDateButton">
                  Change Delivery Date
                </b-button>
                <b-button variant="info"
                          style="margin-right: 20px;"
                          @click="changeOrderStatusModal = true"
                          :disabled="disableChangeStatusButton">
                  Order Status Change
                </b-button>
<!--                <b-button variant="success" style="margin-right: 5px;" @click="showCancelModal()">-->
<!--                  Paid Offline-->
<!--                </b-button>-->
              </b-form-row>
            </b-card>
          </b-col>
        </b-row>

        <br />

        <b-card v-if="order.pictures && order.pictures.length" no-title no-body>
            <div class="own-header">
                <b-row>
                    <b-col class="display-name" sm="12" md="6">
                        <i class="fa fa-align-justify"></i>
                        <strong class="header-spacing">
                            Consultation documents
                        </strong>
                    </b-col>
                </b-row>
            </div>
            <b-card-body>
                <h3 v-if="consultationFiles.docs.length">Documents</h3>
                <b-row v-if="consultationFiles.docs.length">
                    <b-col
                        cols="3"
                        v-for="(item, index) in consultationFiles.docs"
                        v-bind:key="index"
                    >
                        <b-button
                            type="button"
                            variant="light"
                            class="d-flex flex-column align-items-center doc-button"
                            v-on:click="
                                handleOpenPDF(item.url || item.base64value)
                            "
                        >
                            <img src="/static/vectors/doc-icon.svg" alt="" />
                            <span>Document</span>
                        </b-button>
                    </b-col>
                </b-row>
                <h3 v-if="consultationFiles.images.length">Images</h3>
                <b-row
                    v-if="consultationFiles.images.length"
                    class="d-flex flex-column align-items-center"
                >
                    <div
                        class="mb-40 consultation-image"
                        v-for="(item, index) in consultationFiles.images"
                        v-bind:key="index"
                    >
                        <img :src="item.url || item.base64value" alt="" />
                    </div>
                </b-row>
            </b-card-body>
        </b-card>

        <b-card v-if="consultationNotes" no-title no-body>
            <div class="own-header">
                <b-row>
                    <b-col class="display-name" sm="12" md="6">
                        <i class="fa fa-align-justify"></i>
                        <strong class="header-spacing">
                            Consultation notes
                        </strong>
                    </b-col>
                </b-row>
            </div>
            <b-card-body>
                <b-row v-if="consultationNotes">
                    <b-col cols="12">
                        {{ consultationNotes }}
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <voucher-info v-if="order.coupon" :order="order" />

        <additional-notes v-if="filteredNotes.length" :orderNotes="filteredNotes" />
        <!-- <additional-order-info :order="order" /> -->

        <prescription-details :order="order" :prescription="prescription" />

        <b-card v-if="prinkPrescription">
            <div slot="header">
                <i class="fa fa-align-justify"></i
                ><strong class="header-spacing">Pink Prescription <span v-if="!!prinkPrescription.reference">(Ref No: {{prinkPrescription.reference}})</span></strong>
            </div>
            <div v-if="prinkPrescription.contentType.includes('image')">
                <img
                    :src="prinkPrescription.url"
                    alt=""
                    class="pink-prescription"
                />
            </div>
            <div v-if="prinkPrescription.contentType.includes('application')">
                <b-button
                    type="button"
                    variant="light"
                    class="d-flex flex-column align-items-center doc-button"
                    v-on:click="handleOpenPDF(prinkPrescription.url)"
                >
                    <img src="/static/vectors/doc-icon.svg" alt="" />
                    <span>Document</span>
                </b-button>
            </div>
        </b-card>

        <b-card>
            <div slot="header">
                <i class="fa fa-align-justify"></i
                ><strong class="header-spacing">Invoice items</strong>
            </div>
            <div v-if="order.id" class="consultation-invoice-container">
                <h6 class="mb-2"><b>Consultation invoice</b></h6>
                <consultation-invoice />
                <br />
                <hr class="my-5" />
            </div>
            <div v-if="prescription.id" class="prescription-invoice-container">
                <h6 class="mb-2">
                    <b>
                        {{
                            order.isRepeatOrder
                                ? "Repeat prescription invoice"
                                : "Prescription invoice"
                        }}
                    </b>
                </h6>
                <prescription-invoice />
            </div>
        </b-card>

        <!-- <b-card v-if="prescription.id">
      <div slot="header">
        <i class='fa fa-align-justify'></i><strong class='header-spacing'> Delivery details</strong>
      </div>

      <b-row>
        <b-col md="12" xl="4">
          <br/>
          <b-form-group label="How would the patient like to receive his medication?" label-for="basicRadiosCustom" label-class="question-title">
            <b-form-radio-group v-if="order.formType" v-model="deliveryOnly" stacked disabled>
              <b-form-radio value="true" @click.native="findPharmacies()">Collected from local pharmacy</b-form-radio>
              <b-form-radio value="false" @click.native="findPharmacies()">Delivered from local pharmacy</b-form-radio>
            </b-form-radio-group>
            <b-form-radio-group v-if="!order.formType" v-model="deliveryOnly" stacked>
              <b-form-radio value="false" @click.native="findPharmacies()">Collected from local pharmacy</b-form-radio>
              <b-form-radio value="true" @click.native="findPharmacies()">Delivered from local pharmacy</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <br/>

          <div v-if="!order.formType" class="form_group form_group--nested">
            <b-form-group label="Enter your postcode to select your pharmacy:" label-for="postcode" label-class="question-title">
              <b-form-group id="pharmacyIdDescription" description="This field has been pre-populated with the order's pharmacy postcode">
                <b-row class="form-group">
                  <b-col cols="9">
                    <b-input-group class="no-padding">
                      <b-form-input type="search" placeholder="Enter your postcode" v-model="postcode" name="postcode" @search="findPharmacies()" style="min-width:35%"/>
                      <b-input-group-append>
                        <b-button variant="primary" class="btn btn--2" v-on:click="findPharmacies()" style="min-width:30%">Find pharmacies</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-form-group>
          </div>

          <div v-if="order.formType" class="form_group form_group--nested">
            <b-form-group label="Selected postcode for your pharmacy" label-for="postcode" label-class="question-title">
              <b-form-group id="pharmacyIdDescription" description="This field has been pre-populated with the order's pharmacy postcode">
                <b-row class="form-group">
                  <b-col cols="9">
                    <b-input-group class="no-padding">
                      <b-form-input type="text" v-model="postcode" name="postcode" @search="findPharmacies()" style="min-width:35%" disabled/>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-form-group>
          </div>

          <br/>

          <div class="form_group form_group--nested">
            <b-form-group v-if="!order.formType" label="Enter a Pharmacy ID or pick a pharmacy using the map:" label-for="pharmacyId" label-class="question-title">
              <b-form-group id="pharmacyIdDescription" description="This field has been pre-populated with the order's pharmacy ID">
                <b-row class="form-group">
                  <b-col cols="9">
                    <b-input-group class="no-padding" style="min-width:100%">
                      <b-form-input type="search" placeholder="Enter a Pharmacy ID" v-model="pharmacyId" name="pharmacyId" @search="findPharmacies()"/>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-form-group>

            <b-form-group v-if="order.formType" label="Selected Pharmacy ID" label-for="pharmacyId" label-class="question-title">
              <b-form-group id="pharmacyIdDescription" description="This field has been pre-populated with the order's pharmacy ID">
                <b-row class="form-group">
                  <b-col cols="9">
                    <b-input-group class="no-padding" style="min-width:100%">
                      <b-form-input type="text" v-model="pharmacyId" name="pharmacyId" @search="findPharmacies()" disabled/>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-form-group>
          </div>
        </b-col>

        <b-col v-if="!order.formType">
          <p v-if="findPharmacyButtonClicked && !showMap">
            <br /><br /><br />
            Searching...
          </p>
          <div v-if="showMap" class="map_canvas">
              <gmap-map :center="postCodeLocation" ref="map" :zoom="9" style="width: 100%; height: 450px">
                <gmap-marker v-for="p in pharmacies" :key="p.id" @click="selectPharmacy(p)" :position="{lat: p.latitude, lng: p.longitude}" :icon="'https://cdn2.iconfinder.com/data/icons/color-svg-vector-icons-2/512/map_marker_base-48.png'" :opacity="p.member && p.active ? 1 : 0.3">
                  <gmap-info-window :opened="pharmacyId === p.id">
                    <label class="mb-0 text-primary"><h5>{{p.name}}</h5></label> <br/>
                    <span v-if="p.active && p.memberType === 'GOLD'" class="text-primary"><label><strong>Gold Member Pharmacy</strong></label><br/></span>
                    <span v-if="p.active && p.memberType === 'SILVER'" class="text-primary"><label><strong>Silver Member Pharmacy</strong></label><br/></span>
                    <span v-if="p.memberType === 'NONE'" class="text-danger"><label><strong>Non-Member Pharmacy</strong></label><br/></span>
                    <label><strong>Pharmacy ID:</strong> {{p.id}}</label><br/>
                    <label><strong>Email:</strong> {{p.email}}</label><br/>
                    <label><strong>Phone:</strong> {{p.phone}}</label><br/>
                    <b-row v-if="p.address">
                      <b-col cols="4" class="pr-0"><strong>Address:</strong></b-col>
                      <b-col class="pl-0" style="margin-left: -6%">
                        <span><label>{{p.address.line1}} {{p.address.line2}}</label><br/></span>
                        <span v-if="p.address.county"><label>{{p.address.county}}</label><br/></span>
                        <span><label>{{p.address.city}} - {{p.address.postcode}}</label></span>
                      </b-col>
                    </b-row>
                    <span v-if="p.preferences && p.preferences.openingTimes">
                      <label><strong>Opening times:</strong> {{p.preferences.openingTimes}} <br/></label>
                    </span>
                    <span v-if="deliveryOnly">
                      {{p.deliveryNotes}} <br/>
                      <label v-if="p.preferences && p.preferences.deliveryCharge"><strong>Delivery charge:</strong> {{p.preferences.deliveryCharge}}<br/></label>
                    </span>
                    <span v-if="p.settings && p.settings.DOES_VACCINES">
                      <br/>
                      <label><strong>Does vaccines: </strong>Yes</label>
                    </span>
                  </gmap-info-window>
                </gmap-marker>
              </gmap-map>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-3" v-if="!order.formType">
          <b-col xs="6" md="4" class="mb-1">
            <b-button type="button" variant="outline-danger" class="btn-block" @click="reset()">Reset</b-button>
          </b-col>
          <b-col xs="6" md="4">
            <b-button type="button" variant="primary" class="btn-block" @click="confirmModal = true">Confirm</b-button>
          </b-col>
        </b-row>
    </b-card> -->

        <b-modal
            title="Confirm delivery details"
            centered
            class="modal-success"
            v-model="confirmModal"
            @ok="confirm()"
            ok-title="Yes"
            ok-variant="primary"
            cancel-title="No"
            cancel-variant="outline-danger"
        >
            <strong class="text-muted">PLEASE NOTE:</strong>
            <span class="text-danger"
                >This action is not reversible, are you sure you want to confirm
                this order's delivery details?</span
            >
        </b-modal>

      <b-modal
          title="Change Delivery Date"
          centered class="modal-success"
          v-model="changeDeliveryDateModal"
          @ok="confirmDeliveryDate()"
          :ok-disabled="!isDateValid"
          ok-title="Yes"
          ok-variant="primary"
          cancel-title="No"
          cancel-variant="outline-danger">
        <strong class="text-danger">This action is not reversible, are you sure you want to change this order's delivery date?</strong>
        <b-form-group id="deliveryDate" label="Delivery Date" label-for="deliveryDate" label-cols="12">
          <b-form-input
              name="deliveryDate"
              type="date"
              :min="getOrderCreatedDate"
              :max="getTodayDate"
              v-model="deliveryDate"
              @keydown.prevent
              required/>
        </b-form-group>
      </b-modal>
      <b-modal
          title="Change Order Status"
          centered class="modal-success"
          v-model="changeOrderStatusModal"
          @ok="proceedOrderChange"
          ok-title="Proceed"
          ok-variant="primary"
          cancel-title="No"
          cancel-variant="outline-danger">
        <b-form-group id="currentStatus" label="Current Status" label-for="currentStatus" label-cols="12">
          <b-form-input
              name="currentStatus"
              style="text-align: left"
              type="text"
              v-model="order.status"
              disabled/>
        </b-form-group>
        <b-form-group id="todoStatus" label="Todo Status" label-for="todoStatus" label-cols="12">
          <b-form-select
              name="todoStatus"
              v-model="todoStatus"
              :options="orderStatusList"
              required/>
        </b-form-group>
        <b-form-group v-if="todoStatus === 'DELIVERED'" id="deliveryDate" label="Delivery Date" label-for="deliveryDate" label-cols="12">
          <b-form-input
              name="deliveryDate"
              type="date"
              :min="getOrderCreatedDate"
              :max="getTodayDate"
              v-model="deliveryDate"
              @keydown.prevent
              required/>
        </b-form-group>
        <b-form-group v-if="isShowCommentBox" id="changeReason" label="Reason for Change *" label-for="changeReason" label-cols="12">
          <b-form-input
              name="changeReason"
              type="text"
              v-model="changeReason"
              required/>
          <!-- Error message -->
          <b-form-text v-if="showError" class="text-danger" style="color: red !important; font-weight: bold !important;">
            Reason for Change is required.
          </b-form-text>
        </b-form-group>
        <b-form-group id="orderId"
                      label="Order Id"
                      label-for="orderId"
                      label-cols="12">
          <b-form-input
              name="orderId"
              style="text-align: left"
              type="text"
              v-model="order.id"
              disabled/>
        </b-form-group>
      </b-modal>
      <b-modal title="Confirm Order Change"
               centered class="modal-success"
               v-model="confirmOrderChangeModal"
               @ok="confirmOrderChange()"
               ok-title="Yes"
               ok-variant="primary"
               cancel-title="No"
               cancel-variant="outline-danger">
        <strong class="text-muted">PLEASE NOTE: This action is not reversible.</strong>
        <span class="text-danger">
          Are you sure you want to put this order through?
        </span>
      </b-modal>
    </div>
</template>

<script>
import moment from "moment";
import publicService from "../../services/PublicService";
import { gmapApi } from "vue2-google-maps";
import OrderDetails from "../../components/OrderDetails";
import PatientDetails from "../../components/PatientDetails";
import PrescriptionDetails from "../../components/PrescriptionDetails";
// import AdditionalOrderInfo from "../../components/AdditionalOrderInfo";
import VoucherInfo from "../../components/VoucherInfo";
import AdditionalNotes from "../../components/AdditionalNotes";
import OrderEventBus from "../../EventBus.js";
import ConsultationInvoice from "./ConsultationInvoice.vue";
import PrescriptionInvoice from "./PrescriptionInvoice.vue";
import { OrderStatus } from "@/enums/OrderStatus";

export default {
    name: "Orders",
    components: {
        OrderDetails,
        PatientDetails,
        PrescriptionDetails,
        // AdditionalOrderInfo,
        VoucherInfo,
        AdditionalNotes,
        ConsultationInvoice,
        PrescriptionInvoice,
    },
    data: function() {
        return {
            order: {},
            consultationFiles: {
                docs: [],
                images: [],
            },
            consultationNotes: "",
            prescription: {},
            prescriptionStatusChange: null,
            prinkPrescription: "",
            showPrescription: false,
            travelPicker: false,
            deliveryOnly: false,
            doesVaccines: false,
            memberType: "NONE",
            postcode: "",
            findPharmacyButtonClicked: false,
            bounds: {},
            showMap: false,
            pharmacies: [],
            pharmacyId: 0,
            confirmModal: false,
            deliveryDate: "",
            changeDeliveryDateModal: false,
            changeOrderStatusModal: false,
            todoStatus: '',
            confirmOrderChangeModal: false,
            disableChangeDeliveryDateButton: true,
            disableChangeStatusButton: true,
            orderStatusList: [],
            changeReason: '',
            showError: false,
            antimalarialPrescriptionFields: [
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "dosageStartDate",
                    label: "Start Date",
                    tdClass: "text-nowrap",
                },
                {
                    key: "dosageEndDate",
                    label: "End Date",
                    tdClass: "text-nowrap",
                },
                {
                    key: "dosageInstructions",
                    label: "Instructions",
                },
                {
                    key: "dosageExtraInfo",
                    label: "Extra Information",
                },
                {
                    key: "price",
                    label: "Price",
                    tdClass: "text-nowrap",
                },
            ],
            vaccinationPrescriptionFields: [
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "startDate",
                    label: "Start Date",
                    tdClass: "text-nowrap",
                },
                {
                    key: "dosageInstructions",
                    label: "Instructions",
                },
                {
                    key: "price",
                    label: "Price",
                    tdClass: "text-nowrap",
                },
            ],
        };
    },
    computed: {
        creationDate: function() {
            return moment(this.order.creationDate)
                .local()
                .format("Do MMMM YYYY, HH:mm");
        },
        dateOfBirth: function() {
            return moment(this.order.patient.dateOfBirth).format("DD MMM YYYY");
        },
        price: function() {
            return "£" + this.order.price;
        },
        homePhone: function() {
            return this.order.patient.preferences &&
                this.order.patient.preferences.homePhone
                ? this.order.patient.preferences.homePhone
                : "Not set";
        },
        google: function() {
            return gmapApi;
        },
        filteredNotes() {
            return this.order.orderNotes.filter(note => note.type === 'DOCTOR_COMMENT');
        },
        getOrderCreatedDate() {
          const createdDate = new Date(this.order.creationDate);
          return this.formatDate(createdDate);
        },
        getTodayDate() {
          return this.formatDate(new Date());
        },
        isDateValid() {
          return this.deliveryDate !== '';
        },
        isShowCommentBox() {
          return [OrderStatus.AWAITING_PHARMACY_DISPATCH, OrderStatus.REJECTED_BY_PHARMACY_AWAITING_SPECIALIST_ALTERATION]
              .includes(this.todoStatus);        }
    },
    mounted: function() {
        this.getOrderDetails();
        OrderEventBus.$on("getOrderDetails", () => {
            console.log("loading order");
            this.getOrderDetails();
        });
    },
    beforeDestroy() {
        OrderEventBus.$off();
    },
    methods: {
        getOrderDetails() {
            this.$orderService
                .getOrder(this.$route.params.orderId)
                .then((order) => {
                    this.order = order;
                    this.prinkPrescription = order.files.find(
                        (file) => file.action === "PINK_PRESCRIPTION_UPLOAD"
                    );
                    this.deliveryOnly =
                        this.order.deliveryType == "HOME" ? true : false;

                    if (this.order.pharmacy) {
                        this.postcode = this.order.pharmacy.address
                            ? this.order.pharmacy.address.postcode
                            : "";
                        this.pharmacyId = this.order.pharmacy.id;
                    }

                    if ([OrderStatus.AWAITING_PHARMACY_DISPATCH, OrderStatus.AWAITING_PATIENT_PRESCRIPTION_PAYMENT, OrderStatus.AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION, OrderStatus.WAITING_FOR_PHARMACY_APPROVAL].includes(this.order.status)) {
                      this.disableChangeStatusButton = false;

                      switch (this.order.status) {
                        case OrderStatus.REJECTED_BY_PATIENT:
                          this.orderStatusList = [OrderStatus.WAITING_FOR_PHARMACY_APPROVAL]
                          break;
                        case OrderStatus.AWAITING_PHARMACY_DISPATCH:
                          this.orderStatusList = [OrderStatus.WAITING_FOR_PHARMACY_APPROVAL]
                          break;
                        case OrderStatus.AWAITING_PATIENT_PRESCRIPTION_PAYMENT:
                          this.orderStatusList = [OrderStatus.WAITING_FOR_PHARMACY_APPROVAL, OrderStatus.AWAITING_PHARMACY_DISPATCH, OrderStatus.DELIVERED, OrderStatus.REJECTED_BY_PHARMACY_AWAITING_SPECIALIST_ALTERATION]
                          break;
                        case OrderStatus.AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION:
                          this.orderStatusList = [OrderStatus.WAITING_FOR_PHARMACY_APPROVAL, OrderStatus.AWAITING_PHARMACY_DISPATCH, OrderStatus.REJECTED_BY_PHARMACY_AWAITING_SPECIALIST_ALTERATION]
                          break;
                        case OrderStatus.AWAITING_PHARMACY_DELIVER:
                          this.orderStatusList = [OrderStatus.WAITING_FOR_PHARMACY_APPROVAL]
                          break;
                        case OrderStatus.DELIVERED:
                          this.orderStatusList = [OrderStatus.WAITING_FOR_PHARMACY_APPROVAL]
                          break;
                        case OrderStatus.WAITING_FOR_PHARMACY_APPROVAL:
                          this.orderStatusList = [OrderStatus.REJECTED_BY_PHARMACY_AWAITING_SPECIALIST_ALTERATION]
                          break;
                        default:
                          this.$awn.alert("Add wrong operation");
                      }
                    }

                    if ([OrderStatus.DELIVERED].includes(this.order.status)) {
                      this.disableChangeDeliveryDateButton = false;
                    }

                    this.deriveConsultationFiles();
                    this.deriveConsultationNotes();

                    this.$orderService
                        .getPrescription(this.$route.params.orderId)
                        .then((prescription) => {
                            this.prescriptionStatusChange = prescription;
                            if (prescription.medication) {
                                prescription.medication.forEach((m) => {
                                    for (var property in m.formulary) {
                                        if (
                                            Object.prototype.hasOwnProperty.call(
                                                m.formulary,
                                                property
                                            )
                                        ) {
                                            m[property] = m.formulary[property];
                                        }
                                    }
                                });
                            }
                            this.prescription = prescription;
                        }).catch(() => this.prescriptionStatusChange = null);
                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
                        console.log("a", error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.displayNotification("error", error.message);
                    }
                });
        },
        deriveConsultationNotes() {
            this.consultationNotes = this.order.orderNotes?.find(
                (note) => note.type === "PRESENTING_COMPLAINT"
            )?.description;
        },
        deriveConsultationFiles() {
            const { pictures } = this.order;
            if (!pictures.length) {
                return;
            }
            let docs = [];
            let images = [];

            pictures.forEach((item) => {
                if (
                    (item?.contentType || item?.base64value)?.includes("image")
                ) {
                    images.push(item);
                }

                if (
                    (item?.contentType || item?.base64value)?.includes(
                        "application"
                    )
                ) {
                    docs.push(item);
                }
            });

            if (docs.length) {
                this.consultationFiles.docs = docs;
            }

            if (images.length) {
                this.consultationFiles.images = images;
            }
        },
        handleOpenPDF(pdfString) {
            let pdfWindow = window.open("");
            pdfWindow.document.write(
                `<iframe width='100%' height='100%' src='${encodeURI(
                    pdfString
                )}'></iframe>`
            );
        },
        displayNotification(type, message) {
            if (type === "error") {
                this.$awn.alert(message, "Error");
                this.$router.push("/login");
            }
        },
        dateTime(date) {
            return moment(date)
                .local()
                .format("Do MMMM YYYY, HH:mm");
        },
        findPharmacies() {
            this.showMap = false;
            this.findPharmacyButtonClicked = true;
            this.getLatLan(`${this.postcode},UK`).then((location) => {
                this.postCodeLocation = location;
                if (location) {
                    publicService
                        .getNearestPharmacies(
                            location.lat(),
                            location.lng(),
                            5,
                            this.deliveryOnly,
                            this.memberType
                        )
                        .then((pharmacies) => {
                            this.pharmacies = pharmacies;
                            if (this.pharmacies.length > 2) {
                                const maxLat = this.pharmacies
                                    .map((p) => p.latitude)
                                    .reduce((l1, l2) => Math.max(l1, l2));
                                const maxLng = this.pharmacies
                                    .map((p) => p.longitude)
                                    .reduce((l1, l2) => Math.max(l1, l2));
                                const minLat = this.pharmacies
                                    .map((p) => p.latitude)
                                    .reduce((l1, l2) => Math.min(l1, l2));
                                const minLng = this.pharmacies
                                    .map((p) => p.longitude)
                                    .reduce((l1, l2) => Math.min(l1, l2));

                                this.bounds = {
                                    south: minLat,
                                    west: minLng,
                                    north: maxLat,
                                    east: maxLng,
                                };
                            } else {
                                // if (this.pharmacies.length === 0) {
                                //     this.showNoPharmaciesModal = true;
                                // }

                                const r = 0.5;
                                this.bounds = {
                                    south: location.lat() - r,
                                    west: location.lng() - r,
                                    north: location.lat() + r,
                                    east: location.lng() + r,
                                };
                            }
                            this.showMap = true;
                            this.$nextTick(() => {
                                if (this.$refs.map) {
                                    this.$refs.map.$mapPromise.then((map) => {
                                        map.fitBounds(this.bounds);
                                    });
                                }
                            });
                        });
                } else {
                    this.pharmacies = [];
                }
            });
        },
        async getLatLan(address) {
            await this.$gmapApiPromiseLazy();
            const google = this.google();
            return await new Promise((resolve, reject) => {
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode({ address: address }, function(
                    results,
                    status
                ) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        resolve(results[0].geometry.location);
                    } else {
                        reject(status);
                    }
                });
            });
        },
        selectPharmacy(p) {
            this.pharmacyId = p.id;
        },
        reset() {
            this.getOrderDetails();
        },
        confirm() {
            this.confirmModal = false;
            let deliveryType = this.deliveryOnly ? "HOME" : "PHARMACY";
            this.$orderService
                .setDeliveryDetails(
                    this.order.id,
                    this.pharmacyId,
                    deliveryType
                )
                .then(() => {
                    this.$router.push("/orders/waiting-for-admin");
                    this.$awn.success(
                        "Delivery details confirmed successfully"
                    );
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        this.$awn.alert(error.response.data.message);
                    } else {
                        this.$awn.alert("There was a network error");
                    }
                });
        },
        addDeliveryNotes() {
            this.$orderService
                .addDeliveryNotes(
                    this.order.id,
                    this.order.workflow.PHARMACY_DELIVER_NOTES
                )
                .then(() => {
                    this.$awn.success(
                        "Delivery notes added successfully to order " +
                            this.order.id
                    );
                    this.$router.push("/orders/all");
                })
                .catch((error) => {
                    this.$awn.alert(error, "Oops");
                });
        },
        handleAppointmentChange() {
            this.getOrderDetails();
        },
        formatDate(date) {
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const day = date.getDate().toString().padStart(2, '0');
          return `${year}-${month}-${day}`;
        },
        getCurrentTime() {
          const now = new Date();
          const hh = String(now.getHours()).padStart(2, '0');
          const mm = String(now.getMinutes()).padStart(2, '0');
          const ss = String(now.getSeconds()).padStart(2, '0');
          return `${hh}:${mm}:${ss}`;
        },
        confirmDeliveryDate() {
          const currentTime = this.getCurrentTime();
          const formattedDeliveryDateTime = `${this.deliveryDate}T${currentTime}Z`;

          this.$orderService
              .changeDeliveryDate(
                  this.order.id,
                  formattedDeliveryDateTime
              )
              .then(() => {
                this.$awn.success(
                    "Delivery date change successfully " +
                    this.order.id
                );
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              })
              .catch((error) => {
                this.$awn.alert(error, "Oops");
              });
        },
        proceedOrderChange(bvModalEvt) {
          if (this.isShowCommentBox && this.changeReason === '') {
            this.$awn.alert("Please fill the required fields");
            bvModalEvt.preventDefault();
          } else {
            this.changeOrderStatusModal = false;
            this.confirmOrderChangeModal = true;
          }
        },
        confirmOrderChange() {

          let data = {
            orderItemId: this.order.id,
            currentStatus: this.order.status,
            toBeStatus: this.todoStatus,
            comment: this.changeReason
          }

          switch (this.order.status) {
            case OrderStatus.REJECTED_BY_PATIENT:
              if (this.todoStatus === OrderStatus.WAITING_FOR_PHARMACY_APPROVAL) {
                data.type = "PROCED1"
              }
              break;
            case OrderStatus.AWAITING_PHARMACY_DISPATCH:
              if (this.todoStatus === OrderStatus.WAITING_FOR_PHARMACY_APPROVAL) {
                data.type = "PROCED2"
              }
              break;
            case OrderStatus.AWAITING_PATIENT_PRESCRIPTION_PAYMENT:
              if (this.todoStatus === OrderStatus.WAITING_FOR_PHARMACY_APPROVAL) {
                data.type = "PROCED4"
              }
              // else if (this.todoStatus === OrderStatus.AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION) {
              //   data.type = "PROCED6"
              // }
              else if (this.todoStatus === OrderStatus.AWAITING_PHARMACY_DISPATCH) {
                data.type = "PROCED8"
              } else if (this.todoStatus === OrderStatus.DELIVERED) {
                data.type = "PROCED10"
              } else if (this.todoStatus === OrderStatus.REJECTED_BY_PHARMACY_AWAITING_SPECIALIST_ALTERATION) {
                data.type = "PROCED11"
                data.extraInfo = {
                  pharmacyRejectReason: "OTHER",
                  pharmacyRejectReasonDetails: this.changeReason,
                  appointmentPrescriptionJsonString: JSON.stringify(this.prescriptionStatusChange),
                  pin: null,
                  name: null,
                  action: null
                }
              }
              break;
            case OrderStatus.AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION:
              if (this.todoStatus === OrderStatus.WAITING_FOR_PHARMACY_APPROVAL) {
                data.type = "PROCED5"
              } else if (this.todoStatus === OrderStatus.AWAITING_PHARMACY_DISPATCH) {
                data.type = "PROCED7"
              } else if (this.todoStatus === OrderStatus.REJECTED_BY_PHARMACY_AWAITING_SPECIALIST_ALTERATION) {
                data.type = "PROCED12"
                data.extraInfo = {
                  pharmacyRejectReason: "OTHER",
                  pharmacyRejectReasonDetails: this.changeReason,
                  appointmentPrescriptionJsonString: JSON.stringify(this.prescriptionStatusChange),
                  pin: null,
                  name: null,
                  action: null
                }
              }
              break;
            // case OrderStatus.AWAITING_PHARMACY_DELIVER:
            //   if (this.todoStatus === OrderStatus.WAITING_FOR_PHARMACY_APPROVAL) {
            //     data.type = "PROCED9"
            //   }
            //   break;
            case OrderStatus.WAITING_FOR_PHARMACY_APPROVAL:
              if (this.todoStatus === OrderStatus.REJECTED_BY_PHARMACY_AWAITING_SPECIALIST_ALTERATION) {
                data.type = "PROCED13"
                data.extraInfo = {
                  pharmacyRejectReason: "OTHER",
                  pharmacyRejectReasonDetails: this.changeReason,
                  appointmentPrescriptionJsonString: JSON.stringify(this.prescriptionStatusChange),
                  pin: null,
                  name: null,
                  action: null
                }
              }
              break;
            default:
              this.$awn.alert("Add wrong operation");
          }

          // if (this.todoStatus === OrderStatus.DELIVERED) {
          //   console.log('date', this.deliveryDate)
          // }

          this.$orderService
              .changeOrderStatus(data)
              .then(() => {
                this.$awn.success(
                    "Delivery date change successfully " +
                    this.order.id
                );
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              })
              .catch((error) => {
                this.$awn.alert(error, "Oops");
              });
        }
    },
    watch: {
      isShowCommentBox(val) {
        if (val && this.changeReason === '') {
          this.showError = true;
        } else {
          this.showError = false;
        }
      },
      changeReason(val) {
        if (val !== '') {
          this.showError = false;
        } else {
          this.showError = true;
        }
      }
    }
};
</script>

<style lang="scss">
#pharmacyIdDescription small {
    margin-top: -1.8%;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}

.pink-prescription {
    width: 75%;

    @media (max-width: 1199px) {
        width: 100%;
    }
}

.doc-button {
    padding: 0.5rem;
    margin-bottom: 1rem;
    row-gap: 0.5rem;

    img {
        width: 8rem;
        height: auto;
    }
}

.consultation-image {
    width: 100%;

    img {
        width: 100%;
        height: auto;
    }
}
</style>
