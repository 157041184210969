<template>
    <b-card>
        <div slot="header">
            <i class="fa fa-align-justify"></i> Upload Formulary
        </div>

        <b-row class="form-group">
            <b-col>
                <b-form-file
                    type="file"
                    v-model="file"
                    name="file"
                    accept=".csv, .xlsx"
                />
            </b-col>
            <b-col>
                <select class="form-control" v-model="selectedPharmacy">
                    <option value="" selected hidden>Select a pharmacy</option>
                    <option
                        v-for="(pharmacy, index) of pharmacies"
                        :value="pharmacy.id"
                        :key="index"
                    >
                        {{ pharmacy.name }}
                    </option>
                </select>
            </b-col>
        </b-row>
        <b-row class="mt-3 mx-0">
            <div class="ml-auto">
                <b-btn
                    variant="primary"
                    class="w-100"
                    @click="submitCSV"
                    :disabled="file.length === 0 || selectedPharmacy === undefined"
                >
                    Upload CSV
                </b-btn>
            </div>
        </b-row>
    </b-card>
</template>

<script>
export default {
    data: function() {
        return {
            file: [],
            selectedPharmacy: undefined,
            pharmacies: [],
        };
    },
    created() {
        this.getPharmacies();
    },
    watch: {},
    methods: {
        submitCSV() {
            const pharmacyIdString = this.selectedPharmacy.toString()
            this.$formularyService
                .uploadCSV(this.file, pharmacyIdString)
                .then(async (res) => {
                    console.log(res);
                    this.$awn.success("CSV uploaded");
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 412:
                            this.$awn.alert("Cannot update formulary of another pharmacy");
                            break;
                        case 400:
                            this.$awn.alert("File you uploaded has missing fields or is corrupted");
                            break;
                    
                        default:
                            this.$awn.alert(error.message);
                            break;
                    }
                })
                .finally(() => {
                    this.file = [];
                    this.selectedPharmacy = "";
                });
        },
        getPharmacies() {
            this.$pharmacyService
                .getAll()
                .then((pharmacies) => {
                    this.pharmacies = pharmacies.content;
                })
                .catch((error) => {
                    this.$awn.alert(error, "An error occurred");
                });
        },
    },
};
</script>
