
<script>
    const SubscriptionService = {}

    SubscriptionService.install = function (Vue) {

        var subscriptionService = new Object ();

        Object.defineProperty(Vue.prototype, '$subscriptionService', { value: subscriptionService });

        subscriptionService.search = function (searchQuery, page, size) {
            var url;
            url = `/api/admin/subscriptions?start=1970-01-01&end=9999-01-01&query=${searchQuery}&page=${page}&size=${size}&sort=creationDate,desc`;
        
            return Vue.axios.get(url)
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error)
                    return Promise.reject(error);
            });
        };


        subscriptionService.handleError = function(error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
                // Vue.$router.push('/login')
                console.log('error.request ', error.request)
            } else {
                // Something happened in setting up the request that triggered an Error
                // Vue.$router.push('/login')
                console.log('error', error.request)
            }
        };
    }

    export default SubscriptionService;
</script>