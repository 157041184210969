
<script>
  const AdminService = {};

  AdminService.install = function (Vue) {
    
    var AdminService = new Object ();

    Object.defineProperty(Vue.prototype, '$AdminService', { value: AdminService });

    AdminService.search = function (searchQuery, page = 0, size = 10) {
      var url;
      if (searchQuery) {
        url = `/api/admin/admins/?q=${searchQuery}&page=${page}&size=${size}`;
      } else {
        url = `/api/admin/admins/?page=${page}&size=${size}`;
      }
      return Vue.axios.get(url)
        .then(response => { return response.data; })
        .catch(error => {
          this.handleError(error)
          return Promise.reject(error);
        });
    };

    AdminService.handleError = function(error) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      } else if (error.request) {
        // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
        // Vue.$router.push('/login')
        console.log('error.request ', error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        // Vue.$router.push('/login')
        console.log('error', error.request)
      }
    };

    AdminService.resetLoginAttempts = function (id) {
      let url = `/api/admin/reset-admin-login`;

      let admin = {
        "id": id
      }

      return Vue.axios.post(url, admin)
        .then(response => { return response.data; })
        .catch(error => {
          this.handleError(error)
          return Promise.reject(error);
      });
    };
  };



  export default AdminService;
</script>
