<template>
  <b-card>

    <div slot="header">
      <i class='fa fa-align-justify'></i> Change Password
    </div>

    <b-row v-if="userType && userId">
      <b-col cols="8">
        <div v-if="user">
          <b-form-group id="userNameGroup" label="Name" label-for="userName" :horizontal="true" label-cols="3">
            <b-form-input name="userName" type="text" v-model="user.displayName" disabled/>
          </b-form-group>

          <b-row v-if="user.gender && user.dateOfBirth" class="form-group">
            <b-col>
              <label for="userGender">Gender </label>
            </b-col>
            <b-col>
              <b-form-input id="userGender" type="text" v-model="user.gender" disabled></b-form-input>
            </b-col>
            <b-col>
              <label for="userDOB">Date of Birth </label>
            </b-col>
            <b-col>
              <b-form-input id="userDOB" type="text" v-model="dateOfBirth" disabled></b-form-input>
            </b-col>
          </b-row>
          <b-form-group class="mt-3" id="userCreationDateGroup" label="Creation Date" label-for="userCreationDate" :horizontal="true" label-cols="3">
            <b-form-input name="userEmail" type="text" v-model="creationDate" disabled/>
          </b-form-group>
          <b-form-group class="mt-3" id="userEmailGroup" label="Email" label-for="userEmail" :horizontal="true" label-cols="3">
            <b-form-input name="userEmail" type="text" v-model="user.email" disabled/>
          </b-form-group>
          <b-row class="form-group">
            <b-col cols="3">
              <label for="userPhone">Phone </label>
            </b-col>
            <b-col>
              <b-form-input id="userPhone" type="text" v-model="user.phone" disabled></b-form-input>
            </b-col>
            <div v-if="user.homePhone">
              <b-col>
                <label for="userHomePhone">Home Phone </label>
              </b-col>
              <b-col v-if="user.homePhone">
                <b-form-input id="userHomePhone" type="text" v-model="user.homePhone" disabled></b-form-input>
              </b-col>
            </div>
          </b-row>

          <b-row class="form-group" v-if="user && user.address">
            <b-col cols="3">
              <label for="userAddressLine1">Line1</label>
            </b-col>
            <b-col>
              <b-form-input name="userAddressLine1" type="text" v-model="user.address.line1" disabled/>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col cols="3">
              <label for="userAddressLine2">Line2</label>
            </b-col>
            <b-col>
              <b-form-input name="userAddressLine2" type="text" v-model="user.address.line2" disabled/>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col cols="3">
              <label for="userAddressCounty">County</label>
            </b-col>
            <b-col>
              <b-form-input name="userAddressCounty" type="text" v-model="user.address.county" disabled/>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col cols="3">
              <label for="userAddressPostcode">Postcode</label>
            </b-col>
            <b-col cols="3">
              <b-form-input name="userAddressPostcode" type="text" v-model="user.address.postcode" disabled/>
            </b-col>
            <b-col cols="1">
              <label for="userAddressCity">City</label>
            </b-col>
            <b-col cols="5">
              <b-form-input name="userAddressCity" type="text" v-model="user.address.city" disabled/>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col>
        <br/><br/><br/><br/>
        <b-form-group label="New password" description="">
          <input type="text" class="form-control" id="password" v-model="password" placeholder="password">
        </b-form-group>
        <label for="password" style="color: white">Update</label><br/>
        <b-button block variant="primary" class="btn--2" :disabled="!password" v-on:click="update()"><i class="fa fa-edit"></i> Update</b-button>
      </b-col>
    </b-row>
    <b-row v-if="!userType && !userId" class="mt-3 mb-3">
      <b-col md="6">
        <b-form-group>
          <div slot="label">
            <b>Enter your <u>old</u> password</b>
          </div> 
          <input v-bind:type="showPassword1 ? 'text' : 'password'" class="form-control" id="oldPassword" v-model="oldPassword" placeholder="Please type your old password">
          <b-form-checkbox id="showPassword1" v-model="showPassword1">
            show password
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <div slot="label">
            <b>Enter your <u>new</u> password</b>
          </div>
          <input v-bind:type="showPassword2 ? 'text' : 'password'" class="form-control" id="password" v-model="password" placeholder="Please type your new password">
          <b-form-checkbox id="showPassword2" v-model="showPassword2">
            show password
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-button block variant="primary" class="btn--2 mt-3" :disabled="!oldPassword || !password" v-on:click="update()"><i class="fa fa-edit"></i> Update</b-button>
    </b-row>
  </b-card>
</template>

<script>

import moment from 'moment'

export default {
  data: function () {
    return {
      oldPassword: '',
      password: '',
      user: '',
      showPassword1: false,
      showPassword2: false
    }
  },
  props: {
    userType: {
      type: String,
      default: ''
    },
    userId: {
      type: String,
      default: ''
    }
  },
  filters: {
			dateTime: function (date) {
				return moment(date).local().format('HH:mm - DD/MM/YY');
      }
  },
  created() {
    if (this.userType && this.userId) {
      this.getUser();
    }
  },
  computed: {
    creationDate: function () {
      return moment(this.user.creationDate).local().format('Do MMMM YYYY, HH:mm');
    },
    dateOfBirth: function () {
      return moment(this.user.dateOfBirth).format('DD-MM-YYYY');
    }
  },
  methods: {
    getUser() {
      this.$userService.getUser(this.userType, this.userId).then(user => {
          this.user = user;
        }).catch(error => {
          this.$awn.alert(error, 'An error occurred')
        })
    },
    update () {
      if (this.password) {
        if (this.userType && this.userId){
          this.$userService.changePassword(this.userType.toUpperCase(), this.user.id, this.password).then(() =>{
            this.$router.go(-1);
            this.$awn.success('Password changed successfully for ' + this.user.displayName);
          }).catch(error=> {
            this.$awn.alert(error.data.message, 'An error occurred');
          })
        } else {
          this.$userService.changeOwnPassword(this.oldPassword, this.password).then(() =>{
            this.$router.go(-1);
            this.$awn.success('Password changed successfully');
          }).catch(error=> {
            this.$awn.alert(error.data.message, 'An error occurred');
          });
        }
      } else {
        this.$awn.alert('Please enter a valid pin number with exactly 4 digits.', 'An error occurred');
      }
    }
  }
}
</script>
