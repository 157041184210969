<template>
  <footer class="app-footer no-print">
    <span><a href="https://app.treatit.thegpservice.com">Treat it</a> &copy; 2023</span>
  </footer>
</template>
<script>
export default {
  name: 'c-footer'
}
</script>
