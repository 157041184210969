<template>
    <div class="document-viewer">

        <img v-if="file.contentType.includes('image')" :src="file.url" alt="" className="img-fluid" width="20%" @click="openModal('image')" />

        <a
            v-if="
                !file.contentType.includes('image') &&
                    !file.contentType.includes('application/octet-stream')
            "
            href="javascript:void(0)"
            @click="openModal('pdf')"
        >
            Click to view document
        </a>
        <span
            v-if="file && file.contentType.includes('application/octet-stream')"
        >
            No preview available
        </span>
        <br />
        <p class="uploaded-image-name">{{ file.fileName }}</p>
        <a
            v-if="file && file.contentType.includes('application/octet-stream')"
            :href="file.url"
            target="_blank"
            rel="noreferrer"
            className="btn btn-primary"
        >
            <b-button variant="primary">
                Download Image
            </b-button>
        </a>
        <b-button
            v-if="
                file &&
                    !file.contentType.includes('image') &&
                    !file.contentType.includes('application/octet-stream')
            "
            variant="primary"
            @click="downloadFile()"
        >
            Download
            {{ file.contentType.includes("image") ? "Image" : "Document" }}
        </b-button>
        <b-button
            v-if="file && file.status === true"
            @click="archiveImage(file)"
        >
            {{ archiveBtnTxt }} {{ file.contentType.includes("image") ? "Image" : "Document" }}
        </b-button>

        <b-modal v-model="modalShow" :title="file.fileName" hide-footer centered>
            <img v-if="type == 'image'" :src="file.url" alt="" className="img-fluid" width="100%" />
            <embed v-if="type == 'pdf'" :src="file.url + '#toolbar=0'" type="application/pdf" class="pdf-viewer" width="100%" height="100%">
        </b-modal>
        
    </div>
</template>

<script>
export default {
    name: "document-viewer",
    data: function() {
        return {
            displayingFile: "",
            archiveBtnTxt: "Archive",
            modalShow: false,
            type: ''
        };
    },
    props: {
        file: Object,
        archiveMethod: Function
    },
    computed: {},
    methods: {
        downloadFile() {
            const url = this.file.url;
            const fileName = this.file.fileName;

            fetch(url)
                .then((response) => response.blob())
                .then((blob) => {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(() => {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 100);
                });
        },

        //Archive single data from document viewer
        //Ticket: CR-148
        //Author: Sarmilan
        archiveImage(file) {
            this.archiveMethod(file);
        },

        //Display image & pdf on modal
        //Ticket: CR-148
        //Author: Sarmilan
        openModal(doctype) {
            this.modalShow = true;
            this.type = doctype;
        }
    },
};
</script>

<style lang="scss" scoped>

.pdf-viewer {
  width: 100%;
  height: 700px;
}

::v-deep .modal-content {
    width: fit-content !important;
}

::v-deep .modal-body {
    background: #f8f8f8;
    text-align: center;
    width: 100%;
}

::v-deep .modal.show .modal-dialog {
    max-width: 70%;
    justify-content: center;
}

</style>
