<script>
const OrderService = {};

OrderService.install = function(Vue) {
    var orderService = new Object();
    let filterDate = {};

    Object.defineProperty(Vue.prototype, "$orderService", {
        value: orderService,
    });

    orderService.getOrdersAwaitingAdminDeliveryDetails = function(
        page = 0,
        size = 10
    ) {
        var url =
            "/api/admin/order-items?status=WAITING_FOR_ADMIN_DELIVERY_DETAILS&page=" +
            page +
            "&size=" +
            size +
            "&sort=creationDate,DESC";
        return Vue.axios
            .get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    orderService.getPendingAdminOrders = function(page = 0, size = 100) {
        var url =
            "/api/admin/order-items?status=WAITING_FOR_ADMIN_DELIVERY_DETAILS,REJECTED_BY_PHARMACY,PAYMENT_FAILED&page=" +
            page +
            "&size=" +
            size +
            "&sort=creationDate,DESC";
        return Vue.axios
            .get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };
    orderService.getAllowedPharmacyChangeOrders = function(
        page = 0,
        size = 10
    ) {
        var allowedStatus =
            "WAITING_FOR_DOCTOR_PRESCRIPTION_COMPLETION,WAITING_FOR_PHARMACY_APPROVAL";
        var url =
            "/api/admin/order-items?status=" +
            allowedStatus +
            "&page=" +
            page +
            "&size=" +
            size +
            "&sort=creationDate,DESC";
        return Vue.axios
            .get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    orderService.getOrdersByStatus = function(
        page = 0,
        size = 10,
        allowedStatus = ""
    ) {
        var url =
            "/api/admin/order-items?status=" +
            allowedStatus +
            "&page=" +
            page +
            "&size=" +
            size +
            "&sort=creationDate,DESC";
        return Vue.axios
            .get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    orderService.getPaymentFailedOrders = function(page = 0, size = 10) {
        var allowedStatus = "PAYMENT_FAILED";
        var url =
            "/api/admin/order-items?status=" +
            allowedStatus +
            "&page=" +
            page +
            "&size=" +
            size +
            "&sort=creationDate,DESC";
        return Vue.axios
            .get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    orderService.getAllOrders = function(
        page = 0,
        size = 10,
        types = [],
        prescriberId = null
    ) {
        const currentURL = window.location.href;
        const urlParts = currentURL.split('/');
        let lastValue = urlParts[urlParts.length - 1];

        let customUrl = "/api/admin/order-items?page=";
        if(lastValue == "pharmacyorders"){
            customUrl = "api/admin/order-items/channel?channel=pharmacy&page=";
        }

        var url =
            customUrl +
            page +
            "&size=" +
            size +
            "&sort=creationDate,DESC";
        if (prescriberId) {
            url = `${url}&prescriberId=${prescriberId}`;
        }
        if (types.length > 0) {
            url = `${url}&types=${types.join(",")}`;
        }
        return Vue.axios
            .get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    orderService.searchOrder = function(
        orderId,
        types = [],
        prescriberId = null
    ) {
        var url = "/api/admin/order-item/" + orderId;
        var d = "?";
        if (prescriberId) {
            url = `${url}${d}prescriberId=${prescriberId}`;
            d = "&";
        }
        if (types.length > 0) {
            url = `${url}${d}types=${types.join(",")}`;
            d = "&";
        }
        return Vue.axios
            .get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    orderService.getPatientOrders = function(patientId, page = 0, size = 10) {
        var url = `/api/admin/order-items-by-patient?patientId=${patientId}&page=${page}&size=${size}&sort=id,DESC`;
        return Vue.axios
            .get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    orderService.getOrder = function(orderId) {
        var url = "/api/admin/order-item/" + orderId;
        return Vue.axios
            .get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    orderService.getPrescription = function(orderId) {
        var url = "/api/admin/prescription?order-item-id=" + orderId;
        return Vue.axios
            .get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    orderService.setDeliveryDetails = function(
        orderId,
        pharmacyId,
        deliveryType
    ) {
        var url = "/api/admin/order/set-delivery-details";
        var body = {
            orderItemId: orderId,
            deliveryType: deliveryType,
        };
        if (pharmacyId) {
            body.pharmacyId = pharmacyId;
        }

        return Vue.axios
            .put(url, body)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    orderService.addDeliveryNotes = function(orderId, deliveryNotes) {
        var url = "/api/admin/order/set-delivery-notes";
        return Vue.axios
            .put(url, {
                orderItemId: orderId,
                notes: deliveryNotes ? deliveryNotes : "",
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

  orderService.changeDeliveryDate = function(orderId, deliveryDate) {
    var url = `/api/admin/order-items/${orderId}?updateDate=${deliveryDate}`;
    return Vue.axios
        .put(url, {})
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.handleError(error);
          return Promise.reject(error);
        });
  };

  orderService.changeOrderStatus = function(date) {
    var url = `/api/admin/order-items/status-change`;
    return Vue.axios
        .post(url, date)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.handleError(error);
          return Promise.reject(error);
        });
  };

    orderService.confirmOrderRejection = function(orderId) {
        var url = "/api/admin/order/" + orderId + "/confirm-rejection";

        return Vue.axios
            .put(url)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    orderService.getInvoiceItems = function(orderId) {
        var url = `api/admin/document/${orderId}?type=invoice`;
        return Vue.axios
            .get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    orderService.processManualPayment = function(orderId, phase) {
        var url =
            "/api/admin/order/" + orderId + "/manual-payment?phase=" + phase;
        return Vue.axios
            .post(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    orderService.changeAppointment = function(orderId, newAppointmentId) {
        const url = `/api/admin/order-item/${orderId}?action=change-appointment`;
        return Vue.axios
            .put(url, { appointmentId: newAppointmentId })
            .then((response) => ({
                success: true,
                data: response.data,
            }))
            .catch((error) => this.handleError(error));
    };

    orderService.cancelOrder = function(
        appointmentId,
        cancelPaid,
        cancelReason
    ) {
        var url = "/api/admin/appointment/" + appointmentId + "?action=cancel";
        return Vue.axios
            .put(url, { additionalNotes: cancelReason, charge: cancelPaid })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    orderService.cancelFormOrder = function(orderId, cancelReason) {
        var url = "/api/admin/form/" + orderId + "?action=cancel";
        return Vue.axios
            .put(url, { additionalNotes: cancelReason })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    orderService.handleError = function(error) {
        if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            console.debug(error.response.data);
            console.debug(error.response.status);
            console.debug(error.response.headers);
            return {
                success: false,
                status: error.response.status,
                message: error.response.message,
            };
        } else if (error.request) {
            // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
            // Vue.$router.push('/login')
            console.debug("error.request ", error.request);
            return {
                success: false,
                message: "Did not receive a response from the server.",
            };
        } else {
            // Something happened in setting up the request that triggered an Error
            // Vue.$router.push('/login')
            console.debug("error", error.request);
            return {
                success: false,
                message: "Failed to make the request.",
            };
        }
    };

    orderService.getOrderNotes = function(orderNotes) {
        let notes = {};
        for (let index = 0; index < orderNotes.length; index++) {
            const note = orderNotes[index];

            if (note.type === "GP_CONSULTATION_NOTES") {
                notes.gpNote = {
                    id: note.id,
                    note: JSON.parse(note.questionAnswer),
                };
                continue;
            }

            if (note.type === "PEG_FORM") {
                notes.pegNote = {
                    id: note.id,
                    note: JSON.parse(note.questionAnswer),
                };
                continue;
            }

            if (note.type === "GAD_FORM") {
                notes.gadNote = {
                    id: note.id,
                    note: JSON.parse(note.questionAnswer),
                };
                continue;
            }

            if (note.type === "PATIENT_DETAIL_CONFIRMATION") {
                notes.consentNote = {
                    id: note.id,
                    note: JSON.parse(note.questionAnswer),
                };
                continue;
            }

            if (note.type === "AUDIT_FORM") {
                notes.auditNote = {
                    id: note.id,
                    note: JSON.parse(note.questionAnswer),
                };
                continue;
            }

            if (note.type === "GP_RECOMMENDATION") {
                notes.gpRecommendation = {
                    id: note.id,
                    formulary: JSON.parse(note.formulary),
                };
                continue;
            }

            if (note.type === "GP_APPROVAL_DECISION") {
                notes.gpDecision = {
                    approved: note.approved,
                    description: note.description,
                };
                continue;
            }

            if (note.type === "MDT_PROFORMA") {
                notes.mdtProforma = JSON.parse(note.questionAnswer);
                continue;
            }

            if (note.type === "MDT_RECOMMENDATION") {
                notes.mdtRecommendation = {
                    formulary: JSON.parse(note.formulary),
                };
                continue;
            }

            if (note.type === "MDT_DECISION") {
                notes.mdtDecision = note;
                continue;
            }

            if (note.type === "SPECIALIST_DECISION") {
                notes.specialistDecision = note;
                continue;
            }

            if (note.type === "SPECIALIST_CALL_PATIENT") {
                notes.specialistCallPatient = note;
                continue;
            }

            if (note.type === "PHARMACY_DECISION") {
                notes.pharmacyDecision = note;
                continue;
            }

            if (note.type === "PATIENT_PHARMACY_DECISION") {
                notes.patientPharmacyDecision = note;
                continue;
            }

            //Repeat order
            if (
                note.type === "REPEAT_ORDER_PATIENT_PRESCRIPTION_CHANGE_REASON"
            ) {
                notes.prescriptionChangeReason = {
                    description: note.description,
                    descriptionKey: note.descriptionKey,
                };
                continue;
            }

            if (note.type === "REPEAT_ORDER_RECOMMENDATION") {
                notes.repeatOrderRecommendation = JSON.parse(note.formulary);
            }
        }

        const note = orderNotes.filter(
            (note) => note.type === "SPECIALIST_PATIENT_MESSAGE"
        );
        if (note.length > 0) {
            const sortedNotes = note.sort((message1, message2) =>
                new Date(message1.creationDate) >
                new Date(message2.creationDate)
                    ? 1
                    : new Date(message1.creationDate) <
                      new Date(message2.creationDate)
                    ? -1
                    : 0
            );
            notes.specialistPatientConvo = sortedNotes;
        }

        const pharmacyRejectionNote = orderNotes.find(
            (note) => note.type === "PHARMACY_ORDER_REJECTION_NOTE"
        );
        const trackingNote = orderNotes.find(
            (note) => note.type === "REJECTED_TRACKING_CODE"
        );
        if (trackingNote || pharmacyRejectionNote) {
            notes.pharmacyRejecReason = {};
        }
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!trackingNote) {
            notes.pharmacyRejecReason['trackingCode'] = trackingNote.description;
        }

        // eslint-disable-next-line no-extra-boolean-cast
        if (!!pharmacyRejectionNote) {
            // eslint-disable-next-line no-extra-boolean-cast
            notes.pharmacyRejecReason.description = `${
                pharmacyRejectionNote.descriptionKey
            } ${
                // eslint-disable-next-line no-extra-boolean-cast
                !!pharmacyRejectionNote.description
                    ? `- ${pharmacyRejectionNote.description}`
                    : ""
            }`;
            notes.pharmacyRejecReason.medication = JSON.parse(
                pharmacyRejectionNote.formulary
            ).medication;
            notes.pharmacyRejecReason.pharmacy = pharmacyRejectionNote.pharmacy;
        }

        const repeatQuestionnaire = orderNotes.find(
            (note) => note.type === "REPEAT_ORDER_QUESTIONNAIRE"
        );
        if (repeatQuestionnaire) {
            const questionnaire = JSON.parse(
                repeatQuestionnaire.questionAnswer
            );

            if (typeof questionnaire.q_1 === "boolean") {
                notes.repeatQuestionnaire = {
                    version: "old",
                    formValues: questionnaire,
                };
            } else {
                notes.repeatQuestionnaire = {
                    version: "new",
                    formValues: questionnaire,
                };
            }
        }

        return notes;
    };

    orderService.getPinkPrescription = function(files) {
        const pinkPrescriptions = files.filter(
            (file) => file.action === "PINK_PRESCRIPTION_UPLOAD"
        );
        if (pinkPrescriptions.length === 0) {
            return undefined;
        }
        const images = [];
        const documents = [];

        pinkPrescriptions.forEach((item) => {
            if (item.contentType === "application/pdf") {
                documents.push(item);
            } else {
                images.push(item);
            }
        });
        return {
            images,
            documents,
        };
    };

    orderService.getPatientDocs = function(files) {
        const notes = files.filter(
            (file) => file.action === "PATIENT_SCR_DOCUMENTS"
        );
        if (notes.length === 0) {
            return undefined;
        }
        const images = [];
        const documents = [];

        notes.forEach((item) => {
            if (item.contentType === "application/pdf") {
                documents.push(item);
            } else {
                images.push(item);
            }
        });
        return {
            images,
            documents,
        };
    };

    orderService.allOrdersFilters = function (
        page = 0,
        size = 10,
        data = {}
    ) {
        let filter_data = {
            "orderId": data.orderId ? data.orderId : "",
            "pharmacyId": data.pharmacyId ? data.pharmacyId : "",
            "startDate": data.startDate ? data.startDate : "",
            "endDate": data.endDate ? data.endDate : "",
            "channel": data.channel ? data.channel : ""
        }
        var url =
            "/api/admin/order-items/filter?page=" + page + "&size=" + size + "&sort=creationDate,DESC&types=FORM,BOOKING,TRAVEL,PRESCRIPTION_ONLY";
        return Vue.axios
            .post(url, filter_data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    orderService.getDaterange = function () {
        return filterDate;
    };

    orderService.setDaterange = function (date) {
        filterDate = date;
    }

    orderService.getAllTempBookings = function() {
      let url = "/api/admin/temp-bookings";

      return Vue.axios
          .get(url)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            this.handleError(error);
            return Promise.reject(error);
          });
    };
};

export default OrderService;
</script>
