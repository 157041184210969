
<script>

const PackageService = {}

PackageService.install = function (Vue) {

  var packageService = {}

  Object.defineProperty(Vue.prototype, '$packageService', {
    value: packageService
  })

  packageService.search = function (searchQuery, page = 0, size = 10) {
    var url
    url = '/api/admin/packages?sort=code' + searchQuery + '&page=' + page + '&size=' + size;

    return Vue.axios
      .get(url)
      .then(response => {
        return response.data
      })
      .catch(error => {
        this.handleError(error)
        return Promise.reject(error)
      })
  }

  packageService.addPackage = async function (packages) {
    var responses = []
    for (var index = 0; index < packages.length; index++) {
      var pkg = { ...packages[index] }
      pkg.active = true
      var url = '/api/admin/packages'
      // delete pkg.id;
      // delete pkg.total;
      var response = await Vue.axios.post(url, pkg)
      responses.push(response)
    }
    return responses
  }

  packageService.updatePackage = async function (packages) {
    var responses = []
    for (var index = 0; index < packages.length; index++) {
      var pkg = { ...packages[index] }
      var url = '/api/admin/packages/' + pkg.id + '?action=update'
      pkg.charge = Number(pkg.charge)
      pkg.vat = Number(pkg.vat)
      pkg.usages = Number(pkg.usages)
      pkg.subscriptionLimit = Number(pkg.subscriptionLimit)
      pkg.months = Number(pkg.months)
      pkg.billingAmount = Number(pkg.billingAmount)
      delete pkg.id
      delete pkg.total
      var response = await Vue.axios.put(url, pkg)
      responses.push(response)
    }
    return responses
  }

  packageService.disablePackage = function (id) {
    var url = '/api/admin/packages/' + id + '?action=disable'
    return Vue.axios
      .put(url)
      .then(response => {
        console.log(url, response)
        return response.data
      })
      .catch(error => {
        this.handleError(error)
        return Promise.reject(error)
      })
  }

  packageService.enablePackage = function (id) {
    var url = '/api/admin/packages/' + id + '?action=enable'
    return Vue.axios
      .put(url)
      .then(response => {
        console.log(url, response)
        return response.data
      })
      .catch(error => {
        this.handleError(error)
        return Promise.reject(error)
      })
  }

  packageService.handleError = function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    } else if (error.request) {
      // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      // Vue.$router.push('/login')
      console.log('error.request ', error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      // Vue.$router.push('/login')
      console.log('error', error.request)
    }
  }
}

export default PackageService
</script>