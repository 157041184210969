<template>
    <div id="print-content">
        <div class="print-only">
            <div>
                <b-row>
                    <b-col class="mt-5">
                        <img src="/static/img/logo.png" style="width: 25em;" />
                    </b-col>
                    <b-col class="text-center">
                        <h3>Private and Confidential</h3>
                    </b-col>
                    <b-col class="text-right smaller-paragraphs mt-5">
                        <p>Coventry University Technology Park,</p>
                        <p>The TechnoCentre,</p>
                        <p>Puma Way,</p>
                        <p>CV1 2TT Coventry</p>
                        <p>Prescriber Number: 09359853</p>
                        <p>Tel: 0247 509 8777</p>
                        <p>Email: Medical@theGPservice.co.uk</p>
                    </b-col>
                </b-row>
                <div class="clear"></div>
                <div>
                    <br />
                    <h3>Dear Dr.</h3>
                    <p>
                        A patient that is registered with your practice has
                        recently had a private consultation with one of our GPs.
                        The patient requested that a summary of this
                        consultation, whose details are below, be shared with
                        you in order to update their medical records. Should you
                        have any questions, please contact a member of our team
                        on 0203 105 0352.
                    </p>
                </div>
            </div>
        </div>
        <div class="no-print" style="padding-bottom: 3rem;">
            <b-button class="float-right" id="print-btn" size="sm" variant="outline-primary" @click="print()">Print</b-button>
        </div>
        <h4 class="print-only">{{printTitle}}</h4>
        <table class="pt-4">
            <tbody>
                <tr>
                    <th>
                        Type
                    </th>
                    <td>Cannabis</td>
                </tr>
                <tr>
                    <th colspan="2">
                        Basic details
                    </th>
                </tr>
                <tr>
                    <td>Category</td>
                    <td>{{ recommendation.category }}</td>
                </tr>
                <tr>
                    <td>Route of admin</td>
                    <td>{{ recommendation.administrationRoute }}</td>
                </tr>
                <tr>
                    <th colspan="2">
                        Patient preferences
                    </th>
                </tr>
                <tr>
                    <td>Brand</td>
                    <td>{{ recommendation.brandName }}</td>
                </tr>
                <tr>
                    <td>Strain</td>
                    <td>{{ recommendation.strain }}</td>
                </tr>
                <tr>
                    <td>Manufacturer</td>
                    <td>{{ recommendation.manufacture }}</td>
                </tr>
                <tr v-if="!!recommendation.productName">
                    <th>
                        Medication
                    </th>
                    <th>
                        {{ recommendation.productName }}
                    </th>
                </tr>
                <tr>
                    <th colspan="2">
                        Additional comments
                    </th>
                </tr>
                <tr>
                    <td colspan="2">
                        {{ recommendation.comments }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        recommendation: {
            type: Object,
            required: true,
        },
        downloadable: {
            type: Boolean,
        },
        printTitle: {
            type: String,
        },
    },
    methods: {
        print() {
            const printContents = document.getElementById('print-content').innerHTML;

            let stylesHtml = "";
            for (const node of [
                ...document.querySelectorAll('link[rel="stylesheet"], style'),
            ]) {
                stylesHtml += node.outerHTML;
            }
            if (stylesHtml) {
                const WindowPrint = window.open(
                    "",
                    "",
                );

                WindowPrint.document.write(`<!DOCTYPE html>
                <html>
                    <head>
                        ${stylesHtml}
                    </head>
                    <body>
                        ${printContents}
                    </body>
                </html>`);

                const printBtn = WindowPrint.document.getElementById('print-btn');
                printBtn.style.display = 'none';

                WindowPrint.document.close();
                
                const checkCSSApplication = () => {
                    const styleSheets = WindowPrint.document.styleSheets;
                    const isCSSApplied = [...styleSheets].every(sheet => sheet.cssRules.length > 0);

                    if (isCSSApplied) {
                        WindowPrint.onload = null;

                        const beforeUnloadHandler = () => {
                            WindowPrint.close();
                        };

                        WindowPrint.addEventListener("afterprint", beforeUnloadHandler);

                        WindowPrint.focus();
                        WindowPrint.print();

                        const waitForPrintDialogClosed = setInterval(() => {
                            if (WindowPrint.closed) {
                                clearInterval(waitForPrintDialogClosed);
                                WindowPrint.removeEventListener('afterprint', beforeUnloadHandler);
                            }
                        }, 100);
                    } else {
                        setTimeout(checkCSSApplication, 100);
                    }
                };

                WindowPrint.onload = checkCSSApplication;
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.print-only {
    display: none;
}

.smaller-paragraphs {
    p {
        margin: 0;
    }
}

table {
    width: 100%;

    th {
        background-color: #e8e0ff;
    }

    th,
    td {
        padding: 0.5rem;
        border: 1px solid #d3ccff;
    }
}

@media print {
  
    .print-only {
        display: block;
    }

    .no-print {
        display: none;
    }
}

@page {
    size: auto;
    margin: 2rem !important;
}
</style>
