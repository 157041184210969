<template>
  <div>
    <b-card>
      <div slot="header">
        <i class='fa fa-align-justify'></i><strong class='header-spacing'>Invoice items</strong>
      </div>
      <div v-if="consultationInvoiceItems.length > 0" class="mb-5 pb-2">
        <h6 class="mb-2"><b>Consultation invoice</b></h6>
        <b-table responsive :items="consultationInvoiceItems" :fields="['type', 'quantity', 'price']">
          <template v-slot:cell(paymentDate)="row">{{row.value | dateTime}}</template>
          <template v-slot:cell(price)="row">£{{row.value}}</template>
        </b-table>
        <div v-if="consultationInvoiceTotalData && consultationInvoiceTotalData.paymentDate === null">
          <b-button class="float-right" variant="primary" @click="confirmModal = true">Confirm Manual Payment</b-button>
        </div>
        <div v-if="consultationInvoiceTotalData && consultationInvoiceTotalData.paymentDate !== null">
          <label class="float-right bg-success px-3 py-1 rounded" disabled>Paid</label>
        </div>
      </div>
      <div v-if="prescriptionInvoiceItems.length > 0 && consultationInvoiceTotalData && consultationInvoiceTotalData.paymentDate !== null">
        <h6 class="mb-2"><b>Prescription invoice</b></h6>
        <b-table responsive :items="prescriptionInvoiceItems" :fields="['type', 'quantity', 'price']">
          <template v-slot:cell(paymentDate)="row">{{row.value | dateTime}}</template>
          <template v-slot:cell(price)="row">£{{row.value}}</template>
        </b-table>
        <div v-if="prescriptionInvoiceTotalData && prescriptionInvoiceTotalData.paymentDate === null">
          <b-button class="float-right" variant="primary" @click="confirmModal = true">Confirm Manual Payment</b-button>
        </div>
        <div v-if="prescriptionInvoiceTotalData && prescriptionInvoiceTotalData.paymentDate !== null">
          <label class="float-right bg-success px-3 py-1 rounded" disabled>Paid</label>
        </div>
      </div>


      <b-modal title="Confirm manual payment" centered class="modal-success" v-model="confirmModal" @ok="confirmManualPayment()" ok-title="Yes" ok-variant="primary" cancel-title="No" cancel-variant="outline-danger">
        <strong class="text-muted">PLEASE NOTE: This action is not reversible.</strong>
        <span class="text-danger">
          Are you sure you want to put this order through? This means you have already taken manual payment of this order and the order will move to the following status, which will vary depending on what status the order was when taking payment failed. 
        </span>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'invoice-details',
  props: ['consultationInvoiceItems', 'prescriptionInvoiceItems', 'orderId'],
  filters: {
    dateTime(date) {
      return moment(date).local().format('Do MMMM YYYY, HH:mm');
    }
  },
  data: function () {
    return {
      confirmModal: false,
      consultationInvoiceTotalData: {},
      prescriptionInvoiceTotalData: {}
    }
  },
  watch: {
    prescriptionInvoiceItems: {
      handler: function (_invoiceItems) {
        this.prescriptionInvoiceTotalData = this.prescriptionInvoiceItems.find(item => item.type === 'TOTAL');
        if (_invoiceItems) {
          _invoiceItems.forEach(item => {
            if (item.type === 'TOTAL') {
              item._rowVariant = 'secondary';
            }
          });
        }
      }
    },
    consultationInvoiceItems: {
      handler: function (_invoiceItems) {
        this.consultationInvoiceTotalData = this.consultationInvoiceItems.find(item => item.type === 'TOTAL');
        if (_invoiceItems) {
          _invoiceItems.forEach(item => {
            if (item.type === 'TOTAL') {
              item._rowVariant = 'secondary';
            }
          });
        }
      }
    }
  },
  methods: {
    confirmManualPayment() {
      const phase = this.consultationInvoiceTotalData.paymentDate === null ? "CONSULTATION" : "PRESCRIPTION"
      this.$orderService.processManualPayment(this.orderId, phase).then((result) => {
        this.$router.push('/orders/payment-failed');
        this.$awn.success(`Order moved to ${result.status}`);
      });
    }
  }
}
</script>