<template>
    <div class="proforma-container">
        <template v-if="repeatNote.version === 'new'">
            <div
                v-for="key in Object.keys(repeatNote.formValues)"
                :key="key"
                class="new-version-notes"
            >
                <p class="question">{{ repeatNote.formValues[key].question }}</p>
                <p class="d-flex answer-container">
                    <span class="pill">{{
                        repeatNote.formValues[key].answer ? "Yes" : "No"
                    }}</span>
                    <span v-if="!!repeatNote.formValues[key].reason">
                        - {{ repeatNote.formValues[key].reason }}</span
                    >
                </p>
            </div>
        </template>
        <template v-else>
            <div
                v-for="key in Object.keys(repeatNote.formValues)"
                :key="key"
                class="new-version-notes"
            >
                <p class="question">{{ getQuestion(key) }}</p>
                <p class="d-flex answer-container">
                    <span class="pill">{{
                        repeatNote.formValues[key].value ? "Yes" : "No"
                    }}</span>
                </p>
            </div>
        </template>
    </div>
</template>

<script>
import { REPEAT_PROFORMA } from "../util/questionnaires.js";
export default {
    props: {
        repeatNote: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getQuestion(id){
            return REPEAT_PROFORMA.find((item) => item.name === id).text;
        },
    },
};
</script>

<style lang="scss" scoped>
.proforma-container {
    .new-version-notes {
        padding: 1rem;
        border-radius: 5px;
        background-color: #ededed;
        margin-bottom: 1rem;

        .question {
            font-weight: 500;
        }

        .answer-container {
            column-gap: 0.5rem;

            .pill {
                display: block;
                background-color: #550385;
                border-radius: 4px;
                width: 2rem;
                text-align: center;
                color: white;
            }
        }
    }
}
</style>
