export const questions = [
   {
      "id": "Q1",
      "title": "Is the patient above the age of 18?",
      "description": "Patient needs to be 18 or over to join our Treat-It programme to qualify for medical cannabis",
      "patientTitle": "Are you above the age of 18?*",
      "type": "radio",
      "options": [
         "Yes",
         "No"
      ],
      "required": true,
      "validAnswer": [
         "Yes"
      ],
      "errorText": "Patient needs to be above the age of 18."
   },
   {
      "id": "Q2",
      "title": "What type of condition is the patient struggling with?",
      "description": "Time to pick the type of condition the patient is dealing with. Let's get the patient the right help!",
      "patientTitle": "What type of condition are you struggling with?*",
      "type": "radio",
      "options": [
         "Pain",
         "Cancer Related Symptoms",
         "Mental Health"
      ],
      "required": true,
      "validAnswer": [
         "Pain",
         "Cancer Related Symptoms",
         "Mental Health"
      ],
      "errorText": "We currently do not offer mental health treatment"
   },
   {
      "id": "Q3.1",

      "title": "What aspect of the patient's pain affects them the most?",
      "description": "Tell us how the patient's pain is impacting their day-to-day life. This will assist us in our preliminary assessment.Please note pain is a symptom, not a diagnosis.",
      "patientTitle": "What aspect of your pain affects you most?*",
      "dependOn": {
         "id": "Q2",
         "value":"Pain"
      },
      "type": "textArea",
      "required": true,
      "validAnswer": null,
      "errorText": "Please provide an answer to proceed."
   },
   {
      "id": "Q3.2",
      "title": "What specific cancer-related symptom affects the patient the most?",
      "description": "Tell us symptom/s that affect patient most frequently or severely. This will assist us in addressing patient's core pain issues.",
      "patientTitle": "What specific cancer related symptom affects you most?*",
      "dependOn": {
         "id": "Q2",
         "value":"Cancer Related Symptoms"
      },
      "type": "textArea",
      "required": false,
      "validAnswer": null,
      "errorText": "Please provide an answer to proceed."
   },
   {
      "id": "Q3.3",
      "title": "What specific mental health symptom affects the patient the most?",
      "description": "Tell us how patient's mental health symptom  is impacting patient's day to day life . This will assist us in our preliminary assessment.",
      "patientTitle": "What specific mental health symptom affects you most?*",
      "dependOn": {
         "id": "Q2",
         "value":"Mental Health"
      },
      "type": "textArea",
      "required": false,
      "validAnswer": null,
      "errorText": "Please provide an answer to proceed."
   },
   {
      "id": "Q4",
      "title": "Has the patient been diagnosed and prescribed any medications or treatments for this condition?",
      "description": "Just a friendly heads-up - meeting the eligibility criteria often involves trying out at least two treatments or procedures for the patient's condition.",
      "patientTitle": "Have you been diagnosed and prescribed any medications or treatments for this condition?*",
      "type": "radio",
      "options": [
         "Yes",
         "No"
      ],
      "required": true,
      "validAnswer": [
         "Yes"
      ],
      "errorText": "We are unable to prescribe medical cannabis if the patient has not tried at least two treatments or procedures for their condition."
   },
   {
      "id": "Q5",
      "title": "Has the patient ever received a diagnosis of Schizophrenia, Schizoaffective disorder, Psychosis, or similar?",
      "description": "If the patient has been diagnosed with these, it's important to note that safe medical practice may preclude being treated with medical cannabis. The patient's safety and compliance matter to us.",
      "patientTitle": "Have you ever received a diagnosis of Schizophrenia / Schizoaffective disorder, Psychosis or similar?*",
      "type": "radio",
      "options": [
         "Yes",
         "No"
      ],
      "required": true,
      "validAnswer": [
         "No"
      ],
      "errorText": "We are unable to prescribe medical cannabis if the patient is experiencing schizophrenia, schizoaffective disorder, or psychosis."
   },
   {
      "id": "Q6",
      "title": "Is the patient currently pregnant, intending to conceive, or breastfeeding?",
      "description": "In line with UK regulations, please be aware that medical cannabis cannot be prescribed if the patient is pregnant, intending to become pregnant, or breastfeeding. The safety of the patient and their child are paramount to us.",
      "patientTitle": "Are you currently pregnant, intending to conceive or breastfeeding?*",
      "type": "radio",
      "options": [
         "Yes",
         "No"
      ],
      "required": true,
      "validAnswer": [
         "No"
      ],
      "errorText": "We are unable to prescribe medical cannabis if the patient is pregnant, intending to become pregnant, or breastfeeding"
   },
   {
      "id": "Q7",
      "title": "Where does the patient currently live?",
      "description": "Please note that our services are currently limited to specific regions due to varying legalities.",
      "patientTitle": "Where do you currently live?*",
      "type": "dropdown",
      "options": [
         "England",
         "Wales",
         "Scotland",
         "Northern Ireland",
         "Channel Islands",
         "Other"
      ],
      "required": true,
      "validAnswer": [
         "Wales",
         "England",
         "Scotland"
      ],
      "errorText": "We currently do not provide our specialized care in this area."
   }
]