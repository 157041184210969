<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="9" lg="6" class="login-position">
          <b-card-group>
            <b-card no-body class="p-4 dark-colored">
              <b-card-body>
                <img class="logo" src="/static/img/logo.png"/>
                <b-input-group class="mb-3">
                  <b-input-group-prepend><b-input-group-text><i class="icon-user"></i></b-input-group-text></b-input-group-prepend>
                  <input type="text" class="form-control" placeholder="Please enter your email here" v-model="email">
                </b-input-group>
                <b-row>
                  <div v-if="response" class="error-message"><p>{{response}}</p></div>
                  <b-button variant="primary" :disabled="!email" v-on:click="forgetPassword()" v-bind:class="'btn-lg btn-block px-4' + loading">Forgot Password</b-button>
                </b-row>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Forget',
  data () {
    return {
      section: 'Forget Password',
      loading: '',
      response: '',
      email: '',
      error: ''
    }
  },
  methods: {
    forgetPassword () {
      this.response = '';
      this.toggleLoading();
      this.$userService.forgetPassword(this.email).then((result) => {
        console.log(result);
        this.$router.push({ path: '/login' });
        this.$awn.success('You will receive an email with steps to reset your password');
      }).catch((error) => {
        this.response = error.response && error.response.data ? error.response.data.message : 'If we find your email on our database you will receive an email with instructions to reset your password.';
        this.toggleLoading()
      })
    },
    toggleLoading () {
      this.loading = this.loading === '' ? 'loading' : '';
    }
  }
}
</script>

<style lang="scss">
#website-background{
  background-color: #e4e5e6 !important;
}
.logo {
  width: 12rem;
  margin: auto;
  display: block;
  padding-bottom: 1em;
}
.dark-colored {
  background-color: #e4e5e6;
  border: none;
}
.loginForm .input-group {
  padding-bottom: 1em;
  height: 3em;
}
.input-group input {
  height: 3em;
}
.login-position{
  margin-top: -10%;
}
</style>