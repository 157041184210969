<template>
    <b-card>
        <div slot="header"><i class="fa fa-align-justify"></i> Formulary</div>

        <b-row class="form-group">
            <b-col md="12" lg="3" xl="3" class="d-flex pr-lg-0">
                <b-pagination
                    class="my-auto"
                    align="left"
                    size="md"
                    :total-rows="formulary.totalElements"
                    v-model="currentPage"
                    :per-page="perPage"
                ></b-pagination>
            </b-col>
            <b-col md="12" lg="4" xl="4" class="d-flex pl-lg-0 pr-lg-1">
                <b-input-group size="md" class="mt-3 my-lg-auto">
                    <select
                        class="form-control"
                        id="selectedPharmacy"
                        v-model="selectedPharmacy"
                        @change="getFormulary()"
                    >
                        <option value="" selected hidden
                            >Select a pharmacy</option
                        >
                        <option value="">All</option>
                        <option
                            v-for="(pharmacy, index) of pharmacies"
                            :value="pharmacy.id"
                            :key="index"
                        >
                            {{ pharmacy.name }}
                        </option>
                    </select>
                </b-input-group>
            </b-col>
            <b-col md="12" lg="2" xl="3" class="d-flex pl-lg-1 pr-lg-1">
                <b-input-group size="md" class="mt-3 my-lg-auto">
                    <input
                        type="search"
                        class="form-control"
                        id="searchQuery"
                        v-model="searchQuery"
                        placeholder="Search by ID"
                        @search="getFormulary()"
                    />
                </b-input-group>
            </b-col>
            <b-col md="12" lg="3" xl="2" class="d-flex pl-lg-1">
                <b-button
                    variant="primary w-100 mt-3 my-lg-auto"
                    @click="openAddMedicationModal()"
                    >&nbsp;&nbsp;&nbsp;Add
                    medication&nbsp;&nbsp;&nbsp;</b-button
                >
            </b-col>
        </b-row>

        <b-table
            :items="formulary.content"
            :fields="columnData"
            :current-page="1"
            :per-page="perPage"
            hover
            outlined
            show-empty
            responsive
        >
            <template v-slot:cell(category)="row">
                <div class="w-max">
                    {{
                        registeredCategory.find(
                            (value) => row.value === value.key
                        ).value
                    }}
                </div>
            </template>
            <template v-slot:cell(quantity)="row">
                <span v-if="!row.item.toBeUpdated">{{ row.value }}</span>
                <input
                    v-if="row.item.toBeUpdated"
                    class="form-control"
                    type="text"
                    v-model="row.item.quantity"
                />
            </template>
            <template v-slot:cell(stockStatus)="row">
                <span v-if="row.value === 'OUT_OF_STOCK'">Out of stock</span>
                <span v-if="row.value === 'LOW_STOCK'">Low stock</span>
                <span v-if="row.value === 'IN_STOCK'">In stock</span>
            </template>
            <template v-slot:cell(salePrice)="row">
                <span v-if="!row.item.toBeUpdated">£{{ row.value }}</span>
                <input
                    class="form-control"
                    v-if="row.item.toBeUpdated"
                    type="number"
                    v-model="row.item.salePrice"
                />
            </template>
            <template v-slot:cell(unitPrice)="row">
                <span v-if="!row.item.toBeUpdated">£{{ row.value }}</span>
                <input
                    class="form-control"
                    v-if="row.item.toBeUpdated"
                    type="number"
                    v-model="row.item.unitPrice"
                />
            </template>
            <template v-slot:cell(buyPrice)="row">
                <span v-if="!row.item.toBeUpdated">£{{ row.value }}</span>
                <input
                    class="form-control"
                    v-if="row.item.toBeUpdated"
                    type="number"
                    v-model="row.item.buyPrice"
                />
            </template>
            <template v-slot:cell(strain)="row">
                <span v-if="!row.item.toBeUpdated">{{ row.value }}</span>
                <input
                    class="form-control"
                    v-if="row.item.toBeUpdated"
                    type="number"
                    v-model="row.item.strain"
                />
            </template>
            <template v-slot:cell(active)="row">
                <b-form-checkbox
                    block
                    variant="primary"
                    v-model="row.item.active"
                    :disabled="!row.item.toBeUpdated"
                />
            </template>
            <template v-slot:cell(actions)="data">
                <b-button
                    v-if="!data.item.toBeUpdated && !data.item.canEdit"
                    variant="danger"
                    @click="disableMedication(data.item)"
                >
                    {{ data.item.active ? "Disable" : "&nbsp;Enable&nbsp;" }}
                </b-button>
                <b-button
                    v-if="!data.item.toBeUpdated"
                    variant="primary"
                    @click="editItem(data.item)"
                >
                    Update
                </b-button>
                <b-button
                    v-if="data.item.toBeUpdated"
                    variant="warning"
                    @click="updateMedication(data.item)"
                >
                    Confirm
                </b-button>
            </template>
        </b-table>

        <b-row class="form-group">
            <b-col sm="12" md="6">
                <b-pagination
                    align="left"
                    size="md"
                    :total-rows="formulary.totalElements"
                    v-model="currentPage"
                    :per-page="perPage"
                ></b-pagination>
            </b-col>
        </b-row>

        <br />

        <b-modal
            v-model="addMedicationModal"
            centered
            size="lg"
            hide-header
            ok-title="Add medication"
            ok-variant="primary"
            @ok="confirmAllChanges(true)"
        >
            <div
                class="table-responsive reduced-margin-table add-medication-table"
            >
                <table class="table table-striped table-nowrap">
                    <thead>
                        <tr>
                            <th>Pharmacy</th>
                            <th>String Id</th>
                            <th>Brand Name</th>
                            <th>Product Name</th>
                            <th>Category</th>
                            <th>Admin Route</th>
                            <th>Medicine Profile</th>
                            <th>Quantity</th>
                            <th>Available Quantity</th>
                            <th>Manufacturer</th>
                            <th>Sale Price</th>
                            <th>Unit Price</th>
                            <th>Buying Price</th>
                            <th>Strain</th>
                            <th>Original Strain</th>
                            <th>Production Note</th>
                            <th>Origin Country</th>
                            <th>Benefits</th>
                            <th v-if="listOfMedicationsToAdd.length > 1"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(med, index) in listOfMedicationsToAdd"
                            :key="index"
                        >
                            <td>
                                <select
                                    class="form-control"
                                    v-model="med.pharmacyId"
                                >
                                    <option value="" selected hidden
                                        >Select a pharmacy</option
                                    >
                                    <option
                                        v-for="(pharmacy, index) of pharmacies"
                                        :value="pharmacy.id"
                                        :key="index"
                                    >
                                        {{ pharmacy.name }}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="med.stringId"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="med.brandName"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="med.productName"
                                />
                            </td>
                            <td>
                                <select
                                    class="form-control"
                                    v-model="med.category"
                                >
                                    <option
                                        v-for="c in registeredCategory"
                                        :value="c.key"
                                        :key="c.key"
                                        >{{ c.value }}</option
                                    >
                                </select>
                            </td>
                            <td>
                                <select
                                    class="form-control"
                                    v-model="med.administrationRoute"
                                >
                                    <option
                                        v-for="c in administrationRoute"
                                        :value="c.key"
                                        :key="c.key"
                                        >{{ c.value }}</option
                                    >
                                </select>
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="med.medicineProfile"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="med.quantity"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="med.availQuantity"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="med.manufacture"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="med.salePrice"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="med.unitPrice"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="med.buyPrice"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="med.strain"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="med.originalStrain"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="med.productionNote"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="med.originCountry"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="med.benifits"
                                />
                            </td>
                            <td>
                                <b-form-checkbox
                                    block
                                    variant="primary"
                                    v-model="med.active"
                                />
                            </td>
                            <td v-if="listOfMedicationsToAdd.length > 1">
                                <b-button
                                    variant="danger"
                                    @click="deleteMedicationToAdd(index)"
                                    >Remove</b-button
                                >
                            </td>
                        </tr>
                    </tbody>
                    <tfoot align="center">
                        <tr>
                            <td colspan="6">
                                <i
                                    class="huge-icon fa fa-plus-circle"
                                    @click="addMedication()"
                                ></i>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </b-modal>
    </b-card>
</template>

<script>
import moment from "moment";

export default {
    data: function() {
        return {
            pharmacies: [],
            selectedPharmacy: "",
            searchQuery: "",
            formulary: [],
            columnData: [
                { key: "stringId", label: "Id" },
                { key: "brandName", label: "Brand" },
                { key: "productName", label: "Product" },
                { key: "category", label: "Category" },
                { key: "administrationRoute", label: "Admin Route" },
                { key: "medicineProfile", label: "Med. Profile" },
                { key: "quantity", label: "Unit qty" },
                { key: "stockStatus", label: "Stock status" },
                { key: "manufacture", label: "Manufacture" },
                { key: "salePrice", label: "Sale Price" },
                { key: "unitPrice", label: "Unit Price" },
                { key: "buyPrice", label: "Buying Price" },
                { key: "strain", label: "Strain" },
                { key: "originalStrain", label: "Original Strain" },
                { key: "productionNote", label: "Production Note" },
                { key: "originCountry", label: "Origin Country" },
                { key: "benifits", label: "Benefits" },
                { key: "active", label: "Active" },
                { key: "actions", label: "Actions" },
            ],
            administrationRoute: [
                { key: "OIL", value: "Oil" },
                { key: "VAPE", value: "Vape" },
                { key: "FLOWER", value: "Flower" },
            ],
            registeredCategory: [
                { key: "HIGH_CBD", value: "High CBD" },
                { key: "BALANCED", value: "Balanced" },
                { key: "HIGH_THC", value: "High THC" },
            ],
            currentPage: 1,
            perPage: 10,
            addMedicationModal: false,
            listOfMedicationsToAdd: [
                {
                    unitPrice: 0.0,
                    buyPrice: 0.0,
                    salePrice: 0.0,
                    active: true,
                },
            ],
        };
    },
    filters: {
        dateTime: function(date) {
            return moment(date)
                .local()
                .format("HH:mm - DD/MM/YY");
        },
    },
    created() {
        this.getPharmacies();
        this.getFormulary();
    },
    watch: {
        currentPage: function() {
            this.getFormulary();
        },
    },
    methods: {
        getPharmacies() {
            this.$pharmacyService
                .search(this.searchQuery, this.currentPage - 1, this.perPage)
                .then((pharmacies) => {
                    this.pharmacies = pharmacies.content;
                })
                .catch((error) => {
                    this.$awn.alert(error, "An error occurred");
                });
        },
        getFormulary() {
            this.$formularyService
                .search(
                    this.selectedPharmacy,
                    this.searchQuery,
                    this.currentPage - 1,
                    this.perPage
                )
                .then(
                    (formulary) => {
                        this.formulary = formulary;
                    },
                    (error) => {
                        this.$awn.alert(error, "An error occurred");
                    }
                );
        },
        editItem(item) {
            this.$set(item, "toBeUpdated", true);
        },
        toggleActive(med) {
            med.active = !med.active;
        },
        searchForThisMedication(medicationId) {
            this.searchQuery = medicationId;
            this.getFormulary();
        },
        confirmAllChanges() {
            this.finalList = [...this.listOfMedicationsToAdd];

            this.$formularyService.addFormulary(this.finalList).then(
                () => {
                    this.getFormulary();
                },
                (error) => {
                    this.$awn.alert("Error", error);
                    this.getFormulary();
                    this.currentPage = 0;
                }
            );

            this.listOfMedicationsToAdd = [];
            this.addMedicationModal = false;

            this.currentPage = 0;

            this.$awn.success(
                "Formulary submit",
                "Formulary submitted successfully"
            );
        },
        updateMedication(data) {
            this.$formularyService.updateFormulary(data.id, data).then(
                () => {
                    this.$awn.success(
                        `Medication with ID ${data.id} updated successfully`,
                        "Update successful"
                    );
                    this.getFormulary();
                },
                (error) => {
                    console.error(error);
                    this.$awn.alert(error, "Error updating");
                }
            );
        },
        disableMedication(data) {
            const values = { ...data, active: !data.active };

            this.$formularyService.updateFormulary(data.id, values).then(
                () => {
                    this.$awn.success(
                        `Medication with ID ${data.id} ${
                            data.active ? "disabled" : "enabled"
                        } successfully`,
                        `${data.active ? "Disable" : "Enable"} successful`
                    );
                    this.getFormulary();
                },
                (error) => {
                    console.error(error);
                    this.$awn.alert(
                        error,
                        `Error ${data.active ? "disabling" : "enabling"}`
                    );
                }
            );
        },
        openAddMedicationModal() {
            this.addMedicationModal = true;
        },
        addMedication() {
            this.listOfMedicationsToAdd.push({
                unitPrice: 0.0,
                buyPrice: 0.0,
                salePrice: 0.0,
                active: true,
            });
        },
        deleteMedicationToAdd(index) {
            if (this.listOfMedicationsToAdd.length > 1) {
                this.listOfMedicationsToAdd.splice(index, 1);
            }
        },
    },
};
</script>

<style lang="scss">
.input-group {
    input {
        height: unset;
    }
}
.add-medication-table {
    th {
        min-width: 10rem;
    }
}
.huge-icon {
    font-size: 30px;
    color: #550385;
    cursor: pointer;
}
.huge-icon:hover {
    color: #44006b;
}
.reduced-margin-table {
    margin-bottom: -20px;
}
.table-nowrap th,
.table-nowrap td {
    white-space: nowrap;
}
.isDifferentNew {
    color: #009344;
    font-weight: bold;
}
.isDifferentOld {
    color: tomato;
    text-decoration: line-through;
}
.w-max {
    width: max-content;
}
</style>
