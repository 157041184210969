<template>
  <div></div>
  <!-- <div class="sidebar-form"></div> -->
</template>

<script>

export default {
  name: 'sidebar-form'
}
</script>
