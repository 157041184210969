var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.prescription && _vm.prescription.id)?_c('b-btn',{staticClass:"btn-block m-1",class:_vm.showPrescription ? 'collapsed' : null,attrs:{"variant":"primary","aria-controls":"prescriptionCollapse","aria-expanded":_vm.showPrescription ? 'true' : 'false'},on:{"click":function($event){_vm.showPrescription = !_vm.showPrescription}}},[(!_vm.showPrescription)?_c('span',[_vm._v("Show ")]):_vm._e(),(_vm.showPrescription)?_c('span',[_vm._v("Hide ")]):_vm._e(),_vm._v("Prescription ")]):_vm._e(),_c('br'),(_vm.prescription)?_c('b-collapse',{attrs:{"id":"prescriptionCollapse"},model:{value:(_vm.showPrescription),callback:function ($$v) {_vm.showPrescription=$$v},expression:"showPrescription"}},[_c('b-card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('i',{staticClass:"fa fa-align-justify"}),_c('strong',{staticClass:"header-spacing"},[_vm._v(" Prescription details")])]),_c('div',{staticClass:"mt-2 mb-4"},[_c('b-form-group',{attrs:{"id":"orderPlatformGroup","label":"Tracking Code","label-for":"orderPlatform","label-cols":"3"}},[_c('b-form-input',{attrs:{"name":"orderPlatform","type":"text","disabled":""},model:{value:(_vm.prescription.trackingCode),callback:function ($$v) {_vm.$set(_vm.prescription, "trackingCode", $$v)},expression:"prescription.trackingCode"}})],1)],1),(_vm.order.type === 'BOOKING' && _vm.prescription)?_c('span',[_c('b-table',{attrs:{"responsive":"","items":_vm.prescription.medication,"fields":[
                        'productName',
                        'medicineProfile',
                        'category',
                        'dosage',
                        'quantity',
                        'unit',
                    ]}})],1):_vm._e()])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }