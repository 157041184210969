<template>
  <div>
    <b-card>
      <template #header>
        <i class="fa fa-align-justify"></i>
        <strong class="header-spacing">Additional Notes</strong>
      </template>
      <div v-for="note in orderNotes" :key="note.id">
        <p class="note-stats">
          by <span><b>{{ note.doctor.displayName }}</b></span>
          on <span><b>{{ formatDate(note.creationDate) }}</b></span>
        </p>
        <p class="note-bubble">{{ note.description }}</p>
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "AdditionalNotes",
  props: ['orderNotes'],
  methods: {
    formatDate(date) {
      return moment(date).format('LL');
    }
  }
};
</script>
