<template>
    <div>
        <div class="score-label">
            <h4>Score: {{ auditNote.score }}</h4>
        </div>
        <div
            v-for="key in Object.keys(auditForm)"
            :key="key"
            class="question-container"
        >
            <p>{{ auditForm[key].label }}</p>
            <span class="score-pill">{{ auditNote[key] }}</span>
        </div>
    </div>
</template>

<script>
import { AUDIT_FORM } from "../util/questionnaires.js";

export default {
    data() {
        return {
            auditForm: AUDIT_FORM,
        };
    },
    props: {
        auditNote: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.score-label {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.question-container {
    padding: 1rem;
    border-radius: 5px;
    background-color: #ededed;
    margin-bottom: 1rem;

    .score-pill {
        display: block;
        padding: 0.5rem 0;
        background-color: #550385;
        border-radius: 10px;
        width: 3rem;
        text-align: center;
        color: white;
    }
}
</style>
