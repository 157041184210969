<template>
    <div>
        <b-btn
            v-if="prescription && prescription.id"
            variant="primary"
            class="btn-block m-1"
            @click="showPrescription = !showPrescription"
            :class="showPrescription ? 'collapsed' : null"
            aria-controls="prescriptionCollapse"
            :aria-expanded="showPrescription ? 'true' : 'false'"
        >
            <span v-if="!showPrescription">Show </span>
            <span v-if="showPrescription">Hide </span>Prescription
        </b-btn>
        <br />
        <b-collapse
            id="prescriptionCollapse"
            v-if="prescription"
            v-model="showPrescription"
        >
            <b-card>
                <div slot="header">
                    <i class="fa fa-align-justify"></i
                    ><strong class="header-spacing">
                        Prescription details</strong
                    >
                </div>
                <div class="mt-2 mb-4">
                    <b-form-group
                        id="orderPlatformGroup"
                        label="Tracking Code"
                        label-for="orderPlatform"
                        label-cols="3"
                    >
                        <b-form-input
                            name="orderPlatform"
                            type="text"
                            v-model="prescription.trackingCode"
                            disabled
                        />
                    </b-form-group>
                </div>
                <span v-if="order.type === 'BOOKING' && prescription">
                    <b-table
                        responsive
                        :items="prescription.medication"
                        :fields="[
                            'productName',
                            'medicineProfile',
                            'category',
                            'dosage',
                            'quantity',
                            'unit',
                        ]"
                    ></b-table>
                </span>
            </b-card>
        </b-collapse>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "prescription-details",
    props: ["order", "prescription"],
    data: function() {
        return {
            showPrescription: false,
            antimalarialPrescriptionFields: [
                { key: "name", label: "Name" },
                {
                    key: "dosageStartDate",
                    label: "Start Date",
                    tdClass: "text-nowrap",
                },
                {
                    key: "dosageEndDate",
                    label: "End Date",
                    tdClass: "text-nowrap",
                },
                { key: "dosageInstructions", label: "Instructions" },
                { key: "dosageExtraInfo", label: "Extra Information" },
                { key: "price", label: "Price", tdClass: "text-nowrap" },
            ],
            vaccinationPrescriptionFields: [
                { key: "name", label: "Name" },
                {
                    key: "startDate",
                    label: "Start Date",
                    tdClass: "text-nowrap",
                },
                { key: "dosageInstructions", label: "Instructions" },
                { key: "price", label: "Price", tdClass: "text-nowrap" },
            ],
        };
    },
    computed: {
        creationDate: function() {
            return moment(this.order.creationDate)
                .local()
                .format("Do MMMM YYYY, HH:mm");
        },
        price: function() {
            return "£" + this.order.price;
        },
    },
};
</script>
