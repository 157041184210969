<script>
import moment from "moment";
import axios from "axios";

async function saveLoginInfoToLocalStorage(item) {
  await localStorage.setItem("id_token", "Bearer " + item);
  await localStorage.setItem(
    "valid_until",
    moment()
      .add(30, "minutes")
      .toISOString()
  );
}

async function clearLocalStorage() {
  // await localStorage.clear();
  const keysToSkip = ['EligibilityForm', 'patientID', 'userData'];

  // Iterate through each key in localStorage
  for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      // Check if the key is not in the keysToSkip array
      if (!keysToSkip.includes(key)) {
          // Remove the item from localStorage
          await localStorage.removeItem(key);
      }
  }
}
const UserService = {};

UserService.install = function(Vue) {
  var userService = new Object();

  Object.defineProperty(Vue.prototype, "$userService", { value: userService });

  userService.login = function(username, password) {
    var url = "login";
    var credentials = {
      username: username,
      password: password,
      type: "admin",
    };

    return Vue.axios.post(url, credentials).then((result) => {
      if (result.data.value) {
        let token = result.data.value;
        saveLoginInfoToLocalStorage(token).then(
          () => {
            Vue.axios.defaults.headers.common["Authorization"] =
              "Bearer " + token;
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          },
          (error) => {
            this.handleError(error);
          }
        );
      } else {
        return result.data;
      }
    });
  };

  userService.verifyOtp = function(username, password, otp, key) {
    var url = "verify";
    var credentials = {
      username: username,
      password: password,
      type: "admin",
      topt: otp,
      key: key,
    };

    return Vue.axios.post(url, credentials).then((result) => {
      if (result.data.value) {
        let token = result.data.value;
        saveLoginInfoToLocalStorage(token).then(
          () => {
            Vue.axios.defaults.headers.common["Authorization"] =
              "Bearer " + token;
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          },
          (error) => {
            this.handleError(error);
          }
        );
      }
    });
  };

  userService.mandatoryChangePassword = function(
    username,
    currentPassword,
    newPassword
  ) {
    return Vue.axios
      .post("change-password", {
        username: username,
        oldPassword: currentPassword,
        newPassword: newPassword,
        userType: "admin",
      })
      .then((result) => {
        saveLoginInfoToLocalStorage(result.data.value).then(
          () => {
            Vue.axios.defaults.headers.common["Authorization"] =
              "Bearer " + result.data.value;
          },
          (error) => {
            this.handleError(error);
          }
        );
      });
  };

  userService.resetPassword = function(username, newPassword, token) {
    return Vue.axios
      .post("reset-password", {
        username: username,
        newPassword: newPassword,
        token: token,
        type: "admin",
      })
      .then((result) => {
        saveLoginInfoToLocalStorage(result.data.value).then(
          () => {
            Vue.axios.defaults.headers.common["Authorization"] =
              "Bearer " + result.data.value;
          },
          (error) => {
            this.handleError(error);
          }
        );
      });
  };

  userService.forgetPassword = function(email) {
    return Vue.axios
      .post("forget-password", { username: email, type: "admin" })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(this.handleError(error));
      });
  };

  userService.refreshToken = function() {
    var url = "api/admin/refresh";
    return Vue.axios.get(url).then((result) => {
      let token = result.data.value;
      saveLoginInfoToLocalStorage(token).then(
        () => {
          Vue.axios.defaults.headers.common["Authorization"] =
            "Bearer " + token;
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        },
        (error) => {
          this.handleError(error);
        }
      );
    });
  };

  userService.getUser = function(userType, userId) {
    return Vue.axios
      .get("api/admin/" + userType + "/" + userId)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.handleError(error);
        return Promise.reject(error);
      });
  };

  userService.getProfile = function() {
    let token = localStorage.getItem("id_token");
    Vue.axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["Authorization"] = token;
    
    return Vue.axios
      .get("api/admin/me")
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.handleError(error);
        return Promise.reject(error);
      });
  };

  userService.changePin = function(userType, userId, newPin) {
    return Vue.axios
      .put("api/admin/reset-user-pin", {
        pin: newPin,
        type: userType,
        userId: userId,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(this.handleError(error));
      });
  };

  userService.changePassword = function(type, userId, newPassword) {
    return Vue.axios
      .put("api/admin/reset-user-password", {
        password: newPassword,
        type: type,
        userId: userId,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(this.handleError(error));
      });
  };

  userService.changeOwnPassword = function(oldPassword, newPassword) {
    return Vue.axios
      .put("api/admin/password", {
        oldPassword: oldPassword,
        newPassword: newPassword,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(this.handleError(error));
      });
  };

  userService.logout = function() {
    return clearLocalStorage().then(
      () => {
        Vue.axios.defaults.headers.common["Authorization"] = "";
      },
      (error) => {
        this.handleError(error);
      }
    );
  };

  userService.blackListToken = function() {
    return Vue.axios
      .post("logout", {})
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(this.handleError(error));
      });
  };

  userService.handleError = function(error) {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      return error.response;
      // this.displayNotification('error', error.response.status + 'error')
    } else if (error.request) {
      // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      console.log("error.request ", error.request);
      return error.request;
    } else {
      // Something happened in setting up the request that triggered an Error
      // this.displayNotification('error', error.message, true)
    }
  };
  userService.displayNotification = function(type, message, redirect = false) {
    Vue.awn.alert(message, "Error");
    if (redirect) {
      this.$router.push("/login");
    }
  };
};
export default UserService;
</script>
