export const AUDIT_FORM = {
    question1: {
        label: "How often do you have a drink containing alcohol?",
        values: {
            0: "Never",
            1: "Monthly or less",
            2: "2 to 4 times per month",
            3: "2 to 3 times per week",
            4: "4 times or more per week",
        },
    },
    question2: {
        label:
            "How many units of alcohol do you drink on a typical day when you are drinking?",
        values: {
            0: "0 to 2",
            1: "3 to 4",
            2: "5 to 6",
            3: "7 to 9",
            4: "10 or more",
        },
    },
    question3: {
        label:
            "How often have you had 6 or more units if female, or 8 or more if male, on a single occasion in the last year?",
        values: {
            0: "Never",
            1: "Less than monthly",
            2: "Monthly",
            3: "Weekly",
            4: "Daily or almost daily",
        },
    },
    question4: {
        label:
            "How often during the last year have you found that you were not able to stop drinking once you had started?",
        values: {
            0: "Never",
            1: "Less than monthly",
            2: "Monthly",
            3: "Weekly",
            4: "Daily or almost daily",
        },
    },
    question5: {
        label:
            "How often during the last year have you failed to do what was normally expected from you because of your drinking?",
        values: {
            0: "Never",
            1: "Less than monthly",
            2: "Monthly",
            3: "Weekly",
            4: "Daily or almost daily",
        },
    },
    question6: {
        label:
            "How often during the last year have you needed an alcoholic drink in the morning to get yourself going after a heavy drinking session?",
        values: {
            0: "Never",
            1: "Less than monthly",
            2: "Monthly",
            3: "Weekly",
            4: "Daily or almost daily",
        },
    },
    question7: {
        label:
            "How often during the last year have you had a feeling of guilt or remorse after drinking?",
        values: {
            0: "Never",
            1: "Less than monthly",
            2: "Monthly",
            3: "Weekly",
            4: "Daily or almost daily",
        },
    },
    question8: {
        label:
            "How often during the last year have you been unable to remember what happened last night before because you had been drinking?",
        values: {
            0: "Never",
            1: "Less than monthly",
            2: "Monthly",
            3: "Weekly",
            4: "Daily or almost daily",
        },
    },
    question9: {
        label:
            "Have you or somebody else been injured as a result of your drinking?",
        values: {
            0: "No",
            1: "Yes, but not in the last year",
            2: "Yes, during the last year",
        },
    },
    question10: {
        label:
            "Has a relative or friend, doctor or other health worker been concerned about your drinking or suggested that you cut down?",
        values: {
            0: "No",
            1: "Yes, but not in the last year",
            2: "Yes, during the last year",
        },
    },
};

export const PEG_FORM = {
    painInPastWeek:
        "What number best describes your pain on average in the past week?",
    painInterferenceWithEnjoyment:
        "What number best describes how, during the past week, pain has interfered with your enjoyment of life?",
    painInterferenceWithGeneralActivity:
        "What number best describes how, during the past week, pain has interfered with your general activities?",
};

export const GAD_FORM = {
    feeling: {
        label: "Feeling nervous, anxious or on edge?",
        values: {
            0: "Not at all",
            1: "Several days",
            2: "More than half the days",
            3: "Nearly every day",
        },
    },
    worrying: {
        label: "Not being able to stop or control worrying?",
        values: {
            0: "Not at all",
            1: "Several days",
            2: "More than half the days",
            3: "Nearly every day",
        },
    },
};

export const CONSENT_FORM = {
    ageOver25: "They are over 21 or over and a UK resident.",
    answeredAssessment:
        "They have answered the online medical assessment truthfully with the knowledge that giving false information to obtain medicines could lead to a medicine being prescribed that is harmful to my health.",
    soleUseAcceptance: "Any medicine prescribed is for their sole use.",
    agreeToReadInfo:
        "They have agreed to read the patient information leaflet that will accompany the medicine.",
    understandTnc: "They have read and understood the terms and conditions.",
};

export const REPEAT_PROFORMA = [
    {
        name: "q_1",
        text: "Have you been using your medication as prescribed?",
        type: "choice",
    },
    {
        name: "q_2",
        text:
            "Has there been a change in your medical condition since your last appointment?",
        type: "choice",
    },
    {
        name: "q_3",
        text:
            "Has the amount of pain medication you have been taking decreased since beginning your medical cannabis treatment (meaning, have you been consuming less than what was recommended)?",
        type: "choice",
    },
    {
        name: "q_4",
        text:
            "Have you started any new medication since your last appointment?",
        type: "choice",
    },
    {
        name: "q_5",
        text:
            "Have you been experiencing  any significant side effects (dizziness, anxiety, heart palpitations) since starting your medical cannabis treatment?",
        type: "choice",
    },
    {
        name: "q_6",
        text: "How do you currently rate your pain level?",
        type: "modal",
    },
    {
        name: "q_7",
        text: "Any other comments or concerns?",
        type: "choice",
    },
];

export const GP_QUESTIONNAIRE = [
    {
        title: "Question set 1",
        step: 1,
        questions: [
            {
                active: true,
                question: "SCR accessed and reviewed?",
                questionId: "q_28",
            },
            {
                active: true,
                question:
                    "What is your occupation/Does this involve the performance of skilled tasks, manual or cognitive?",
                questionId: "q_29",
            },
            {
                active: true,
                question:
                    "For what condition(s) and resultant symptoms are you hoping to be treated for?",
                questionId: "q_1",
            },
            {
                active: true,
                question: "Are your symptoms stable?",
                questionId: "q_2",
            },
            {
                active: true,
                question: "What treatments have you tried to date?",
                questionId: "q_30",
            },
            {
                active: true,
                question: "Investigations diagnostics",
                questionId: "q_31",
            },
            {
                active: true,
                question: "Past medical history",
                questionId: "q_32",
            },
            {
                active: false,
                question: "What is your occupation?",
                questionId: "q_3",
            },
            {
                active: false,
                question:
                    "Does your job involved skilled tasks or the operation of machinery?",
                questionId: "q_4",
            },
            {
                active: true,
                question:
                    "Have you ever had any medical problems affecting the following?",
                questionId: "q_5",
                choices: [
                    { label: "Heart", id: "heart" },
                    { label: "Lungs", id: "lungs" },
                    { label: "GIT", id: "git" },
                    { label: "Eyes", id: "eyes" },
                    { label: "Skin", id: "skin" },
                    { label: "Brain", id: "brain" },
                    { label: "Liver", id: "liver" },
                    { label: "Kidney", id: "kidney" },
                    { label: "Anything else", id: "others" },
                    { label: "None of the above", id: "none" },
                ],
            },
        ],
    },
    {
        title: "Question set 2",
        step: 2,
        questions: [
            {
                active: true,
                question:
                    "Are you currently under the care of a specialist for the condition you are requesting Medical Cannabis for?",
                questionId: "q_11",
            },
            {
                active: true,
                question:
                    "Have you previously or are you currently prescribed Medical Cannabis?",
                questionId: "q_7",
            },
            {
                active: false,
                question:
                    "Have you used Cannabis within the last year via any of the following methods?",
                questionId: "q_8",
                choices: [
                    { label: "Oil", id: "oil" },
                    { label: "Flower", id: "flower" },
                    { label: "Smoke", id: "smoke" },
                    { label: "Oral", id: "oral" },
                    { label: "Other", id: "other" },
                ],
            },
            {
                active: true,
                question: "Have you used Cannabis within the last year?",
                questionId: "q_37",
            },
            {
                active: true,
                question: "Via what method did you take cannabis?",
                questionId: "q_38",
                choices: [
                    { label: "Oil", id: "oil" },
                    { label: "Flower", id: "flower" },
                    { label: "Smoke", id: "smoke" },
                    { label: "Vape", id: "vape" },
                    { label: "Oral", id: "oral" },
                    { label: "Other", id: "other" },
                ],
            },
            {
                active: true,
                question: "Any preference for formulation",
                questionId: "q_12",
                choices: [
                    { label: "Vape", id: "vape" },
                    { label: "Oil", id: "oil" },
                    { label: "Flower", id: "flower" },
                    { label: "Oral", id: "oral" },
                    { label: "Other", id: "other" },
                    { label: "No preference", id: "none" },
                ],
            },
            {
                active: true,
                question:
                    "I understand that should I be prescribed medical cannabis I require an effective means of contraception?",
                questionId: "q_14",
            },
            {
                active: true,
                question:
                    "Has a healthcare professional ever advised that you should not take Cannabis?",
                questionId: "q_6",
            },
            {
                active: true,
                question:
                    "Have you ever had an allergic or hypersensitivity reaction to Cannabis or Cannabis related products?",
                questionId: "q_13",
            },
            {
                active: true,
                question:
                    "Are you allergic to any other medication or anything else including nuts seeds and oils?",
                questionId: "q_33",
            },
            {
                active: true,
                question: "Current medication",
                questionId: "q_34",
            },
            {
                active: true,
                question: "Have you ever been prescribed the following?",
                questionId: "q_9",
                choices: [
                    { label: "PreGabalin", id: "preGabalin" },
                    { label: "Codeine", id: "codeine" },
                    { label: "Morphine", id: "morphine" },
                    { label: "Oxycodone", id: "oxycodone" },
                    { label: "Oxycontin", id: "oxycontin" },
                    { label: "Tapentadol", id: "tepantadol" },
                    {
                        label: "Fentanyl or Buprenoprphine Patch",
                        id: "fentanyl",
                    },
                    { label: "Tramadol", id: "tramadol" },
                    { label: "Co-Codamol", id: "codamol" },
                    { label: "Gabapentin", id: "gabapentin" },
                    { label: "Amitriptyline", id: "amitriptyline" },
                    { label: "Duloxetine", id: "duloxetine" },
                    // {
                    //   label: 'Benzodiazepine such as Diazepam, Temazepam, Clobazam',
                    //   id: 'benzodiazepine',
                    // },
                    // {
                    //   label:
                    //     'ADHD Medication such as Methylphenidate, Ritalin, Amphetamines, Aderrall',
                    //   id: 'adhd',
                    // },
                    { label: "Other", id: "other" },
                    { label: "None of the above", id: "none" },
                ],
            },
            {
                active: false,
                question: "Are you taking any regular medication?",
                questionId: "q_10",
            },
        ],
    },
    {
        title: "Question set 3",
        step: 3,
        questions: [
            {
                active: true,
                question:
                    "Have you ever been diagnosed with any of the following?",
                questionId: "q_15",
                choices: [
                    { label: "Personality disorder", id: "personality" },
                    {
                        label: "Schizophrenia or Psychosis",
                        id: "schizophrenia",
                    },
                    { label: "Depression", id: "depression" },
                    { label: "PTSD", id: "ptsd" },
                    { label: "Bipolar disorder", id: "bipolar" },
                    {
                        label: "History of Suicide attempts or Self harm",
                        id: "selfharm",
                    },
                    { label: "History of addiction", id: "addiction" },
                    {
                        label:
                            "A blood relative with a history of Schizophrenia or Psychosis",
                        id: "bloodRelative",
                    },
                    { label: "Anything else", id: "any" },
                    { label: "None of the above", id: "none" },
                ],
            },
            {
                active: true,
                question:
                    "Have you ever been diagnosed with a mental health disorder?",
                questionId: "q_35",
            },
            {
                active: true,
                question:
                    "During the last month, have you often been bothered by feeling down, depressed or hopeless?",
                questionId: "q_22",
            },
            {
                active: true,
                question:
                    "During the last month, have you often been bothered by having little interest or pleasure in doing things?",
                questionId: "q_23",
            },
            {
                active: true,
                question: "MSE - General comments?",
                questionId: "q_24",
            },
            {
                active: true,
                question: "Do you drink alcohol?",
                questionId: "q_17",
            },
            {
                active: true,
                question: "Do you use tobacco?",
                questionId: "q_16",
                choices: [
                    { label: "Non-smoker", id: "none" },
                    { label: "Ex-smoker", id: "ex" },
                    { label: "Current-smoker", id: "smoker" },
                ],
            },
            {
                active: true,
                question: "Do you currently use any recreational drugs?",
                questionId: "q_18",
                choices: [
                    { label: "Cocaine", id: "cocaine" },
                    { label: "Ecstasy", id: "ecstasy" },
                    { label: "Heroin", id: "heroin" },
                    { label: "Anything else", id: "any" },
                    { label: "None of the above", id: "none" },
                ],
            },
            {
                active: false,
                question: "Do you have children at home with you?",
                questionId: "q_20",
            },
        ],
    },
    {
        title: "Question set 4",
        step: 4,
        questions: [
            {
                active: true,
                question:
                    "Do you have any mobility issues/Have you been advised you are at risk of falls?",
                questionId: "q_19",
            },
            {
                active: true,
                question: "Are you the main carer for anyone you live with?",
                questionId: "q_21",
            },
            {
                active: true,
                question: "",
                questionId: "q_25",
                choices: [
                    {
                        label:
                            "Advice regarding Driving/ operating skilled machinery",
                        id: "operatingAdvice",
                    },
                ],
            },
            {
                active: true,
                question: "",
                questionId: "q_26",
                choices: [
                    {
                        label: "Advice regarding Cannabis and travel",
                        id: "travelAdvice",
                    },
                ],
            },
            {
                active: true,
                question: "",
                questionId: "q_27",
                choices: [
                    {
                        label: "Advice regarding Cannabis and side effects",
                        id: "sideEffectAdvice",
                    },
                ],
            },
            {
                active: true,
                question:
                    "Do you believe this patient meets the criteria to be prescribed and would benefit from being prescribed medical cannabis?",
                questionId: "q_36",
            },
        ],
    },
];

export const MDT_PROFORMA = {
    gc_q_1: {
      title: 'Has the patient completed an eligibility questionnaire?',
    },
    gc_q_2: {
      title: 'Has the patient satisfied eligibility assessment?',
    },
    gc_q_3: {
      title: 'Has the patient had a clinical review?',
    },
    gc_q_4: {
      title:
        'Has the patient been deemed appropriate for prescription of medicinal cannabis?',
    },
    ca_q_1: {
      title: 'What is the condition being treated *',
    },
    ca_q_2: {
      title:
        'Is medicinal cannabis an appropriate treatment for this condition *',
    },
    ca_q_3: {
      title: 'Brief summary of patient case *',
    },
    ca_q_4: {
      title:
        'Are there any contra-indications (clinical or pharmacological) identified by the MDT from case note assessment for medicinal cannabis for this patient?',
    },
    ca_q_5: {
      title:
        'Has a psychological assessment been performed as per protocol via the traffic light system?',
    },
    ca_q_6: {
      title:
        'Does the patient meet the criteria as per protocol for unmet clinical need?',
    },
    ca_q_7: {
      title:
        'Is the initial dose, configuration, and delivery appropriate for this patient?',
    },
    ca_q_8: {
      title: 'Is this patient already on medicinal cannabis?',
    },
    ca_q_9: {
      title:
        'Is the MDT satisfied with the dose adjustment plan or plan to continue at same doses?',
    },
    ca_q_10: { title: 'MDT Discussion' },
    signOff: { title: '' },
  };