<template>
  <orders-table :title="title" :orders="orders" :page="currentPage" :action="action" :click-event="clickEvent" :column-names="columnNames" :search-action="searchAction" :filter-action="filterAction"></orders-table>
</template>

<script>

import OrdersTable from '../../components/OrdersTable'
import OrderEventBus from '../../EventBus.js'

export default {
  components: {
    OrdersTable
  },
  props: {
    types: {
      default: () => ['FORM', 'BOOKING', 'TRAVEL', 'PRESCRIPTION_ONLY']
    }
  },
  data: function () {
    return {
      title: 'All Orders',
      orders: {},
      currentPage: 1,
      action: "<button class='btn btn-sm btn-outline-primary'>Open Order</button>",
      columnNames: ['id', 'creationDate', 'status', 'isRepeatOrder', 'description', 'patient', 'ConsultationType', 'consultedBy', 'consultedPharmacistName', 'consultedPharmacyEmail', 'consultedPharmacyPhone', 'pharmacy', 'actions'],
      clickEvent: 'open-order-click',
      newOrderSound: new Audio('/static/message.mp3'),
      timer: '',
      searchAction: "search-order-click",
      filterAction: "All-order-filter",
      filterData: {
        "orderId": "",
        "pharmacyId": "",
        "startDate": "",
        "endDate": "",
        "channel": ""
      }
    }
  },
  mounted () {
    this.getOrders()
    OrderEventBus.$on('currentPage', (currentPage) => {
      this.currentPage = currentPage;
    })
    OrderEventBus.$on(this.clickEvent, (orderId) => {
      this.$router.push('/order/' + orderId)
    })
    OrderEventBus.$on(this.searchAction, (orderId) => {
      this.searchOrder(orderId)
    })
    OrderEventBus.$on(this.filterAction, (data) => {
      this.orders = {};
      this.currentPage = 1;
      this.FilterAllOrderTable(data)
    })
  },
  watch: {
    currentPage: function () {
      if(this.filterData.orderId === "" && this.filterData.pharmacyId === "" && this.filterData.startDate === "" && this.filterData.endDate === "" && this.filterData.channel === ""){
        this.getOrders();
      }
      else {
        this.orders = {};
        this.FilterAllOrderTable (this.filterData);
      }
      this.scrollToTop();
    },
    types: function () {
      this.getOrders(true);
    }
  },
  methods: {

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // You can use 'auto' or 'smooth' for the scrolling behavior
      })
    },

    getOrders (muted = false) {
      if(this.filterData.orderId === "" && this.filterData.pharmacyId === "" && this.filterData.startDate === "" && this.filterData.endDate === "" && this.filterData.channel === ""){
        this.$orderService.getAllOrders(this.currentPage - 1, 10, this.types).then(orders => {
          if (!muted && this.orders.totalElements < orders.totalElements) {
            this.newOrderSound.play();
          }
          this.orders = orders;
          console.log('this.orders', this.orders)
          this.timer = setTimeout(this.getOrders, 60000);
        }).catch(error => {
          console.error(error);
          this.$awn.alert('There was an error getting orders');
        });
      }
    },

    searchOrder (searchOrderId) {
      if(searchOrderId === ""){
        this.getOrders();
        return;
      }

      this.$orderService.searchOrder(searchOrderId, this.types).then(orders => {
        this.orders = {
          content: [orders]
        };
      }).catch(error => {
        console.error(error);
        this.orders = {content: []};
        this.$awn.alert('There was an error getting orders');
      });
    },

    FilterAllOrderTable (data) {
      this.filterData = data;
      if(data.orderId === "" && data.pharmacyId === "" && data.startDate === "" && data.endDate === "" && data.channel === ""){
        this.getOrders();
        return;
      }

      this.$orderService.allOrdersFilters(this.currentPage - 1, 10, data).then(orders => {
        this.orders = orders;
      }).catch(error => {
        console.error(error);
        this.orders = {content: []};
        this.$awn.alert('There was an error getting orders');
      });
    }
  },
  beforeDestroy () {
    clearTimeout(this.timer);
    OrderEventBus.$off();
  }
}

</script>