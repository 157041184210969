<template>
    <date-range-picker
        ref="picker"
        :opens="opens"
        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
        :minDate="minDate"
        :maxDate="maxDate"
        :singleDatePicker="singleDatePicker"
        :timePicker="timePicker"
        :timePicker24Hour="timePicker24Hour"
        :showWeekNumbers="showWeekNumbers"
        :showDropdowns="showDropdowns"
        :autoApply="autoApply"
        v-model="dateRange"
        @update="updateValues($event)"
        @toggle="logEvent('event: close', $event)"
        @start-selection="logEvent('event: startSelection', $event)"
        @finish-selection="logEvent('event: finishSelection', $event)"
        :linkedCalendars="linkedCalendars"
    >
        <template v-slot:input="picker" style="min-width: 350px;">
            <label v-if="picker.startDate && picker.endDate">{{ picker.startDate | date }} - {{ picker.endDate | date }}</label>
            <button v-if="dateClearBtn" class="close-btn" @click="clearDate($event)"><i class="fa fa-times" aria-hidden="true"></i></button>
            <label v-if="!picker.startDate && !picker.endDate"><i class="fa fa-calendar" aria-hidden="true"></i> Select Date Range</label>
        </template>

    </date-range-picker>
</template>
  
<script>
    import DateRangePicker from 'vue2-daterange-picker';
    import moment from 'moment';
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

    export default {
        components: {
            DateRangePicker,
        },
        data() {
            let startDate = null;
            let endDate = null;
            
            return {
                opens: "right",
                minDate: null,
                maxDate: null,
                singleDatePicker: false,
                timePicker: false,
                timePicker24Hour: false,
                showWeekNumbers: false,
                showDropdowns: true,
                autoApply: false,
                linkedCalendars: true,
                dateRange: {startDate, endDate},
                dateClearBtn: false,
            };
        },

        created() {
            this.dateRange = this.$orderService.getDaterange() ? this.$orderService.getDaterange() : {};
            this.dateClearBtn = (Object.keys(this.dateRange).length > 0) ? true : false;
        },

        methods: {
            updateValues(event) {
                let start = moment(event.startDate).local().format('YYYY-MM-DD');
                let end = moment(event.endDate).local().format('YYYY-MM-DD');
                let range = {
                    "start": start,
                    "end": end
                }
                this.$emit('dateRange', range);
                this.dateClearBtn = true;
                this.$orderService.setDaterange(this.dateRange);
            },
            logEvent(status, event) {
                if(status == "event: startSelection"){
                    this.startDate = moment(event).local().format('DD/MM/YY');
                }
                else if(status == "event: finishSelection"){
                    this.endDate = moment(event).local().format('DD/MM/YY');
                }
            },
            clearDate(event){
                this.dateRange.startDate = null;
                this.dateRange.endDate = null;
                this.$orderService.setDaterange({});
                this.dateClearBtn = false;
                let range = {
                    "start": "",
                    "end": ""
                }
                this.$emit('dateRange', range);
                event?.stopPropagation();
            }
        },
    };
</script>

<style lang="scss">

    .vue-daterange-picker[data-v-1ebd09d2] {
        display: block;
    }

    .daterangepicker td.active, .daterangepicker td.active:hover {
        background-color: #805ad5 !important;
        border-color: transparent;
        color: #fff !important;
    }

    .vue-daterange-picker[data-v-1ebd09d2] {
        min-width: max-content;
    }

    .daterangepicker td.in-range {
        background-color: #eee4f3;
        border-color: transparent;
        color: #000;
        border-radius: 0;
    }

    .daterangepicker .btn-primary, .daterangepicker .btn-success {
        background-color: #805ad5;
        color: #fff;
    }

    .close-btn {
        background: transparent;
        border: 0;
    }

    td[data-v-98ac2448], th[data-v-98ac2448] {
        padding: 2px !important;
    }

</style>
  