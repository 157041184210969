<template>
  <b-card>

    <div slot="header">
      <i class='fa fa-align-justify'></i> Manage Appointments
    </div>

    <div v-if="!loading">
      <b-row>
        <b-col>
          <b-button class="pull-right" :disabled="!selectedDates" variant="primary" @click="resetDates"> Clear Selection </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <v-date-picker color="purple" mode='range' :columns="$screens({ default: 1, md:2, lg: 3 })" :step="1" is-inline is-expanded :attributes='attributes' v-model='selectedDates' @update:from-page="updateCalendar"></v-date-picker>
        </b-col>
      </b-row>
      <div v-if="selectedDates && !loadingAvailabilities">
        <b-row>
          <b-col>
            <br/>
            <multiselect v-if="availableDoctors.length" v-model="doctorsSelected" :options="availableDoctors" :multiple="true" :close-on-select="true" :clear-on-select="true" :preserve-search="false" placeholder="Pick a doctor" label="displayName" track-by="id" :show-labels="false" @select="pickDoctor" @remove="removeDoctor">
              <template v-slot:cell(tag)="{option, remove}">
                <div class="doctor-option-selected">
                  <span> {{option.id}} - {{ option.displayName }} </span>
                  <span class="doctor-option-selected-remove" @click="remove(option)">x</span>
                </div>
              </template>
              <template v-slot:cell(option)="props">
                <span class="doctor-option">{{ props.option.id }} - </span> {{ props.option.displayName }}
              </template>
            </multiselect>
          </b-col>
        </b-row>
        <br/>
        <b-row v-if="doctorsSelected.length">
          <b-col>
            <b-tabs class="appointments-tab">
              <b-tab v-bind:title="a.doctor.id + ' - ' + a.doctor.displayName" v-for="a in doctorsAvailabilities" :key="a.doctor.id" active>
                <b-card-group deck class="appointment-slot-container">
                  <div class="checkboxes" v-for="(slot, index) in a.times" :key="index" @click.stop="selectSlot(a, slot, index)" @mouseover="hoverSlot(a, slot, index)" >
                    <input type="checkbox" v-bind:id="'checkbox_' + index + '_' + a.doctor.id" class="appointment-slot"/>
                    <label class="appointment-slot"  v-bind:for="'checkbox_' + index + '_' + a.doctor.id" @click.prevent
                    v-bind:class="{         
                                    ' ' : slot.status === 'CANCELED_BY_ADMIN',
                                    'appointment-slot-canceled' : slot.status === 'CANCELED_BY_DOCTOR',
                                    'appointment-slot-booked' : (slot.orderItemId && slot.status !== 'CANCELED_BY_ADMIN') || (slot.bookingPlatform === 'GPS' && slot.status === 'GPS_BOOKING') || slot.status === 'LINKED', 
                                    'appointment-slot-gps': slot.bookingPlatform === 'GPS' && slot.status === 'GPS_BOOKING',
                                    'appointment-slot-scheduled' : slot.scheduled && !slot.hovered && slot.active,
                                    'appointment-slot-remove-scheduled' : slot.scheduled && !slot.hovered && !slot.active,
                                    'appointment-slot-selected' : !slot.hovered && slot.active && !(slot.bookingPlatform === 'GPS' && slot.status === 'GPS_BOOKING'), 
                                    'appointment-slot-hovered-active': slot.hovered && slot.active,
                                    'appointment-slot-hovered-inactive': slot.hovered && !slot.active,
                                  }">
                      {{slot.time}}
                    </label>
                  </div>
                </b-card-group>
              </b-tab>
            </b-tabs>
            <br/>
            <b-button variant="primary" class="float-right" @click="confirmChanges">
              Confirm Doctors Availabilities
            </b-button>
            <div class="color-info">
              <div>
                <span class="square appointment-slot-free"></span> Free
              </div>
              <div>
                <span class="square appointment-slot-selected"></span> Doctor available
              </div>
              <div>
                <span class="square appointment-slot-booked"></span> Appointment Booked
              </div>
              <div>
                <span class="square appointment-slot-gps"></span> Booked on GPS
              </div>
              <div>
                <span class="square appointment-slot-canceled"></span> Canceled by Doctor
              </div>
              <div>
                <span class="square appointment-slot-scheduled"></span> Unscheduling
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="loadingAvailabilities">
        <div class="loader">Loading...</div>
      </div>
    </div>
    <div v-if="loading">
      <div class="loader">Loading...</div>
    </div>
    <b-modal title="Error" centered class="modal-alert" v-model="appointmentsIssuesModal" ok-title="Acknowledged" ok-variant="primary" ok-only>
      <span class="justified" v-if="unableToDisplayAppointmentSlots">
        <p><strong class="text-muted">PLEASE NOTE:</strong> <span class="text-danger">We are unable to display the doctors availabilities because they are not available at exactly the same times across the dates you have selected. You can still add availabilities for each doctor but it will override whatever availabilities that might already have there. </span></p>
        <p><strong class="text-muted">Try changing the dates you've selected in order to see them displayed below.</strong></p>
      </span>
      <span class="justified" v-else-if="noDoctorAvailabilitiesToUpdate">
        <p class="text-muted">You have not updated the doctors availabilities to be able to confirm them.</p>
      </span>
    </b-modal>
    <b-modal title="Info" centered class="modal-alert" v-model="orderPicked" hide-footer>
      <span class="justified">
        <p v-if="orderIdPicked !== 0">There is an order placed for this time slot.</p>
        <p v-if="orderIdPicked === 0">There is an order placed for this time slot in GPS platform.</p>
        <router-link v-if="orderIdPicked !== 0" class="float-right" :to="{path: '/order/' + orderIdPicked }"><b-button variant="primary">Open order</b-button></router-link>
      </span>
    </b-modal>
  </b-card>
</template>

<script>
import moment from 'moment'
import * as _ from 'lodash'

export default {
  data: function () {
    return {
      dateFormat: 'YYYY-MM-DD',
      isoDateFormat: 'YYYY-MM-DDTHH:mm:ss.sssZ',
      timeFormat: 'HH:mm',
      hourFormat: 'HH',
      calendarPages: 3,
      firstSelectedSlotNo: -1,
      lastHoveredSlotNo: -1,
      times: ['08:00', '08:15', '08:30', '08:45', '09:00','09:15', '09:30', '09:45', '10:00','10:15', '10:30', '10:45', '11:00','11:15', '11:30', '11:45', '12:00','12:15', '12:30', '12:45', '13:00','13:15', '13:30', '13:45', '14:00','14:15', '14:30', '14:45', '15:00','15:15', '15:30', '15:45', '16:00','16:15', '16:30', '16:45', '17:00','17:15', '17:30', '17:45', '18:00','18:15', '18:30', '18:45', '19:00','19:15', '19:30', '19:45'],
      appointmentSlots: [],
      availableDoctors: [],
      doctorsSelected: [],
      doctorsAvailabilities: [],
      updatingCalendar: false,
      selectedDates: { start: new Date(), end: new Date() },
      attributes: [],
      appointmentsIssuesModal: false,
      unableToDisplayAppointmentSlots: false,
      noDoctorAvailabilitiesToUpdate: false,
      loading: false,
      loadingAvailabilities: false,
      orderPicked: false,
      orderIdPicked: 0
    }
  },
  created() {
    this.selectedDatesChanged(this.selectedDates)
  },
  components: { },
  methods: {
    updateCalendar(page) {
      if (!page) return;
      let [month, year] = [page.month, page.year];
      let startDate = moment().month(month - 1).year(year).startOf("month");
      let from = startDate.format(this.dateFormat);
      let to = startDate.add(this.calendarPages - 1, 'month').endOf('month').format(this.dateFormat);
      this.getAvailabilities(from, to).then(availabilities => {
        availabilities.forEach(availability => {
          if (this.availableDoctors.every(d=> {return d.id !== availability.doctor.id})) {
            if (availability.doctor.settings.DOCTOR_TYPE === "GP") {
              this.availableDoctors.push(availability.doctor);
            }
          }
          availability.appointmentList.forEach(appointment => {          
            // prevent showing someone's on, if they can't see on the appointment slots
            let hour = Number(moment(appointment.datetime).local().format(this.hourFormat));
            if (hour > 7 && hour < 20) {
              let formattedDate = moment(appointment.datetime).local().format(this.dateFormat);
              if (this.attributes.length === 0) {
                this.attributes.push({ bar: {}, dates: [] });
              }
              if (this.attributes[0].dates.indexOf(formattedDate) < 0) {
                this.attributes[0].dates.push(formattedDate);
              }
            }            
          });
        });
        this.availableDoctors = [...this.availableDoctors];
        this.attributes = [...this.attributes];
      });
    },
    getAvailabilities(from, to) {
      return new Promise((resolve) => {
        this.$doctorService.getAvailabilities(from, to).then(availabilities => {
          resolve(availabilities);
        })
      });
    },
    pickDoctor(doctor) {
      let availability = {
        doctor: doctor,
        times: this.initializeAppointmentSlots(),
        firstSelectedSlotNo: -1,
      }
      this.doctorsAvailabilities.push(availability);
    },
    removeDoctor(doctor) {
      this.doctorsAvailabilities = this.doctorsAvailabilities.filter(a => a.doctor.id !== doctor.id);
    },
    initializeAppointmentSlots(appointmentList) {
      let slots = this.times.map(time => ({time: time, active: false, hovered: false, selected: false}));
      if (appointmentList) {
        appointmentList.forEach(slot => {
          let time = moment(slot.datetime).local().format(this.timeFormat);
          let id = slots.findIndex(i => i.time === time);
          if( !slots[id]?.id || slots[id]?.id < slot.id) {
            slots[id] = {...slots[id], ...slot};
            slots[id].active = slots[id].orderItemId ? false : true;
            slots[id].scheduled = slots[id].status == 'SCHEDULED';
          }
        })
      }
      return slots;
    },
    selectSlot(availability, slot, lastSelectedSlotNo) {
      if (!slot.orderItemId && !(slot.bookingPlatform === 'GPS' && slot.status === 'GPS_BOOKING')) {
        if (availability.firstSelectedSlotNo == -1) {
          availability.firstSelectedSlotNo = lastSelectedSlotNo;
          slot.hovered = true;
        } else {
          let higherValue, lowerValue;
          if (availability.firstSelectedSlotNo > lastSelectedSlotNo) {
            higherValue = availability.firstSelectedSlotNo;
            lowerValue = lastSelectedSlotNo;
          } else {
            higherValue = lastSelectedSlotNo;
            lowerValue = availability.firstSelectedSlotNo;
          }
          for (let i = lowerValue; i <= higherValue; i++) {
            availability.times[i].active = !availability.times[i].active;
          }
          availability.firstSelectedSlotNo = -1;
          this.clearHoveredSlots(availability);
        }
      } else {
        this.orderPicked = true;
        this.orderIdPicked = slot.orderItemId;
      }
    },
    hoverSlot(availability, slot, lastSelectedSlotNo) {
      if (!slot.orderItemId && !(slot.bookingPlatform === 'GPS' && slot.status === 'GPS_BOOKING')) {
        if (availability.firstSelectedSlotNo != -1) {
          if (lastSelectedSlotNo) { 
            this.clearHoveredSlots(availability)
            let higherValue, lowerValue;
            if (availability.firstSelectedSlotNo > lastSelectedSlotNo) {
              higherValue = availability.firstSelectedSlotNo;
              lowerValue = lastSelectedSlotNo;
            } else {
              higherValue = lastSelectedSlotNo;
              lowerValue = availability.firstSelectedSlotNo;
            }
            for (let i = lowerValue; i <= higherValue; i++) {
              availability.times[i].hovered = true;
            }
          }
        }
      }
    },
    clearHoveredSlots(availability) {
      for (let i = 0; i < 48; i++) { 
        availability.times[i].hovered = false;
      }
    },
    selectedDatesChanged(dates) {
      if(!dates || !dates.start || !dates.end) return;
      this.doctorsSelected = [];
      this.doctorsAvailabilities = [];
      let from = moment(dates.start).local().format(this.dateFormat);
      let to = moment(dates.end).local().format(this.dateFormat);
      this.loadingAvailabilities = true;
      this.getAvailabilities(from, to).then(result => {
        if (!this.areDoctorsAvailabilitiesConsistent(result, dates)) {
          this.loadingAvailabilities = false;
          this.appointmentsIssuesModal = true;
          this.unableToDisplayAppointmentSlots = true;
          return;
        }
        let slots = result.filter(a => a.appointmentList.length > 0);
        this.doctorsSelected = slots.map(a => a.doctor);
        this.doctorsAvailabilities = slots.map(a => ({
            doctor: a.doctor,
            times: this.initializeAppointmentSlots(a.appointmentList.filter(appointment => appointment.status != 'CANCELED_BY_PATIENT' && appointment.status != 'ROOT_APPOINTMENT_CANCELED')),
            firstSelectedSlotNo: -1
        }));
        this.loadingAvailabilities = false;
      })
    },
    areDoctorsAvailabilitiesConsistent(availabilities, dates) {
      let from = moment(dates.start);
      let to = moment(dates.end);
      let noOfDays = to.diff(from, 'days') + 1;
      for (let i = 0; i < availabilities.length; i ++) {
        let availability = availabilities[i];
        let noOfAppointments = availability.appointmentList.length;
        if (noOfAppointments) {
          // dummy check if appointment slots are equally divided by days between range selected
          if (noOfAppointments % noOfDays > 0) {
            console.log(1)
            return false;
          }
          // sort and group by date
          availability.appointmentList = _.orderBy(availability.appointmentList, 'datetime');
          let self = this;
          let appointmentListGroups = _.groupBy(availability.appointmentList, function(slot) {
            return moment(slot.datetime).local().format(self.dateFormat);
                                      });
          let appointmentListGroupsLength = Object.keys(appointmentListGroups).length;
          // check if there are as many dates with appointments as days between dates
          if (appointmentListGroupsLength != noOfDays) {
            console.log(2)
            return false;
          }
          let firstAppointmentListGroup;
          for (let date in appointmentListGroups) {
            if (Object.prototype.hasOwnProperty.call(appointmentListGroups, date)) {
              if (!firstAppointmentListGroup) {
                // capture first date so we can compare them with the others
                firstAppointmentListGroup = appointmentListGroups[date];
              } else {
                let currentAppointmentListGroup = appointmentListGroups[date];
                // check if there aren't the same number of appointments in both dates
                if (currentAppointmentListGroup.length !== firstAppointmentListGroup.length) {
                  return false;
                }
                for (var j = 0; j < firstAppointmentListGroup.length; j++) {
                  let slot1 = firstAppointmentListGroup[j];
                  let slot2 = currentAppointmentListGroup[j];
                  // check if the hours match(this works because we already sorted by date above)
                  if (moment(slot1.datetime).local().format(this.timeFormat) !== moment(slot2.datetime).local().format(this.timeFormat)) {
                    console.log(4)
                    return false;
                  }
                  // check if statuses match
                  if (slot1.status !== slot2.status) {
                    console.log(5)
                    return false;
                  }
                }
              }
            }
          }
        }
      }
      return true;
    },
    resetDates() {
      this.selectedDates = { start: null, end: null };
    },
    async confirmChanges() {
      this.loading = true;
      let from = moment(this.selectedDates.start).local().format(this.dateFormat);
      let to = moment(this.selectedDates.end).local().format(this.dateFormat);
      let availabilities = [];
      this.doctorsAvailabilities.forEach(a => {
        let toAdd = this.addAvailabilities(a, from, to);
        if (toAdd) {
          availabilities.push(toAdd);
        }
        let toRemove = this.removeAvailabilities(a, from, to);
        if (toRemove) {
          availabilities.push(toRemove);
        }
      })
      if (availabilities.length) {
        await this.updateAvailabilities(availabilities);
      } else {
        this.appointmentsIssuesModal = true;
        this.noDoctorAvailabilitiesToUpdate = true;
      }
      this.loading = false;
    },
    addAvailabilities(a, from, to) {
      let first, last;
      let timeRanges = [];
      a.times.forEach((slot, i) => {
        if (!first && slot.active && !slot.orderItemId && !slot.scheduled && slot.bookingPlatform !== 'GPS' && slot.status !== 'LINKED') {
          first = slot;
        }
        if (first) {
          if (slot.active && !slot.scheduled && !slot.orderItemId && slot.bookingPlatform !== 'GPS' && slot.status !== 'LINKED') {
            last = slot;
          }
          if (last && slot.status !== 'LINKED' && (slot.scheduled || slot.orderItemId || !slot.active) || i === a.times.length - 1 ) {
            timeRanges.push({ "from": first.time, "to": last.time });
            first = undefined;
            last = undefined;
          }
        }
      })
      if (timeRanges.length > 0) {
        let availabilityToAdd = {
          "action" : "ADD",
          "doctorIdList" : [ a.doctor.id ],
          "dateRange": { "from": from, "to": to },
          "timeRangeList" : timeRanges
        }
        return availabilityToAdd;
      }
    },
    removeAvailabilities(a, from, to) {
      let first, last;
      let timeRanges = [];
      a.times.forEach((slot, i) => {
        if (!first && slot.scheduled && !slot.active && !slot.orderItemId && slot.bookingPlatform !== 'GPS') {
          first = slot;
        }
        if (first) {
          if (!slot.active && slot.scheduled && !slot.orderItemId && slot.bookingPlatform !== 'GPS') {
            last = slot;
          }
          if ((last && !slot.scheduled) || i === a.times.length - 1 ) {
            timeRanges.push({ "from": first.time, "to": last.time });
            first = undefined;
            last = undefined;
          }
        }
      })
      if (timeRanges.length > 0) {
        let availabilityToDelete = {
          "action" : "DELETE",
          "doctorIdList" : [ a.doctor.id ],
          "dateRange": { "from": from, "to": to },
          "timeRangeList" : timeRanges
        }
        return availabilityToDelete;
      }
    },
    async updateAvailabilities(availabilities) {
      this.loading = true;
      await this.$doctorService.updateAvailabilities(availabilities).then(() => {
        this.loading = false;
        this.selectedDatesChanged(this.selectedDates);
        this.$awn.success('Availabilities updated successfully');
      }, (error) => {
        this.loading = false;
        this.$awn.alert('Unable to update availabilities', error);
      })
    }
  },
  watch: {
    selectedDates: function(selectedDates) {
      this.selectedDatesChanged(selectedDates);
    }
  },
}
</script>

<style lang="scss">

.multiselect__tag {
  background: #550385;

  .multiselect__tag-icon {
    background: #550385;

    &:after{
      color: #b3b3b3;
    }
    
    &:hover {
      &:after{
        color: #fff !important;
      }
    }
  }
}

// .multiselect__tags {
//   border: 1px solid rgb(218, 218, 218);
// }
// .vc-day-background, .vc-day-content, .multiselect__tags {
//   border-radius: 0 !important;
// }
// .vc-highlights .vc-highlight.vc-rounded-full {
//   background-color: #25924b !important;
//   color: white !important;
// }
// .vc-bar {
//   background-color: #25924b !important;
// }
.justified {
  text-align: justify;
}

.doctor-option-selected {
  background: #550385;
  position: relative;
  display: inline-block;
  padding: 8px 4px 8px 8px;
  border-radius: 1px;
  color: #fff;
  line-height: 1;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  box-sizing: border-box;
  margin-right: 2px;
}
.doctor-option-selected span:first-child:hover {
  background: #550385;
}
.doctor-option-selected span:first-child {
  padding: 4px 26px 4px 10px;
  font-size: 16px;
}

.doctor-option-selected-remove:hover {
  background: #6d4485;
}
.doctor-option-selected-remove {
  font-size: 18px;
  cursor: pointer;
  margin-left: 0;
  position: absolute;
  padding: 4px 21px 4px 7px;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  color: #fff;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all .2s ease;
  box-sizing: border-box;
}

.doctor-option {
  min-width: 40px;
  text-align: right;
  display: inline-block;
}

.appointment-slot {
  border: 0;
  cursor: pointer;
  padding-top: 5px;
  margin-bottom: 0;
  width: 100%;
  min-height: 60px;
  line-height: 60px;
  text-align: center;
  padding: 4px !important;
  background-color: #fbfbfb;
  transition: background-color .5s ease-out;
}

.checkboxes input[type=checkbox] {
  display: none;
}

.appointment-slot-booked {
  background-color: #3e515b !important;
  color: #fff !important;
}
.appointment-slot-scheduled {
  background-color: #f78d8c;
}
.appointment-slot-canceled {
  background-color: darkorange !important ;
}
.appointment-slot-remove-scheduled {
  background-color: #ffc9c9;
}
.appointment-slot:hover, .appointment-slot-hovered-inactive {
  background-color: #b493c9;;
}
.appointment-slot-hovered-active, .appointment-slot-hovered-active:hover {
  background-color: #f78d8c;
  color: #fff;
}
.appointment-slot-selected {
  background-color: #6d4485;
  color: white;
}
.appointment-slot-gps {
  background-color: #009345 !important;
  color: white;
}
.appointment-slot-free {
  border: 1px dashed #536c79;
}
.appointment-slot-container {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
  border: 1px solid rgb(218, 218, 218);
}
.appointment-slot-row {
  flex: space-between;
}
.appointments-tab .nav-tabs .nav-link:hover {
  background-color: #550385 !important;
  color: white;
}
/* Extra Small Devices, Phones */  
@media only screen and (min-width : 480px) {
  .appointment-slot-container > * {
    flex: 1 calc(100%/6);
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  .appointment-slot-container > * {
    flex: 1 calc(100%/8);
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  .appointment-slot-container > * {
    flex: 1 calc(100%/12);
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  .appointment-slot-container > * {
    flex: 1 calc(100%/16);
  }
}
.loader,
.loader:before,
.loader:after {
  background: #550385;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #550385;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
.color-info {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  align-items: center;
  /* grid-auto-flow: row; */
  grid-auto-columns: minmax(180px, 1fr);
  color: #536c79;
}
.square {
  width: 20px!important;
  height: 20px!important;
  margin-right: 5px;
  display: inline-block;
  vertical-align: bottom;
}

</style>
