<template>
  <b-card>
    <div slot="header">
      <i class='fa fa-align-justify'></i> {{title}}
      <b-button class="pull-right" variant="primary" @click="$router.push('/patient/create-patient')">Create Reservation</b-button>
    </div>
    <b-row>
      <b-col sm="12" md="6">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
      </b-col>
<!--      Search functionality-->
<!--      <b-col sm="12" md="6" >-->
<!--        <b-input-group size="md"  >-->

<!--          <vue-bootstrap-typeahead-->
<!--              :data="orders"-->
<!--              v-model="searchQuery"-->
<!--              placeholder="Type patient name"-->
<!--              @hit="selectedQuery = $event"-->
<!--          />-->

<!--          <vue-bootstrap-typeahead-->
<!--              :data="orders"-->
<!--              v-model="emailSearchQuery"-->
<!--              placeholder="Type patient email"-->
<!--              @hit="selectedEmailQuery = $event"-->
<!--          />-->

<!--          <b-input-group-append>-->
<!--            <b-button block variant="primary" class="btn&#45;&#45;2" :disabled="!(searchQuery || emailSearchQuery)" v-on:click="searchButton()"><i class="fa fa-search"></i></b-button>-->
<!--          </b-input-group-append>-->
<!--        </b-input-group>-->
<!--      </b-col>-->
    </b-row>
    <div v-if="!isLoadingDate">
      <b-table hover :items="displayedOrders" :fields="columnNames" outlined show-empty responsive thead-tr-class="custom-table-header" class="custom-table">
      </b-table>
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
      <br>
    </div>
    <div v-else>
      <div class="loadingOrders">Loading...</div>
    </div>
  </b-card>

</template>

<script>

import OrderEventBus from "@/EventBus";
import moment from "moment/moment";
export default {
  components: {},
  data: function () {
    return {
      title: 'Call to Book Reservations',
      orders: [],
      columnNames: [
        {
          key: 'patientId',
          label: 'Patient ID'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'patientName',
          label: 'Name'
        },
        {
          key: 'bookingDate',
          label: 'Booking Date'
        },
        {
          key: 'bookingTime',
          label: 'Booking Time'
        }
      ],
      clickEvent: 'open-order-click',
      newOrderSound: new Audio('/static/message.mp3'),
      timer: '',
      searchAction: "search-order-click",
      filterAction: "All-order-filter",
      filterData: {
        "orderId": ""
      },
      isLoadingDate: false,
      perPage: 10,
      currentPage: 1,
      searchQuery: '',
      selectedQuery: '',
      emailSearchQuery: '',
      selectedEmailQuery: ''
    }
  },
  created () {
    this.getTempBookings()
  },
  computed: {
    totalRows() {
      return this.orders.length; // Total number of rows in the table
    },
    displayedOrders() {
      const startIndex = (this.currentPage - 1) * this.perPage; // Calculate start index of current page
      const endIndex = startIndex + this.perPage; // Calculate end index of current page
      return this.orders.slice(startIndex, endIndex); // Slice the array to get orders for the current page
    },
    // filteredOrders() {
    //   let filteredData = this.orders;
    //
    //   // Filter by patient name if searchQuery is provided
    //   if (this.searchQuery) {
    //     const searchQueryLowerCase = this.searchQuery.toLowerCase();
    //     filteredData = filteredData.filter(order => order.patientName.toLowerCase().includes(searchQueryLowerCase));
    //   }
    //
    //   // Filter by patient email or ID if emailSearchQuery is provided
    //   if (this.emailSearchQuery) {
    //     const emailSearchQueryLowerCase = this.emailSearchQuery.toLowerCase();
    //     filteredData = filteredData.filter(order => order.email.toLowerCase().includes(emailSearchQueryLowerCase));
    //   }
    //
    //   return filteredData;
    // }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // You can use 'auto' or 'smooth' for the scrolling behavior
      })
    },
    formatDate(date) {
      const datePart = date.split('[')[0]; // example datepart: "2024-03-07T06:45Z"
      const convertedDate = moment(datePart).format('DD/MM/YY');
      return convertedDate;
    },
    formatTime(date) {
      const datePart = date.split('[')[0]; // example datepart: "2024-03-07T06:45Z"
      const convertedTime = moment(datePart).format('hh:mm A');
      return convertedTime;
    },
    getTempBookings () {
      this.isLoadingDate = true;
      this.$orderService.getAllTempBookings().then(orders => {
        orders.forEach(order => {
          this.orders.push({
            patientId: order.patientId,
            email: order.email,
            patientName: `${order.firstName} ${order.lastName ? order.lastName : ""}`,
            bookingDate: this.formatDate(order.dateTime),
            bookingTime: this.formatTime(order.dateTime)
          })
        });
        this.orders.reverse();
        this.isLoadingDate = false;
      }).catch(error => {
        this.isLoadingDate = false;
        console.error(error);
        this.$awn.alert('There was an error getting orders');
      });
    },
    // searchButton(){
    //   if (this.searchQuery) {
    //     this.selectedQuery = this.searchQuery;
    //     this.filteredOrders;
    //   } else if (this.emailSearchQuery) {
    //     this.selectedEmailQuery = this.emailSearchQuery;
    //     this.filteredOrders;
    //   }
    // }
  },
  beforeDestroy () {
    clearTimeout(this.timer);
    OrderEventBus.$off();
  }
}

</script>

<style scoped lang="scss">

</style>