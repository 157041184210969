<template>
    <invoice
        v-if="lineItems.length > 0"
        :elementId="'prescription-invoice'"
        :invoiceNumber="`${order.id}-001`"
        :invoiceDate="invoiceDate"
        :customerName="order.patient.displayName"
        :address="address"
        :customerEmail="order.patient.email"
        :lineItems="lineItems"
        :subTotal="subTotal"
        :discount="discount"
        :prescriptionFee="prescriptionFee"
        :baseDelivery="baseDelivery"
        :deliveryVat="deliveryVat"
        :totalAmount="total"
        :paid="!!invoiceDate"
        :pharmacy="prescription.pharmacy.name"
        :gphcNumber="prescription.pharmacy.gphc"
    />
</template>

<script>
import moment from "moment";
import Invoice from "../../components/Invoice.vue";

export default {
    name: "prescription-invoice",
    components: {
        Invoice,
    },
    data: function() {
        return {
            order: {},
            address: {},
            prescription: {},
            subTotal: 0,
            baseDelivery: 0,
            deliveryVat: 0,
            prescriptionFee: 0,
            total: 0,
            invoiceDate: 0,
            lineItems: [],
        };
    },
    mounted: function() {
        this.getOrderDetails();
    },
    watch: {
        prescription(newValue) {
            if (newValue.id) {
                this.getPrescriptionInvoice();
            }
        },
    },
    methods: {
        getOrderDetails() {
            this.$orderService
                .getOrder(this.$route.params.orderId)
                .then((order) => {
                    this.order = order;
                    this.address = { ...order.patient.address };

                    this.$orderService
                        .getPrescription(this.$route.params.orderId)
                        .then((prescription) => {
                            this.prescription = prescription;
                        });
                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
                        console.log("a", error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.displayNotification("error", error.message);
                    }
                });
        },
        getPrescriptionInvoice() {
            this.$orderService
                .getInvoiceItems(this.$route.params.orderId)
                .then((result) => {
                    if (result?.length > 0) {
                        const subTotal =
                            result.find(
                                (data) =>
                                    data.phase === "PRESCRIPTION" &&
                                    data.type === "PRESCRIPTION_DRUG"
                            )?.price || 0;
                        const baseDelivery =
                            result.find(
                                (data) =>
                                    data.phase === "PRESCRIPTION" &&
                                    data.type === "BASE_DELIVERY"
                            )?.price || 0;
                        const deliveryVat =
                            result.find(
                                (data) =>
                                    data.phase === "PRESCRIPTION" &&
                                    data.type === "DELIVERY_VAT"
                            )?.price || 0;
                        const total =
                            result.find(
                                (data) =>
                                    data.phase === "PRESCRIPTION" &&
                                    data.type === "TOTAL"
                            )?.price || 0;
                        const prescriptionFee =
                            result.find(
                                (data) =>
                                    data.phase === "PRESCRIPTION" &&
                                    data.type === "PRESCRIPTION"
                            )?.price || 0;

                        const invoiceDate = result.find(
                            (data) =>
                                data.phase === "PRESCRIPTION" &&
                                data.type === "TOTAL"
                        )?.paymentDate;

                        const formattedInvoiceDate = invoiceDate
                            ? moment(invoiceDate).format("MMMM Do, YYYY")
                            : null;

                        let lineItems = [];

                        lineItems = this.prescription?.medication.map(
                            (data) => ({
                                desc: {
                                    title: `${data.formulary.productName} - ${data.formulary.quantity}`,
                                    items: {
                                        medication: data.formulary.productName,
                                        adminRoute:
                                            data.formulary.administrationRoute,
                                        quantity: data.quantity,
                                        medicineProfile:
                                            data.formulary.medicineProfile,
                                        strain: data.formulary.strain,
                                    },
                                },
                                qty: data.quantity,
                                unitPrice: data.formulary.salePrice,
                                vat: 0,
                                net: data.charge,
                            })
                        );

                        this.subTotal = subTotal;
                        this.baseDelivery = baseDelivery;
                        this.deliveryVat = deliveryVat;
                        this.prescriptionFee = prescriptionFee;
                        this.total = total;
                        this.invoiceDate = formattedInvoiceDate;
                        this.lineItems = lineItems;
                    }
                });
        },
    },
};
</script>
