<template>
  <b-card>

    <div slot="header">
      <i class='fa fa-align-justify'></i>Form Formulary
    </div>

    <b-row class="form-group">
      <b-col sm="12" md="6">
        <b-pagination align="left" size="md" :total-rows="formulary.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>
      </b-col>
      <b-col sm="12" md="4" offset-md="2">
        <b-input-group size="md">
          <input type="search" class="form-control" id="searchQuery" v-model="searchQuery" placeholder="Id, name, formulation and strength" @search="getFormulary()">
          <b-input-group-append>
            <b-button block variant="primary" class="btn--2" :disabled="!searchQuery" v-on:click="getFormulary()"><i class="fa fa-search"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <b-table :items="formulary.content" :fields="columnNames" :current-page="1" :per-page="perPage" hover outlined show-empty responsive>
      <template v-slot:cell(name)="row">
        <span>{{row.value}}</span>
        <!-- <span v-if="!row.item.toBeUpdated">{{row.value}}</span>
        <input v-if="row.item.toBeUpdated" type="text" v-model="row.item.name"/> -->
      </template>
      <template v-slot:cell(formulation)="row">
        <span>{{row.value}}</span>
        <!-- <span v-if="!row.item.toBeUpdated">{{row.value}}</span>
        <input v-if="row.item.toBeUpdated" type="text" v-model="row.item.formulation"/> -->
      </template>
      <template v-slot:cell(strength)="row">
        <span>{{row.value}}</span>
        <!-- <span v-if="!row.item.toBeUpdated">{{row.value}}</span>
        <input v-if="row.item.toBeUpdated" type="text" v-model="row.item.strength"/> -->
      </template>
      <template v-slot:cell(quantity)="row">
        <span>{{row.item.tablets}}</span>
        <!-- <span v-if="!row.item.toBeUpdated">{{row.item.tablets}}</span>
        <input v-if="row.item.toBeUpdated" type="number" v-model="row.item.tablets"/> -->
      </template>
      <template v-slot:cell(unitPrice)="row">
        <span v-if="!row.item.toBeUpdated">£{{row.value}}</span>
        <input v-if="row.item.toBeUpdated" type="number" v-model="row.item.unitPrice"/>
      </template>
      <!-- <template v-slot:cell(active)="row">
        <b-form-checkbox block variant="primary" v-model="row.item.active" :disabled="!row.item.toBeUpdated" />
      </template> -->
      <!-- <template v-slot:head(actions)>
        <b-button variant="primary" @click="openAddMedicationModal()">&nbsp;&nbsp;&nbsp;Add Formulary&nbsp;&nbsp;&nbsp;</b-button>
      </template> -->
      <template v-slot:cell(actions)="data">
        <!-- <b-button v-if="!data.item.toBeUpdated && !data.item.canEdit" variant="primary" @click="toggleActive(data.item)">
          {{data.item.active ? 'Disable' : '&nbsp;Enable&nbsp;' }}
        </b-button> -->
        <!-- <b-button v-if="!data.item.toBeUpdated && data.item.canEdit" variant="primary" @click="editItem(data.item)">
          Update
        </b-button> -->
        <b-button v-if="!data.item.toBeUpdated" variant="primary" @click="editItem(data.item)">
          Update Unit Price
        </b-button>
        <b-button v-if="data.item.toBeUpdated" variant="warning" @click="confirmItemUpdate(data.item)">
          Confirm
        </b-button>
        <b-button v-if="data.item._rowVariant" variant="danger" @click="resetToOriginalState(data.item)">
          &nbsp;Reset
        </b-button>
        <!-- <b-button v-if="!data.item._rowVariant" variant="danger" @click="openDeleteMedicationModal(data.item)">
          Delete
        </b-button> -->
      </template>
    </b-table>

    <b-row class="form-group">
      <b-col sm="12" md="6">
        <b-pagination align="left" size="md" :total-rows="formulary.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>
      </b-col>
      <b-col sm="12" md="4" offset-md="2">
        <b-button variant="primary" block @click="openConfirmAllChangesModal()" :disabled="listOfMedicationsToUpdate.length < 1"> Confirm </b-button>
      </b-col>
    </b-row>

    <br>

    <!-- <b-modal v-model="addMedicationModal" centered size="lg" hide-header ok-title="Add Formulary" ok-variant="primary" @ok="confirmAllChanges(true)">
      <div class="table-responsive reduced-margin-table">
        <table class="table table-striped table-nowrap">
          <thead>
            <tr>
              <th>Name</th>
              <th>Formulation</th>
              <th>Strength</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Active</th>
              <th v-if="listOfMedicationsToAdd.length>1"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(med,index) in listOfMedicationsToAdd" :key="index">
              <td><input type="text" v-model="med.name"/> </td>
              <td><input type="text" v-model="med.formulation"/> </td>
              <td><input type="text" v-model="med.strength"/> </td>
              <td><input type="number" v-model="med.tablets"/> </td>
              <td><input type="number" step="2" v-model="med.unitPrice"/> </td>
              <td><b-form-checkbox block variant="primary" v-model="med.active"/></td>
              <td v-if="listOfMedicationsToAdd.length>1"><b-button variant="danger" @click="deleteMedication(index)">Remove</b-button></td>
            </tr>
          </tbody>
          <tfoot align="center">
            <tr><td colspan="6">
            <i class="huge-icon fa fa-plus-circle" @click="addMedication()"></i>
            </td></tr>
          </tfoot>
        </table>
      </div>
    </b-modal> -->

    <b-modal v-if="medicationToDelete" v-model="deleteMedicationModal" hide-footer centered size="sm" title="Confirm deletion">
      <div>
        <b-row>
          <b-col cols="5"><strong><label label-for="medicationId">ID</label></strong></b-col>
          <b-col><span name="medicationId">{{medicationToDelete.id}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="medicationName">Name</label></strong></b-col>
          <b-col><span name="medicationName">{{medicationToDelete.name}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="medicationFormulation">Formulation</label></strong></b-col>
          <b-col><span name="medicationFormulation">{{medicationToDelete.formulation}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="medicationStrength">Strength</label></strong></b-col>
          <b-col><span name="medicationStrength">{{medicationToDelete.strength}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="medicationTablets">Quantity</label></strong></b-col>
          <b-col><span name="medicationTablets">{{medicationToDelete.tablets}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="medicationUnitPrice">Unit Price</label></strong></b-col>
          <b-col><span name="medicationUnitPrice">£{{medicationToDelete.unitPrice}}</span></b-col>
        </b-row>
        <!-- <b-row>
          <b-col cols="5"><strong><label label-for="medicationActive">Active</label></strong></b-col>
          <b-col><span name="medicationActive">{{medicationToDelete.active}}</span></b-col>
        </b-row> -->
      </div>
      <b-alert variant="danger" show>
        <p><strong>Please note</strong></p>
        <div v-if="!medicationToDelete.canEdit">
          This medication has already been used in a prescription, therefore you may only disable or enable it.
        </div>
        <div v-if="medicationToDelete.canEdit">
          This medication will be deleted as soon as you click on the button to delete.
        </div>
      </b-alert>
      <hr/>
      <div>
        <b-button variant="danger" v-if="medicationToDelete.canEdit" @click="confirmMedicationDeletion()">
          Delete medication
        </b-button>
        <b-button variant="danger" v-if="!medicationToDelete.canEdit" @click="toggleActive(medicationToDelete)">
          {{medicationToDelete.active ? 'Disable' : '&nbsp;Enable&nbsp;'}}
        </b-button>
        <b-button variant="secondary" @click="deleteMedicationModal = false">Cancel</b-button>
      </div>
    </b-modal>

    <b-modal v-model="confirmAllChangesModal" centered size="lg" title="Confirm formulary change" ok-title="Confirm" ok-variant="primary" @ok="confirmAllChanges(false)">
      <div class="table-responsive">
        <table class="table table-striped table-nowrap">
          <thead>
            <tr>
              <th>ID</th>
              <th colspan="2">Name</th>
              <th colspan="2">Formulation</th>
              <th colspan="2">Strength</th>
              <th colspan="2">Quantity</th>
              <th colspan="2">Unit Price</th>
              <!-- <th colspan="2">Active</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="med in listOfMedicationsToUpdate" :key="med.id" @click="searchForThisMedication(med.id)">
              <td>{{ med.id }}</td>
              <td v-bind:class="{ 'isDifferentNew': isDifferent(med, 'name') }">{{ med.name }}</td>
              <td v-bind:class="{ 'isDifferentOld': isDifferent(med, 'name') }">{{ isDifferent(med, 'name') ? med.originalName : '' }}</td>

              <td v-bind:class="{ 'isDifferentNew': isDifferent(med, 'formulation') }">{{ med.formulation }}</td>
              <td v-bind:class="{ 'isDifferentOld': isDifferent(med, 'formulation') }">{{ isDifferent(med, 'formulation') ? med.originalFormulation : '' }}</td>

              <td v-bind:class="{ 'isDifferentNew': isDifferent(med, 'strength') }">{{ med.strength }}</td>
              <td v-bind:class="{ 'isDifferentOld': isDifferent(med, 'strength') }">{{ isDifferent(med, 'strength') ? med.originalStrength : '' }}</td>

              <td v-bind:class="{ 'isDifferentNew': isDifferent(med, 'tablets') }">{{ med.tablets }}</td> 
              <td v-bind:class="{ 'isDifferentOld': isDifferent(med, 'tablets') }">{{ isDifferent(med, 'tablets') ? med.originalTablets : '' }}</td>

              <td v-bind:class="{ 'isDifferentNew': isDifferent(med, 'unitPrice') }">{{ "£" + med.unitPrice }}</td>
              <td v-bind:class="{ 'isDifferentOld': isDifferent(med, 'unitPrice') }">{{ isDifferent(med, 'unitPrice') ? "£" + med.originalUnitPrice : '' }}</td>

              <!-- <td v-bind:class="{ 'isDifferentNew': isDifferent(med, 'active') }">{{ med.active ? 'Yes' : 'No' }}</td>
              <td v-bind:class="{ 'isDifferentOld': isDifferent(med, 'active') }">{{ isDifferent(med, 'active') ? med.originalActive ? 'Yes' : 'No' : '' }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>

  </b-card>
</template>

<script>

import moment from 'moment';

export default {
  data: function () {
    return {
      searchQuery: '',
      formulary: [],
      // columnNames: ['id', 'name', 'formulation', 'strength', 'quantity', { 'key': 'unitPrice', 'class': 'text-center' }, 'active' , 'actions'],
      columnNames: ['id', 'name', 'formulation', 'strength', 'quantity', { 'key': 'unitPrice', 'class': 'text-center' }, 'actions'],
      currentPage: 1,
      perPage: 10,
      confirmAllChangesModal: false,
      listOfMedicationsToUpdate: [],
      addMedicationModal: false,
      listOfMedicationsToAdd: [],
      deleteMedicationModal: false,
      medicationToDelete: {}
    }
  },
  filters: {
    dateTime: function (date) {
      return moment(date).local().format('HH:mm - DD/MM/YY');
    }
  },
  created () {
    this.getFormulary();
  },
  watch: {
    currentPage: function () {
      this.getFormulary();
    }
  },
  methods: {
    getFormulary () {
      this.$formFormularyService.search(this.searchQuery, this.currentPage - 1, this.perPage).then(formulary => {
        this.setupFormulary(formulary);
      }, error => {
        this.$awn.alert(error, 'An error occurred');
      })
    },
    setupFormulary(formulary) {
      formulary.content.forEach(element => {
        this.$set(element, 'originalName', element.name);
        this.$set(element, 'originalFormulation', element.formulation);
        this.$set(element, 'originalStrength', element.strength);
        this.$set(element, 'originalTablets', element.tablets);
        this.$set(element, 'originalUnitPrice', element.unitPrice);
        this.$set(element, 'originalActive', element.active);
        this.listOfMedicationsToUpdate.forEach(med => {
          if (element.id == med.id) {
            element._rowVariant = 'warning';
            element.name = med.name;
            element.formulation = med.formulation;
            element.strength = med.strength;
            element.tablets = med.tablets;
            element.unitPrice = med.unitPrice;
            element.active = med.active;
          }
        });
      });
      this.formulary = formulary;
    },
    editItem (item) {
      this.$set(item, 'toBeUpdated', true);
    },
    resetToOriginalState(medication) {
      medication.active = medication.originalActive;
      medication.name = medication.originalName;
      medication.formulation = medication.originalFormulation;
      medication.strength = medication.originalStrength;
      medication.tablets = medication.originalTablets;
      medication.unitPrice = medication.originalUnitPrice;
      this.$delete(medication, 'toBeUpdated');
      this.$delete(medication, '_rowVariant');
      this.listOfMedicationsToUpdate = this.listOfMedicationsToUpdate.filter(element => element.id !== medication.id);
    },
    confirmItemUpdate (med) {
      this.$delete(med, 'toBeUpdated');
      if (med.originalName != med.name || 
          med.originalFormulation != med.formulation || 
          med.originalStrength != med.strength ||
          med.originalTablets != med.tablets ||
          med.originalUnitPrice != med.unitPrice || 
          med.originalActive != med.active) {
        if (this.listOfMedicationsToUpdate.length === 0 || !this.listOfMedicationsToUpdate.some(element => element.id === med.id)) {
          this.listOfMedicationsToUpdate.push(med);
        }
        this.$set(med,'_rowVariant', 'warning');
      } else {
        this.$delete(med, '_rowVariant');
        this.listOfMedicationsToUpdate = this.listOfMedicationsToUpdate.filter(element => element.id !== med.id);
      }
    },
    openDeleteMedicationModal(medication) {
      this.deleteMedicationModal = true;
      this.medicationToDelete = medication;
    },
    toggleActive (med) {
      med.active = !med.active;
      this.confirmItemUpdate(med);
      this.deleteMedicationModal = false;
    },
    searchForThisMedication(medicationId) {
      this.confirmAllChangesModal = false;
      this.searchQuery = medicationId;
      this.getFormulary();
    },
    isNumber(event) {
      var iKeyCode = (event.which) ? event.which : event.keyCode
      if (iKeyCode != 46 && iKeyCode > 31 && (iKeyCode < 48 || iKeyCode > 57)) {
          return false;
      }
      return true;
    },
    isNumberOrLetter(event) {
      const pattern = /^[a-z0-9]+$/i;
      let inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        event.preventDefault();
      }
    },
    isDifferent(element, property) {
      let originalProperty = "original" + property.replace(/^\w/, prop => prop.toUpperCase());
      if (element[property] != element[originalProperty]) {
        return true;
      } 
      return false;
    },
    openConfirmAllChangesModal() {
      this.confirmAllChangesModal = true;
    },
    confirmAllChanges(create) {
      if (create) {
        this.finalList = this.listOfMedicationsToAdd;
      } else {
        this.finalList = this.listOfMedicationsToUpdate;
      }
      this.$formFormularyService.updateFormulary(this.finalList).then(() => {
        this.$awn.success('Formulary update', 'Formulary updated successfully');
        if (create) {
          this.listOfMedicationsToAdd = [];
          this.addMedicationModal = false;
        } else {
          this.listOfMedicationsToUpdate = [];
          this.confirmAllChangesModal = false;
        }
        this.getFormulary();
        this.currentPage = 0;
      },(error) =>{ 
        if (create) {
          this.confirmAllChangesModal = false;
        } else {
          this.addMedicationModal = false;
        }
        this.$awn.alert('Error', error);
        this.getFormulary();
        this.currentPage = 0;
      });
    },
    confirmMedicationDeletion () {
      this.$formFormularyService.deleteFormulary(this.medicationToDelete.id).then((result) => {
        console.log(result);
        this.$awn.success('Medication with ID ' + this.medicationToDelete.id + ' deleted successfully', 'Deletion successful');
        this.getFormulary();
      }, (error) => {
        console.error(error);
        this.$awn.alert(error, 'Error deleting');
      })
      this.deleteMedicationModal = false;
    },
    openAddMedicationModal() {
      this.addMedicationModal = true;
      this.addMedication();
    },
    addMedication() {
      this.listOfMedicationsToAdd.push({ name: '', formulation: '', strength: '', tablets:0, unitPrice: 0.00, active: true});
    },
    deleteMedication(index){
      if (this.listOfMedicationsToAdd.length>1){
        this.listOfMedicationsToAdd.splice(index,1)
      }
    }
  }
}
</script>

<style lang="scss">
.huge-icon {
  font-size:30px;
  color: #550385;
  cursor: pointer;
}
.huge-icon:hover {
  color: #44006b;
}
.reduced-margin-table {
  margin-bottom: -20px;
}
.table-nowrap th, .table-nowrap td {
  white-space: nowrap;
}
.isDifferentNew {
  color: #009344;
  font-weight: bold;
}
.isDifferentOld {
  color: tomato;
  text-decoration: line-through; 
}
</style>

