<script>
const FileService = {};

FileService.install = function(Vue) {
  var fileService = new Object();

  Object.defineProperty(Vue.prototype, "$fileService", { value: fileService });

  fileService.uploadFile = function(file, path) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("path", path);
    var url = "/api/admin/files/upload";
    return Vue.axios.post(url, formData).catch((error) => {
      this.handleError(error);
      return Promise.reject(error);
    });
  };

  fileService.documentUpload = function(file, action, category, patientId) {
    const formData = new FormData();
    formData.append("files", file);
    formData.append("action", action);
    formData.append("category", category);
    var url = `/api/admin/patient/document-upload?patientId=${patientId}`;
    return Vue.axios.post(url, formData).catch((error) => {
      this.handleError(error);
      return Promise.reject(error);
    });
  };
};

export default FileService;
</script>
