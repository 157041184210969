<template>
    <div>
        <b>
            <b-row :no-gutters="true">
                <b-col class="pr-3">Question</b-col>
                <b-col cols="1">Answer</b-col>
                <b-col cols="4" class="pl-3">Description</b-col>
            </b-row>
        </b>
        <template v-for="key in mdtProforma">
            <b-row
                :key="key"
                v-if="key !== 'signOff'"
                :no-gutters="true"
                class="mb-3"
            >
                <b-col class="pr-3">{{ mdtNote[key].question }}</b-col>
                <template v-if="isAnswerBoolean(key)">
                    <b-col cols="1">
                        <span v-if="mdtNote[key].answer" class="pill yes">Yes</span>
                        <span v-else class="pill no">No</span>
                    </b-col>

                    <b-col v-if="mdtNote[key].answer" cols="4" class="pl-3"
                        >-</b-col
                    >
                    <b-col v-else cols="4" class="pl-3">
                        <span v-if="!!mdtNote.reason">{{
                            mdtNote.reason.answer
                        }}</span>
                    </b-col>
                </template>

                <template v-else>
                    <b-col cols="1">-</b-col>
                    <b-col cols="4" class="pl-3">{{
                        mdtNote[key].answer
                    }}</b-col>
                </template>
            </b-row>
        </template>
    </div>
</template>

<script>
import { MDT_PROFORMA } from "../util/questionnaires.js";

export default {
    data() {
        return {
            mdtProforma: undefined,
        };
    },
    props: {
        mdtNote: {
            type: Object,
            required: true,
        },
    },
    created() {
        this.mdtProforma = Object.keys(MDT_PROFORMA).filter(
            (key) => key in this.mdtNote
        );
    },
    methods: {
        isAnswerBoolean(key) {
            return typeof this.mdtNote[key].answer === "boolean";
        },
    },
};
</script>

<style lang="scss" scoped>
.pill {
    width: 2rem;
    display: block;
    text-align: center;
    border-radius: 5px;

    &.yes {
        background-color: #c8a6ff;
    }

    &.no {
        background-color: #ff8f90;
    }
}
</style>
