<template>
  <div>
    <b-row>
      <b-col md="12" lg="6">
        <order-details :order="order" />
      </b-col>
      <b-col>
        <patient-details :patient="order.patient" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <prescription-details :order="order" :prescription="prescription" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <invoice-details
          :orderId="order.id"
          :consultationInvoiceItems="consultationInvoiceItems"
          :prescriptionInvoiceItems="prescriptionInvoiceItems"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import moment from 'moment'
import PatientDetails from '../../components/PatientDetails'
import OrderDetails from '../../components/OrderDetails'
import PrescriptionDetails from '../../components/PrescriptionDetails'
import InvoiceDetails from '../../components/InvoiceDetails'

export default {
  name: 'Orders',
  components: {
    PatientDetails,
    OrderDetails,
    PrescriptionDetails,
    InvoiceDetails
  },
  data: function () {
    return {
      order: {},
      prescription: {},
      consultationInvoiceItems: [],
      prescriptionInvoiceItems: []
    }
  },
  computed: {
    creationDate: function () {
      return moment(this.order.creationDate).local().format('Do MMMM YYYY, HH:mm');
    },
    dateOfBirth: function () {
      return moment(this.order.patient.dateOfBirth).format('DD-MM-YYYY');
    },
    price: function () {
      return '£' + this.order.price;
    }
  },
  mounted: function () {
    this.getOrderDetails()
  },
  methods: {
    getOrderDetails () {
      this.$orderService.getOrder(this.$route.params.orderId).then(result => {
        this.order = result;
        this.$orderService.getPrescription(this.$route.params.orderId).then(prescription => {
          if (prescription.medication) {
            prescription.medication.forEach(m => {
              for (var property in m.formulary) {
                if (Object.prototype.hasOwnProperty.call(m.formulary, property)) {
                  m[property] = m.formulary[property];
                }
              }
            })
          }
          this.prescription = prescription;
        });
        this.$orderService.getInvoiceItems(this.$route.params.orderId).then(result => {
          if (result.length > 0) {
              const consultation = result.filter(
                  (data) => data.phase === "CONSULTATION"
              );
              this.consultationInvoiceItems = consultation;
              const prescription = result.filter(
                  (data) => data.phase === "PRESCRIPTION"
              );
              this.prescriptionInvoiceItems = prescription;
          }
        })
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
          console.log(error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          this.$awn.alert(error.message);
          this.$router.push('/login')
        }
      });
    },
    dateTime(date) {
      return moment(date).local().format('Do MMMM YYYY, HH:mm');
    },
    reset() {
      this.getOrderDetails()
    }
  }
}
</script>

<style lang="scss">
  .collapsed > .when-opened, :not(.collapsed) > .when-closed {
    display: none;
  }
</style>
