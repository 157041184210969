export default {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },
    {
      title: true,
      name: "Entities",
      class: "",
      wrapper: {
        element: "",
        attributes: {},
      },
    },
    {
      name: "Admins",
      url: "/admins",
      icon: "fa fa-shield",
    },
    {
      name: "Appointments",
      url: "/appointments",
      icon: "fa fa-calendar",
    },
    {
      name: "Patients",
      url: "/patients",
      icon: "fa fa-users",
    },
    {
      name: "Orders",
      icon: "fa fa-shopping-cart",
      children: [
        {
          name: "All orders",
          url: "/orders/all",
          icon: "fa fa-tasks",
        },
        // {
        //   name: 'GPS orders',
        //   url: '/orders/gps',
        //   icon: 'fa fa-tasks'
        // },
        // {
        //   name: 'Prescriber orders',
        //   url: '/orders/prescriber',
        //   icon: 'fa fa-tasks'
        // },
        // {
        //   name: "Waiting for admin",
        //   url: "/orders/waiting-for-admin",
        //   icon: "fa fa-pause",
        // },
        // {
        //   name: 'Change delivery',
        //   url: '/orders/change-delivery',
        //   icon: 'fa fa-truck'
        // },
        {
          name: "Pharmacy Orders",
          url: "/orders/pharmacyorders",
          icon: "fa fa-ambulance",
        },
        {
          name: "Waiting for Patient",
          url: "/orders/waiting-for-patient",
          icon: "fa fa-pause",
        },
        {
          name: "Waiting for MDT",
          url: "/orders/waiting-for-mdt",
          icon: "fa fa-pause",
        },
        {
          name: "Waiting for Specialist",
          url: "/orders/waiting-for-specialist",
          icon: "fa fa-pause",
        },
        {
          name: "Waiting for Pharmacy",
          url: "/orders/waiting-for-pharmacy",
          icon: "fa fa-pause",
        },
        {
          name: "Rejected",
          url: "/orders/rejected",
          icon: "fa fa-exclamation-triangle",
        },
        {
          name: "Payment failed",
          url: "/orders/payment-failed",
          icon: "fa fa-times-circle",
        },
        {
          name: "Call to Book Reservation",
          url: "/orders/call-to-book",
          icon: "fa fa-phone",
        },
      ],
    },
    {
      name: 'Coupons',
      icon: 'fa fa-percent',
      url: '/coupons'
    },
    // {
    //   name: 'Enterprises',
    //   icon: 'fa fa-percent',
    //   url: '/enterprises'
    // },
    // {
    //   name: 'Platforms',
    //   icon: 'fa fa-percent',
    //   url: '/platforms'
    // },
    // {
    //   name: 'Subscriptions',
    //   icon: 'fa fa-credit-card',
    //   url: '/subscriptions'
    // },
    // {
    //   name: 'Packages',
    //   icon: 'fa fa-archive',
    //   url: '/packages'
    // },
    {
      name: 'GP Notes',
      icon: 'fa fa-address-card',
      url: '/gp-notes'
    },
    {
      name: "Pharmacies",
      url: "/pharmacies",
      icon: "fa fa-ambulance",
    },
    // {
    //   name: 'Prescribers',
    //   icon: 'fa fa-medkit',
    //   url: '/prescribers'
    // },
    {
      name: "Doctors",
      url: "/doctors",
      icon: "fa fa-heartbeat",
    },
    {
      name: "Formularies",
      icon: "fa fa-shopping-cart",
      children: [
        {
          name: "All Formularies",
          icon: "fa fa-medkit",
          url: "/formulary/all",
        },
        {
          name: "Upload CSV",
          url: "/formulary/upload",
          icon: "fa fa-archive",
        },
      ],
    },
    // {
    //   name: 'Form Formulary',
    //   icon: 'fa fa-medkit',
    //   url: '/form-formulary'
    // },
  ],
};
