<template>
  <b-card>

    <div slot="header">
      <i class='fa fa-align-justify'></i> {{title}}
    </div>
    
    <div v-if="orders && orders.content">
      <b-row>
        <b-col sm="12" md="8">
          <b-pagination v-if="orders.content.length" align="left" size="md" :total-rows="orders.totalElements" v-model="currentPage" :per-page="orders.size" :first-text="'First'" :prev-text="'Prev'" :next-text="'Next'" :last-text="'Last'"></b-pagination>
        </b-col>
        <b-col sm="12" md="4">
          <div style="display: flex; flex-direction: row; justify-content: flex-end;">
            <vue-bootstrap-typeahead v-if="prescriberSearch" :data="prescribers" :serializer="s => s.name" placeholder="Filter by prescriber" :minMatchingChars="1" v-model="prescriberName" @hit="prescriberChanged($event)" :style="{width: prescriberSearch? '49%' : '100%', 'margin-right': '2%'}"/>
            <div v-if="!allOrdersFilterIcon()" class="input-group" :style="{width: prescriberSearch? '49%' : '100%'}">
              <input type="search" placeholder="Enter Order ID" class="form-control" id="orderIdInput" v-model="orderIdInput" @search="orderSearch()">
            </div>
            <button v-if="allOrdersFilterIcon()" class="btn btn-sm btn-outline-primary filter-btn" @click="handleFilterButtonClick">
              <i class="fa fa-filter" aria-hidden="true"></i>
              {{ filterText }}
            </button>
          </div>
        </b-col>
      </b-row>

      <b-table hover :items="tableContent" :fields="columnNames" :current-page="1" :per-page="orders.size" outlined show-empty responsive thead-tr-class="custom-table-header" class="custom-table">
        <template slot="top-row" slot-scope="{ fields }" v-if="showFilter">
          <td v-for="field in fields" :key="field.key" class="filter-row-bg">

            <input type="search" class="form-control orderId-search" v-if="field.key == 'id'" v-model="orderIdFilterInput" :id="field.key" :placeholder="'Enter ' + field.label" @search="searchFilter()">
            
            <DateRangePickerWrapper v-if="field.key == 'creationDate'" class="w-auto" @dateRange="getDateRange" />

            <span class="pharmacy-select-parent" v-if="field.key == 'pharmacy'">
              <select v-model="selected" :id="field.key" class="form-control selectpicker w-auto" @change="handleDropdownSelection">
                <option disabled value="">Choose Pharmacy</option>
                <option v-for="pharmacy in pharmacies" :key="pharmacy.id" :value="pharmacy.id">{{ pharmacy.displayName }}</option>
              </select>
              <button v-if="selected !== ''" class="close-btn" @click="clearPharmacy()"><i class="fa fa-times" aria-hidden="true"></i></button>
            </span>

            <span class="pharmacy-select-parent" v-if="field.key == 'ConsultationType'">
              <select v-model="selectedConsultationType" :id="field.key" class="form-control selectpicker w-auto" @change="handleDropdownSelection">
                <option disabled value="">Choose consultation type</option>
                <option :key="'1'" :value="'pharmacy'">Pharmacy</option>
                <option :key="'2'" :value="'online'">Online</option>
              </select>
              <button v-if="selectedConsultationType !== ''" class="close-btn" @click="clearConsultationType()"><i class="fa fa-times" aria-hidden="true"></i></button>
            </span>

          </td>
        </template>
        
        <template #head(isRepeatOrder)>Order Type</template>
        <template v-slot:cell(status)="row"><span class="smaller">{{row.value | orderStatus}}</span></template>
        <template v-slot:cell(name)="row">
          <span v-if="row.item.type !== 'BOOKING'">{{row.value}}</span>
          <span v-else>{{row.item.consultationDuration === 'IN_PERSON_MINUTE_00' ? "Pharmacy ": ""}} Consultation {{row.item.appointment ? row.item.appointment.datetime : consultationDatetime | datetime }}</span></template>
        <template v-slot:cell(patient)="row">
          <router-link :to="{path: '/patient/' + row.value.id }">
              {{row.value.id}} - {{row.value.displayName}} 
          </router-link>
        </template>
        <template v-slot:cell(isRepeatOrder)="row">
          {{ row.item.isRepeatOrder  ? "Repeat order" : "Consultation" }}
        </template>
        <template v-slot:cell(platform)="row">{{row.value}} </template>
        <template v-slot:cell(ConsultationType)="row">{{row.value}} </template>
        <template v-slot:cell(consultedBy)="row">
          <span v-if="row.value">{{row.value.id}} - {{row.value.displayName}}</span>
          <span v-else>N/A</span>
        </template>
        <template v-slot:cell(consultedPharmacistName)="row">
          {{row.item.prescribingPharmacist ? row.item.workflow.PRESCRIBING_PHARMACIST_NAME : "N/A"}}
        </template>
        <template v-slot:cell(consultedPharmacyEmail)="row">
          {{row.item.prescribingPharmacist ? row.item.prescribingPharmacist.email : "N/A"}}
        </template>
        <template v-slot:cell(consultedPharmacyPhone)="row">
          {{row.item.prescribingPharmacist ? row.item.prescribingPharmacist.phone : "N/A"}}
        </template>
        <template v-slot:cell(pharmacy)="row">
          <router-link v-if="row.value" :to="{path: '/pharmacy/' + row.value.id }">
              {{row.value.id}} - {{row.value.displayName}} 
          </router-link>
          <span v-else>N/A</span>
        </template>
        <template v-slot:cell(creationDate)="row">{{row.value | datetime}} </template>
        <template v-slot:cell(actions)="row">
          <span v-html="action" @click="sendToParent(row.item.id)"></span>
        </template>
      </b-table>
      <b-pagination v-if="orders.content.length"  align="center" size="md" :total-rows="orders.totalElements" v-model="currentPage" :per-page="orders.size" :first-text="'First'" :prev-text="'Prev'" :next-text="'Next'" :last-text="'Last'"></b-pagination>
      <br>
    </div>
    <div v-else>
      <div class="loadingOrders">Loading...</div>
    </div>
  </b-card>
</template>

<script>
import moment from 'moment'
import OrderEventBus from '../EventBus.js'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import DateRangePickerWrapper from './common/DateRangePickerWrapper.vue';

export default {
  name: 'orders-table',
  components: {
    VueBootstrapTypeahead,
    DateRangePickerWrapper
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    prescriberSearch: {
      type: Boolean,
      default: false
    },
    prescribers: {
      default: () => []
    },
    orders: {
      required: true,
      default: () => {}
    },
    columnNames: {
      default: () => ['id', 'creationDate', 'description', 'patient', 'platform', 'consultedBy', 'pharmacy', 'actions']
    },
    page: {
      type: Number,
      default: 1,
    },
    action: {
      type: String,
      default: '',
    },
    clickEvent: {
      type: String,
      default: ''
    },
    searchAction: {
      type: String,
      default: ''
    },
    filterAction: {
      type: String,
      default: ''
    },
    redStyled: {
      default: function () {
        return ['PAYMENT_FAILED', 'CANCELED_BY_DOCTOR', 'CANCELED_BY_PHARMACY', 'REJECTED_BY_PHARMACY', 'REJECTED_BY_DOCTOR', 'CANCELED_BY_ADMIN']
      }
    }
  },
  watch: {
    orders: {
      handler: function (_orders) { 
        // style rows
        if (_orders) {
          _orders?.content?.forEach(order => {
            if (order.status === 'DELIVERED') {
              order._rowVariant = 'success';
            }
            if (this.redStyled.indexOf(order.status) !== -1) {
              order._rowVariant = 'danger';
            }
          });
          this.setupTableContent(_orders.content);
        }
      }
    },
    currentPage: function () {
      OrderEventBus.$emit('currentPage', this.currentPage);
    },
    selectedPrescriber: function(prescriber) {
      this.$emit("prescriber-changed", prescriber);
    },
    prescriberName: function(name) {
      if (this.selectedPrescriber && !name) {
        this.selectedPrescriber = null;
      }
    }
  },
  data: function () {
    return {
      currentPage: this.page,
      orderIdInput: "",
      orderIdFilterInput: "",
      prescriberName: '',
      selectedPrescriber: null,
      tableContent: [],
      customFilters: ['id', 'pharmacy'],
      filterText: "Show Filters",
      showFilter: false,
      selected: "",
      selectedConsultationType: "",
      pharmacies: [],
      dateRange: {}
    }
  },

  created() {
    this.getPharmacies();
  },

  filters: {
    datetime: function (date) {
      return moment(date).local().format('HH:mm - DD/MM/YY');
    },
    consultationDatetime: function (date) {
      return moment(date).local().format('(DD/MM/YY HH:mm)');
    }
  },

  methods: {
    sendToParent(orderId) {
      OrderEventBus.$emit(this.clickEvent, orderId);
    },

    //Filter by order id
    orderSearch(){
      OrderEventBus.$emit(this.searchAction, this.orderIdInput);
    },

    //pass filter data to filter api
    searchFilter(){
      this.currentPage = 1;
      let data = {
        "orderId": this.orderIdFilterInput ? this.orderIdFilterInput : "",
        "pharmacyId": this.selected ? this.selected : "",
        "startDate": this.dateRange.start ? this.dateRange.start : "",
        "endDate": this.dateRange.end ? this.dateRange.end : "",
        "channel": this.selectedConsultationType ? this.selectedConsultationType : ""
      }
      OrderEventBus.$emit(this.filterAction, data);
    },

    prescriberChanged(prescriber) {
      this.selectedPrescriber = prescriber;
    },

    //Set content for table
    setupTableContent(content){
      this.tableContent = content?.map(order => {
        return {
          id: order.id,
          creationDate: order.creationDate,
          description: order.name,
          status: order.status,
          patient: order.patient,
          platform: order.platform,
          ConsultationType: order.consultationDuration === 'IN_PERSON_MINUTE_00' ? "Pharmacy" : "Online",
          consultedBy: order.consultationDuration === 'IN_PERSON_MINUTE_00' ? order.prescribingPharmacist : order.doctor,
          pharmacy: order.pharmacy,
          isRepeatOrder: order.isRepeatOrder,
          consultationDuration: order.consultationDuration,
          prescribingPharmacist: order.prescribingPharmacist,
          workflow: order.workflow
        }
      })
    },

    //Common filter button
    allOrdersFilterIcon() {
      const currentURL = window.location.href;
      const urlSections = currentURL.split('/');
      const lastSection = urlSections[urlSections.length - 1];
      return lastSection === 'all';
    },

    //Main Filter button click event
    handleFilterButtonClick() {
      if(this.filterText == "Show Filters"){
        this.filterText = "Hide Filters";
        this.showFilter = true;
      }
      else {
        this.filterText = "Show Filters";
        this.showFilter = false;
        this.dateRange = {};
        this.selected = "";
        this.selectedConsultationType = "";
        this.orderIdFilterInput = "";
        this.searchFilter();
      }
    },

    //Get all pharmacies
    getPharmacies() {
      this.$pharmacyService
          .getAll()
          .then((pharmacies) => {
              this.pharmacies = pharmacies.content;
          })
          .catch((error) => {
              this.$awn.alert(error, "An error occurred");
          });
    },

    //Dropdown filter selection
    handleDropdownSelection() {
      this.searchFilter();
    },

    //Date range filter for Creation Date
    getDateRange(data) {
      this.dateRange = data;
      this.searchFilter();
    },

    //Clear pharmacy selection filter
    clearPharmacy() {
      this.selected = "";
      this.searchFilter();
    },

    //clear consultation type filter
    clearConsultationType() {
      this.selectedConsultationType = "";
      this.searchFilter();
    }
  },
}
</script>

<style lang="scss">

.smaller{
    font-size: x-small;
}

.loadingOrders,
.loadingOrders:before,
.loadingOrders:after {
  border-radius: 100%;
  width: 1.5em;
  height: 1.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 2.2s infinite ease-in-out;
  animation: load7 2.2s infinite ease-in-out;
}
.loadingOrders {
  color: #550385;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loadingOrders:before,
.loadingOrders:after {
  content: '';
  position: absolute;
  top: 0;
}
.loadingOrders:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loadingOrders:after {
  left: 3.5em;
}
.filter-button {
  border: 0;
  background: #3d0260;
}

.filter-row-bg {
  background-color: #e4e5e6;
}

.custom-table th {
  white-space: nowrap;
}

.custom-table-header th {
  position: relative;
}

.custom-table-header th:after {
  content: '';
  height: 50%;
  width: 1px;
  position: absolute;
  right: 0;
  top: 25%;
  background-color: #dee2e6;
}

select option {
  background-color: #fff;
  color: #333;
  padding: 5px !important;
}

select option:hover {
  background: #805ad5;
  color: white;
}

select option:checked{
  background-color: #805ad5;
  color: white;
}

.pharmacy-select-parent {
  display: flex;
}

.close-btn {
  background: transparent;
  border: 0;
}

.filter-btn {
  padding: 0.5rem 0.75rem;
}

.orderId-search {
  width: 100px !important;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

</style>
