<template>
  <!-- Navigation window in the center -->
  <b-row>
    <b-col sm="12">
      <b-card>
        <!-- Navigation tabs -->
        <b-nav v-model="activeTab" card class="nav-custom justify-content-center">
          <b-nav-item disabled
            :class="{ 'active': activeTab === 'check-eligibility', 'custom-active': activeTab === 'check-eligibility' }">Eligibility
            Test</b-nav-item>
          <b-nav-item disabled
            :class="{ 'active': activeTab === 'create-account', 'custom-active': activeTab === 'create-account' }">Create
            Account</b-nav-item>
          <b-nav-item disabled
            :class="{ 'active': activeTab === 'booking-appointment', 'custom-active': activeTab === 'booking-appointment' }">Book
            an Appointment</b-nav-item>
        </b-nav>
        <!-- Dynamic component rendering based on active tab -->
        <b-card-body>
          <component :is="activeTabComponent" @next-page-clicked="changeTab"></component>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import CheckEligibility from './CheckEligibility.vue';
import BookingAppointment from './BookingAppointment.vue';
import PatientProfileCreation from './PatientProfileCreation.vue';

export default {
  data() {
    return {
      activeTab: 'check-eligibility',
      activeTabComponent: CheckEligibility
    };
  },
  methods: {
    // Change active tab based on event emitted from child components
    changeTab(tabName) {
      switch (tabName) {
        case 'check-eligibility':
          this.activeTab = 'check-eligibility';
          this.activeTabComponent = CheckEligibility;
          break;
        case 'create-account':
          this.activeTab = 'create-account';
          this.activeTabComponent = PatientProfileCreation;
          break;
        case 'booking-appointment':
          this.activeTab = 'booking-appointment';
          this.activeTabComponent = BookingAppointment;
          break;
        default:
          this.activeTabComponent = null;
          break;
      }
    }
  }
};
</script>

<style scoped>
.custom-active {
  background-color: #e2baf4 !important;
  color: black !important;
}

.nav-custom {
  justify-content: center;
}
</style>
