
<script>
    const PharmacyService = {}

    PharmacyService.install = function (Vue) {
        
        var pharmacyService = new Object ();

        Object.defineProperty(Vue.prototype, '$pharmacyService', { value: pharmacyService });

        pharmacyService.getPharmacy = function (id) {
            var url = '/api/admin/pharmacy/' + id;
            return Vue.axios.get(url)
                .then(response => { return response.data; })
                .catch(error => {
                return this.handleError(error);
            });
        };

        pharmacyService.updatePharmacy = function (pharmacy) {
            var url = '/api/admin/edit-profile/pharmacy';
            return Vue.axios.put(url, pharmacy)
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error);
                    return Promise.reject(error);
            });
        };

        pharmacyService.search = function (searchQuery, page = 0, size = 10) {
            var url;
            if (searchQuery) {
                url = '/api/admin/pharmacy/search?q=' + searchQuery + '&page=' + page + '&size=' + size;
            } else {
                url = '/api/admin/pharmacy/search?page=' + page + '&size=' + size;
            }
            return Vue.axios.get(url)
            .then(response => { return response.data; })
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error);
            });
        };

        pharmacyService.getAll = function () {
            const url = '/api/admin/pharmacy/search';
            return Vue.axios.get(url)
            .then(response => { return response.data; })
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error);
            });
        };

        pharmacyService.searchByPostcode = function (postcode, page = 0, size = 10) {
            var url = '/api/admin/patient/search/by-postcode/' + postcode + '?page=' + page + '&size=' + size + '&sort=id,DESC';
            return Vue.axios.get(url)
            .then(response => { return response.data; })
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error);
            });
        };

        pharmacyService.handleError = function(error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
                // Vue.$router.push('/login')
                console.log('error.request ', error.request)
            } else {
                // Something happened in setting up the request that triggered an Error
                // Vue.$router.push('/login')
                console.log('error', error.request)
            }
        };
    }

    export default PharmacyService;
</script>