<template>
  <orders-table @prescriber-changed="filterByPrescriber($event)" :prescribers="prescribers" :prescriberSearch="true" :title="title" :orders="orders" :page="currentPage" :action="action" :click-event="clickEvent" :column-names="columnNames" :search-action="searchAction"></orders-table>
</template>

<script>

import OrdersTable from '../../components/OrdersTable'
import OrderEventBus from '../../EventBus.js'

export default {
  components: {
    OrdersTable
  },
  data: function () {
    return {
      title: 'Prescriber Orders',
      orders: {},
      currentPage: 1,
      action: "<button class='btn btn-sm btn-outline-primary'>Open Order</button>",
      columnNames: ['id', 'creationDate', 'status', 'name', 'patient', 'doctor', 'pharmacy', 'actions'],
      clickEvent: 'open-order-click',
      newOrderSound: new Audio('/static/message.mp3'),
      timer: '',
      searchAction: "search-order-click",
      selectedPrescriber: null,
      prescribers: []
    }
  },
  props: {
    types: {
      default: () => ['PRESCRIPTION_ONLY']
    }
  },
  mounted () {
    this.getOrders().then(() => {
      this.getPrescribers()
    });
    OrderEventBus.$on('currentPage', (currentPage) => {
      this.currentPage = currentPage;
    })
    OrderEventBus.$on(this.clickEvent, (orderId) => {
      this.$router.push('/order/' + orderId)
    })
    OrderEventBus.$on(this.searchAction, (query) => {
      this.searchOrder(query)
    })
  },
  watch: {
    currentPage: function () {
      this.getOrders();
    },
    selectedPrescriber: function() {
      this.getOrders();
    }
  },
  methods: {
    getPrescribers() {
      this.$prescriberService.search('', 0, 10000).then(prescribers => {
        this.prescribers = prescribers.content;
      })
    },
    getOrders () {
      return this.$orderService.getAllOrders(this.currentPage - 1, 10, this.types, this.selectedPrescriber?.id).then(orders => {
        this.orders = orders;
        this.timer = setTimeout(this.getOrders, 60000);
      }).catch(error => {
        console.error(error);
        this.$awn.alert('There was an error getting orders');
      });
    },

    searchOrder (query) {
      if(query === "") {
        this.getOrders();
        return;
      }

      this.$orderService.searchOrder(query, this.types, this.selectedPrescriber?.id).then(orders => {
        this.orders = {
          content: [orders]
        };
      }).catch(error => {
        console.error(error);
        this.orders = {
          content: []
        };
        // this.$awn.alert(error, 'An error occurred')
      });
    },
    filterByPrescriber(prescriber) {
      this.selectedPrescriber = prescriber;
      this.title = "Prescriber Orders" + (prescriber ? ` - ${prescriber.name}` : '');
    }
  },
  beforeDestroy () {
    clearTimeout(this.timer);
    OrderEventBus.$off();
  }
}

</script>