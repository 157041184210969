<template>
    <div>
        <div class="average-label">
            <h4>Average: {{ getAverage() }}</h4>
        </div>

        <div
            v-for="key in Object.keys(pegForm)"
            :key="key"
            class="question-container"
        >
            <p>{{ pegForm[key] }}</p>
            <span class="score-pill">{{ pegNote[key] }}</span>
        </div>
    </div>
</template>

<script>
import { PEG_FORM } from "../util/questionnaires.js";

export default {
    data() {
        return {
            pegForm: PEG_FORM,
        };
    },
    props: {
        pegNote: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getAverage() {
            if (typeof this.pegNote.average === "string") {
                return this.pegNote.average;
            }

            return this.pegNote.average.toFixed(2);
        },
    },
};
</script>

<style lang="scss" scoped>
.average-label {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.question-container {
    padding: 1rem;
    border-radius: 5px;
    background-color: #ededed;
    margin-bottom: 1rem;

    .score-pill {
        display: block;
        padding: 0.5rem 0;
        background-color: #550385;
        border-radius: 10px;
        width: 3rem;
        text-align: center;
        color: white;
    }
}
</style>
