<template>
  <b-card>

    <div slot="header">
      <i class='fa fa-align-justify'></i> All Pharmacies
    </div>
    

    <b-row class="form-group">
      <b-col sm="12" md="3" offset-md="9">
        <b-input-group size="md">
          <input type="search" class="form-control" id="searchQuery" v-model="searchQuery" @search="search()">
          <b-input-group-append>
            <b-button block variant="primary" class="btn--2" :disabled="!searchQuery" v-on:click="search()"><i class="fa fa-search"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <b-pagination align="center" size="md" :total-rows="pharmacies.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>

    <b-table :items="pharmacies.content" :fields="columnNames" :current-page="1" :per-page="perPage" hover outlined show-empty responsive>
      <template v-slot:cell(active)="row">
        <b-form-checkbox block variant="primary" v-model="row.value" :disabled="true" />
      </template>
      <template v-slot:cell(email)="row">
        <a :href="'mailto:' + row.value" target="_blank">{{row.value}}</a>
      </template>
      <template v-slot:cell(location)="data">
        <a :href="'https://www.google.com/maps/?q=' + data.item.latitude + ',' + data.item.longitude" target="_blank">
          View in map  <i class="fa fa-external-link"></i>
        </a>
      </template>
      <template v-slot:cell(actions)="data">
        <b-dropdown variant="primary" size="sm" center text="Action" class="m-3">
          <b-dropdown-item :to="{ path: '/pharmacy/' + data.item.id}">View / Edit</b-dropdown-item>
          <b-dropdown-item :to="{ path: '/change-pin/pharmacy/' + data.item.id}">Change PIN</b-dropdown-item>
          <b-dropdown-item :to="{ path: '/change-password/pharmacy/' + data.item.id}">Change Password</b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-pagination align="center" size="md" :total-rows="pharmacies.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>

    <br>

  </b-card>
</template>

<script>

import moment from 'moment';

export default {
  data () {
    return {
      searchQuery: '',
      pharmacies: [],
      columnNames: [
        'id', 
        { key: 'active', 'class': 'text-right' } ,	
        { key: 'memberType', 'class': 'capitalized' } ,	
        'displayName', 
        { key:'gphc', label:'GPhC' }, 
        'phone', 
        'email', 
        'location', 
        'actions'
      ],
      currentPage: 1,
      perPage: 10
    }
  },
  filters: {
			dateTime: function (date) {
				return moment(date).local().format('HH:mm - DD/MM/YY');
      }
  },
  created () {
    this.search();
  },
  watch: {
    currentPage: function () {
      this.search();
    }
  },
  methods: {
    search () {
      this.$pharmacyService.search(this.searchQuery, this.currentPage - 1, this.perPage).then(pharmacies => {
        this.pharmacies = pharmacies;
        console.log(pharmacies)
      }).catch(error => {
        this.$awn.alert(error, 'An error occurred')
      });
    }
  }
}
</script>
