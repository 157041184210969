<template>
    <div class="p-1 message-container">
        <b>
            <b-row class="text-center mb-2">
                <b-col cols="6">Question</b-col>
                <b-col cols="6">Answer</b-col>
            </b-row>
        </b>

        <b-row
            v-for="message in convoList"
            :key="message.id"
            class="border-top pt-1 pb-1 pl-2 pr-2"
            :no-gutters="true"
        >
            <b-col cols="6">
                <div class="date-pill">
                    {{ parseDate(message.creationDate) }}
                </div>
                <p>{{ getQuesion(message.questionAnswer) }}</p>
            </b-col>
            <b-col cols="6">
                <div class="date-pill">{{ parseDate(message.updateDate) }}</div>
                <p>{{ getAnswer(message.questionAnswer) }}</p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    props: {
        convoList: {
            type: Array,
            required: true,
        },
    },
    methods: {
        getQuesion(value) {
            return JSON.parse(value).question;
        },
        getAnswer(value) {
            const qna = JSON.parse(value);
            if (qna.answer) {
                return qna.answer;
            }

            return "-";
        },
        parseDate(dateValue) {
            if (!dateValue) {
                return "";
            }
            const date = new Date(dateValue);
            const options = {
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            };
            const formattedDate = date.toLocaleString("en-GB", options);
            return formattedDate;
        },
    },
};
</script>

<style lang="scss" scoped>
.message-container {
    border: 1px solid #e7e3ff;
    border-radius: 5px;

    .border-top {
        border-color: #e7e3ff !important;
    }

    p {
        margin: 0;
    }

    .date-pill {
        width: fit-content;
        background-color: #e1c0ff;
        font-size: 0.8rem;
        padding: 0.2rem 0.4rem;
        border-radius: 7px;
        margin: 0.2rem 0;
    }
}
</style>
