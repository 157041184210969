<template>
    <orders-table
        :title="title"
        :orders="orders"
        :page="currentPage"
        :action="action"
        :column-names="columnNames"
        :click-event="clickEvent"
        :search-action="searchAction"
    ></orders-table>
</template>

<script>
import OrdersTable from "../../components/OrdersTable";
import OrderEventBus from "../../EventBus.js";

export default {
    components: {
        OrdersTable,
    },
    data: function() {
        return {
            title: "Waiting for Pharmacy",
            orders: {},
            currentPage: 1,
            action:
                "<button class='btn btn-sm btn-outline-primary'>View details</button>",
            columnNames: [
                "id",
                "creationDate",
                "status",
                "isRepeatOrder",
                "description",
                "patient",
                "consultedBy",
                "pharmacy",
                "actions",
            ],
            clickEvent: "waiting",
            searchAction: "search-order-click",
        };
    },
    mounted() {
        this.getOrders();
        OrderEventBus.$on("currentPage", (currentPage) => {
            this.currentPage = currentPage;
        });
        OrderEventBus.$on(this.clickEvent, (orderId) => {
            this.$router.push("/order/waiting-for-pharmacy/" + orderId);
        });
        OrderEventBus.$on(this.searchAction, (orderId) => {
            this.searchOrder(orderId);
        });
    },
    watch: {
        currentPage: function() {
            this.getOrders();
        },
    },
    methods: {
        getOrders() {
            this.$orderService
                .getOrdersByStatus(this.currentPage - 1, 10, [
                    "AWAITING_PHARMACY_DISPENSE",
                    "AWAITING_PHARMACY_DISPATCH",
                    "AWAITING_PHARMACY_DELIVER",
                    "WAITING_FOR_PHARMACY_APPROVAL",
                ])
                .then((orders) => {
                    this.orders = orders;
                })
                .catch((error) => {
                    console.log(error);
                    this.$awn.alert("There was an error getting orders");
                });
        },
        searchOrder(searchOrderId) {
            if (searchOrderId === "") {
                this.getOrders();
                return;
            }

            this.$orderService
                .searchOrder(searchOrderId, this.types)
                .then((orders) => {
                    this.orders = {
                        content: [orders],
                    };
                })
                .catch((error) => {
                    console.error(error);
                    this.orders = { content: [] };
                    this.$awn.alert("There was an error getting orders");
                });
        },
    },
    beforeDestroy() {
        OrderEventBus.$off();
    },
};
</script>
