import { render, staticRenderFns } from "./DocumentViewer.vue?vue&type=template&id=3301e3f4&scoped=true"
import script from "./DocumentViewer.vue?vue&type=script&lang=js"
export * from "./DocumentViewer.vue?vue&type=script&lang=js"
import style0 from "./DocumentViewer.vue?vue&type=style&index=0&id=3301e3f4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3301e3f4",
  null
  
)

export default component.exports