<template>
  <div v-if="prescriber.id">

    <b-modal v-model="showAddNewPrescriberDoctorModal" title="Add Prescriber Doctor" centered size="xl" ok-title="Confirm" ok-variant="primary" @ok="addNewPrescriberDoctor($event)" :ok-disabled="addNewPrescriberModalSubmitting">
      <b-row>
      <b-col sm="12">
        <h4>Doctor Information</h4>
        <b-row>
        <b-col cols="6">
        <b-form-group label="First Name *">
          <b-form-input data-vv-scope="doc" type="text" v-validate="{ required: true }" v-model="newPrescriberDoctor.firstName" name="firstName"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('doc.firstName') }}&nbsp;</span>
        </b-form-group>
        <b-form-group label="Last Name *">
          <b-form-input data-vv-scope="doc" type="text" v-validate="{ required: true }" v-model="newPrescriberDoctor.lastName" name="lastName"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('doc.lastName') }}&nbsp;</span>
        </b-form-group>
        <b-form-group label="Email *">
          <b-form-input data-vv-scope="doc" type="text" v-validate="{ required: true }" v-model="newPrescriberDoctor.email" name="email"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('doc.email') }}&nbsp;</span>
        </b-form-group>
        <b-form-group label="Phone *">
          <b-form-input data-vv-scope="doc" type="text" v-validate="{ required: true }" v-model="newPrescriberDoctor.phone" name="phone"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('doc.phone') }}&nbsp;</span>
        </b-form-group>
        <b-form-group label="GMC/RCV/GPhC *">
          <b-form-input data-vv-scope="doc" type="text" v-validate="{ required: true }" v-model="newPrescriberDoctor.gmc" name="gmc"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('doc.gmc') }}&nbsp;</span>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox data-vv-scope="doc" v-model="newPrescriberDoctor.companyAdmin" name="companyAdmin">Grant Admin Access</b-form-checkbox>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('doc.companyAdmin') }}&nbsp;</span>
        </b-form-group>
        <b-form-group label="Doctor Type *">
          <b-form-select data-vv-scope="doc" v-validate="{ required: true }" v-model="newPrescriberDoctor.settings.DOCTOR_TYPE" name="doctorType" :options="doctorTypes"></b-form-select>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('doc.doctorType') }}&nbsp;</span>
        </b-form-group>
        <p class="mt-4 pt-2 text-muted">Fields marked with * are required</p>
        </b-col>
        <b-col cols="6">
        <b-form-group label="Signature *">
          <div class="border mb-1" style="width: 100%; height: 150px; display: flex; justify-content: center; align-content: center;">
            <img v-if="Boolean(signatureFile)" style="max-width: 100%; max-height: 100%; object-fit: contain;" :src="urlOf(signatureFile)">
          </div>
          <b-form-file data-vv-scope="doc" accept="image/png, image/jpeg" v-validate="{ required: true }" v-model="signatureFile" :state="Boolean(signatureFile)" name="signatureUrl"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('doc.signatureUrl') }}&nbsp;</span>
        </b-form-group>
        <b-form-group label="Password *">
          <b-form-input data-vv-scope="doc" type="text" v-validate="{ required: true }" v-model="newPrescriberDoctor.password" name="password"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('doc.password') }}&nbsp;</span>
        </b-form-group>
        <b-form-group label="PIN *">
          <input class="form-control" data-vv-scope="doc" type="text" v-validate="{ required: true }" :value="newPrescriberDoctor.pin" name="pin" placeholder="PIN (Digits Only)" v-on:input="$event.preventDefault(); newPrescriberDoctor.pin = $event.target.value.replace(/[^\d]/g, '');" maxlength="6" minlength="4"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('doc.pin') }}&nbsp;</span>
        </b-form-group>
        </b-col>
        </b-row>
      </b-col>
      </b-row>
    </b-modal>

    <b-modal v-model="newImageModal" title="Update Image" size="md" :ok-disabled="uploadingImage" :cancel-disabled="uploadingImage" :ok-title="uploadingImage ? 'Uploading, Please Wait...' : 'Confirm'" ok-variant="primary" @show="resetImageModal($event)" @ok="putImage($event)" no-close-on-backdrop no-close-on-esc hide-header-close>
      <b-row>
        <b-col>
          <b-form-group id="newImg" label="Select Image" label-for="new_image">
          <div class="border mb-1" style="display: flex; justify-content: center; align-items: center; height: 150px;">
            <img v-if="Boolean(newImage)" style="max-width: 100%; max-height: 100%; object-fit: contain;" :src="urlOf(newImage)">
          </div>
          <b-form-file data-vv-scope="img" accept="image/png, image/jpeg" v-validate="{ required: true }" v-model="newImage" :state="Boolean(newImage)" name="new_image"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('img.new_image') }}&nbsp;</span>
        </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal v-model="previewImageModal" hide-footer title="Preview" size="md">
      <b-row>
        <b-col>
          <div class="border" style="display: flex; justify-content: center; align-items: center; height: 150px;">
            <img v-if="Boolean(previewImage)" style="max-height: 100%; max-width: 100%; object-fit: contain;" :src="previewImage">
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal v-model="previewUpdateAdditionalInfo" title="Update Additional Information" size="md" @ok="updateDoctorAdditionalInfo()" ok-title="Confirm" ok-variant="primary">
      <b-row>
        <b-col>
          <b-form-group label="Area of Qualification" label-for="update_aoq">
            <b-form-input data-vv-scope="doc" type="text" v-validate="{ required: true }" v-model="doctorAdditionalInfoToBeUpdated.settings.AREA_OF_QUALIFICATION" name="update_aoq"/>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <b-card no-title no-body>
      <div class="own-header">
        <b-row>
          <b-col class="display-name" sm="6" md="6">
            <i class="fa fa-align-justify"></i>&nbsp;
            <strong class="header-spacing">Prescriber Information</strong>
          </b-col>
          <b-col sm="6" md="6">
            <b-button v-if="!editable" variant="warning" @click="allowChanges()" class="float-right">Update Prescriber</b-button>
            <b-button v-if="editable" variant="primary" @click="updatePrescriber()" class="float-right">Confirm</b-button>
            <b-button v-if="editable" variant="outline-danger" @click="reset()" class="float-right margin-1-percent">Reset</b-button>
          </b-col>
        </b-row>
      </div>
      <b-card-body>
        <b-row>
          <b-col md="12" lg="6">
            <b-form-group label="Prescriber ID">
              <b-form-input name="id" type="text" v-model="prescriber.id" disabled />
            </b-form-group>
            <b-form-group label="Active">
              <b-form-radio-group v-if="editable" :block="true" button-variant="outline-primary" buttons v-model="prescriber.active" name="active" :options="truthyFalsy"/>
              <b-button-toolbar v-if="!editable">
                <b-button v-bind:variant="prescriber.active ? 'primary' : 'outline-primary'" name="active" type="text" disabled> Yes </b-button>
                <b-button v-bind:variant="!prescriber.active ? 'primary' : 'outline-primary'" name="active" type="text" disabled> No </b-button>
              </b-button-toolbar>
            </b-form-group>
            <b-form-group label="Prescriber Name">
              <b-form-input name="name" v-model="prescriber.name" class="mb-6" :disabled="!editable"/>
              <span v-show="errors.has('name')" class="text-danger">{{ errors.first('name') }}</span>
            </b-form-group>
            <b-form-group label="Email">
              <b-form-input data-vv-scope="prescriber" name="email" type="text" v-model="prescriber.email" v-validate="'required|email'" :disabled="!editable"/>
              <span v-show="errors.has('email')" class="text-danger">{{ errors.first('prescriber.email') }}</span>
            </b-form-group>
            <b-form-group label="Phone">
              <b-form-input data-vv-scope="prescriber" name="phone" type="text" v-model="prescriber.phone" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('phone')" class="text-danger">{{ errors.first('prescriber.phone') }}</span>
            </b-form-group>
          </b-col>

          <b-col md="12" lg="6">
            <b-form-group id="line1" label="Line 1">
              <b-form-input data-vv-scope="prescriber" name="line1" type="text" v-model="prescriber.address.line1" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('line1')" class="text-danger">{{ errors.first('prescriber.line1') }}</span>
            </b-form-group>
            <b-form-group id="line2" label="Line 2">
              <b-form-input name="line2" type="text" v-model="prescriber.address.line2" :disabled="!editable"/>
            </b-form-group>
            <b-form-group id="city" label="City">
              <b-form-input data-vv-scope="prescriber" name="city" type="text" v-model="prescriber.address.city" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('city')" class="text-danger">{{ errors.first('prescriber.city') }}</span>
            </b-form-group>
            <b-form-group id="county" label="County">
              <b-form-input name="county" type="text" v-model="prescriber.address.county" :disabled="!editable"/>
            </b-form-group>
            <b-form-group id="postcode" label="Postcode">
              <b-form-input data-vv-scope="prescriber" name="postcode" type="text" v-model="prescriber.address.postcode" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('postcode')" class="text-danger">{{ errors.first('prescriber.postcode') }}</span>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-title no-body>
      <div class="own-header">
        <b-row>
          <b-col class="display-name" sm="6" md="6">
            <i class="fa fa-align-justify"></i>&nbsp;
            <strong class="header-spacing">Doctors</strong>
          </b-col>
          <b-col sm="6" md="6">
            <b-button :disabled="!editable" variant="primary" @click="showAddNewPrescriberDoctorModal = true" class="float-right">Add Doctor</b-button>
          </b-col>
        </b-row>
      </div>
      <b-card-body>
        <b-table :items="doctors.content" :fields="columnNames" :current-page="1" :per-page="perPage" hover outlined show-empty responsive>
          <template v-slot:cell(firstName)="row">
            <b-input v-if="prescriberDoctorToBeUpdated.id == row.item.id" v-model="prescriberDoctorToBeUpdated.firstName"></b-input>
            <span v-if="prescriberDoctorToBeUpdated.id != row.item.id">{{row.value}}</span>
          </template>
          <template v-slot:cell(lastName)="row">
            <b-input v-if="prescriberDoctorToBeUpdated.id == row.item.id" v-model="prescriberDoctorToBeUpdated.lastName"></b-input>
            <span v-if="prescriberDoctorToBeUpdated.id != row.item.id">{{row.value}}</span>
          </template>
          <template v-slot:cell(email)="row">
            <b-input v-if="prescriberDoctorToBeUpdated.id == row.item.id" v-model="prescriberDoctorToBeUpdated.email"></b-input>
            <a v-if="prescriberDoctorToBeUpdated.id != row.item.id" :href="'mailto:' + row.value" target="_blank">{{row.value}}</a>
          </template>
          <template v-slot:cell(phone)="row">
            <b-input v-if="prescriberDoctorToBeUpdated.id == row.item.id" v-model="prescriberDoctorToBeUpdated.phone"></b-input>
            <span v-if="prescriberDoctorToBeUpdated.id != row.item.id">{{row.value}}</span>
          </template>
          <template v-slot:cell(gmc)="row">
            <b-input v-if="prescriberDoctorToBeUpdated.id == row.item.id" v-model="prescriberDoctorToBeUpdated.gmc"></b-input>
            <span v-if="prescriberDoctorToBeUpdated.id != row.item.id">{{row.value}}</span>
          </template>
          <template v-slot:cell(companyAdmin)="row">
            <b-checkbox v-if="prescriberDoctorToBeUpdated.id == row.item.id" v-model="prescriberDoctorToBeUpdated.companyAdmin"></b-checkbox>
            <span v-if="prescriberDoctorToBeUpdated.id != row.item.id">{{row.value ? 'Yes' : 'No'}}</span>
          </template>
          <template v-slot:[`cell(settings.DOCTOR_TYPE)`]="row">
            <b-form-select v-if="prescriberDoctorToBeUpdated.id == row.item.id" v-model="prescriberDoctorToBeUpdated.settings.DOCTOR_TYPE" :options="doctorTypes"></b-form-select>
            <span v-if="prescriberDoctorToBeUpdated.id != row.item.id">{{getDoctorType(row.value)}}</span>
            <b-button v-if="prescriberDoctorToBeUpdated.id != row.item.id && row.item.settings.DOCTOR_TYPE == 'PRESCRIBING_PHARMACIST'" @click="doctorAdditionalInfoToBeUpdated = {... row.item}; previewUpdateAdditionalInfo = true;" class="mx-1" :disabled="!editable" variant="primary" size="sm" center><i class="fa fa-pencil" aria-hidden="true"></i></b-button>
          </template>
          <template v-slot:[`cell(settings.SIGNATURE_URL)`]="row">
            <b-button variant="primary" size="sm" v-if="row.value" @click="previewImageModal = true; previewImage = row.value;">Preview</b-button>
            <b-button :disabled="!editable" variant="dark" size="sm" @click="newImageModal = true; newImagePath = row.value;">{{row.value ? 'Change' : 'Add'}}</b-button>
          </template>
          <template v-slot:cell(actions)="data">
            <b-button v-if="prescriberDoctorToBeUpdated.id != data.item.id" class="mx-1" :disabled="!editable" @click="prescriberDoctorToBeUpdated = {...data.item};" variant="primary" size="sm" center>Edit</b-button>
            <b-button v-if="prescriberDoctorToBeUpdated.id == data.item.id" class="mx-1" :disabled="!editable" @click="updatePrescriberDoctor()" variant="warning" size="sm" center>Confirm</b-button>
            <b-dropdown class="mx-1" :disabled="!editable" @click="data = data;" variant="outline-primary" size="sm" center text="Actions">
              <b-dropdown-item :to="{ path: '/change-pin/doctor/' + data.item.id}">Change PIN</b-dropdown-item>
              <b-dropdown-item :to="{ path: '/change-password/doctor/' + data.item.id}">Change Password</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination align="center" size="sm" :total-rows="doctors.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>
      </b-card-body>
    </b-card>
    
  </div>
</template>

<script>

export default {
  name: 'Prescriber',
  data: function () {
    return {
      prescriber: {},
      editable: false,
      doctors: {},
      currentPage: 1,
      perPage: 10,
      columnNames: [
        'id', 'firstName', 'lastName', 'email', 'phone',
        {key: 'gmc', label: 'GMC/RCV/GPhC'},
        {key: 'companyAdmin', label: 'Admin'},
        {key: 'settings.DOCTOR_TYPE', label: 'Type'},
        {key: 'settings.SIGNATURE_URL', label: 'Signature'},
        {key: 'actions', label: 'Actions'}
      ],
      doctorTypes: [
        { value: 'DOCTOR', text: 'Doctor' },
        { value: 'VET', text: 'Vet' },
        { value: 'PRESCRIBING_PHARMACIST', text: 'Pharmacist' }
      ],
      // image upload
      uploadingImage: false,
      previewImageModal: false,
      previewImage: null,
      newImageModal: false,
      newImage: null,
      newImagePath: null,
      // add prescriber doctor
      showAddNewPrescriberDoctorModal: false,
      newPrescriberDoctor: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        gmc: '',
        companyAdmin: false,
        settings: {
          DOCTOR_TYPE: 'DOCTOR',
        },
        signatureUrl: '',
        password: '',
        pin: ''
      },
      addNewPrescriberModalSubmitting: false,
      signatureFile: null,
      // update prescriber doctor
      prescriberDoctorToBeUpdated: {settings: {}},
      doctorAdditionalInfoToBeUpdated: {settings: {}},
      previewUpdateAdditionalInfo: false,
      // other options
      truthyFalsy: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ]
    }
  },
  watch: {
    currentPage: function () {
      this.getPrescriberDoctors();
    },
  },
  mounted: function () {
    this.getPrescriber();
  },
  methods: {
    getDoctorType(key) {
      return this.doctorTypes.filter(k => k.value == key)[0].text;
    },
    getPrescriber () {
      return this.$prescriberService.getPrescriber(this.$route.params.prescriberId).then(prescriber => {
        this.prescriber = prescriber;
        this.getPrescriberDoctors();
      }).catch(this.handleError)
    },
    getPrescriberDoctors () {
      this.$prescriberService.getPrescriberDoctors(this.prescriber.id, this.currentPage - 1, this.perPage).then(doctors => {
        this.doctors = doctors;
      }).catch(this.handleError)
    },
    getRandomString () {
      var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      var result = ''
      for (var i = 0; i < 4; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length))
      }
      return result
    },
    addNewPrescriberDoctor (event) {
      event.preventDefault()
      this.addNewPrescriberModalSubmitting = true
      this.$validator.validateAll("doc").then((success) => {
        if (success) {
          var randId = this.getRandomString()
          var signaturePath = `img/doctor-${randId}-signature`
          this.newPrescriberDoctor.settings = {...this.newPrescriberDoctor.settings, SIGNATURE_URL: `https://static.thegpservice.co.uk/${signaturePath}`}
          this.newPrescriberDoctor.username = this.newPrescriberDoctor.email
          this.newPrescriberDoctor.prescriptionCompany = {id: this.prescriber.id}
          this.newPrescriberDoctor.address = {...this.prescriber.address}
          this.$prescriberService.createPrescriberDoctor(this.newPrescriberDoctor).then(res1 => {
            console.log(res1);
            return this.$fileService.uploadFile(this.signatureFile, signaturePath).then(res2 => {
              console.log(res2);
              this.$awn.success('Doctor added successfully')
              this.showAddNewPrescriberDoctorModal = false
              this.newPrescriberDoctor = {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                gmc: '',
                companyAdmin: false,
                settings: {
                  DOCTOR_TYPE: 'DOCTOR',
                },
                signatureUrl: '',
                password: '',
                pin: ''
              }
              this.signatureFile = null
              this.addNewPrescriberModalSubmitting = false
              this.getPrescriberDoctors()
            })
          }).catch(err => {
            this.addNewPrescriberModalSubmitting = false
            this.handleError(err);
          })
        } else {
          this.$awn.alert('Please check the form above for any missing fields or errors.')
          this.addNewPrescriberModalSubmitting = false
        }
      })
    },
    allowChanges() {
      this.editable = true;
      this.truthyFalsy.forEach(option => {
        this.$delete(option, 'disabled');
      })
    },
    reset() {
      this.truthyFalsy.forEach(option => {
        this.$set(option, 'disabled');
      })
      this.editable = false;
      this.getPrescriber();
    },
    updatePrescriber () {
      this.$validator.validateAll("prescriber").then((success) => {
        if (success) {
          return this.$prescriberService.updatePrescriber(this.prescriber).then(() => {
            this.$router.push('/prescribers')
            this.$awn.success('Prescriber updated successfully')
          }).catch(this.handleError);
        } else {
          this.$awn.alert('Please check the form above for any missing fields or errors.'); 
        }
      })
    },
    updatePrescriberDoctor() {
      this.$prescriberService.updatePrescriberDoctor(this.prescriberDoctorToBeUpdated).then(res1 => {
        console.log(res1);
        this.$awn.success('Doctor updated successfully')
        this.prescriberDoctorToBeUpdated = {settings: {}}
        this.getPrescriberDoctors()
      }).catch(this.handleError);
    },
    updateDoctorAdditionalInfo() {
      this.$prescriberService.updatePrescriberDoctor(this.doctorAdditionalInfoToBeUpdated).then(res1 => {
        console.log(res1);
        this.$awn.success('Doctor additional information updated successfully')
        this.doctorAdditionalInfoToBeUpdated = {settings: {}}
        this.getPrescriberDoctors()
      }).catch(this.handleError);
    },
    concatErrorMessage (message) {
      let errorMessage = message.join(". ");
      return errorMessage;
    },
    handleError(error) {
      console.log(error.response)
      if (error.response && error.response.data) {
        let data = error.response.data
        if (Array.isArray(data)){
          this.$awn.alert(this.concatErrorMessage(data)); 
        } else {
          this.$awn.alert(data.message); 
        }
      } else {
        this.$awn.alert('There was a network error'); 
      }
    },
    urlOf (file) {
      return URL.createObjectURL(file)
    },
    resetImageModal (event) {
      console.log(event);
      this.newImage = null
      this.previewImage = null
    },
    putImage (event) {
      event.preventDefault();
      this.uploadingImage = true;
      var key = this.newImagePath.match(/(.*?\/)(img\/.+)$/)[2]
      this.$fileService.uploadFile(this.newImage, key).then(async (res) => {
        console.log(res);
        await this.getPrescriber()
        this.$awn.success('Image Updated')
      }).catch(this.handleError)
      .finally(() => {
        this.uploadingImage = false;
        this.newImageModal = false;
        this.resetImageModal();
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .action-buttons {
    display: flex; 
    justify-content: flex-end;
  }
  .margin-1-percent {
    margin-right:1%
  }
  .btn-group .btn + .btn { 
    background-color: #009344 !important;
    border-color: #009344 !important;
  }
</style>
