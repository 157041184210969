<template>
  <div></div>
  <!-- <div class="sidebar-footer"></div> -->
</template>

<script>
export default {
  name: 'sidebar-footer'
}
</script>
