<template>
  <b-row :no-gutters="true" align-h="start">
    <template v-if="approved === false">
        <img class="image-size mr-2" src="/static/vectors/close.svg" alt="">
        <span><b>{{role}} Rejected</b></span>
    </template>
    <template v-if="approved === true">
        <img class="image-size mr-2" src="/static/vectors/success.svg" alt="">
        <span><b>{{role}} Approved</b> {{description ? `- ${description}` : ''}}</span>
    </template>
    <template v-if="approved === undefined">
        <img class="image-size mr-2" src="/static/vectors/pending.svg" alt="">
        <span><b>{{role}} Pending</b> {{description ? `- ${description}` : ''}}</span>
    </template>
  </b-row>
</template>

<script>
export default {
  props: {
    role: {
      type: String,
      required: true
    },
    approved: {
      type: Boolean || undefined,
    },
    description: {
      type: String,
    }
  }
};
</script>

<style scoped>
.image-size {
    width: 1.3rem;
}
/* Component-specific styles */
</style>