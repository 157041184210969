<template>
  <div class="no-print">
    <ol class="breadcrumb">
      <li>
        <button class="simple-button" @click="sidebarToggle">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </button>
      </li>
      <li
        class="breadcrumb-item"
        v-for="(item, index) in list"
        v-bind:key="index"
      >
        <span class="active" v-if="isLast(index)">{{ showName(item) }}</span>
        <router-link :to="item" v-else>{{ showName(item) }}</router-link>
      </li>
      <b-dropdown class="settings-dropdown" variant="primary" text="Actions">
        <b-dropdown-item @click="changePassword">
          Change Password
        </b-dropdown-item>
        <b-dropdown-item @click="enableTFA">
          Two Factor Authentication
        </b-dropdown-item>
        <b-dropdown-item @click="logout">
          Logout
        </b-dropdown-item>
      </b-dropdown>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    sidebarToggle(e) {
      e.preventDefault();
      document.body.classList.toggle("sidebar-hidden");
    },
    isLast(index) {
      return index === this.list.length - 1;
    },
    showName(item) {
      if (item.meta && item.meta.label) {
        item = item.meta && item.meta.label;
      }
      if (item.name) {
        item = item.name;
      }
      return item;
    },
    logout() {
      this.$userService.blackListToken().then(() => {
        this.$userService.logout().then(() => {
          console.log("You've been logged out successfully");
          this.$router.push("/login");
        });
      });
    },
    changePassword() {
      this.$router.push("/change-password");
    },
    enableTFA() {
      this.$router.push("/two-factor-auth");
    },
  },
};
</script>
