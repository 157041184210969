
<script>
    const FormFormularyService = {}

    FormFormularyService.install = function (Vue) {

        var formFormularyService = new Object ();

        Object.defineProperty(Vue.prototype, '$formFormularyService', { value: formFormularyService });

        formFormularyService.search = function (searchQuery = '', page = 0, size = 10) {
            var url;
            if (searchQuery) {
                url = '/api/admin/form/formulary?q=' + searchQuery + '&page=' + page + '&size=' + size;
            } else {
                url = '/api/admin/form/formulary?page=' + page + '&size=' + size;
            }
            return Vue.axios.get(url)
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error)
                    return Promise.reject(error);
            });
        };

        formFormularyService.updateFormulary = function (formulary) {
            var url = '/api/admin/form/formulary/upsert';
            return Vue.axios.put(url, { medications: formulary })
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error);
                    return Promise.reject(error);
            });
        };

        formFormularyService.deleteFormulary = function (id) {
            var url = '/api/admin/form/formulary/delete/' + id;
            return Vue.axios.delete(url)
                .then(response => { console.log(url, response); return response.data; })
                .catch(error => {
                    this.handleError(error);
                    return Promise.reject(error);
            });
        };

        formFormularyService.handleError = function(error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
                // Vue.$router.push('/login')
                console.log('error.request ', error.request)
            } else {
                // Something happened in setting up the request that triggered an Error
                // Vue.$router.push('/login')
                console.log('error', error.request)
            }
        };
    }

    export default FormFormularyService;
</script>