<template>

    <div>
        <b-row class="mb-6" v-if="!confirmation">
            <div class="col-12 col-sm-6 py-4 px-10 pe-sm-12 divider text-center">
                <h3 class="text-primary fw-bold mb-3 text-center text-sm-start">
                    Appointment Date*
                </h3>
                <p class="fs-6 subtitle text-primary-200 text-center text-sm-start mb-5">
                    Select a date for your appointment.
                </p>
                <div class="react-datepicker custom-calendar">
                    <vue-cal v-if="!isFetching"
                        small
                        hide-view-selector
                        :dblclickToNavigate="false"
                        :time="false"
                        active-view="month"
                        :min-date="minimumDate"
                        :max-date="maximumDate"
                        :selected-date="clickedDateSlot"
                        @cell-click="handleDateSelect"
                        :disable="true"
                        style="width: 50%; height: 100%;">
                    </vue-cal>
                    <div v-if="isFetching" class="loading">Loading...</div>
                </div>
            </div>

            <div class="col-12 col-sm-6 py-4 px-1 ps-sm-6 text-center">
                <h3 class="text-primary fw-bold mb-3 text-center text-sm-start">
                    Appointment Time*
                </h3>
                <p class="fs-6 subtitle text-primary-200 text-center text-sm-start mb-5">
                    Select a time for your appointment.
                </p>
                <div class="time-slot-card card py-4 px-3 mx-auto time-card">
                    <div class="timeslot-header pt-2 mb-4">
                        <button
                            class="bg-transparent p-0 my-auto d-flex border-0"
                            @click="handleClickPrev"
                            type="button"
                        >
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                        </button>

                        <div class="text-primary fw-semibold my-auto">
                            <div v-if="isFetching" class="loading">Loading...</div>
                            <div v-if="!isFetching">{{ selectedDateSlot() }}</div>
                        </div>

                        <button
                            class="bg-transparent p-0 my-auto d-flex border-0"
                            @click="handleClickNext"
                            type="button"
                        >
                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                        </button>
                    </div>

                    <div class="d-flex flex-wrap justify-content-center h-100">
                        <template v-if="timeSlots && !isFetching">
                            <button
                                v-for="(slot, idx) in displayedTimeSlots"
                                :key="idx"
                                :class="`rounded-1 bg-white text-primary text-start fw-light fs-6 p-3 ${slot.id === clickedTimeslot ? 'border-primary-1' : 'border-light'} 'm-20'`"
                                :disabled="isFetching"
                                @click="handleSelectTime(slot.id)"
                                type="button"
                            >
                                {{ slot.formattedTime }}
                            </button>
                        </template>

                        <template v-if="!timeSlots && timeSlots.length <= 0">
                            <div>
                                <b-row>
                                    <p class="fs-6 subtitle text-primary text-center m-auto mb-12">
                                        No available time slots for today. Please
                                        select another day
                                    </p>
                                </b-row>

                                <b-row>
                                    <p class="fs-6 subtitle text-primary text-center m-auto mb-12">
                                        Or
                                    </p>
                                </b-row>

                                <b-row>
                                    <button
                                        class="refresh-btn"
                                        type="button"
                                        @click="loadAvailableAppointmentSlots"
                                    >
                                        Refresh Slots
                                    </button>
                                </b-row>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </b-row>

        <b-row v-if="!confirmation" class="confirm-btn-parent">
            <div class="confirm-btn-div">
                <button
                    class="confirm-btn"
                    type="button"
                    @click="handleSubmit"
                >
                    Confirm Date & Time
                </button>
            </div>
        </b-row>

        <Spinner :isLoading="appointment" :text="loadingText" />

        <BookingConfirmation v-if="confirmation"/>
    </div>
    
</template>
  
<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import publicService from '../../services/PublicService.vue';
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import BookingConfirmation from './BookingConfirmation.vue';
import Spinner from '../../components/common/Spinner.vue';

dayjs.extend(advancedFormat);
  
export default {
    components: {
        VueCal,
        BookingConfirmation,
        Spinner
    },
    data: function () {
      return {
        minimumDate: null,
        maximumDate: null,
        appointmentList: [],
        dateSlots: [],
        isFetching: true,
        values: {},
        root: 0,
        SHOWING_TIME_SLOTS: 5,
        date: null,
        time: null,
        confirmation: false,
        clickedTimeslot: null,
        clickedDateSlot: null,
        loadingText: "Loading...",
        appointment: false
      }
    },
    computed: {
        selectedDate: function() {
            return this.updateSelectedDate();
        },

        timeSlots: function() {
            return this.updatedTimeSlots();
        },

        displayedTimeSlots() {
            return this.timeSlots.slice(this.root * this.SHOWING_TIME_SLOTS, this.root * this.SHOWING_TIME_SLOTS + this.SHOWING_TIME_SLOTS);
        }
    },
    created() {
        this.loadAvailableAppointmentSlots();
    },
    methods: {
        //Date select method from calendar widget
        //Ticket: CG-319
        //Author: Sarmilan
        handleDateSelect(selectedDate) {
            // this.values = { 'date': selectedDate };
            this.clickedDateSlot = selectedDate;
            this.date = new Date(selectedDate)
        },

        //Display selected date on UI
        //Ticket: CG-319
        //Author: Sarmilan
        selectedDateSlot() {
            if(this.clickedDateSlot !== null){
                return dayjs(this.clickedDateSlot).format("dddd DD, MMMM YYYY");
            }
        },

        //Get all available time slots
        //Ticket: CG-319
        //Author: Sarmilan
        loadAvailableAppointmentSlots() {
            this.isFetching = true;
            publicService.getAppointments(0, 320, this.isRepeatOrder)
            .then(response => {
                this.isFetching = false;
                if (response.success) {
                    if (response.data?.length > 0) {
                        this.appointmentList = response.data;
                        this.dateSlots = this.getDateSlots(this.appointmentList);
                        if(this.dateSlots.length > 0){
                            this.maximumDate = new Date(this.dateSlots[this.dateSlots.length - 1]);
                            this.minimumDate = new Date(this.dateSlots[0]);
                            this.clickedDateSlot = this.minimumDate;
                        }
                    }
                }
            })
            .catch(error => {
                this.$awn.alert("Error loading appointment slots");
                console.error("Error loading appointment slots:", error);
                this.isFetching = false;
            });
        },

        //Extract available dates from above api call
        //Ticket: CG-319
        //Author: Sarmilan
        getDateSlots(data){
            let arr = [];
            data.forEach(({ datetime }) => {
                const date = dayjs(datetime);
                const formattedDate = `${date.year()}-${date.month() + 1}-${date.date()}`;

                arr.push(formattedDate);
            });

            let unique = [...new Set(arr)];
            return unique;
        },

        formatTime(datetime) {
            return dayjs(datetime).format('hh:mma');
        },

        //On time slot pannel
        //Ticket: CG-319
        //Author: Sarmilan
        handleClickPrev() {
            if (this.root > 0) {
                this.root = this.root - 1;
            }
        },

        //On timeslot pannel
        //Ticket: CG-319
        //Author: Sarmilan
        handleClickNext() {
            if (this.timeSlots.length / (this.root + 1) > this.SHOWING_TIME_SLOTS) {
                this.root = this.root + 1;
            }
        },

        //Time slot selection
        //Ticket: CG-319
        //Author: Sarmilan
        handleSelectTime(id) {
            // this.values = { 'id': id };
            this.clickedTimeslot = id;
        },

        //Convert date based on selected dates
        //Ticket: CG-319
        //Author: Sarmilan
        updateSelectedDate() {
            this.root = 0;
            if(this.appointmentList.length > 0){
                if(this.clickedDateSlot){
                    return dayjs(this.clickedDateSlot).format("YYYY-MM-DD");
                }
                return dayjs(this.appointmentList[0].datetime).format("YYYY-MM-DD");
            }

            return null;
        },

        //Extract timeslots based on selected dates
        //Ticket: CG-319
        //Author: Sarmilan
        updatedTimeSlots() {
            const selectedDateTimeSlots = this.appointmentList.filter(({ datetime }) => {
                const date = dayjs(datetime);
                const formattedDate = `${date.year()}-${date.month() + 1}-${date.date()}`;
                return !!dayjs(this.selectedDate).isSame(dayjs(formattedDate), "day");
            });

            return selectedDateTimeSlots.map(slot => ({
                ...slot,
                formattedTime: this.formatTime(slot.datetime)
            }));
        },

        //Create appointment
        //Ticket: CG-319
        //Author: Sarmilan
        handleSubmit() {
            let patientID = localStorage.getItem('patientID') ? localStorage.getItem('patientID') : '';

            if(this.clickedTimeslot){
                this.appointment = true;
                let data = {
                    'patientId': patientID,
                    'appointmentId': this.clickedTimeslot.toString()
                }

                this.$orderService.getPatientOrders(patientID).then(orders => {
                    if(orders?.numberOfElements == 0){
                        publicService.tempAppointment(data).then( response => {
                            if(response && response.error){
                                this.appointment = false;
                                this.$awn.alert(response.error);
                            }
                            if(response && response.message && response.message == "Partial appointment created successfully"){
                                this.appointment = false;
                                this.$awn.success(response.message);
                                let data = {
                                    "patientName": response.patientName,
                                    "doctorName": response.doctorName,
                                    "consultationDate": response.consultationDate,
                                    "message": response.message
                                }
                                localStorage.setItem('appointmentDetails', JSON.stringify(data));
                                this.confirmation = true;
                            }
                            else {
                                this.appointment = false;
                                this.$awn.alert("Cannot create appointment at the moment!");
                                this.confirmation = false;
                            }
                        })
                        .catch(error => {
                            console.log("error: ", error)
                            this.appointment = false;
                            this.confirmation = false;
                            this.$awn.alert(error);
                        })
                    }
                    else {
                        this.appointment = false;
                        this.$awn.alert('This patient has previous appointments with treat-it clinic. Please direct the patient to help desk');
                    }
                }).catch(error => {
                    this.appointment = false;
                    console.error(error);
                    this.$awn.alert('There was an error getting orders');
                });
            }
            else {
                this.appointment = false;
                this.$awn.alert("Please choose any timeslot to continue");
            }
        }
    },
    watch: {
    }
}
</script>
  
<style scoped lang="scss">

    .divider {
        border-right: 1px solid #2a00431a;
        height: 60vh;

        @media (max-width: 575px) {
            border: unset;
        }
    }

    .custom-calendar {
        border: none;
        border-radius: 8px;
        font-family: Titillium Web;
        padding: 1.5rem 1.5rem;
    }

    .react-datepicker {
        background-color: #fff;
        border: 1px solid #aeaeae;
        border-radius: 0.3rem;
        color: #000;
        display: inline-flex;
        font-family: Helvetica Neue, helvetica, arial, sans-serif;
        font-size: .8rem;
        position: relative;
        width: 80%;
        height: 70%;
        text-align: -webkit-center;
    }

    .time-slot-card {
        border-radius: 8px;

        .btn-white {
            border: 1px solid #bab3bf;
            box-shadow: none;

            &:hover {
                border: 1px solid #8754af;
            }
        }
    }

    .loading {
        align-self: center;
        width: 100%;
        font-weight: bold;
    }

    .timeslot-header {
        display: flex !important;
        flex-direction: row;
        justify-content: space-around;
    }

    .rounded-1 {
        border-radius: 8px !important;
        margin: 10px !important;
        height: fit-content;
    }

    .border-light {
        border: 1px solid #bab3bf !important;
    }

    .time-card {
        width: 60%;
        align-self: center;
    }

    .confirm-btn-parent {
        justify-content: center;
        margin-top: 5%;
    }

    .confirm-btn-div {
        display: flex;
        justify-content: center;
    }

    .confirm-btn {
        border: 2px solid #fff;
        border-radius: 8px !important;
        font-size: 1.25rem !important;
        font-weight: 600 !important;
        padding: 16px 30px !important;
        background: #550385;
        color: #fff;
    }

    .refresh-btn {
        border: 1px solid #bab3bf;
        border-radius: 8px !important;
        margin: 10px auto auto auto;
        padding: 12px 20px !important;
        background: transparent;
        color: #550385;
    }

    ::v-deep .vuecal__cell--selected {
        background-color: #805ad5 !important;
        color: #fff !important;
    }

</style>