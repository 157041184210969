
<script>
  const EnterpriseService = {}

  EnterpriseService.install = function (Vue) {

    var enterpriseService = {}

    Object.defineProperty(Vue.prototype, '$enterpriseService', { value: enterpriseService })

    enterpriseService.getEnterprise = function (id) {
      var url = '/api/admin/enterprise/' + id
      return Vue.axios.get(url)
        .then(response => { return response.data })
        .catch(error => {
          this.handleError(error)
          return Promise.reject(error)
        })
    }

    enterpriseService.createEnterprise = function (enterprise) {
      var url = '/api/admin/enterprise/'
      return Vue.axios.post(url, enterprise)
        .then(response => { return response.data })
        .catch(error => {
          this.handleError(error)
          return Promise.reject(error)
        })
    }

    enterpriseService.editEnterprise = function (enterprise) {
      var url = '/api/admin/enterprise/' + enterprise.id
      var body = {...enterprise}
      delete body.id
      delete body.pkg.id
      delete body.pkg.total
      return Vue.axios.post(url, body)
        .then(response => { return response.data })
        .catch(error => {
          this.handleError(error)
          return Promise.reject(error)
        })
    }

    enterpriseService.search = function (searchQuery, page = 0, size = 10) {
      var url
      if (searchQuery) {
        url = '/api/admin/enterprise/search?q=' + searchQuery + '&page=' + page + '&size=' + size
      } else {
        url = '/api/admin/enterprise/search?page=' + page + '&size=' + size
      }
      return Vue.axios.get(url)
        .then(response => { return response.data })
        .catch(error => {
          this.handleError(error)
          return Promise.reject(error)
        })
    }

    enterpriseService.handleError = function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      } else if (error.request) {
        // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
        // Vue.$router.push('/login')
        console.log('error.request ', error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        // Vue.$router.push('/login')
        console.log('error', error.request)
      }
    }
  }

  export default EnterpriseService
</script>