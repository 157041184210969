<template>
    <div>
        <h4>The patient has confirmed that:</h4>
        <div
            v-for="key in Object.keys(consentForm)"
            :key="key"
            class="mb-4 d-flex consent-container"
        >
            <img src="/static/vectors/success.svg" alt="" />
            <span>{{ consentForm[key] }}</span>
        </div>
    </div>
</template>

<script>
import { CONSENT_FORM } from "../util/questionnaires.js";

export default {
    data() {
        return {
            consentForm: CONSENT_FORM,
        };
    },
};
</script>

<style lang="scss" scoped>
.consent-container {
    column-gap: 0.5rem;
    align-items: flex-start;

    img {
        width: 1.2rem;
    }
}
</style>
