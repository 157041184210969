<template>
    <div class="surgery-card p-4" @click="handleClickInternal">
        <p class="title">{{ title }}</p>
        <div v-if="phone" class="d-flex row-items">
            <i class="fa fa-phone" aria-hidden="true"></i>
            <p class="my-auto ml-2">{{ phone }}</p>
        </div>
        <div v-if="address" class="d-flex mt-2 row-items">
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            <p class="my-auto text-truncate ml-2">{{ address }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        phone: String,
        address: String,
        onClick: Function,
    },
    methods: {
        handleClickInternal() {
            if (this.onClick) {
                this.onClick();
            }
        },
    },
};
</script>

<style scoped>

    .surgery-card {
        background: #fff;
        transition: 0.1s;
        border-radius: 8px;
        border: none;
        box-shadow: 0px 1px 4px 0px #989393;
        margin: 7px;
        cursor: pointer;
    }

    .row-items {
        align-items: center;
    }

    .title {
        text-align: left;
        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 1rem;
        --bs-text-opacity: 1;
        color: rgba(85, 3, 133);
    }

</style>